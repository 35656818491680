import axios from "../util/axios";

export const fetchPendingBookingStatus = async (bookingData) => {
  const response = await axios.post(
    `admin/v1/clinic/check-booking-status`,
    bookingData,
  );
  if (response.data.retry) {
    throw new Error("Response flag is false");
  }
  return response.data;
};
