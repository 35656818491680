import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import FirstVisitAppointmentProcedures from "./FirstVisitAppointmentProcedures";
import DentalHistoryChecking from "./DentalHistoryChecking";
import CleaningPhotos from "./CleaningPhotos";
import AlignerTreatmentProcess from "./AlignerTreatmentProcess";

const FirstVisitAppointment = ({ align }) => {
  const { t } = useTranslation();

  return (
    <Layout isProfile={true}>
      <div
        className="learning_container"
        style={{ backgroundColor: "transparent" }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 2, textAlign: "center", paddingTop: "100px" }}
          component="h1"
        >
          {t("basma-first-visit-in-the-clinic")}
        </Typography>
        <FirstVisitAppointmentProcedures align={align} isPage={false} />
        <DentalHistoryChecking align={align} isPage={false} />
        <CleaningPhotos align={align} isPage={false} />
        <AlignerTreatmentProcess align={align} isPage={false} />
      </div>
    </Layout>
  );
};

export default FirstVisitAppointment;
