import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    dropdownContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "0.5rem",
        marginTop: "0.5rem"
    },
}));
