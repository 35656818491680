import { makeStyles } from "@material-ui/core";

export const AppointmentsTableStyles = makeStyles({
  btn: {
    height: "32px",
    width: "fit-content",
    borderRadius: "4.7px",
    backgroundColor: "#FF2C46",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF2C46",
    },
  },
  typeIcon: {
    color: "#fff",
    fontSize: "0.5rem !important",
    padding: "0.2rem 0.5rem",
    textAlign: " center",
    borderRadius: "10rem",
    marginRight: "0.2rem",
  },
  submissionStatusEn: {
    marginLeft: "12px",
  },
  submissionStatusAr: {
    marginRight: "12px",
  },
  appointmentTableProfile: {
    "& .MuiTableCell-alignCenter:nth-child(1)": {
      textAlign: "center !important",
    },
  },
  proceduresBox: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "0.3rem",
  },
  proceduresColumn: {
    minWidth: "220px",
    "@media (max-width: 576px)": {
      minWidth: 0,
    },
  },
  submissionStatusColumn: {
    maxWidth: "100px"
  }
});
