import EnhancedTable from "../DataTable/EnhancedTable";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { headCells } from "./need-rescheduling-table-properties";
import axios from "../../util/axios";
import { TabsControllerContext } from "../../Context/Tabs/TabsProvider";
import {
  clinicImpersonated,
  convertNumberToArabic,
  handleReadNotes,
} from "../../util/functions";
import Edit from "../Note/EditNote";
import Note from "../Note/Note";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import ActiveContext from "../../Context/Context";
import { useStyles } from "./need-rescheduling.styles";
import { Alert } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function NeedRescheduling() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    openNote,
    actions: { setOpenNote, setPendingBooking },
  } = useContext(TabsControllerContext);

  const { setFilesImage, imagesKey, setImageKey, setImages } =
    useContext(ActiveContext);

  // Data for the table
  const [data, setData] = useState([]);

  const [openEditNote, setOpenEditNote] = useState(0);
  const [editNote, setEditNote] = useState({});
  const [notes, setNotes] = useState([]);
  const [notificationLoading, setNotificationLoading] = useState(true);
  const [openAddNote, setOpenAddNote] = useState(0);
  const [newNote, setNewNote] = useState("");

  // Page and per page for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  // For sorting
  const [field, setField] = useState("");

  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const tableColumns = headCells(t);

  const refreshPage = () => {
    window.location.reload();
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setMessage("");
  };

  const handleOutsideClick = () => {
    setOpenNote(0);
    setOpenAddNote(0);
    setOpenEditNote(0);
    setNewNote("");
    setEditNote({});
    setFilesImage([]);
    setImageKey([]);
    setImages([]);
  };
  const handleClose = () => {
    if (openEditNote) {
      setNotificationLoading(true);
      setOpenEditNote(0);
      setEditNote({});
    } else {
      setNotes([]);
      setNewNote("");
      setOpenAddNote(0);
    }
    setFilesImage([]);
    setImageKey([]);
    setImages([]);
  };

  const handleConfirmReschedule = async (row) => {
    setLoading(true);
    const name = row.patient_name.split(" ");
    const lastName = name.pop();
    const firstName = name.shift();
    const email = row.user_email;
    const phone = row.phone_number;
    let sentId = row.id ?? "";
    let isAppointment = true;
    let scn = row.scn ?? "";
    try {
      const clinicId = Cookies.get("clinic_id");
      const query = `id=${sentId}&isAppointment=${isAppointment}&scn=${scn}&clinicId=${clinicId}&isReschedule=true`;
      const response = await axios.get(
        `admin/v1/clinic/${clinicId}/reschedule/booking/${row.user_id}?${query}`,
      );
      if (response?.data?.error) {
        setOpenAlert(true);
        setError(true);
        setMessage(t("This-update-is-not-eligible"));
      } else if (response.data.eligible === true) {
        const pendingBookingId = response?.data?.pendingBookingId;
        setPendingBooking({ id: pendingBookingId, isReschedule: true });
        const query = `id=${row.user_id}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}${row.id ? "&usiId=" + row.id : ""}`;
        navigate(`/dashboard/booking?${query}`);
      } else {
        setOpenAlert(true);
        setMessage(t("This-user-is-not-eligible-to-book-a-scan"));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleConfirm = async () => {
    if (openEditNote) {
      //update before closing edit modal
      let body = { comment: editNote, images: imagesKey };
      try {
        setNotificationLoading(true);
        const res = await axios.post(
          `/admin/v1/users/${openNote}/comments/${openEditNote}`,
          body,
        );
        if (res.data.success) {
          console.log("updated");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setNotificationLoading(false);
      }
      setNotificationLoading(true);
      setOpenEditNote(0);
      setEditNote({});
    } else {
      if (newNote) {
        let body = { comment: newNote, images: imagesKey };
        try {
          setNotificationLoading(true);
          const res = await axios.post(
            `/admin/v1/users/${openNote}/comments`,
            body,
          );
          if (res.data.success) {
            console.log("added");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setOpenAddNote(0);
          setNewNote("");
        }
      }
    }
    setFilesImage([]);
    setImageKey([]);
    setImages([]);
  };

  const fetchNeedRescheduling = async () => {
    setLoading(true);
    const clinicId = Cookies.get("clinic_id");
    try {
      const response = await axios.get(
        `/admin/v1/clinic/appointments/need-rescheduling?clinic_id=${clinicId}&page=${page}&per_page=${perPage}`,
      );
      if (response.data) {
        setData(response.data.data);
        setTotal(response.data.total);
      }
    } catch (err) {
      console.log("Error fetching need rescheduling", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (!openNote) {
      setNotes([]);
      setOpenEditNote(0);
      setEditNote({});
      return; //not chosen a note
    }
    if (!openEditNote) {
      //not chosen a comment to edit but chosen a user to view the note
      try {
        setNotificationLoading(true);
        const res = await axios.get(`/admin/v1/users/${openNote}/comments`);
        if (res.data.success) {
          setNotes(res.data.data.data);
          const newData = handleReadNotes(
            data,
            openNote,
            "unread_comments",
            false,
          );
          setData(newData);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setNotificationLoading(false);
      }
    } else {
      //chosen a comment to edit display it
      try {
        setNotificationLoading(true);
        const res = await axios.get(
          `/admin/v1/users/${openNote}/comments/${openEditNote}`,
        );
        if (res.data.success) {
          setEditNote(res.data.data.comment);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setNotificationLoading(false);
      }
    }
  }, [openNote, openEditNote, openAddNote]);

  useEffect(() => {
    void fetchNeedRescheduling();
  }, [page, perPage, field]);

  return loading || !page ? (
    <Box className={classes.loadingBox}>
      <CircularProgress className={classes.loading} />
    </Box>
  ) : (
    <Box className={classes.boxWrapper}>
      {openAlert && (
        <Alert
          open={openAlert}
          className={classes.alert}
          severity="error"
          onClose={handleCloseAlert}
        >
          <Box className={classes.alertBox}>
            {message}
            {error && (
              <Button
                variant="outlined"
                onClick={refreshPage}
                color="inherit"
                size="small"
              >
                {t("refresh")}
              </Button>
            )}
          </Box>
        </Alert>
      )}
      <Box className={`${classes.box} ${classes.filterWrapper}`}>
        <Typography variant="body1" className={classes.appointment}>
          {convertNumberToArabic(t, total)} {t("Appointment")}
          {total === 0 || total > 1 ? (i18n.language === "ar" ? "" : "s") : ""}
        </Typography>
      </Box>
      <Box className={classes.box}>
        {data && (
          <EnhancedTable
            rowsData={data}
            headCells={tableColumns}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            handleOpenDialog={handleConfirmReschedule}
            setField={setField}
            total={total}
            setOpenNote={setOpenNote}
          />
        )}
      </Box>
      <ConfirmationDialog
        open={openNote > 0}
        handleOutsideClick={handleOutsideClick}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        notes={true}
        confirmationButtonText={openEditNote || openAddNote ? t("Save") : ""}
        cancelButtonText={openEditNote || openAddNote ? t("Cancel") : ""}
        dialogTitle={
          openEditNote
            ? t("Edit-Note")
            : openAddNote
              ? t("Add-Note")
              : t("Notes")
        }
        dialogContentText=""
        loading={notificationLoading}
      >
        {openEditNote ? (
          <Edit data={editNote} setData={setEditNote} />
        ) : openAddNote ? (
          <Edit data={newNote} setData={setNewNote} />
        ) : (
          <>
            <Note
              setOpenEditNote={setOpenEditNote}
              data={notes}
              setnotificationLoading={setNotificationLoading}
            />
            <Button
              disabled={clinicImpersonated()}
              className={`${classes.addNote} ${i18n.language === "ar" ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}
              color="secondary"
              variant="contained"
              onClick={() => {
                setOpenAddNote(1);
              }}
            >
              {t("Add-Note")}
            </Button>
          </>
        )}
      </ConfirmationDialog>
    </Box>
  );
}

export default NeedRescheduling;
