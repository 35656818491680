import { makeStyles } from '@material-ui/core'
import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
const useStyles = makeStyles(()=>{return {
    iconAlign:{
        fontSize:'1.8rem',
        transform:"translateX(-50%)"
    },
    checkIcon:{
        color:'green',
    },
    closeIcon:{
        color:'red',
    },
    attentionIcon:{
        color: '#FCBD17'
    },
}})
export default function IconCheckView({value}) {
    const classes = useStyles();
  return (
    <>
        {parseInt(value) == -1 ?
            <WarningIcon className={`${classes.iconAlign} ${classes.attentionIcon}`}/> 
            :value ? 
            <CheckIcon className={`${classes.iconAlign} ${classes.checkIcon}`}/> 
            : 
            <CloseIcon className={`${classes.iconAlign} ${classes.closeIcon}`}/>
        }
    </>
  )
}
