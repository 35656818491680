import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as UpperTeeth } from '../../assets/images/fv-info-details/upper-teeth.svg';
import { ReactComponent as LowerTeeth } from '../../assets/images/fv-info-details/lower-teeth.svg';

const TeethComponent = ({
    type,
    handleClick,
    ids,
    onTagClick,
    title,
}) => {
    useEffect(() => {
        ids.forEach(id => {
            document.getElementById(id).style.fill = '#fea3a3';
        });
    }, [ids])

    return (
        <div className='additional-instructions-teeth-wrapper'>
            <p className='additional-instructions-teeth-title'>{title}</p>
            <div className='additional-instructions-teeth-svg-div'>
                {
                    type === 'upper' ?
                    <UpperTeeth onClick={(e) => handleClick(e, type)} className='additional-instructions-teeth-svg' />
                    :
                    <LowerTeeth onClick={(e) => handleClick(e, type)} className='additional-instructions-teeth-svg' />
                }
                <div className='additional-instructions-teeth-direction-container'>
                    <p className='additional-instructions-teeth-direction'>R</p>
                    <p className='additional-instructions-teeth-direction'>L</p>
                </div>
            </div>
            <div className='additional-instructions-tags-wrapper'>
                {
                    ids.map((id, key) => {
                        return (
                            <div key={key} className='additional-instructions-tag-container'>
                                <p className='additional-instructions-tag'>{id}</p>
                                <div className='additional-instructions-tag-close' onClick={() => onTagClick(type, id)}>
                                    <CloseIcon fontSize='small' />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TeethComponent