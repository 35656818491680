import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";

function IPRIntro({ align, isPage = true }) {
  const { t } = useTranslation();

  const Component = ({ t, align }) => {
    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };

    const title = { fontSize: "14px", mb: 2 };

    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("ipr_2") }} />
          </Typography>
        </Typography>
      </div>
    );
  };

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} />
    </Layout>
  ) : (
    <Component t={t} align={align} />
  );
}

export default IPRIntro;
