import { useStyles } from "./loading.styles";
import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

function Loading() {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.loadingBox}>
        <CircularProgress className={classes.loading} />
      </Box>
    </div>
  );
}

export default Loading;
