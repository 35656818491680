import ProcedureHeader from "./procedure-header.component";
import { Box, Button, makeStyles } from "@material-ui/core";
import { clinicImpersonated } from "../../util/functions";
import React, { useCallback, useEffect, useState } from "react";
import { TextField, TextStyle } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import axios from "../../util/axios";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
  };
});
const Checkup = ({
  data = { data: {}, isAllProvided: null },
  appointmentId,
  userId,
  appointmentType,
  handleTabsExpand,
  index,
  openCloseTabs,
  scrollToExpandedTab,
}) => {
  if (data?.data === null) {
    data.data = { information: "" };
  }
  const {
    data: { information: informationData },
    isAllProvided,
  } = data;
  const { procedure } = appointmentType;
  const title = procedure;
  const classes = useStyles();
  const { t } = useTranslation();
  const [information, setInformation] = useState(informationData);
  const [iconInformation, setIconInformation] = useState(isAllProvided);
  const [success, setSuccess] = useState(false);
  const [informationErrorMessage, setInformationErrorMessage] = useState("");
  const handleChangeInformationValue = useCallback(
    (newValue) => setInformation(newValue),
    []
  );

  const handleSave = async () => {
    if (informationErrorMessage === "") {
      const data = { user_scan_info_id: appointmentId, procedure, information };
      const url = `/admin/v2/users/${userId}/checkup-information`;
      try {
        const response = await axios.post(url, data);
        if (response.status === 200) {
          setSuccess(true);
          handleIconInformationView(response?.data?.isAllProvided);
        }
      } catch (error) {
        console.log(error);
        setSuccess(false);
        setInformationErrorMessage("Something Wrong Happened");
      }
    }
  };

  const handleIconInformationView = (value) => {
    setIconInformation(value);
  };

  useEffect(() => {
    scrollToExpandedTab(index, "checkup");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="checkup"
      headerTitle={title}
      index={index}
      iconCheck={iconInformation}
    >
      {openCloseTabs[index]?.checkup && (
        <Box className={`${classes.wrapper} checkup-${index}`}>
          <TextField
            label={t(title)}
            value={information}
            onChange={handleChangeInformationValue}
            multiline={4}
            autoComplete=""
          />
          <TextStyle variation="negative">{informationErrorMessage}</TextStyle>
          <Button
            disabled={clinicImpersonated()}
            className={classes.saveBtn}
            onClick={handleSave}
          >
            {success ? t("Saved") : t("Save")}
          </Button>
        </Box>
      )}
    </ProcedureHeader>
  );
};

export default Checkup;
