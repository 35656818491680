import { Box, makeStyles, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MultipleUploadPhotos from './MutipleUploadPhotos'

const useStyles=makeStyles((theme)=>{ return {
    field:{
    }
}})

function EditNote({setData,data}) {
const { t } = useTranslation()
 const classes=useStyles()
  return (
    <Box>
     <TextField  placeholder={t('Leave-a-comment...')} variant="outlined" fullWidth className={classes.field} multiline={true} rows={5}  value={data} onChange={(e)=>{setData(e.target.value)}} /> 
     <MultipleUploadPhotos/>
    </Box>
  )
}

export default EditNote