import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import IPRIntro from "./IPRIntro";
import IPRFaqs from "./IPRFaqs";

function InterProximalReduction({ align }) {
  const { t } = useTranslation();
  return (
    <Layout isProfile={true}>
      <div className="learning_container">
        <Typography
          variant="h4"
          sx={{ mb: 2, textAlign: "center", paddingTop: "100px" }}
          component="h1"
        >
          {t("ipr_1")}
        </Typography>

        <IPRIntro align={align} isPage={false} />
        <IPRFaqs align={align} isPage={false} />
      </div>
    </Layout>
  );
}

export default InterProximalReduction;
