import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";

function IPRAttachmentsButtonsProtocol({ align, isPage = true }) {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  const Component = ({ t, align, isArabic }) => {
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };

    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };

    const title = { fontSize: "14px", mb: 2 };

    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_92")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_93")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_94")}</li>
            <br />
            <li>{t("clinic_treatment_95")}</li>
            <br />
            <li>{t("clinic_treatment_96")}</li>
            <br />
            <li>{t("clinic_treatment_97")}</li>
            <br />
            <li>{t("clinic_treatment_98")}</li>
            <br />
            <li>{t("clinic_treatment_99")}</li>
            <br />
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies11.png"
              alt=""
              className="doc_img"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies12.png"
              alt=""
              className="doc_img"
            />
          </div>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_100")}</li>
            <br />
            <li>{t("clinic_treatment_101")}</li>
            <br />
            <li>{t("clinic_treatment_102")}</li>
            <br />
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies13.png"
              alt=""
              className="doc_img"
            />
          </div>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_103")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies14.png"
              alt=""
              className="doc_img"
            />
          </div>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_104")}
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#A31C1C" }}>{t("clinic_treatment_105")}</p>
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_106")}</li>
            <br />
            <li>{t("clinic_treatment_107")}</li>
            <br />
            <li>{t("clinic_treatment_108")}</li>
            <br />
            <li>{t("clinic_treatment_109")}</li>
            <br />
            <li>{t("clinic_treatment_110")}</li>
            <br />
            <li>{t("clinic_treatment_111")}</li>
            <br />
            <li>{t("clinic_treatment_112")}</li>
            <br />
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies15.png"
              alt=""
              className="doc_img"
            />
          </div>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_113")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_114")}</li>
            <br />
            <li>{t("clinic_treatment_115")}</li>
            <br />
            <li>{t("clinic_treatment_116")}</li>
            <br />
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies16.png"
              alt=""
              className="doc_img"
            />
          </div>
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#FF0000" }}>{t("clinic_treatment_117")}</p>
          </Typography>
          <br />
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_118")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_119")}</li>
            <br />
            <li>{t("clinic_treatment_120")}</li>
            <br />
            <li>{t("clinic_treatment_121")}</li>
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <ol style={unorderedListStyle}>
            <li>
              {t("clinic_treatment_122")}
              <div
                style={{
                  display: "flex",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                }}
              >
                <img
                  src="/assets/images/clinicpolicies17.jpg"
                  alt=""
                  className="doc_img"
                />
              </div>
            </li>
            <br />
            <li>
              {t("clinic_treatment_123")}
              <div
                style={{
                  display: "flex",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                }}
              >
                <img
                  src="/assets/images/clinicpolicies18.jpg"
                  alt=""
                  className="doc_img"
                />
              </div>
            </li>
            <br />
            <li>
              {t("clinic_treatment_124")}
              <div
                style={{
                  display: "flex",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                }}
              >
                <img
                  src="/assets/images/clinicpolicies19.png"
                  alt=""
                  className="doc_img"
                />
              </div>
            </li>
            <br />
          </ol>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "#A31C1C",
            }}
            component="h1"
          >
            {t("clinic_treatment_125")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_126")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies20.png"
              alt=""
              className="doc_img"
            />
          </div>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "#A31C1C",
            }}
            component="h1"
          >
            {t("clinic_treatment_127")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_128")}</li>
            <br />
            <li>{t("clinic_treatment_129")}</li>
            <br />
          </ul>
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
}

export default IPRAttachmentsButtonsProtocol;
