import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import moment from "moment";
import i18n from "../../util/i18n";
import axios from '../../util/axios';

export const TabsControllerContext = createContext();

const TabsProvider = (props) => {
    const [currentTab, setCurrentTab] = React.useState(0)
    const [search, setSearch] = useState('');
    const [searchCalendar, setSearchCalendar] = useState('');
    const [searchCancelled, setSearchCancelled] = useState('');
    const [searchPatients, setSearchPatients] = useState('');
    const [patientfilter, setPatientfilter] = useState("");
    const [cancelledFilter, setCancelledFilter] = useState("");
    const [appointmentfilter, setAppointmentfilter] = useState("");
    const [pendingBooking, setPendingBooking] = useState({Id: null, isReschedule: false});
    const[openNote,setOpenNote]=useState(0)
    const [calendarFilter, setCalendarfilter] = useState("");
    const [appointmentTypeFilter, setAppointmentTypeFilter] = useState("");
    const [checkHideUnqualified,setCheckHideUnqualified] = useState(false);
    const [refreshComment,setRefreshComment] = useState(false);
    const[checkHide,setCheckHide]=useState(false);
    const [state, setState] = React.useState({
        value: moment.range(moment(), moment()),
        })
    const [clinicNotifications, setClinicNotifications] = useState({
        size_all: 0,
        pending: 0,
        notifications: [],
    });
    const handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            setSearch(e.target.value)
        }
    }
    const handleSearch = (e) =>{
        if(!e.target.value)
            setSearch('');
    }
    const handleKeyPressCalendar = (e) => {
        if(e.key === 'Enter'){
            setSearchCalendar(e.target.value)
        }
    }
    const handleCalendarSearch = (e) =>{
        if(!e.target.value)
            setSearchCalendar('');
    }
    const handleKeyPressPatient = (e) => {
        if(e.key === 'Enter'){
            setSearchPatients(e.target.value)
        }
    }
    const handlePatientSearch = (e) =>{
        if(!e.target.value)
            setSearchPatients('');
    }
    const [lang, setLang] = React.useState('en')
    const handleChange = (lang) => {
      i18n.changeLanguage(lang)
      setLang(lang)
    }

    const handleKeyPressCancelled = (e) => {
        if(e.key === 'Enter'){
            setSearchCancelled(e.target.value)
        }
    }
    const handleCancelledSearch = (e) =>{
        if(!e.target.value)
            setSearchCancelled('');
    }

    const fetchClinicNotifications = () => {
        axios.get(`/admin/v1/clinic/notifications?clinic_id=${Cookies.get('clinic_id')}`).then((response) => {
            setClinicNotifications(response.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(()=>{
        setLang(Cookies.get('i18next'))
        if(Cookies.get('currentTab')){
            setCurrentTab(parseInt(Cookies.get('currentTab')))
        }
    }, [])

    useEffect(()=>{
        if(Cookies.get('i18next') == 'ar' || lang == 'ar'){
            document.body.className = 'ar'
            console.log('ar')
        } else{
            console.log('en')
            document.body.className = ''
        }
    }, [lang])

    const context = {
		currentTab,
        search,
        searchCalendar,
        searchPatients,
        patientfilter,
        appointmentfilter,
        searchCancelled,
        lang,
        refreshComment,
        calendarFilter,
        appointmentTypeFilter,
        cancelledFilter,
        checkHideUnqualified,
        openNote,
        checkHide,
        pendingBooking,
        state,
        clinicNotifications,
		actions: {
			setCurrentTab,
            setLang,
            handleChange,
            setOpenNote,
            setPendingBooking,
            setSearch,
            setSearchPatients,
            setSearchCalendar,
            setSearchCancelled,
            handleKeyPressCancelled,
            handleCancelledSearch,
            handleCalendarSearch,
            handlePatientSearch,
            handleSearch,
            handleKeyPress,
            handleKeyPressCalendar,
            handleKeyPressPatient,
            setAppointmentfilter,
            setCancelledFilter,
            setRefreshComment,
            setPatientfilter,
            setCalendarfilter,
            setAppointmentTypeFilter,
            setCheckHideUnqualified,
            setCheckHide,
            fetchClinicNotifications,
            setState
		},
	};
return (
    <TabsControllerContext.Provider value={context}>
           <Helmet htmlAttributes={{
          lang: lang,
          dir: lang === 'en' ? 'ltr' : 'rtl'
        }}/>
        {props.children}</TabsControllerContext.Provider>
);
}

export default TabsProvider;