import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "../../util/axios";
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../util/functions";
import ProcedureHeader from "./procedure-header.component";

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    photoWidthSM: {
      width: "40%",
    },
    photoWidth: {
      width: "70%",
    },
    singlePhotoBox: {
      display: "flex",
      width: "70.19px",
      height: "109.03px",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    title: {
      height: "16px",
      width: "134px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
      marginBottom: "1rem",
    },
    imageTitle: {
      height: "33.6px",
      width: "73px",
      color: "#212B36",
      fontSize: "0.8rem !important",
      letterSpacing: 0,
      lineHeight: "16.8px",
      textAlign: "center",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    procWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    noBorder: {
      border: "none",
    },
    add: {
      backgroundColor: "#8EE2ED",
      color: "#fff",
    },
    errorMsg: {
      margin: "1rem 0",
      color: "red",
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    textarea: {
      width: "100%",
      border: "none",
      resize: "none",
      backgroundColor: "rgba(211,215,219,0.25)",
      color: "#939393",
      padding: "0.8rem",
      "&:focus": {
        outline: "none",
      },
    },
    textField: {
      width: "85%",
      backgroundColor: "rgba(211,215,219,0.25)",
      color: "#939393",
      padding: "0 0.8rem",
      borderBottomColor: "red",
    },
    btnStyle: {
      backgroundColor: "rgba(211,215,219,0.25)",
      fontSize: "14px !important",
      width: "6.5rem",
      padding: "0.5rem 2.5rem",
      color: "#939393",
      cursor: "pointer",
    },
    paddingMd: {
      padding: "0.5rem 2rem",
    },
    paddingSM: {
      padding: "0.5rem 1.7rem",
    },
    paddingBG: {
      padding: "0.5rem 2.3rem",
    },
    archesBtn: {
      backgroundColor: "rgba(211,215,219,0.25)",
      fontSize: "14px !important",
      width: "6.5rem",
      color: "#939393",
      cursor: "pointer",
    },
    flexBox: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "0.5rem",
    },
    fullWidth: {
      width: "100%",
    },
    mediumWidth: {
      width: "14rem",
    },
    seMiWidth: {
      width: "21rem",
    },
    viewWidth: {
      display: "flex",
      marginTop: "0.5rem",
    },
    dateSelectoreWidth: {
      width: "30rem",
    },
    selected: {
      backgroundColor: "#8ee2ed",
      color: "#fff",
    },
    checkBoxWrapper: {
      display: "flex",
      flexDirection: "row",
      margin: "4px 0",
    },
    viewOnlyDate: {
      backgroundColor: "rgba(211,215,219,0.25)",
      fontSize: "14px !important",
      padding: "0.5rem 1.5rem 0.5rem",
      textAlign: " center",
      color: "#939393",
    },
  };
});
export default function ScreeningInformationMallCollaboration({
  userId,
  appointmentId,
  appointmentType,
  data: infoData = { data: {}, isAllProvided: null },
  handleTabsExpand,
  index,
  openCloseTabs,
  setDisable,
  scrollToExpandedTab,
}) {
  const { data: informationData, isAllProvided, mandatory } = infoData;
  const { t } = useTranslation();
  const [information, setInformation] = useState(informationData);
  const [iconInf, setIconInf] = useState(isAllProvided);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [errorMsg, setErrorMsg] = useState({
    problem_question: "",
  });

  const handleIconInformationView = (value) => {
    setIconInf(value);
  };

  const handleInformationValue = (value) => {
    setInformation(value);
  };

  const handleChange = (value, type) => {
    if (!clinicImpersonated()) {
      if (type === "problem_question") {
        setErrorMsg((prev) => ({
          ...prev,
          [type]: "",
        }));
      }
      setSuccess(false);
      setData((prev) => ({
        ...prev,
        [type]: value,
      }));
    }
  };

  const handleSave = () => {
    let errMsg = {
      problem_question: "",
    };
    setErrorMsg(errMsg);
    if (mandatory && !data?.problem_question) {
      errMsg = {
        ...errMsg,
        problem_question: t("radio-required"),
      };
      setErrorMsg(errMsg);
    } else {
      setLoading(true);
      let body = {
        ...data,
        procedure: appointmentType?.procedure,
        user_scan_info_id: appointmentId,
      };
      if (body) {
        axios
          .post(`admin/v2/users/${userId}/case-record-information`, body)
          .then((res) => {
            if (res.data.success) {
              handleIconInformationView(res.data.isAllProvided);
              handleDataFetched(res.data.data);
              handleInformationValue(res.data.data);
              setSuccess(true);
            }
          })
          .catch((err) => {
            setSuccess(false);
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  const handleDataFetched = (value) => {
    setData({
      problem_question: value?.problem_question,
      dental_history_na: value?.dental_history_na === 1,
    });
  };

  useEffect(() => {
    handleDataFetched(information);
  }, []);

  useEffect(() => {
    if (clinicImpersonated()) {
      setDisable(true);
    } else {
      setDisable(data?.patient_data_locked);
    }
  }, []);

  useEffect(() => {
    scrollToExpandedTab(index, "screeningInformation");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="screeningInformation"
      headerTitle="Information"
      index={index}
      iconCheck={iconInf}
    >
      {openCloseTabs[index]?.screeningInformation && (
        <Box
          className={`${classes.photoWrapper} screeningInformation-${index}`}
        >
          {loading ? (
            <Box className={classes.loadingBox}>
              <CircularProgress className={classes.loading} />
            </Box>
          ) : (
            <>
              <Box style={{ marginBottom: "1rem" }}>
                <Typography style={{ color: "#363636" }}>
                  {t("What-problem-are-you-looking-to-get-fixed?")}
                </Typography>
                <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                  <TextareaAutosize
                    disabled={clinicImpersonated()}
                    placeholder={t("Add-comment...")}
                    className={classes.textarea}
                    style={{ height: "6rem" }}
                    value={data?.problem_question}
                    onChange={(e) =>
                      handleChange(e.target.value, "problem_question")
                    }
                  />
                </Box>
              </Box>
              {errorMsg?.problem_question && (
                <Typography className={classes.errorMsg}>
                  {errorMsg.problem_question}
                </Typography>
              )}

              <Button
                disabled={clinicImpersonated()}
                className={classes.saveBtn}
                onClick={handleSave}
              >
                {success ? t("Saved") : t("Save")}
              </Button>
            </>
          )}
        </Box>
      )}
    </ProcedureHeader>
  );
}
