import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";

function ProceduresAndCancelledBookingsProtocol({ align, isPage = true }) {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  const Component = ({ t, align, isArabic }) => {
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };

    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };

    const title = { fontSize: "14px", mb: 2 };
    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_61")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_62")}</li>
            <br />
            <li>{t("clinic_treatment_63")}</li>
            <br />
            <li>{t("clinic_treatment_64")}</li>
            <br />
          </ul>
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#FF0000" }}>{t("clinic_treatment_65")}</p>{" "}
          </Typography>
          <br />
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#A31C1C" }}>{t("clinic_treatment_66")}</p>{" "}
          </Typography>
          <br />
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_67")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies10.png"
              alt=""
              className="doc_img"
            />
          </div>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_68")}</li>
            <br />
            <li>{t("clinic_treatment_69")}</li>
            <br />
            <li>
              {t("clinic_treatment_70")} <b>{t("clinic_treatment_71")}</b>
            </li>
            <br />
            <li>
              {t("clinic_treatment_72")} <b>{t("clinic_treatment_73")}</b>
            </li>
            <br />
          </ul>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_74")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src="/assets/images/clinicpolicies111.png"
              alt=""
              className="doc_img"
            />
          </div>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_75")}</li>
            <br />
            <li>
              {t("clinic_treatment_76")} <b>{t("clinic_treatment_77")}</b>{" "}
              {t("clinic_treatment_78")}{" "}
            </li>
            <br />
          </ul>
        </Typography>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_79")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_80")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("clinic_treatment_81")}</li>
            <br />
            <li>{t("clinic_treatment_82")}</li>
            <br />
            <li>{t("clinic_treatment_83")}</li>
            <br />
            <li>{t("clinic_treatment_84")}</li>
            <br />
            <li>{t("clinic_treatment_85")}</li>
            <br />
            <li>{t("clinic_treatment_86")}</li>
            <br />
            <li>{t("clinic_treatment_87")}</li>
            <br />
            <li>{t("clinic_treatment_88")}</li>
            <br />
            <li>{t("clinic_treatment_89")}</li>
            <br />
            <li>{t("clinic_treatment_90")}</li>
            <br />
            <li>{t("clinic_treatment_91")}</li>
            <br />
          </ul>
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
}

export default ProceduresAndCancelledBookingsProtocol;
