import Layout from "../../Components/TopNav/Topnav";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import SideBar from "./Learning/SideBar/SideBar";
import Content from "./Learning/Defination/Content";
import CardContainer from "./Card/CardContainer";
import Cookies from 'js-cookie';

const InstructionsPage = () => {
  const { t, i18n } = useTranslation();
  const [activeItem, setActiveItem] = useState('home');
  const [show, setShow] = useState(false);
  const [sideBarCards, setSideBarCards] = useState([]);

  const handleActiveItem = (item) => {
    console.log('item', item);
    Cookies.set('sidebar-tab', item);
    setActiveItem(item);
    setShow(item !== 'home');
  }

  const sideBarTabs = [
    {
      value: 'home',
      label: t('home-side-bar'),
      img: '/assets/images/home-icon.png',
      onClick: () => handleActiveItem('home'),
    },
    {
      value: 'protocol',
      label: t('Protocol'),
      onClick: () => handleActiveItem('protocol'),
    },
    {
      value: 'scans',
      label: t('scans-tag'),
      onClick: () => handleActiveItem('scans'),
    },
    {
      value: 'dental-checkup',
      label: t('dental-checkup-tag'),
      onClick: () => handleActiveItem('dental-checkup'),
    },
    {
      value: 'ipr',
      label: t('ipr-tag'),
      onClick: () => handleActiveItem('ipr'),
    },
    {
      value: 'attachments',
      label: t('attachments-tag'),
      onClick: () => handleActiveItem('attachments'),
    },
    {
      value: 'buttons',
      label: t('buttons-tag'),
      onClick: () => handleActiveItem('buttons'),
    },
  ];

  const tabTopics = [
    {
      value: 'youtube',
      name: t('videos'),
    },
    {
      value: 'document',
      name: t('documents'),
    },
    {
      value: 'faq',
      name: t('faq-tag'),
    },
  ];

  const allCards = [
    {
      tags: [t('Protocol')],
      title: t('card-tab-1'),
      content: t('card-tab-1-content'),
      icon: '/assets/images/faq.png',
      destination: 'clinic-protocol-and-policies',
      src: '/assets/images/clinicpolicies1.png',
    },
    {
      tags: [t('Protocol')],
      title: t('card-tab-2'),
      content: t('card-tab-2-content'),
      icon: '/assets/images/document.png',
      destination: 'first-visit-appointment',
    },
    {
      tags: [t('Protocol'), t('ipr-tag')],
      title: t('card-tab-3'),
      content: t('card-tab-3-content'),
      icon: '/assets/images/faq.png',
      destination: 'inter-proximal-reduction',
    },
    {
      tags: [t('Protocol'), t('attachments-tag')],
      title: t('card-tab-4'),
      content: t('card-tab-4-content'),
      icon: '/assets/images/faq.png',
      destination: 'clear-aligner-attachments',
    },
    {
      tags: [t('Protocol')],
      title: t('card-tab-5'),
      content: t('ipr_faq_1'),
      icon: '/assets/images/document.png',
      destination: 'clinical-procedures-manual',
      src: '/assets/images/ipr1.png',
    },
    {
      tags: [t('ipr-tag')],
      title: t('learn-more-ipr-title'),
      content: t('learn-more-ipr-content'),
      icon: '/assets/images/youtube.png',
      destination: 'ipr',
      src: '/assets/images/ipr1.png',
    },
    {
      tags: [t('attachments-tag')],
      title: t('learn-more-attachments-title'),
      content: t('learn-more-attachments-content'),
      icon: '/assets/images/document.png',
      destination: 'attachments',
    },
    {
      tags: [t('buttons-tag')],
      title: t('learn-more-buttons-title'),
      content: t('learn-more-buttons-content'),
      icon: '/assets/images/youtube.png',
      destination: 'buttons',
      src: '/assets/images/ipr2.png',
    },
    {
      tags: [t('scans-tag'), t('dental-checkup-tag')],
      title: t('learn-more-scan-types-title'),
      content: t('learn-more-scan-types-content'),
      icon: '/assets/images/document.png',
      destination: 'scans',
    },
    {
      tags: [t('attachments-tag'), t('buttons-tag')],
      title: t('learn-more-attachments-&-buttons-title'),
      content: t('learn-more-attachments-&-buttons-content'),
      icon: '/assets/images/document.png',
      destination: 'attachments-buttons',
    },
    {
      tags: [t('Protocol')],
      title: t('learn-more-bookings-protocol-title'),
      content: t('clinic_treatment_1'),
      icon: '/assets/images/document.png',
      destination: 'bookings-protocol',
      src: '/assets/images/clinicpolicies2.png',
    },
    {
      tags: [t('Protocol')],
      title: t('learn-more-examination-protocol-title'),
      content: t('learn-more-examination-protocol-content'),
      icon: '/assets/images/document.png',
      destination: 'examination-protocol',
      src: '/assets/images/clinicpolicies3.png',
    },
    {
      tags: [t('Protocol'), t('scans-tag')],
      title: t('learn-more-scans-protocol-title'),
      content: t('learn-more-scans-protocol-content'),
      icon: '/assets/images/document.png',
      destination: 'scans-protocol',
      src: '/assets/images/clinicpolicies5.png',
    },
    {
      tags: [t('Protocol')],
      title: t('learn-more-cancelled-bookings-protocol-title'),
      content: t('learn-more-cancelled-bookings-protocol-content'),
      icon: '/assets/images/document.png',
      destination: 'procedures-and-cancelled-bookings-protocol',
      src: '/assets/images/clinicpolicies10.png',
    },
    {
      tags: [t('Protocol'), t('attachments-tag'), t('ipr-tag'), t('buttons-tag')],
      title: t('learn-more-ipr-attachments-buttons-protocol-title'),
      content: t('learn-more-ipr-attachments-buttons-protocol-content'),
      icon: '/assets/images/document.png',
      destination: 'ipr-attachments-buttons-protocol',
      src: '/assets/images/clinicpolicies11.png',
    },
    {
      tags: [t('Protocol')],
      title: t('learn-more-general-guidelines-protocol-title'),
      content: t('learn-more-general-guidelines-protocol-content'),
      icon: '/assets/images/document.png',
      destination: 'clinic-protocol-and-policies-general-guidelines',
    },
    {
      tags: [t('Protocol')],
      title: t('learn-more-fv-app-proc-title'),
      content: t('learn-more-intro-1'),
      icon: '/assets/images/document.png',
      destination: 'first-visit-appointment-procedures',
    },
    {
      tags: [t('dental-checkup-tag')],
      title: t('learn-more-dental-history-checking-title'),
      content: t('learn-more-dental-history-checking-content'),
      icon: '/assets/images/document.png',
      destination: 'dental-history-checking',
    },
    {
      tags: [t('scans-tag')],
      title: t('learn-more-cleaning-photos-scans-title'),
      content: t('learn-more-cleaning-photos-scans-content'),
      icon: '/assets/images/document.png',
      destination: 'cleaning-photos-scans',
    },
    {
      tags: [t('Protocol')],
      title: t('learn-more-aligner-treatment-process-title'),
      content: t('first-visit-app-23'),
      icon: '/assets/images/document.png',
      destination: 'aligner-treatment-process',
    },
    {
      tags: [t('ipr-tag')],
      title: t('learn-more-ipr-intro-title'),
      content: t('learn-more-ipr-intro-content'),
      icon: '/assets/images/document.png',
      destination: 'ipr-intro',
    },
    {
      tags: [t('ipr-tag')],
      title: t('learn-more-ipr-faqs-title'),
      content: t('learn-more-ipr-faqs-content'),
      icon: '/assets/images/faq.png',
      destination: 'ipr-faqs',
    },
  ];

  const cards = [
    {
      title: t('all-topics'),
      cards: allCards,
    },
    {
      title: t('Protocol'),
      cards: allCards.filter(card => card.tags.includes(t('Protocol'))),
    },
    {
      title: t('ipr-tag'),
      cards: allCards.filter(card => card.tags.includes(t('ipr-tag'))),
    },
    {
      title: t('buttons-tag'),
      cards: allCards.filter(card => card.tags.includes(t('buttons-tag'))),
    },
    {
      title: t('scans-tag'),
      cards: allCards.filter(card => card.tags.includes(t('scans-tag'))),
    },
    {
      title: t('dental-checkup-tag'),
      cards: allCards.filter(card => card.tags.includes(t('dental-checkup-tag'))),
    },
    {
      title: t('attachments-tag'),
      cards: allCards.filter(card => card.tags.includes(t('attachments-tag'))),
    },
  ];

  useEffect(() => {
    setShow(false);
  }, [])

  useEffect(() => {
    switch(activeItem) {
      case 'home':
        setSideBarCards(cards[0].cards);
        break;
      case 'protocol':
        setSideBarCards(cards[1].cards);
        break;
      case 'ipr':
        setSideBarCards(cards[2].cards);
        break;
      case 'buttons':
        setSideBarCards(cards[3].cards);
        break;
      case 'scans':
        setSideBarCards(cards[4].cards);
        break;
      case 'dental-checkup':
        setSideBarCards(cards[5].cards);
        break;
      case 'attachments':
        setSideBarCards(cards[6].cards);
        break;
      case 'faq':
        setSideBarCards(cards[7].cards);
        break;
      default:
        setSideBarCards([]);
        break;
    }
  }, [activeItem])

  useEffect(() => {
    const tab = Cookies.get('sidebar-tab');
    if(tab) {
      handleActiveItem(tab);
    }
  }, [])

  return (
    <Layout isProfile={true}>
      <div style={{overflow:'hidden'}}>
        <SideBar
          tabs={sideBarTabs}
          activeItem={activeItem}
        />
        <div className={`${i18n.language=='en'?'content':'content-ar'}`}>
          <div className='learning_container'>
            <Content/>
            {
              !show ?
              cards.map((card, key) => {
                return (
                  <CardContainer
                    key={key}
                    title={card.title}
                    cardstab={tabTopics}
                    cards={card.cards}
                  />
                )
              })
              :
              <CardContainer cardstab={tabTopics} cards={sideBarCards} />
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InstructionsPage;
