import React from "react";
import {
    Box,
    LinearProgress,
    makeStyles,
    Typography,
} from "@material-ui/core";
import SinglePhoto from "./SinglePhoto";

const useStyles = makeStyles(() => {
    return {
        photoWrapper: {
            padding: "1rem",
            boxShadow:
                "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        },
        photoCheckWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        checkBoxWrapper: {
            transform: "scale(0.7)",
        },
        photoBox: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
        },
        photoWidthSM: {
            width: "60%",
            ["@media only screen and (max-width: 700px) "]: {
                width: "100%",
            },
        },
        photoWidth: {
            width: "70%",
            marginBottom: 15,
            ["@media only screen and (max-width: 700px) "]: {
                width: "100%",
            },
        },
        singlePhotoBox: {
            display: "flex",
            width: "70.19px",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "1rem",
            marginBottom: "10px",
            ["@media only screen and (max-width: 500px) "]: {
                flex: "33%",
            },
        },
        title: {
            height: "16px",
            width: "134px",
            color: "#303030",
            fontSize: "16px !important",
            letterSpacing: 0,
            lineHeight: "22px",
            marginBottom: "1rem",
        },
        imageTitle: {
            height: "33.6px",
            width: "73px",
            color: "#212B36",
            fontSize: "11px !important",
            letterSpacing: 0,
            lineHeight: "16.8px",
            textAlign: "center",
        },
        loading: {
            textAlign: "center",
            margin: "0 auto",
            color: "#ff2c46",
        },
    };
});

const ScreeningPhotoUpload = ({ disabled, value, fileType = '', handleImages, id, title, uploadProgress, identifier }) => {
    const classes = useStyles();

    return (
        <div className={classes.photoCheckWrapper}>
            <Box className={classes.singlePhotoBox}>
                <SinglePhoto
                    disable={disabled}
                    value={value}
                    fileType={fileType}
                    handleImages={handleImages}
                    id={id}
                    identifier={identifier}
                />
                {title && <Typography className={classes.imageTitle}>{title}</Typography>}
                {uploadProgress && (
                    <Box>
                        <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                        />
                    </Box>
                )}
            </Box>
        </div>
    )
}

export default ScreeningPhotoUpload