import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Card from "./Card";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

function CardContainer({ title = '', cardstab, cards }) {
  const elementRef = useRef(null);
  const { i18n } = useTranslation();
  const [arrowDisable, setArrowDisable] = useState(true);
  const [selectedTab, setSelectedTab] = useState(null);
  const [topicCards, setTopicCards] = useState(cards);

  const handleTabSelect = (tabName) => {
    setSelectedTab(tabName);
  };
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if ( i18n.language === "ar") {
        element.scrollLeft -= step; // Scroll right to left for Arabic
      } else {
        element.scrollLeft += step; // Default scroll left to right
      }
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  // handle scroll position after content load
  const handleScrollPosition = () => {
    const scrollPosition = Cookies.get(`scrollPosition-${title}`);
    if (scrollPosition) {
      handleHorizantalScroll(elementRef.current, 25, 100, parseInt(scrollPosition)); // scroll to the position
      Cookies.remove(`scrollPosition-${title}`);
    }
  };

  const scrollTo = () => {
    Cookies.set(`scrollPosition-${title}`, elementRef.current.scrollLeft);
  }

  useEffect(() => {
    handleScrollPosition();
  }, [])

  useEffect(() => {
    setTopicCards(cards);
  }, [cards])

  useEffect(() => {
    if(selectedTab) {
      const filteredCards = cards.filter((card) => card.icon.includes(selectedTab.value));
      if(filteredCards.length) {
        setTopicCards(filteredCards);
      }
    }
  }, [selectedTab]);

  return (
    <>
      <h3 className="def_txt_2" style={{ paddingTop: "70px" }}>
        {title}
      </h3>
      <ul className="subcategories-list-wrapper">
        {
          cardstab.map((tab) => (
            <li className="list-inline-item m-0">
              <button
                className={`paragon__chip-light paragon__chip ${
                  selectedTab?.name === tab.name ? "paragon__chip_selected" : ""
                }`}
                onClick={() => handleTabSelect(tab)}
              >
                {tab.name}
              </button>
            </li>
          ))
        }
      </ul>
      <div style={{ position: "relative" }}>
        <div class="button-container">
          <div className="card-arrows">
            <button
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 25, 100, -10);
              }}
              disabled={arrowDisable}
              style={{ backgroundColor: "transparent", border: "0" }}
            >
              
              <img
                src="/assets/images/left-arrow.png"
                alt=""
                className="arrow-img"
              />
            </button>
            <button
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 25, 100, 10);
              }}
              style={{ backgroundColor: "transparent", border: "0" }}
            >
              <img
                src="/assets/images/right-arrow.png"
                alt=""
                className="arrow-img"
              />
            </button>
          </div>
        </div>

        <div class="img-container" ref={elementRef}>
          {
            topicCards.map((card, key) => (
              <Card
                key={key}
                title={card.title}
                tags={card.tags}
                icon={card.icon}
                content={card.content}
                destination={card.destination}
                scrollTo={scrollTo}
                src={card?.src}
              />
            ))
          }
        </div>
      </div>
    </>
  );
}

export default CardContainer;
