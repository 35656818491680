import React, { useState } from 'react'
import { Box, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const InformationRadioOptions = ({
    title,
    subtitle,
    children,
}) => {
    const [isActive, setIsActive] = useState(true);

    const toggleAccordion = () => {
        setIsActive(!isActive);
    };

    return (
        <div>
            <Typography className='treatment-approach-title'>{title}</Typography>
            <Box className='treatment-approach-box'>
                <div className="accordion__section">
                    <button
                        className='info-dentition-accordion-btn'
                        onClick={toggleAccordion}
                    >
                        <Typography className='treatment-approach-select-title'>{subtitle}</Typography>
                        <KeyboardArrowDownIcon style={{transform: isActive ? 'scale(-1, -1)' : 'initial'}} />
                    </button>
                    {
                        isActive ?
                        children
                        :
                        null
                    }
                </div>
            </Box>
        </div>
    )
}

export default InformationRadioOptions