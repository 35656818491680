import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ThreeDViewerLoader = ({ progress }) => {

  return (
    <div className="loader3D">
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        styles={buildStyles({
          pathTransitionDuration: 0.15,
        })}
      />
    </div>
  );
};

export default ThreeDViewerLoader;
