import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import AttachmentsAndButtonsComponent from "../../../Components/AttachmentsAndButtonsComponent";
import IPRComponent from "../../../Components/IPRComponent";
import AttachmentsComponent from "../../../Components/AttachmentsComponent";
import ButtonsComponent from "../../../Components/ButtonsComponent";
import ScansComponent from "../../../Components/ScansComponent";

function ClinicalProceduresManual({ align }) {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    }
    if (i18n.language === "en") {
      setIsArabic(false);
    }
  }, [i18n.language, isArabic]);

  return (
    <Layout isProfile={true}>
      <div className="learning_container">
        <Typography
          variant="h4"
          sx={{ mb: 2, textAlign: "center", paddingTop: "100px" }}
          component="h1"
        >
          {t("ipr_faq_title")}
        </Typography>
        <IPRComponent isArabic={isArabic} align={align} t={t} />

        <AttachmentsComponent isArabic={isArabic} align={align} t={t} />

        <ButtonsComponent isArabic={isArabic} align={align} t={t} />

        <ScansComponent isArabic={isArabic} align={align} t={t} />
        <AttachmentsAndButtonsComponent
          isArabic={isArabic}
          align={align}
          t={t}
        />
      </div>
    </Layout>
  );
}

export default ClinicalProceduresManual;
