import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { DropZone } from '@shopify/polaris';
import React from 'react'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(()=>{return{
    fontFamilyAlmarai:{
        fontFamily:'Almarai !important'
    },
    fontFamilyHelvetica:{
        fontFamily:"Helvetica!important",
    },
    wrapper:{
        height: '128px',
        width: '204px',
        borderRadius: '1.5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        margin:'0.5rem',
        cursor:'pointer',
    },
    relative:{
        position:'relative',
        cursor:'pointer',
    },
    imageSign:{
        position:'absolute',
        width: 'fit-content',
        padding: '0 0.5rem',
        left:'50%',
        transform: 'translateX(-50%)',
        color: '#FFFFFF',
        backgroundColor:'rgba(0,0,0,0.6)',
        fontSize: '9px !important',
        letterSpacing: 0,
        lineHeight: '13px',
        textAlign: 'center',
    },
    bottom:{
        bottom: 10
    },
    top:{
        top: '45%',
    },
    imagePlaceholder:{
        position:'absolute',
        left: '50%',
        top:'50%',
        transform: 'translate(-50%,-50%)',
        color: '#007ACE',
        // fontFamily: 'Helvetica',
        fontSize: '9px !important',
        width: 'fit-content',
        letterSpacing: 0,
        lineHeight: '13px',
        textAlign: 'right'
    },
    label: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
    },
    dropZoneDesign:{
        width:'100%',
        height:'100%',
        position:'absolute',
        top:0,
        right:0,
        border: '0.1rem solid #000',
        opacity: 0,
        '& div':{
            height: '100%',
            '& div':{
                height: '100%',
            }
        }
   }
}})
export default function ScansDropZone({
    value,
    id,
    handleChange,
    message,
    name,
    disable,
    uploadProgress,
    folderUpload = false,
}) {
    const classes = useStyles();
    const { t, i18n } = useTranslation()
  return (
                
    <>
        <Box style={{position: 'relative'}}>
            <Box className={`${classes.relative} ${classes.wrapper}`}>
                {value ?
                    <>
                        <Box component="img" sx={{ height: '100%', width: '100%'}} src={value}/>
                        <Typography className={`${classes.imageSign} ${classes.top} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyHelvetica}`}>{name}</Typography>
                    </>
                    :
                    <>
                        <Box sx={{ height: '100%', width: '100%'}}/>
                        <Typography className={`${classes.imagePlaceholder} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyHelvetica}`}>{message}</Typography>
                    </>
                }
                <Typography className={`${classes.imageSign} ${classes.bottom} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyHelvetica}`}>{value ? t('Replace') : t('Upload-image')}</Typography>
            <div
                className={classes.dropZoneDesign}
            >
                {
                        !folderUpload ?
                        <DropZone
                            disabled={disable}
                            onDropAccepted={(file) => handleChange(file, id)}
                            id={id}
                        /> :
                        <label className={classes.label}>
                            <input
                                type="file"
                                webkitdirectory="true"
                                mozdirectory="true"
                                onChange={handleChange}
                            />
                        </label>
                    }
            </div>
            </Box>
            {(uploadProgress) &&
            <Box style={{ width:'90%', margin:"auto"}}>
                <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
            }
        </Box>
    </>
  )
}
