import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import instance from '../../util/axios';
import UserPhotoIdContext from './UserPhotoIdContext'

function UserPhotoIdProvider({children}) {

    const [frontPath, setFrontPath] = useState('');
    const [backPath, setBackPath] = useState('');
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const navigate = useNavigate();
    const PHOTO_FRONT_ID = 'photo_front_id';
    const PHOTO_BACK_ID = 'photo_back_id';

    const [loading, setLoading] = useState(false);
    const [previewFront, isPreviewFront] = useState(true);
    const [previewBack, isPreviewBack] = useState(true);

    const emptyImages = ()=>{
        setFrontImage("");
        setBackImage("");
    }

    const handleFrontImage =  async (files) => {

        setFrontImage(window.URL.createObjectURL(files[0]));
        isPreviewFront(true);
        setFrontPath(await handleImageUpload(files[0]));
    }

    const handleBackImage = async (files) => {

        setBackImage(window.URL.createObjectURL(files[0]));
        isPreviewBack(true);
        setBackPath(await handleImageUpload(files[0]));
     }

    const fetchPhotoIdByUserId = async (id) =>{
        try{
            const res = await instance.get(`/admin/v1/user/${id}/photoId/`);
              const data = res.data;
              if(data.success){
                isPreviewFront(false);
                isPreviewBack(false);
                setFrontImage(data.url_front);
                setBackImage(data.url_back);
              }else{
                setFrontImage("");
                setBackImage("");
              }
        }catch(error){
            setFrontImage("");
            setBackImage("");
            console.log({error});
        }
    }

    const deleteImage = async (value, id) =>{
        setLoading(true);
        try{
            const res = await instance.delete(`/admin/v1/user/${id}/photoId/${value}/delete`);
            const data = res.data;
            if(data.success){
                if(data.reference == PHOTO_FRONT_ID){
                    setFrontImage(null);
                }else if(data.reference == PHOTO_BACK_ID){
                    setBackImage(null);
                }
            }
            setLoading(false);
        }catch(error){
            console.log({error});
            setLoading(false);
        }
        
    }

    const saveImage = async (id) =>{
        setLoading(true);
        try{
          const res = await instance.post(`/admin/v1/user/${id}/photoId/upload`, {
                'photo_front_id' : frontPath,
                'photo_back_id' : backPath,
            });
            const data = res.data;
            if(data.success){
                navigate(`/dashboard/users/${id}`);
            }
            setLoading(false);
        }catch(error){
            console.log({error});
            setLoading(false);
        }
    }

    const handleImageUpload = async (value) =>{
        setLoading(true);
        try{
            const res = await instance.post('/admin/v1/images/s3', {
                'content_type' : value.type
            });
            const data = await res.data;
            if(res.status == 200){
                const res2 = await axios.put(data.url, value , {
                    headers:{
                        "x-amz-acl": ["public-read-write"],
                        "Content-Type":value.type ,
                    }
                });
                setLoading(false);
                return data.key;
            }
        }catch(error){
            console.log({error});
        setLoading(false);

        }
    }

    const context ={
        constants:{ PHOTO_FRONT_ID, PHOTO_BACK_ID },
        state:{frontImage, backImage, loading, previewFront, previewBack},
        actions:{
            fetchPhotoIdByUserId,
            deleteImage,
            saveImage,
            handleImageUpload,
            handleFrontImage,
            handleBackImage,
            emptyImages
        }
    };
  return (
    <UserPhotoIdContext.Provider value={context}>
        {children}
    </UserPhotoIdContext.Provider>
  )
}

export default UserPhotoIdProvider