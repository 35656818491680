import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    root: {
      backgroundColor: "rgb(246, 246, 247)",
    },
    wrapper: {
      backgroundColor: "#FFF",
      flex: 1,
      padding: "1rem",
      borderRadius: "3px",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      ["@media only screen and (max-width: 1170px) "]: {
        width: "100%",
      },
    },
    typoColor: {
      color: "#637381",
    },
    before_step: {
      color: "#637381",
      fontFamily: "Eina-light!important",
    },
    header: {
      fontSize: "18px !important",
      letterSpacing: 0,
      lineHeight: "32px",
      minHeight: "32px",
      paddingBottom: "1rem",
    },
    heightMD: {
      margin: "0.5rem 0",
    },
    bold: {
      // fontFamily: "Eina-bold !important",
      fontWeight: "bold",
    },
    thin: {
      // fontFamily: "Eina-light !important"
      fontWeight: "500",
    },
    clinicName: {
      fontSize: "14px !important",
      letterSpacing: 0,
      lineHeight: "20px",
      fontFamily: "Eina-light !important",
    },
    dotStyle: {
      backgroundColor: "#637381",
      height: "0.2rem",
      width: "0.2rem",
      borderRadius: "50%",
      display: "inline-block",
      marginLeft: "0.1rem",
    },
    pending: {
      color: "#fff",
      backgroundColor: "#fcbd17",
      height: 20,
    },
    completed: {
      color: "#fff",
      backgroundColor: "#98bae7",
      height: 20,
    },
    confirmed: {
      color: "#fff",
      backgroundColor: "#45da10",
      height: 20,
    },
    qualified: {
      color: "#fff",
      backgroundColor: "#8ee2ed",
      height: 20,
    },
    cancelled: {
      color: "#fff",
      backgroundColor: "red",
      height: 20,
    },
    noShowUp: {
      color: "#fff",
      backgroundColor: "#FFA500",
      height: 20,
    },
    noAnswer: {
      color: "#fff",
      backgroundColor: "#FFA500",
      height: 20,
    },
    unqualified: {
      color: "#fff",
      backgroundColor: "#65737E",
      height: 20,
    },
    postponed: {
      color: "#fff",
      backgroundColor: "#E99235",
      height: 20,
    },
    typoWrite: {
      letterSpacing: 0,
      lineHeight: "32px",
    },
    fontSizeMD: {
      fontSize: "14px !important",
    },
    marginHorizantal: {
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
    formrow: {
      display: "flex",
      alignItems: "start",
      flexWrap: "wrap",
    },
    ul: {
      listStyle: "none",
    },
    data: {
      height:'300px',
      overflow:'auto'
    }
  };
});
export default function ProcedureComments({ data }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Typography
        className={`${classes.header} ${classes.typoColor} ${classes.bold} ${
          i18n.language == "ar"
            ? classes.fontFamilyAlmarai
            : classes.fontFamilyEina
        }`}
      >
        {t("procedure-comments")}
      </Typography>
      <div className={`${classes.data }`}>
        {data.map((data) => (
          <div className={`${classes.formrow}`}>
            <div style={{ width: "fit-content" }}>
              <Typography
                className={`${classes.clinicName} ${classes.heightMD} ${
                  classes.thin
                } ${classes.before_step} ${
                  i18n.language == "ar"
                    ? classes.fontFamilyAlmarai
                    : classes.fontFamilyEina
                }`}
              >
                {t("before-step")} {data.before_step}
                {data.position}:
              </Typography>
            </div>
            <ul
              style={{
                listStyle: "none",
                paddingInline: "10px",
                paddingBottom: "30px",
                width: "83%",
              }}
            >
              {data.descriptions.map((desc) => (
                <li style={{ display: "flex" }}>
                  <p
                    className={`${classes.clinicName} ${classes.heightMD} ${
                      classes.thin
                    } ${classes.typoColor} ${
                      i18n.language == "ar"
                        ? classes.fontFamilyAlmarai
                        : classes.fontFamilyEina
                    }`}
                  >
                    {" "}
                    {desc.type}
                  </p>
                  <p
                    className={`${classes.clinicName} ${classes.heightMD} ${
                      classes.thin
                    } ${classes.typoColor} ${
                      i18n.language == "ar"
                        ? classes.fontFamilyAlmarai
                        : classes.fontFamilyEina
                    }`}
                    style={{ paddingInline: "10px", flexWrap: "wrap" }}
                  >
                    {t("comment-type")} {desc.comment}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Box>
  );
}
