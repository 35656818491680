export const headCells = (t) => [
  {
    id: "user_id",
    label: t("ID"),
    hideSort: true,
  },
  {
    id: "booking_date",
    label: t("Booking-Date"),
    type: "booking_date",
    hideSort: true,
  },
  {
    id: "patient_name",
    label: t("Patient-name"),
    type: "appointment_name",
    hideSort: true,
  },
  {
    id: "user_type",
    label: t("Type"),
    type: "type",
    hideSort: true,
  },
  {
    id: "user_sub_status",
    label: t("Status"),
    type: "number",
    hideSort: true,
  },
  {
    id: "unread_comments",
    label: "",
    hideRow: true,
  },
  {
    id: "user_action",
    label: t("Action"),
    type: "menu",
    hideSort: true,
  },
];
