import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import IconCheckView from "../IconCheckView";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.375rem 1.313rem",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    typoDesign: {
      height: "24px",
      width: "fit-content",
      color: "#212B36",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "24px",
    },
    bold: {
      fontWeight: "bold",
    },
    arrowBtn: {
      padding: 0,
      "&:hover": {
        backgroundColor: "#ffff",
      },
    },
    margin: {
      margin: "1rem",
    },
    flex: {
      display: "flex",
    },
  };
});
const ProcedureHeader = ({ handleTabsExpand, index, type, headerTitle, iconCheck, children }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Box className={`${classes.margin}`}>
      <div
        onClick={() => {
          handleTabsExpand(index, type);
        }}
        className={`${classes.headerWrapper} ${index}-${type}`}
      >
        <Typography
          className={`${classes.typoDesign} ${classes.bold} ${
            i18n.language === "ar"
              ? classes.fontFamilyAlmarai
              : classes.fontFamilyEina
          }`}
        >
          {t(headerTitle)}
        </Typography>
        <Box className={classes.flex}>
          <IconCheckView value={iconCheck} />
          <Button className={classes.arrowBtn}>
            <KeyboardArrowDownIcon />
          </Button>
        </Box>
      </div>
      {children}
    </Box>
  );
};

export default ProcedureHeader;
