import {
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useStyles } from "./calendar-filter.styles";
import { Divider, FormLabel } from "@mui/material";

function CalendarFilter({
  anchorElFilter,
  open,
  handleCloseFilter,
  appointmentTypeFilter,
  calendarFilter,
  handleRadioButtonsChange,
  t,
}) {
  const classes = useStyles();
  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorElFilter}
      open={open}
      onClose={handleCloseFilter}
    >
      <FormControl fullWidth>
        <MenuItem>
          <FormLabel id="demo-controlled-radio-buttons-group">
            {t("Status")}
          </FormLabel>
        </MenuItem>
        <Divider />
        <RadioGroup
          name="radio-buttons-group"
          onChange={(e) => handleRadioButtonsChange(e, "status")}
          value={calendarFilter}
        >
          <MenuItem>
            <FormControlLabel
              className={classes.fullWidth}
              label={t("All")}
              value={""}
              control={<Radio />}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              className={classes.fullWidth}
              label={t("No-Show")}
              value={"No Show"}
              control={<Radio />}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              className={classes.fullWidth}
              value={"Confirmed"}
              control={<Radio />}
              label={t("Confirmed")}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              className={classes.fullWidth}
              value={"Completed"}
              control={<Radio />}
              label={t("Completed")}
            />
          </MenuItem>
        </RadioGroup>
      </FormControl>
      <Divider />
      <FormControl>
        <MenuItem>
          <FormLabel id="demo-controlled-radio-buttons-group">
            {t("Type")}
          </FormLabel>
        </MenuItem>
        <Divider />
        <RadioGroup
          name="radio-buttons-group"
          onChange={(e) => handleRadioButtonsChange(e, "appointmentType")}
          value={appointmentTypeFilter}
        >
          <MenuItem>
            <FormControlLabel
              label={t("All")}
              value={""}
              className={classes.fullWidth}
              control={<Radio />}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              className={classes.fullWidth}
              label={t("procedures")}
              value={"procedures"}
              control={<Radio />}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              className={classes.fullWidth}
              value={"first-visit"}
              control={<Radio />}
              label={t("first-visit")}
            />
          </MenuItem>
        </RadioGroup>
      </FormControl>
    </Menu>
  );
}

export default CalendarFilter;
