import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import AttachmentsAndButtonsComponent from "../../../Components/AttachmentsAndButtonsComponent";

function AttachmentsAndButtons({ align }) {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    }
    if (i18n.language === "en") {
      setIsArabic(false);
    }
  }, [i18n.language, isArabic]);

  return (
    <>
      <Layout isProfile={true}>
        <AttachmentsAndButtonsComponent
          isArabic={isArabic}
          align={align}
          t={t}
        />
      </Layout>
    </>
  );
}

export default AttachmentsAndButtons;
