import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Layout from "../../../Components/TopNav/Topnav";

const CleaningPhotos = ({ align, isPage = true }) => {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);
  
  const Component = ({ t, align, isArabic }) => {
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };
  
    const divStyles = {
      mb: 2,
      mt: 2,
      textAlign: align,
      color: "#ff2c46",
      padding: 2,
      borderRadius: "5px",
      fontWeight: "bold",
    };

    return (
      <div
        className="learning_container"
        style={{ backgroundColor: "transparent" }}
      >
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            5.{t("cleaning")}{" "}
            <span style={{ color: "#ff2c46" }}>{`(5 ${t("minutes")})`}</span>
          </Typography>

          <ul style={unorderedListStyle}>
            <li>{t("learn-more-cleaning-1")}</li>
            <li>{t("learn-more-cleaning-2")}</li>
            <li>{t("learn-more-cleaning-3")}</li>
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            6.{isArabic ? t("photos-info") : t("photos")}{" "}
            <span style={{ color: "#ff2c46" }}>{`(5 ${t("minutes")})`}</span>
          </Typography>

          <ul style={unorderedListStyle}>
            <li>{t("learn-more-photos-1")}</li>
            <li>{t("learn-more-photos-2")}</li>
            <li>{t("learn-more-photos-3")}</li>
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            7.{" "}
            {isArabic
              ? t("intra-oral-scan-explanation-info")
              : t("intra-oral-scan-explanation")}{" "}
            <span style={{ color: "#ff2c46" }}>{`(10 ${t("minutes")})`}</span>
          </Typography>

          <ul style={unorderedListStyle}>
            <li>{t("learn-more-inter-oral-scan-1")}</li>
            <li>{t("learn-more-inter-oral-scan-2")}</li>
            <li>{t("learn-more-inter-oral-scan-3")}</li>
          </ul>
        </Typography>
      </div>
    )
  }

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
};

export default CleaningPhotos;
