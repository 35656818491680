import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../../Components/TopNav/Topnav';
import axios from '../../util/axios'

const useStyles = makeStyles(()=>{ return {
    loadingBox:{
        margin: "12% auto",
        textAlign: "center"
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
 } });
function Impersonate() {
    const query = new URLSearchParams(document.location.search);
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(()=>{
      if(query.get('token') && query.get('clinicId') && query.get('name')){
          Cookies.set('access_token_clinic', query.get('token'), { expires: 1 })
          Cookies.set('clinic_name', query.get('name'), { expires: 1 })
          Cookies.set('clinic_id', query.get('clinicId'), { expires: 1 })
          Cookies.set('is_scan_at_home', query.get('isScanAtHome'), { expires: 1 })
          axios.get(`/admin/v1/clinic-admin-access/${query.get('clinicId')}`).then((res) => {
            if(res.data.redirect_to_home){
              Cookies.get('access_token_clinic') && Cookies.remove('access_token_clinic');
              Cookies.get('clinic_name') && Cookies.remove('clinic_name');
              Cookies.get('clinic_address') && Cookies.remove('clinic_address');
              Cookies.get('clinic_id') && Cookies.remove('clinic_id');
              Cookies.get('is_scan_at_home') && Cookies.remove('is_scan_at_home');
              Cookies.get('type') && Cookies.remove('type');
              navigate('/');
            }else{
              if(res.data.success){
                if(res.data.role == 'clinic-admin'){   
                    Cookies.get('clinic_impersonate') && Cookies.remove('clinic_impersonate');
                } else if (res.data.role == 'clinic-moderator')
                    Cookies.set('clinic_impersonate', 1, { expires: 1 })
              }
              if(query.get('user_id')){
                navigate(`/dashboard/users/${query.get('user_id')}`);
              }else{
                navigate('/dashboard');
              }
            }
          }).catch(err => console.log(err))
        }
    }, [])
  return (
    <Layout>
      <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box>
    </Layout>
  )
}

export default Impersonate