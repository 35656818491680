import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";

function AttachmentsFaqs({ align, isPage = true }) {
  const { t } = useTranslation();

  const Component = ({ t, align }) => {
    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };
  
    const title = { fontSize: "14px", mb: 2 };
  
    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("aligner_2")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("aligner_3") }} />
          </Typography>
        </Typography>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("aligner_4")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("aligner_5") }} />
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("aligner_6")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("aligner_7") }} />
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("aligner_8")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("aligner_9") }} />
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("aligner_10")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("aligner_11") }} />
          </Typography>
        </Typography>
      </div>
    )
  }

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} />
    </Layout>
  ) : (
    <Component t={t} align={align} />
  );
}

export default AttachmentsFaqs;
