import { Box, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "../../util/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppointmentsTable from "../UploadedFiles/appointments-table.component";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    wrapper: {
      flex: 1,
      backgroundColor: "#fff",
      borderRadius: "3px",
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      ["@media only screen and (max-width: 1170px) "]: {
        width: "100%",
        marginLeft: "0",
      },
    },
    color: {
      color: "#637381",
    },
    typoWrite: {
      fontSize: "18px !important",
      letterSpacing: 0,
      lineHeight: "32px",
      marginBottom: "0.8rem",
    },
    bold: {
      fontWeight: "bold",
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
  };
});
const AppointmentsPatientProfile = ({
  clinicId,
  setCompletedProceduresDialog,
}) => {
  const classes = useStyles();
  const [appointments, setAppointments] = useState([]);
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const fetchAppointments = async () => {
    try {
      setLoading(true);
      const url = `admin/v2/users/${id}/case-records`;
      const response = await axios.get(url, {
        params: { clinic_id: clinicId },
      });
      if (response.status === 200) {
        const { data } = response.data;
        setAppointments(data);
      }
    } catch (error) {
      console.log("Error Fetching Appointments", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchAppointments();
  }, []);
  return (
    appointments.length > 0 && (
      <Box className={`${classes.wrapper}`}>
        <Typography
          className={`${classes.typoWrite} ${classes.color} ${classes.bold} ${
            i18n.language === "ar"
              ? classes.fontFamilyAlmarai
              : classes.fontFamilyEina
          }`}
        >
          {t("appointments-summary")}
        </Typography>
        {loading ? (
          <Box className={classes.loadingBox}>
            <CircularProgress className={classes.loading} />
          </Box>
        ) : (
          <AppointmentsTable appointments={appointments} setCompletedProceduresDialog={setCompletedProceduresDialog}/>
        )}
      </Box>
    )
  );
};

export default AppointmentsPatientProfile;
