import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ProcedureCheckboxList = ({ procedures, checkboxRefs }) => {
  const {t} = useTranslation();
  return (
    <FormGroup>
      {procedures.map((procedure, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              defaultChecked={procedure.value}
              inputRef={(ref) => (checkboxRefs.current[index] = ref)}
            />
          }
          label={t(procedure.label)}
        />
      ))}
    </FormGroup>
  );
};

export default ProcedureCheckboxList;
