import { makeStyles } from "@material-ui/core";

const TreatmentPlanStyles = makeStyles({
  fontFamilyAlmarai: {
    fontFamily: "Almarai !important",
  },
  fontFamilyEina: {
    fontFamily: "Eina-semi !important",
  },
  fontFamilyEinaLight: {
    fontFamily: "Eina-light!important",
  },
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    borderRadius: "3px",
    flex: 1,
    ["@media only screen and (max-width: 1170px) "]: {
      width: "100%",
      margin: "1rem 0",
    },
  },
  color: {
    color: "#637381",
  },
  bold: {
    fontWeight: "bold",
  },
  thin: {
    fontWeight: "400",
  },
  fontSizeLG: {
    fontSize: "18px !important",
  },
  fontSizeMD: {
    fontSize: "14px !important",
  },
  typoWrite: {
    letterSpacing: 0,
    lineHeight: "32px",
  },
  btnBoxWrapper: {
    justifyContent: "space-evenly",
    width: "100%",
    display: "flex",
    borderTop: "0.1rem solid #DFE3E8",
  },
  btnTypo: {
    color: "#007ACE",
    fontSize: "11px !important",
    letterSpacing: 0,
    lineHeight: "13px",
    textAlign: "right",
    padding: "1.5rem 0",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  marginHorizantal: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  loadingBox: {
    margin: "0 auto",
    textAlign: "center",
    padding: 30,
  },
  loading: {
    textAlign: "center",
    margin: "0 auto",
    color: "#ff2c46",
  },
  tabTitles: {
    position: "absolute",
    top: 0,
    left: ({ titleWidthTransform }) => titleWidthTransform + "px",
    width: ({ titleWidth }) => titleWidth,
    height: "2px",
    transition: "left 0.3s",
    backgroundColor: "#ff2c46",
  },
  procedureDisplay: {
    maxHeight: ({ procedureVisible, iprInfo }) =>
      procedureVisible ? iprInfo?.procedures?.length * 5 + "rem" : 0,
    overflow: "hidden",
    transition: "max-height 0.8s ease-out",
  },
  typeIcon: {
    color: "#fff",
    fontSize: "0.5rem !important",
    padding: "0.2rem 0.5rem",
    textAlign: " center",
    borderRadius: "10rem",
    marginRight: "0.2rem",
  },
  chip: {
    height: 20,
    color: "#fff",
    backgroundColor: "#FFA500",
  },
  completed: {
    backgroundColor: "#98bae7",
  },
  active: {
    backgroundColor: "#45da10",
  },
  replaced: {
    backgroundColor: "#65737E",
  },
  pending: {
    backgroundColor: "#E99235",
  },
});

export default TreatmentPlanStyles;
