import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flexWrap: "wrap",
    },
    checkContainer: {
      flex: "1 1 auto",
      margin: 0,
      minWidth: "fit-content",
      justifyContent: "center",
    },
    menuBox: {
      display: "flex",
    },
    filterButtonWithArrow: {
      border: "1px lightgray solid",
      borderRadius: 0,
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    appointmentHide: {
      color: "#212B36",
      fontWeight: "400",
      fontSize: "12px!important",
    },
    RadioGroup: {
      "& li": {
        paddingLeft: 0,
      },
    },
    alertBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    alert: {
      alignItems: "center",
    },
    filterWrapper: {
      flex: "1 1 auto",
      justifyContent: "center",
      display: "flex",
      padding: 20,
    },
    addNote: {
      width: "25%",
      textAlign: "center",
      marginTop: 10,
      backgroundColor: "#FF2C46",
      float: "right",
      boxSizing: " border-box",
      border: "1px solid #C4CDD5",
      borderRadius: "3px",
      boxShadow: " 0 1px 0 0 rgba(22,29,37,0.05)",
    },
    appointment: {
      flex: "1 1 auto",
      display: "inline-block",
      minWidth: "fit-content",
      color: "#9B9BA1",
      fontWeight: "Eina-semi!important",
      fontSize: "14px!important",
      textAlign: "center",
    },
    blackColor: {
      color: "#000",
    },
  };
});
