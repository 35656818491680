import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../TopNav/Topnav";
import { scrollToTop } from "../../util/functions";
import axios from "../../util/axios";
import { useTranslation } from "react-i18next";
import AppointmentProcedures from "./appointment-procedures.component";
import instance from "../../util/axios";
import WarningDialog from "../ConfirmationDialog/ConfirmationDialog";
import DoctorsDropdownContainer from "../Appointments/doctors-dropdown-container.component";
import Cookies from "js-cookie";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    wrapper: {
      margin: "auto",
      width: "60%",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      ["@media only screen and (max-width: 1000px) "]: {
        width: "100%",
      },
    },
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.375rem 1.313rem",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    infoWrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    boxWrapper: {
      height: "50%",
      width: "60%",
      borderRadius: "4px",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      margin: "auto",
    },
    typoDesign: {
      height: "24px",
      width: "fit-content",
      color: "#212B36",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "24px",
    },
    bold: {
      fontWeight: "bold",
    },
    btn: {
      height: "36px",
      width: "78px",
      border: "1px solid #C4CDD5",
      borderRadius: "3px",
      background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
      boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
    },
    arrowBtn: {
      padding: 0,
      "&:hover": {
        backgroundColor: "#ffff",
      },
    },
    margin: {
      margin: "1rem",
    },
    flex: {
      display: "flex",
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    alertWrapper: {
      width: "60%",
      margin: "0 auto 0.7rem",
      gap: "5px",
      alignItems: "center",
      ["@media only screen and (max-width: 1000px) "]: {
        width: "100%",
      },
    },
    alertBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    alertButton: {
      textDecoration: "underline",
      fontWeight: "600",
    },
  };
});
export default function EditAppointmentPatientData() {
  const { t } = useTranslation();
  const { id, appointmentId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useState([]);
  const [loadingRequiredProcedures, setLoadingRequiredProcedures] =
    useState(false);
  const [disable, setDisable] = useState(false);
  const [openCloseTabs, setOpenCloseTabs] = useState([{}]);
  const [isCompleted, setIsCompleted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [otherDoctor, setOtherDoctor] = useState("");
  const [enableDialog, setEnableDialog] = useState(false);
  const dialogMessage = "completed-appointment-dialog";

  const markAsCompleted = async () => {
    setLoading(true);
    try {
      let doctor = selectedDoctor;
      if (selectedDoctor === "Other") {
        doctor = otherDoctor;
      }
      const response = await instance.put(
        `/admin/v1/clinic/user/${id}/sub-status/update`,
        {
          action: "Completed",
          scan_id: appointmentId,
          clinicId: Cookies.get('clinic_id'),
          doctor
        }
      );
      if (response?.data?.success) {
        setIsCompleted(true);
        await fetchAppointmentData();
      } else if (response?.data?.success === false) {
        navigate(`/dashboard/users/${id}`, {state: {error: true}});
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const scrollToExpandedTab = (id, type) => {
    let dox = document.querySelector(`.${type}-${id}`);
    if (dox) {
      document.documentElement.style.scrollPaddingTop = "150px";
      dox.scrollIntoView({ behavior: "smooth" });
      document.documentElement.style.scrollPaddingTop = "0";
    }
  };

  const handleTabsExpand = (id, type) => {
    const arr = Array(openCloseTabs.length).fill({});
    if (openCloseTabs[id][type] === undefined) {
      arr[id] = { [type]: true };
    }
    setOpenCloseTabs([...arr]);
  };

  const fetchAppointmentData = async () => {
    try {
      setLoadingRequiredProcedures(true);
      const response = await axios.get(
        `admin/v2/clinic/user/case-record-requirements/${appointmentId}`
      );
      if (response.status === 200) {
        const { data, isCompleted: isCompletedFlag, has_required_files_data, new_user } = response.data;
        if(!has_required_files_data || !new_user){
          navigate(`/dashboard/users/${id}`);
        }
        setAppointmentData(data);
        setIsCompleted(isCompletedFlag);
        setOpenCloseTabs(Array(data.length).fill({}));
        scrollToTop(0);
      }
    } catch (error) {
      console.log("Error in fetching required procedures", error);
    } finally {
      setLoadingRequiredProcedures(false);
    }
  };

  const handleDialogClose = () => {
    navigate(`/dashboard/users/${id}`);
    setOpen(false);
  };

  useEffect(() => {
    if (appointmentData.length === 0) {
      void fetchAppointmentData();
    }
  }, []);

  return (
    <Layout isProfile={true}>
      {loadingRequiredProcedures ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : !isCompleted ? (
        <WarningDialog
          open={open}
          handleConfirm={markAsCompleted}
          dialogTitle={t("uploading-appointment-files")}
          confirmationButtonText={t("Yes-mark-appointment-as-completed")}
          dialogContentText={dialogMessage}
          isWarning={true}
          handleClose={handleDialogClose}
          loading={loading}
          enableDialog={enableDialog}
          confirmBtnStyle={{ width: "fit-content" }}
        >
          <DoctorsDropdownContainer
              selectedDoctor={selectedDoctor}
              setSelectedDoctor={setSelectedDoctor}
              setEnableDialog={setEnableDialog}
              otherDoctor={otherDoctor}
              setOtherDoctor={setOtherDoctor}
          />
        </WarningDialog>
      ) : (
        appointmentData.map((procedure, index) => (
          <AppointmentProcedures
            key={index}
            handleTabsExpand={handleTabsExpand}
            procedure={procedure}
            index={index}
            userId={id}
            openCloseTabs={openCloseTabs}
            appointmentId={appointmentId}
            setDisable={setDisable}
            disable={disable}
            fetchAppointmentData={fetchAppointmentData}
            scrollToExpandedTab={scrollToExpandedTab}
          />
        ))
      )}
    </Layout>
  );
}
