import { Box, Button, CircularProgress, Radio, RadioGroup, FormControlLabel, makeStyles, MenuItem, Select, TextareaAutosize, Typography, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SelectDatePicker from "@netojose/react-select-datepicker";
import axios from "../../util/axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clinicImpersonated } from '../../util/functions';
import { Checkbox } from '@shopify/polaris';

const useStyles = makeStyles(()=>{return {
  photoWrapper:{
    padding:'2rem',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
  },
  photoBox:{
    display:'flex',
    justifyContent:'space-between'
  },
  photoWidthSM:{
    width:'40%'
  },
  photoWidth:{
    width:'70%'
  },
  singlePhotoBox:{
    display:'flex',
    width: '70.19px',
    height: '109.03px',
    flexDirection:'column',
    justifyContent:'space-between',
    marginTop:'1rem'
  },
  title:{
    height: '16px',
    width: '134px',
    color: '#303030',
    fontSize: '16px !important',
    letterSpacing: 0,
    lineHeight: '22px',
    marginBottom:'1rem'
  },
  imageTitle:{
    height: '33.6px',
    width: '73px',
    color: '#212B36',
    fontSize: '0.8rem !important',
    letterSpacing: 0,
    lineHeight: '16.8px',
    textAlign: 'center',
  },
  saveBtn:{
    display:'block',
    width: '65px',
    marginTop:'0.5rem',
    marginLeft:'auto',
    borderRadius: '4.7px',
    color:'#fff',
    backgroundColor: '#FF2C46',
    '&:hover':{
      backgroundColor: '#FF2C46',
    }
  },
  procWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  noBorder: {
    border: 'none',
  },
  add: {
    backgroundColor: '#8EE2ED',
    color:'#fff',
  },
  errorMsg: {
    margin: '1rem 0',
    color: 'red'
  },
  loadingBox:{
    margin: "0 auto",
    textAlign: "center",
    padding : 30,
    color:'#FF2C46'
  },
  loading: {
    textAlign: 'center',
    margin: "0 auto",
    color: '#ff2c46'
  },
  textarea:{
    width: '100%',
    border:'none',
    resize:'none',
    backgroundColor:'rgba(211,215,219,0.25)',
    color:'#939393',
    padding:'0.8rem',
    '&:focus':{
      outline:'none'
    }
  },
  textField:{
    width: '85%',
    backgroundColor:'rgba(211,215,219,0.25)',
    color:'#939393',
    padding:'0 0.8rem',
    borderBottomColor: 'red'
  },
  btnStyle:{
    backgroundColor:'rgba(211,215,219,0.25)',
    fontSize:'14px !important',
    width:'6.5rem',
    padding: '0.5rem 2.5rem',
    color:'#939393',
    cursor:'pointer'
  },
  paddingMd:{
    padding: '0.5rem 2rem',
  },
  paddingSM:{
    padding: '0.5rem 1.7rem',
  },
  paddingBG:{
    padding: '0.5rem 2.3rem',
  },
  archesBtn:{
    backgroundColor:'rgba(211,215,219,0.25)',
    fontSize:'14px !important',
    width:'6.5rem',
    color:'#939393',
    cursor:'pointer'
  },
  flexBox:{
    display: 'flex',
    justifyContent:'space-between',
    marginTop:'0.5rem'
  },
  fullWidth:{
    width:'100%'
  },
  mediumWidth:{
    width:'14rem',
  },
  seMiWidth:{
    width:'21rem',
  },
  viewWidth:{
    display: 'flex',
    marginTop:'0.5rem'
  },
  dateSelectoreWidth:{
    width:'30rem'
  },
  selected:{
    backgroundColor:'#8ee2ed',
    color:"#fff"
  },
  checkBoxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '4px 0',
  },
  viewOnlyDate:{
    backgroundColor:'rgba(211,215,219,0.25)',
    fontSize:'14px !important',
    padding: '0.5rem 1.5rem 0.5rem',
    textAlign:' center',
    // width: '6rem',
    color:'#939393',
  }
}})
export default function Information({isMandatory, userId, handleIconInformationView, information, handleInformationValue }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState(null);
  const dateOfBirth = information?.date_of_birth ? moment(information?.date_of_birth) :  null;
  const [result, setResult] = useState(null);
  const [errorMsg, setErrorMsg] = useState({
    problem_question: '',
    dental_history: '',
    list_of_procedures: '',
  });
  const navigate = useNavigate();

  const handleChange = (value, type) =>{
    if(!clinicImpersonated()){
      if(type == 'problem_question' || type == 'dental_history'){
        setErrorMsg(prev => ({
          ...prev,
          [type]: '',
        }));
      }
      setSuccess(false);
      setData((prev) =>({
        ...prev,
        [type]: type == 'date_of_birth' ?  moment(value).format("YYYY-MM-DD") : value
      }))
    }
  }

  const handleChangeProc = (value, i, type) => {
    if(!clinicImpersonated()){
      setErrorMsg(prev => ({
        ...prev,
        [type]: '',
      }));
      setData(prev => {
        prev[type][i] = value;
        return {...prev}
      })
    }
  };

  const addRow = () => {
    setData(prev => ({
      ...prev,
      list_of_procedures: [...prev.list_of_procedures, '']
    }))
  }

  const handleSave = () =>{
    let errMsg = {
      problem_question: '',
      dental_history: '',
      list_of_procedures: '',
    }
    setErrorMsg(errMsg);
    let listOfProcedures = [];
    if(data?.list_of_procedures){
      listOfProcedures = data.list_of_procedures.filter(proc => {
        if(proc){
          return proc;
        }
      });
    }
    if((isMandatory && (!data?.problem_question || !data?.dental_history)) || (data?.dental_history_na == 1 && listOfProcedures.length == 0)){
      if(isMandatory && !data?.problem_question){
        errMsg = {
          ...errMsg,
          problem_question: t('radio-required'),
        }
      }
      if(isMandatory && !data?.dental_history){
        errMsg = {
          ...errMsg,
          dental_history: t('radio-required'),
        }
      }
      if(data?.dental_history_na == 1 && listOfProcedures.length == 0){
        errMsg = {
          ...errMsg,
          list_of_procedures: t('error-add-procedure'),
        }
      }
      setErrorMsg(errMsg);
    } else {
      setLoading(true);
      let body = {
        ...data,
        list_of_procedures: listOfProcedures
      };
      if(body){
        axios
          .post(`admin/v1/users/${userId}/your-photos`, body)
          .then((res) => {
            if(res.data.success){
              handleIconInformationView(res.data.isAllProvided);
              handleDatafetched(res.data.data);
              handleInformationValue(res.data.data);
              // navigate("/dashboard/users/" + userId);
              setSuccess(true);
            }

          })
          .catch((err) => {
            setSuccess(false);
            console.log(err);
          }).finally(() => {
          setLoading(false);
        });
      }else{
        setLoading(false);
      }
    }
  }

  const handleDatafetched = (value) =>{
    setData({
      date_of_birth: value?.date_of_birth ? new Date(value?.date_of_birth) : null,
      problem_question: value?.problem_question,
      visited_dentist: value?.visited_dentist,
      teeth_healthy: value?.teeth_healthy,
      dental_history: value?.dental_history ,
      dental_history_na: value?.dental_history_na == 1 ? true : false,
      list_of_procedures: value?.list_of_procedures ? (value.list_of_procedures.length > 0 ? value.list_of_procedures : ['']) : [''],
      explain_issue: value?.explain_issue,
      arches_to_treat: value?.arches_to_treat,
      pain_symptoms: value?.pain_symptoms,
    });
  }

  useEffect(()=>{
    handleDatafetched(information);
  },[])

  return (
    <Box className={classes.photoWrapper}>
      {loading ? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box>:
        <>
          <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('Date-of-Birth')}</Typography>
            {clinicImpersonated() ?
              <Box className={`${classes.viewWidth}`}>
                <Typography className={classes.viewOnlyDate}>{dateOfBirth ? dateOfBirth.format('MMMM') : 'Month'}</Typography>
                <Typography className={classes.viewOnlyDate}>{dateOfBirth ? dateOfBirth.format('DD') : 'Date'}</Typography>
                <Typography className={classes.viewOnlyDate}>{dateOfBirth ? dateOfBirth.format('YYYY') : 'Year'}</Typography>
              </Box>
              :
              <Box className={`${classes.dateSelectoreWidth} ${classes.flexBox}}`}>
                <SelectDatePicker minDate={new Date(1940, 1, 1)} className={'date-time-picker'} value={information?.date_of_birth ? new Date(data?.date_of_birth) : null} onDateChange={(e) => handleChange(e , 'date_of_birth')}/>
              </Box>
            }
          </Box>

          <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('What-problem-are-you-looking-to-get-fixed?')}</Typography>
            <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
              <TextareaAutosize
                disabled={clinicImpersonated()}
                placeholder={t('Add-comment...')}
                className={classes.textarea}
                style={{height: '6rem'}}
                value={data?.problem_question}
                onChange={(e)=> handleChange(e.target.value, 'problem_question')}
              />
            </Box>
          </Box>
          {errorMsg?.problem_question && <Typography className={classes.errorMsg}>{errorMsg.problem_question}</Typography>}

          {/* <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('Have-you-visited-you-dentist-in-the-past-6-months?')}</Typography>
            <Box className={`${classes.flexBox} ${classes.mediumWidth}`}>
                <Typography onClick={() => handleChange(true, 'visited_dentist')} className={data && data?.visited_dentist ? `${classes.btnStyle} ${classes.selected}`: `${classes.btnStyle}`}>{t('Yes')}</Typography>
                <Typography onClick={() => handleChange(false, 'visited_dentist')} className={data && !data?.visited_dentist ? data?.visited_dentist != null ?`${classes.btnStyle} ${classes.selected}`: `${classes.btnStyle}` : `${classes.btnStyle}`}>{t('No')}</Typography>
            </Box>
        </Box>

        {data?.visited_dentist && <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('According-to-your-dentist,-are-your-teeth-and-gums-healthy?')}</Typography>
            <Box className={`${classes.flexBox} ${classes.mediumWidth}`}>
                <Typography  onClick={() => handleChange(true, 'teeth_healthy')} className={data && data?.teeth_healthy ?`${classes.btnStyle} ${classes.selected}`: `${classes.btnStyle}`}>{t('Yes')}</Typography>
                <Typography onClick={() => handleChange(false, 'teeth_healthy')} className={data && !data?.teeth_healthy ? data?.teeth_healthy != null ? `${classes.btnStyle} ${classes.selected}`:  `${classes.btnStyle}` : `${classes.btnStyle}`}>{t('No')}</Typography>
            </Box>
        </Box>} */}

          {/* <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>Do you have any wisdom teeth?</Typography>
            <Box className={`${classes.flexBox} ${classes.mediumWidth}`}>
                <Typography onClick={() => handleChange("Yes", 'wisdom_teeth')} className={data?.wisdom_teeth == "Yes" ?`${classes.btnStyle} ${classes.selected}`: `${classes.btnStyle}`}>Yes</Typography>
                <Typography onClick={() => handleChange("No", 'wisdom_teeth')} className={data?.wisdom_teeth == "No" ? `${classes.btnStyle} ${classes.selected}`: `${classes.btnStyle}`}>No</Typography>
            </Box>
        </Box> 

        {data?.wisdom_teeth == 'Yes' &&<Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>According to your dentist, do your wisdom teeth need removal?</Typography>
            <Box className={`${classes.flexBox} ${classes.mediumWidth}`}>
                <Typography onClick={() => handleChange(true, 'wisdom_need_removal')} className={data && data?.wisdom_need_removal ?`${classes.btnStyle} ${classes.selected}`: `${classes.btnStyle}`}>Yes</Typography>
                <Typography onClick={() => handleChange(false, 'wisdom_need_removal')} className={data && !data?.wisdom_need_removal ? data?.wisdom_need_removal != null ? `${classes.btnStyle} ${classes.selected}` : `${classes.btnStyle}` : `${classes.btnStyle}`}>No</Typography>
            </Box>
        </Box>} */}

          {/* <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('Which-arches-do-you-want-to-treat?')}</Typography>
            <Box className={`${classes.flexBox} ${classes.seMiWidth}`}>
                <Typography onClick={() => handleChange(1, 'arches_to_treat')} className={data?.arches_to_treat == 1 ? `${classes.archesBtn} ${classes.paddingBG} ${classes.selected}`: `${classes.archesBtn} ${classes.paddingMd}`}>{t('Top')}</Typography>
                <Typography onClick={() => handleChange(2, 'arches_to_treat')} className={data?.arches_to_treat == 2 ? `${classes.archesBtn} ${classes.paddingMd} ${classes.selected}`: `${classes.archesBtn} ${classes.paddingMd}`}>{t('Bottom')}</Typography>
                <Typography onClick={() => handleChange(3, 'arches_to_treat')} className={data?.arches_to_treat == 3 ? `${classes.archesBtn} ${classes.paddingMd} ${classes.selected}`: `${classes.archesBtn} ${classes.paddingMd}`}>{t('Both')}</Typography>
            </Box>
        </Box> */}

          <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('Any-other-dental-history-we-should-know-about?')}</Typography>
            <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
              <TextareaAutosize
                disabled={clinicImpersonated()}
                placeholder={t('Add-comment...')}
                className={classes.textarea}
                style={{height: '6rem'}}
                value={data?.dental_history}
                onChange={(e)=> handleChange(e.target.value, 'dental_history')}
              />
            </Box>
          </Box>
          <Typography className={classes.errorMsg}>{errorMsg.dental_history}</Typography>

          <Checkbox
            label={t('pre-treatment-procedures-check')}
            checked={data?.dental_history_na}
            onChange={(e) => handleChange(e, 'dental_history_na')}
          />
          {data?.dental_history_na && <Box style={{margin:'1rem 0'}}>
            <Typography style={{color:'#363636'}}>{t('pre-treatment-procedures-title')}</Typography>
            {data?.list_of_procedures && data.list_of_procedures.map((proc, key) => (
              <div key={key} className={classes.procWrapper}>
                <TextareaAutosize
                  disabled={clinicImpersonated()}
                  placeholder={t('add-procedure')}
                  className={classes.textarea}
                  style={{width: '85%'}}
                  value={proc}
                  onChange={(e) => handleChangeProc(e.target.value, key, 'list_of_procedures')}
                />
                {key == data?.list_of_procedures.length - 1 && <Button disabled={clinicImpersonated()} onClick={addRow} className={classes.add}>{t('pre-treatment-procedures-add')}</Button>}
              </div>
            ))}
          </Box>}

          <Typography className={classes.errorMsg}>{errorMsg.list_of_procedures}</Typography>

          {/* <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('Please-explain-the-issue')}</Typography>
            <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                <TextareaAutosize
                    disabled={clinicImpersonated()}
                    placeholder={t('Add-comment...')}
                    className={classes.textarea}
                    style={{height: '6rem'}}
                    value={data?.explain_issue}
                    onChange={(e)=> handleChange(e.target.value, 'explain_issue')}
                />
            </Box>
        </Box> */}
          <Box style={{marginBottom:'1rem'}}>
            <Typography style={{color:'#363636'}}>{t('pain-symtpoms')}</Typography>
            <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
              <TextareaAutosize
                disabled={clinicImpersonated()}
                placeholder={t('Add-comment...')}
                className={classes.textarea}
                style={{height: '6rem'}}
                value={data?.pain_symptoms}
                onChange={(e)=> handleChange(e.target.value, 'pain_symptoms')}
              />
            </Box>
          </Box>
          <Button disabled={clinicImpersonated()} className={classes.saveBtn} onClick={handleSave}>{success ? t('Saved') : t('Save')}</Button>
        </>
      }
    </Box>
  )
}
