import { Caption, Stack, Thumbnail } from "@shopify/polaris";
import { clinicImpersonated } from "../../util/functions";
import {
  Box,
  Button,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../util/axios";
import axiosAws from "axios";
import ProcedureHeader from "./procedure-header.component";
import SinglePhoto from "./SinglePhoto";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    singlePhotoBox: {
      display: "flex",
      width: "70.19px",
      height: "109.03px",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "1rem",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    imageTitle: {
      height: "33.6px",
      width: "73px",
      color: "#212B36",
      fontSize: "9px !important",
      letterSpacing: 0,
      lineHeight: "16.8px",
      textAlign: "center",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    errorMessage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    errors: {
      color: "#D72C0D",
      fontSize: "12px !important",
      marginTop: "1rem",
    },
  };
});
const Xray = ({
  data = { data: {}, isAllProvided: null },
  appointmentId,
  userId,
  appointmentType,
  handleTabsExpand,
  index,
  openCloseTabs,
  scrollToExpandedTab,
}) => {
  const classes = useStyles();
  const { data: xrayData, isAllProvided } = data;
  const xrayPreview = xrayData?.x_ray_url;
  const [iconXray, setIconXray] = useState(isAllProvided);
  const [imagePreviewXRay, setImagePreviewXRay] = useState(xrayPreview);
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [popupActive, setPopupActive] = useState(false);
  const [filesXRay, setFilesXRay] = useState([]);
  const [imageKeyXRay, setImageKeyXRay] = useState("");
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [xRayErrorMsg, setXRayErrorMsg] = useState("");
  const [uploadProgress, setUploadProgress] = useState({});

  const handleIconXrayView = (value) => {
    setIconXray(value);
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const handleSave = async () => {
    setSuccess(false);
    setXRayErrorMsg("");
    const arrayOfKeys = [imageKeyXRay];
    const arrayOfStrings = ["x_ray"];
    let bodyObj = {};
    for (let i = 0; i < arrayOfKeys.length; i++) {
      if (arrayOfKeys[i] !== "") {
        bodyObj[arrayOfStrings[i]] = arrayOfKeys[i];
      }
    }
    if (
      !Object.values(bodyObj).every(
        (x) => x === null || x === "" || x === undefined
      )
    ) {
      setPopupActive(true);
      try {
        const response = await axios.post(
          `admin/v2/users/${userId}/case-record-xray`,
          {
            x_ray: imageKeyXRay,
            procedure: appointmentType?.procedure,
            user_scan_info_id: appointmentId,
          }
        );
        if (response.status === 200) {
          if (imageKeyXRay !== "") {
            setImageKeyXRay("");
          }
          handleIconXrayView(response?.data?.isAllProvided);
          setSuccess(true);
          setPopupActive(false);
        }
      } catch (error) {
        setSuccess(false);
        setPopupActive(false);
        if (imageKeyXRay !== "") {
          setXRayErrorMsg(t("upload-failed"));
        }
        console.log(error);
      }
    }
  };

  const sendImageXRay = useCallback(
    async (filesXRay) => {
      setSuccess(false);
      setPopupActive(true);
      setImagePreviewXRay(
        validImageTypes.indexOf(filesXRay[filesXRay.length - 1].type) > 0
          ? window.URL.createObjectURL(filesXRay[filesXRay.length - 1])
          : "/assets/images/Fileicon.svg"
      );
      const imageType = filesXRay[filesXRay.length - 1].type;
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesXRay[filesXRay.length - 1].name);
      try {
        const response = await axios.post("/admin/v1/images/s3", form_data);
        if (response.status === 200) {
          setImageKeyXRay(response.data.key);
          const url2 = response.data.url;
          try {
            const awsResponse = await axiosAws.put(
              url2,
              filesXRay[filesXRay.length - 1],
              {
                headers: {
                  "x-amz-acl": ["public-read-write"],
                  "Content-Type": imageType,
                },
                onUploadProgress: (event) => uploadConfig(event, "x_ray"),
              }
            );
            if (awsResponse.status === 200) {
              setCallHandleSave(1);
            }
          } catch (error) {
            console.log("AWS Error", error);
            setPopupActive(false);
            setFilesXRay([]);
            setImageKeyXRay("");
            setImagePreviewXRay("");
            setXRayErrorMsg(t("upload-failed"));
          }
        }
      } catch (error) {
        console.log(error);
        setPopupActive(false);
        setFilesXRay([]);
        setImageKeyXRay("");
        setImagePreviewXRay("");
        setXRayErrorMsg(t("upload-failed"));
      }
    },
    [filesXRay]
  );

  useEffect(() => {
    scrollToExpandedTab(index, "xray");
  }, [openCloseTabs]);

  useEffect(() => {
    if (callHandleSave === 1) {
      void handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="xray"
      headerTitle="X-Ray"
      index={index}
      iconCheck={iconXray}
    >
      {openCloseTabs[index]?.xray && (
        <Box className={`${classes.wrapper} xray-${index}`}>
          <Box className={classes.singlePhotoBox}>
            <SinglePhoto
              disable={clinicImpersonated() || popupActive}
              value={imagePreviewXRay}
              handleImages={sendImageXRay}
              id={"X-Ray"}
            />
            <Typography className={classes.imageTitle}>{t("X-Ray")}</Typography>
            {uploadProgress?.x_ray && (
              <Box>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress?.x_ray}
                />
              </Box>
            )}
          </Box>
          <Box className={classes.errorMessage}>
            <Button
              disabled={clinicImpersonated()}
              className={classes.saveBtn}
              onClick={handleSave}
            >
              {success ? t("Saved") : t("Save")}
            </Button>
            <Typography className={classes.errors}>{xRayErrorMsg}</Typography>
          </Box>
        </Box>
      )}
    </ProcedureHeader>
  );
};

export default Xray;
