import { Box, makeStyles, Typography,Checkbox ,FormControlLabel, Button, Menu, Grid, MenuItem, Radio, CircularProgress, RadioGroup } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import EnhancedTable from '../DataTable/EnhancedTable';
import axios from '../../util/axios'
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import instance from '../../util/axios';
import Note from '../Note/Note';
import Edit  from '../Note/EditNote' 
import "../../App.css";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import { clinicImpersonated, convertNumberToArabic, handleReadNotes } from '../../util/functions';
import ActiveContext from '../../Context/Context';
import DoctorsDropdownContainer from "../Appointments/doctors-dropdown-container.component";
import { TableActionsConstants } from "../../util/constants/table-actions.constants";

const useStyles=makeStyles(()=> ({
    boxWrapper: {
    },
    box:{
        display:'flex',
        alignItems:"center",
        justifyContent:"space-around",
        flexWrap:"wrap"
    },
    filterBox: {
        width: "60%",
        marginLeft: "auto"
    },
    checkContainer: {
        visibility: ({walkIn}) => walkIn ? 'hidden' : 'visible',
        flex: "1 1 auto",
        margin: 0,
        minWidth: 'fit-content',
        justifyContent: "center",
    },
    menuBox: {
        display: "flex"
    },
    filterButtonWithArrow: {
        border: '1px lightgray solid',
        borderRadius: 0
    },
    loadingBox:{
        margin: "0 auto",
        textAlign: "center"
    },
    RadioGroup:{
        "& li":{
            paddingLeft:0
        }
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
    appointmentHide:{
        color: '#212B36',
        fontWeight: '400',
        fontSize: '12px!important'
    },
    alertBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    alert: {
        alignItems: 'center',
    },
    searchBar: {

        "&:focus":{
            outlineColor: "#ff2c46",
            outlineStyle: "solid",
        },
        // end
        width: '60%',
        paddingLeft: 10,
        height:41,
        border: '1px lightgray solid',
        '&::placeholder': {
            fontWeight: 400,
          },
          "& label.Mui-focused": {
            color: "#ff2c46"
          },
    },
    filterWrapper: {
        flex:"1 1 auto",
        justifyContent:"center",
        display: "flex",
        padding: 20
    },
    addNote: {
        width:"25%",
        textAlign: 'center',
        marginTop: 10,
        backgroundColor:"#FF2C46",
        float:"right",
        boxSizing:" border-box",
        border: "1px solid #C4CDD5",
        borderRadius: "3px",
        boxShadow:" 0 1px 0 0 rgba(22,29,37,0.05)",
    
     },
    appointment: {
        flex: "1 1 auto",
        display: "inline-block",
        minWidth: "fit-content",
        color: "#9B9BA1",
        fontWeight: "Eina-semi!important",
        fontSize: "14px!important",
        textAlign: "center",
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    }));

export default function Ipr(props){
    const { walkIn } = props;
    const classes = useStyles({walkIn});
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([])
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [field, setField] = useState('');
    const [sort, setSort] = useState('desc')
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [notificationLoading, setnotificationLoading] = useState(true)
    const[openEditNote,setOpenEditNote]=useState(0)
    const[notes,setNotes]=useState([])
    const[openAddNote,setOpenAddNote]=useState(0)
    const[newNote,setNewNote]=useState("");
    const [showArchived, setShowArchived] = useState(false);
    const [singleRow, setSingleRow] = useState({})
    const[editNote,setEditNote]=useState({})
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [openActionConfirm, setOpenActionConfirm] = useState({open : false, data: null});
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState("");
    const [otherDoctor, setOtherDoctor] = useState("");
    const [enableDialog, setEnableDialog] = useState(false);
    const tableActions = TableActionsConstants;
    const handleCloseScheduleDialog = () => {
        setOpenScheduleDialog(false);
    }
    const {
      openNote,
      actions: { setOpenNote, setPendingBooking },
    } = useContext(TabsControllerContext);
    const {
        filesImage,setFilesImage,imagesKey,setImageKey,images,setImages
      } = useContext(ActiveContext);

    const handleOpenScheduleDialog = (row) => {
        setOpenScheduleDialog(true);
        setSingleRow(row)
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
        setMessage('')
    }

    const handleConfirmSchedule = async(row) => {
        setLoading(true);
        const lastName = row.last_name;
        const firstName = row.first_name;
        const email = row.user_email;
        const phone = row.user_phone;
        let sentId, isAppointment, scn;
        scn = row.scn ?? '';
        if (walkIn) {
            sentId = row.id ?? '';
            isAppointment = true;
        } else {
            if (scn === '') {
                scn = row.procedure_scn;
            }
            sentId = row.ipr_id ?? '';
            isAppointment = false;
        }
        const clinicId = Cookies.get('clinic_id');
        const query = `id=${sentId}&isAppointment=${isAppointment}&scn=${scn}&clinicId=${clinicId}&isReschedule=false`;
        const response = await axios.get(`admin/v1/clinic/${clinicId}/reschedule/booking/${row.user_id}?${query}`)
        setLoading(false);
        if (response?.data?.error) {
            setOpenAlert(true);
            setError(true);
            setMessage(t('This-update-is-not-eligible'));
        } else if(response.data.eligible === true) {
            const pendingBookingId = response?.data?.pendingBookingId;
            setPendingBooking({ id: pendingBookingId, isReschedule: false });
            const query = `id=${row.user_id}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}`;
            let navigateToUrl = `/dashboard/booking?${query}`;
            if (walkIn) {
              navigateToUrl += `&bas_schedule=true${row.id ? '&usiId=' + row.id : ''}`;
            } else {
              navigateToUrl += '&proc_schedule=true';
            }
            navigate(navigateToUrl);
        } else {
            setOpenAlert(true)
            setMessage(t('This-user-is-not-eligible-to-book-a-scan'))
        }
        setOpenScheduleDialog(false);
    }

    useEffect(()=>{
        setLoading(true);
        const newField = field == 'user_first_name'?'first_name': field == 'user_sub_status'?'sub_status': field == 'user_last_name'?'last_name':field;
        axios.get(`/admin/v1/${walkIn ? 'clinic-walk-in' : 'clinic-ipr'}?is_archived=${showArchived ? 1 : 0}&clinic_id=${Cookies.get('clinic_id')}&per_page=${perPage}&page=${page}&field=${newField}&type=${sort}`)
        .then((response) => {
            const dat = response.data.data
            setData(dat)
            setTotal(response.data.total)
        }).catch((err)=> {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    },[page,perPage,field,sort, showArchived])
    
    const headCells = [
        {
            id: 'user_id',
            label: t('ID'),
        },
        {
            id: 'first_name',
            label: t('First-Name'),
            from:'appointments',
            type:"appointment_name"
        },
        {
            id: 'last_name',
            label: t('Last-Name'),
            type:"appointment_name"
        },
        {
            id: 'user_step',
            label: t('Step'),
            type:"",
            hideSort: walkIn
        },
        {
            id: 'user_type',
            label: t('Type'),
            type:'type',
            hideSort: true
        },
        {
            id: 'user_sub_status',
            label: t('Status'),
            type: '',
            hideSort: true
        },
        {
            id: 'schedule_before',
            label: t('Schedule-before'),
            type:'schedule',
            hideSort: true
        },
        {
            id: 'user_action',
            label: t('Action'),
            type: 'menu',
            hideSort: true,
        },
        {
            id: 'warning_appointment',
            label: '',
            type: '',
            hideRow: true,
        },
    ]
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()

    useEffect(async()=>{
        if(!openNote){
            setNotes([])
            setOpenEditNote(0)
            setEditNote({})
            return;//not chosen a note
        }
        if(!openEditNote){
            //not chosen a comment to edit but chosen a user to view the note
            try{
            setnotificationLoading(true)
            const res= await axios.get(`/admin/v1/users/${openNote}/comments`)
            if(res.data.success){
                setNotes(res.data.data.data)
                const newData = handleReadNotes(data, openNote, 'unread_comments', false)
                setData(newData)
            }
            }catch(err){
                console.log(err)
            }finally{
            setnotificationLoading(false)
            }
            
        }
        else{
            //chosen a comment to edit display it
            try{
                setnotificationLoading(true)
                const res= await axios.get(`/admin/v1/users/${openNote}/comments/${openEditNote}`)
                if(res.data.success){
                    setEditNote(res.data.data.comment)
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
        }
    

    },[openNote,openEditNote,openAddNote])
    const handleCloseNote=()=>{
        if(openEditNote){
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            setNotes([])
            setNewNote("")
            setOpenAddNote(0)
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleConfirmNote=async()=>{
        if(openEditNote){
            //update before closing edit modal
            let body={comment:editNote,images:imagesKey}
            try{
                setnotificationLoading(true)
                const res= await axios.post(`/admin/v1/users/${openNote}/comments/${openEditNote}`,body)
                if(res.data.success){
                console.log('updated')
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            if(newNote){
                let body={comment:newNote,images:imagesKey}
                try{
                    setnotificationLoading(true)
                    const res= await axios.post(`/admin/v1/users/${openNote}/comments`,body)
                    if(res.data.success){
                    console.log('added')
                    }
                    }catch(err){
                        console.log(err)
                    }finally{
                    setOpenAddNote(0)
                    setNewNote("")
                    }
                }
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    
    const handleOutsideClick=()=>{
        setOpenNote(0)
        setOpenAddNote(0)
        setOpenEditNote(0)
        setNewNote("")
        setEditNote({})
        setFilesImage([])
        setImageKey([])
        setImages([])
    }

    const updatePatientActions = async (value, userId, scanId = 0, dialog, procedureId = null)=>{
        if(value == 'Completed' && !dialog){
            setOpenActionConfirm({open : true , data : { value, userId, scanId }});
        }else{
            setLoading(true);
            let doctor = selectedDoctor;
            if (selectedDoctor === "Other") {
                doctor = otherDoctor;
            }
            try{
                const clinicId = Cookies.get("clinic_id");
                const response = await instance.put(`/admin/v1/clinic/user/${userId}/sub-status/update`,{
                    action: value,
                    scan_id : scanId,
                    isWalkIn : walkIn,
                    timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone,
                    clinicId,
                    procedureId,
                    doctor
                });
            const dataRes = response.data;
            if(dataRes.success){
                if(dataRes?.booking_status_completed){
                    const appointmentTypes = data.filter(
                      (data) => data.id === scanId
                    )[0]?.user_type;
                    navigate(
                      `/dashboard/users/${userId}/appointments/${scanId}/edit-patient-data`,
                      { state: appointmentTypes }
                    );
                } else if(dataRes?.action_name === "No Answer") {
                    const procedureId = dataRes?.procedure_id;
                    const newSubStatus = dataRes?.user_sub_status;
                    const rowToUpdate = data.find(datum => datum.ipr_id === procedureId);
                    if (rowToUpdate) {
                        rowToUpdate.user_sub_status = newSubStatus;
                        rowToUpdate.user_action = [
                            { action: 'Reschedule', disabled: false },
                        ];
                    }
                } else if (dataRes.action_name === 'Cancel') {
                    const newSortedRow = data.filter((row) => row.user_id !== userId );
                    setData(newSortedRow);
                    setTotal(newSortedRow.length)
                } else if (dataRes.action_name === tableActions.DONE_ELSEWHERE) {
                    const procedureId = dataRes?.procedure_id;
                    const newSortedRow = data.filter((row) => row.ipr_id !== procedureId );
                    setData(newSortedRow);
                    setTotal(newSortedRow.length)
                } else if (
                    dataRes.action_name === tableActions.FIRST_CALL_ATTEMPTED ||
                    dataRes.action_name === tableActions.SECOND_CALL_ATTEMPTED ||
                    dataRes.action_name === tableActions.WHATSAPP_SENT
                ) {
                    const procedureId = dataRes?.procedure_id;
                    const newSubStatus = dataRes?.user_sub_status;
                    const rowToUpdate = data.find(datum => datum.ipr_id === procedureId);
                    if (rowToUpdate) {
                        const actions = dataRes?.user_action;
                        rowToUpdate.user_sub_status = newSubStatus;
                        rowToUpdate.user_action = actions;
                    }
                }
            }else{
                setOpenAlert(true)
                setMessage(t('This-user-is-not-eligible-to-book-a-scan'))
            }
                setLoading(false);
            }catch(err){
                console.log('error', err);
                setOpenAlert(true)
                setMessage(t('This-user-is-not-eligible-to-book-a-scan'));
                setLoading(false);
            }
        }
    }


    return (
        (loading || !page )? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box> : <Box className={classes.boxWrapper}>
            {openAlert && (
                <Alert
                    open={openAlert}
                    className={classes.alert}
                    severity="error"
                    onClose={handleCloseAlert}
                >
                    <Box className={classes.alertBox}>
                        {message}
                        {error && (
                            <Button variant="outlined" onClick={refreshPage} color="inherit" size="small">
                                {t('refresh')}
                            </Button>
                        )}
                    </Box>
                </Alert>
            )}
            <Box className={`${classes.box} ${classes.filterBox}`}>
                <Typography variant='body1' className={classes.appointment} >
                    {convertNumberToArabic( t, total)} {t('Appointment')}{total === 0 || total > 1 ? i18n.language == 'ar' ? '' : 's' : ''}
                </Typography>
                <FormControlLabel
                    className={classes.checkContainer}
                    control={
                        <Checkbox
                            checked={showArchived}
                            onClick={() => {
                                setShowArchived((prev) => {
                                    return !prev;
                                });
                            }}
                        />
                    }
                    label={
                        <Typography
                            variant="body1"
                            className={`${classes.appointmentHide} ${
                                i18n.language == "ar"
                                    ? classes.fontFamilyAlmarai
                                    : classes.fontFamilyEina
                            }`}
                        >
                            {t("show-archived-procedures")}
                        </Typography>
                    }
                />
            </Box>
            <Box className={classes.box}>
           {data && <EnhancedTable
                rowsData={data}
                headCells={headCells}
                setPage={setPage}
                perPage={perPage}
                handleOpenScheduleDialog={handleConfirmSchedule}
                page={page}
                field={field}
                setField={setField}
                sort={sort}
                setSort={setSort}
                total={total}
                ipr={true}
                updatePatientActions={updatePatientActions}
                setPerPage={setPerPage}
                setOpenNote={setOpenNote}
                ></EnhancedTable>}
           </Box>
           <ConfirmationDialog  
                open={openNote>0}
                handleOutsideClick={()=>{handleOutsideClick()}}
                handleClose={()=>{handleCloseNote()}}
                handleConfirm={()=>{handleConfirmNote()}}
                confirmationButtonText={openEditNote||openAddNote ? t("Save")  : ""}
                cancelButtonText=  {openEditNote||openAddNote ? t("Cancel")  : ""}  
                dialogTitle= {openEditNote ? t("Edit Note")  :openAddNote? t("Add Note") : t("Notes")}  
                dialogContentText=""
                loading={notificationLoading}
            > 
            {openEditNote  ? 
                <Edit data={editNote} setData={setEditNote} />
                : openAddNote ? <Edit data={newNote} setData={setNewNote} />:<><Note  setOpenEditNote={setOpenEditNote} data={notes} setnotificationLoading={setnotificationLoading}/>
                <Button disabled={clinicImpersonated()} className={`${classes.addNote} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} color="secondary" variant='contained' onClick={()=>{setOpenAddNote(1)}}>{t('Add-Note')}</Button></>}
            </ConfirmationDialog>
            <ConfirmationDialog
                open={openScheduleDialog}
                handleClose={handleCloseScheduleDialog}
                handleConfirm={handleConfirmSchedule}
                confirmationButtonText={t('Confirm')}
                cancelButtonText={t('Cancel')}
                dialogTitle={t('Schedule')}
                dialogContentText={t('Are-you-sure-you-want-to-Schedule?')}
            
           />
           <ConfirmationDialog
                open={openActionConfirm.open}
                handleConfirm={() => updatePatientActions(openActionConfirm?.data?.value, openActionConfirm?.data?.userId, openActionConfirm?.data?.scanId, true)}
                dialogTitle={t('Confirm')}
                notes={true}
                confirmationButtonText={t('Yes-mark-appointment-as-completed')}
                dialogContentText={t('mark-the-appointment-completed-confirmation')}
                isWarning={true}
                handleClose={()=>(setOpenActionConfirm(false))}
                loading={loading}
                confirmBtnStyle={{width:'fit-content'}}
                enableDialog={enableDialog}
           >
               <DoctorsDropdownContainer
                   selectedDoctor={selectedDoctor}
                   setSelectedDoctor={setSelectedDoctor}
                   setEnableDialog={setEnableDialog}
                   otherDoctor={otherDoctor}
                   setOtherDoctor={setOtherDoctor}
               />
           </ConfirmationDialog>
        </Box>
    )
}