import {
  Box,
  Button,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../util/axios";
import axiosAws from "axios";
import SinglePhoto from "./SinglePhoto";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../util/functions";
import { Checkbox } from "@shopify/polaris";
import ProcedureHeader from "./procedure-header.component";
import leftBite from '../../assets/images/fv-info-details/leftbite.svg';
import lowerOcclusal from '../../assets/images/fv-info-details/Bottombite.svg';
import rightBite from '../../assets/images/fv-info-details/rightbite.svg';
import upperOcclusal from '../../assets/images/fv-info-details/Topbite.svg';
import centerBite from '../../assets/images/fv-info-details/centerbite.svg';
import frontSmile from '../../assets/images/fv-info-details/frontbigsmile.svg';
import frontNoSmile from '../../assets/images/fv-info-details/frontnosmile.svg';
import profile from '../../assets/images/fv-info-details/profile.svg';

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoCheckWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    checkBoxWrapper: {
      transform: "scale(0.7)",
      marginTop: "8px",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    singlePhotoBox: {
      display: "flex",
      width: "70.19px",
      height: "109.03px",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "1rem",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    errorMessage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    title: {
      height: "16px",
      width: "134px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
      marginBottom: "1rem",
    },
    errors: {
      color: "#D72C0D",
      fontSize: "12px !important",
      marginTop: "1rem",
    },
    error: {
      color: "#D72C0D",
      fontSize: "12px !important",
    },
    imageTitle: {
      height: "33.6px",
      width: "73px",
      color: "#212B36",
      fontSize: "9px !important",
      letterSpacing: 0,
      lineHeight: "16.8px",
      textAlign: "center",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
  };
});
export default function AppointmentPhotos({
  disable,
  appointmentId,
  appointmentType,
  handleTabsExpand,
  index,
  data = { isAllProvided: null, data: {} },
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const { data: photosData, isAllProvided } = data;
  const { id } = useParams();
  const classes = useStyles();
  const [photos, setPhotos] = useState(photosData);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [imageKey, setImageKey] = useState({});
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [iconPhotos, setIconPhotos] = useState(isAllProvided);
  const [success, setSuccess] = useState(false);
  const [fetchById, setFetchById] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [notApplicable, setNotApplicable] = useState({
    front_facing_big_smile_na: false,
    front_facing_no_smile_na: false,
    right_side_profile_no_smile_na: false,
  });
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const { t } = useTranslation();

  const handlePhotosValue = (value) => {
    setPhotos(value);
  };

  const handleIconPhotosView = (value) => {
    setIconPhotos(value);
  };

  const handleImages = (files, id) => {
    setErrorMsg("");
    setSuccess(false);
    setDisabled(true);
    const imageId = id;
    const file = files[0];
    let imgUrl =
      validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : "/assets/images/Fileicon.svg";
    setImagePreview((prev) => ({
      ...prev,
      [imageId]: imgUrl,
    }));
    saveInAws(file, imageId);
  };

  const handleUploadError = (type, err) => {
    setErrorMsg("Upload failed. Please try again.");
    setImagePreview((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setImageKey((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    console.log(err);
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const saveInAws = (value, type) => {
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios
      .post("/admin/v1/images/s3", form_data)
      .then((res) => {
        setImageKey((prev) => ({
          ...prev,
          [type]: res.data.key,
        }));
        const url2 = res.data.url;
        axiosAws
          .put(url2, value, {
            headers: {
              "x-amz-acl": ["public-read-write"],
              "Content-Type": imageType,
            },
            onUploadProgress: (event) => uploadConfig(event, type),
          })
          .then((res) => {
            setCallHandleSave(1);
          })
          .catch((err) => {
            setDisabled(false);
            handleUploadError(type, err);
          });
      })
      .catch((err) => {
        setDisabled(false);
        handleUploadError(type, err);
      });
  };

  const handleSave = () => {
    setErrorMsg("");
    let body = {
      ...imageKey,
      ...notApplicable,
      procedure: appointmentType?.procedure,
      user_scan_info_id: appointmentId,
    };
    if (
      !Object.values(body).every(
        (x) => x === null || x === "" || x === undefined
      )
    ) {
      axios
        .post(`admin/v2/users/${id}/case-record-screening-photos`, body)
        .then((res) => {
          if (res.data.success) {
            handleDatafetched(res.data.data);
            handlePhotosValue(res.data.data);
            handleIconPhotosView(res.data.isAllProvided);
            setFetchById(true);
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        })
        .catch((err) => {
          setErrorMsg("Upload failed. Please try again.");
          console.log(err);
        })
        .finally(() => setDisabled(false));
    }
  };

  const handleChangeNotApplicable = (prop) => {
    setNotApplicable((prev) => ({ ...prev, [prop]: !prev[prop] }));
    setCallHandleSave(1);
  };

  const handleDatafetched = (value) => {
    setImagePreview({
      front_facing_big_smile: value?.front_facing_big_smile_url ? value?.front_facing_big_smile_url : frontSmile,
      front_facing_no_smile: value?.front_facing_no_smile_url ? value?.front_facing_no_smile_url : frontNoSmile,
      right_side_profile_no_smile: value?.right_side_profile_no_smile_url ? value?.right_side_profile_no_smile_url : profile,
      center_bite: value?.center_bite_url ? value?.center_bite_url : centerBite,
      right_bite: value?.right_bite_url ? value?.right_bite_url : rightBite,
      left_bite: value?.left_bite_url ? value?.left_bite_url : leftBite,
      upper_teeth: value?.upper_teeth_url ? value?.upper_teeth_url : upperOcclusal,
      lower_teeth: value?.lower_teeth_url ? value?.lower_teeth_url : lowerOcclusal,
    });
    setNotApplicable({
      front_facing_big_smile_na: value?.front_facing_big_smile_na,
      front_facing_no_smile_na: value?.front_facing_no_smile_na,
      right_side_profile_no_smile_na: value?.right_side_profile_no_smile_na,
    });
  };

  useEffect(() => {
    handleDatafetched(photos);
  }, [fetchById]);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, "photos");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="photos"
      headerTitle="Photos"
      index={index}
      iconCheck={iconPhotos}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.photoWrapper} photos-${index}`}>
          <Typography className={classes.title}>
            {t("Screening Photos")}
          </Typography>
          <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
            <div className={classes.photoCheckWrapper}>
              <Box className={classes.singlePhotoBox}>
                <SinglePhoto
                  disable={disable || disabled}
                  value={imagePreview?.front_facing_big_smile}
                  handleImages={handleImages}
                  id={"front_facing_big_smile"}
                  title={imagePreview?.front_facing_big_smile !== frontSmile ? t('Replace') : t('Upload-image')}
                />
                <Typography className={classes.imageTitle}>
                  {t("Front-Facing-Big-Smile")}
                </Typography>
                {uploadProgress?.front_facing_big_smile && (
                  <Box>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                  </Box>
                )}
              </Box>
              <div className={classes.checkBoxWrapper}>
                <Checkbox
                  label={t("not-applicable")}
                  checked={notApplicable.front_facing_big_smile_na}
                  onChange={() =>
                    handleChangeNotApplicable("front_facing_big_smile_na")
                  }
                />
              </div>
            </div>

            <div className={classes.photoCheckWrapper}>
              <Box className={classes.singlePhotoBox}>
                <SinglePhoto
                  disable={disable || disabled}
                  value={imagePreview?.front_facing_no_smile}
                  handleImages={handleImages}
                  id={"front_facing_no_smile"}
                  title={imagePreview?.front_facing_no_smile !== frontNoSmile ? t('Replace') : t('Upload-image')}
                />
                <Typography className={classes.imageTitle}>
                  {t("Front-Facing-No-Smile")}
                </Typography>
                {uploadProgress?.front_facing_no_smile && (
                  <Box>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress?.front_facing_no_smile}
                    />
                  </Box>
                )}
              </Box>
              <div className={classes.checkBoxWrapper}>
                <Checkbox
                  label={t("not-applicable")}
                  checked={notApplicable.front_facing_no_smile_na}
                  onChange={() =>
                    handleChangeNotApplicable("front_facing_no_smile_na")
                  }
                />
              </div>
            </div>

            <div className={classes.photoCheckWrapper}>
              <Box className={classes.singlePhotoBox}>
                <SinglePhoto
                  disable={disable || disabled}
                  value={imagePreview?.right_side_profile_no_smile}
                  handleImages={handleImages}
                  id={"right_side_profile_no_smile"}
                  title={imagePreview?.right_side_profile_no_smile !== profile ? t('Replace') : t('Upload-image')}
                />
                <Typography className={classes.imageTitle}>
                  {t("Profile-No-Smile")}
                </Typography>
                {uploadProgress?.right_side_profile_no_smile && (
                  <Box>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress?.right_side_profile_no_smile}
                    />
                  </Box>
                )}
              </Box>
              <div className={classes.checkBoxWrapper}>
                <Checkbox
                  label={t("not-applicable")}
                  checked={notApplicable.right_side_profile_no_smile_na}
                  onChange={() =>
                    handleChangeNotApplicable("right_side_profile_no_smile_na")
                  }
                />
              </div>
            </div>
          </Box>

          <Box className={`${classes.photoBox} ${classes.photoWidth}`}>
            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={imagePreview?.center_bite}
                handleImages={handleImages}
                id={"center_bite"}
                title={imagePreview?.center_bite !== centerBite ? t('Replace') : t('Upload-image')}
              />
              <Typography className={classes.imageTitle}>
                {t("Center-Bite")}
              </Typography>
              {uploadProgress?.center_bite && (
                <Box>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress?.center_bite}
                  />
                </Box>
              )}
            </Box>

            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={imagePreview?.right_bite}
                handleImages={handleImages}
                id={"right_bite"}
                title={imagePreview?.right_bite !== rightBite ? t('Replace') : t('Upload-image')}
              />
              <Typography className={classes.imageTitle}>
                {t("Right-Bite")}
              </Typography>
              {uploadProgress?.right_bite && (
                <Box>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress?.right_bite}
                  />
                </Box>
              )}
            </Box>
            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={imagePreview?.left_bite}
                handleImages={handleImages}
                id={"left_bite"}
                title={imagePreview?.left_bite !== leftBite ? t('Replace') : t('Upload-image')}
              />
              <Typography className={classes.imageTitle}>
                {t("Left-Bite")}
              </Typography>
              {uploadProgress?.left_bite && (
                <Box>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress?.left_bite}
                  />
                </Box>
              )}
            </Box>

            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={imagePreview?.upper_teeth}
                handleImages={handleImages}
                id={"upper_teeth"}
                title={imagePreview?.upper_teeth !== upperOcclusal ? t('Replace') : t('Upload-image')}
              />
              <Typography className={classes.imageTitle}>
                {t("Top-Bite")}
              </Typography>
              {uploadProgress?.upper_teeth && (
                <Box>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress?.upper_teeth}
                  />
                </Box>
              )}
            </Box>

            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={imagePreview?.lower_teeth}
                handleImages={handleImages}
                id={"lower_teeth"}
                title={imagePreview?.lower_teeth !== lowerOcclusal ? t('Replace') : t('Upload-image')}
              />
              <Typography className={classes.imageTitle}>
                {t("Bottom-Bite")}
              </Typography>
              {uploadProgress?.lower_teeth && (
                <Box>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress?.lower_teeth}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.errorMessage}>
            <Button
              disabled={clinicImpersonated()}
              className={classes.saveBtn}
              onClick={handleSave}
            >
              {success ? t("Saved") : t("Save")}
            </Button>
            <Typography className={classes.errors}>{errorMsg}</Typography>
          </Box>
        </Box>
      )}
    </ProcedureHeader>
  );
}
