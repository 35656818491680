import { styled, tableCellClasses, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useEffect, useState } from "react";
import Layout from "../../../Components/TopNav/Topnav";

const FirstVisitAppointmentProcedures = ({ align, isPage = true }) => {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);
  
  const Component = ({ t, align, isArabic }) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        fontWeight: "bold",
      },
    }));

    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };
  
    const divStyles = {
      mb: 2,
      mt: 2,
      textAlign: align,
      color: "#ff2c46",
      padding: 2,
      borderRadius: "5px",
      fontWeight: "bold",
    };
  
    const rows = [
      {
        name: `1. ${t("waiting-time")}`,
        duration: t("lessthan10"),
        responsible: t("medical-assistant"),
      },
      {
        name: `2. ${t("welcome")}`,
        duration: "5",
        responsible: t("medical-assistant"),
      },
      {
        name: `3. ${t("opg-x-ray")}`,
        duration: "5",
        responsible: t("medical-assistant"),
      },
      {
        name: `4. ${t("dental-checkup")}`,
        duration: "5",
        responsible: t("medical-assistant"),
      },
      { name: `5. ${t("cleaning")}`, duration: "5", responsible: t("doctor") },
      { name: `6. ${t("photos")}`, duration: "5", responsible: t("doctor") },
      {
        name: `7. ${t("intra-oral-scan-explanation")}`,
        duration: "10",
        responsible: t("doctor"),
      },
      {
        name: `8. ${t("show-the-box")}`,
        duration: "5",
        responsible: t("doctor"),
      },
  
      {
        name: t("total-treatment-time"),
        duration: t("sixty-minutes"),
        responsible: "",
      },
    ];
  
    return (
      <div
        className="learning_container"
        style={{ backgroundColor: "transparent" }}
      >
        <Typography
          variant="p"
          sx={{
            mb: 2,

            padding: 2,
            borderRadius: "5px",
          }}
          component="p"
        >
          {t("learn-more-intro-1")}
          <br />
          <br />
          {t("learn-more-intro-2")}
          <br />
          <br />
          {t("learn-more-intro-3")}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="one-heading-table">
                <StyledTableCell variant="head" align="center" colSpan={3}>
                  {t("learn-more-table-header")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <StyledTableCell variant="head" align={align}>
                  {t("task")}
                </StyledTableCell>
                <StyledTableCell variant="head" align={align}>
                  {t("duration")}
                </StyledTableCell>
                <StyledTableCell variant="head" align={align}>
                  {t("responsible")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      backgroundColor: "green",
                      color: "white",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableCell align={align} component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align={align}>{row.duration}</TableCell>
                  <TableCell align={align}>{row.responsible}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            1.{t("waiting-time")}{" "}
            <span style={{ color: "#ff2c46" }}>{`(${t(
              "lessthan10min"
            )})`}</span>
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("learn-more-waiting-1")}</li>
            <li>{t("learn-more-waiting-2")}</li>
            <li>{t("learn-more-waiting-3")}</li>
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            2.{t("learn-more-welcome")}{" "}
            <span style={{ color: "#ff2c46" }}>{`(5 ${t("minutes")})`}</span>
          </Typography>

          <ul style={unorderedListStyle}>
            <li>{t("learn-more-welcome-1")}</li>
            <li>{t("learn-more-welcome-2")}</li>
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            3.{t("opg-x-ray")}{" "}
            <span style={{ color: "#ff2c46" }}>{`(5 ${t("minutes")})`}</span>
          </Typography>

          <ul style={unorderedListStyle}>
            <li>{t("learn-more-opg-1")}</li>
            <li>{t("learn-more-opg-2")}</li>
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            4.{t("dental-checkup")}{" "}
            <span style={{ color: "#ff2c46" }}>{`(5 ${t("minutes")})`}</span>
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("learn-more-dental-1")}</li>
            <li>{t("learn-more-dental-2")}</li>
            <li>{t("learn-more-dental-3")}</li>
          </ul>
        </Typography>
      </div>
    )
  }

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
};

export default FirstVisitAppointmentProcedures;
