import { DropzoneArea } from 'material-ui-dropzone';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { Button, Caption, Card, DropZone, FormLayout,Icon,Modal, Page, PageActions, Stack, TextStyle, Thumbnail } from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from "../TopNav/Topnav";
import UserPhotoIdContext from '../../Context/UserPhotoId/UserPhotoIdContext';
import Cookie from 'js-cookie';
import axios from 'axios';
import instance from '../../util/axios';
import { DeleteMinor } from "@shopify/polaris-icons"
import { useTranslation } from 'react-i18next';
import { clinicImpersonated } from '../../util/functions';
import Cookies from 'js-cookie';

const useStyles=makeStyles((theme)=>{ return {
    loadingBox:{
        margin: "0 auto",
        textAlign: "center",
        padding : 30
    },
    margin :{
        margin:"0.5rem"
    },
    label:{
        fontWeight: "700", 
        fontSize:"1.3rem !important",
    },
    btnBox:{
        width:"100%",
        margin:"1.5rem 0" 
    },
    btn:{
        marginLeft: "auto",
        padding:"0.5rem 3rem",
        backgroundColor: "#ff2c46",
        color:"#fff",
        "&:hover":{
            backgroundColor: "rgb(255,44,70,0.7)",
        }
    },
    imageWrapper: {
        display:"grid",
        gridTemplateColumns:"1fr 1fr",
        gridGap:"40px",
        ['@media only screen and (max-width: 900px) '] : {
           display:"grid",
           gridTemplateColumns:"1fr !important"
           },
    },
    image:{
        width:"100%",
        height:"15rem"
    },
    deleteBtn:{
        '&:hover':{
            backgroundColor:"#fff",
            minWidth:0
        } 
    }
 } });
function AddPhotoIdImages({ api, title, key1, key2, photoTitle1, photoTitle2 }) {
    const { id } = useParams();
    const classes = useStyles();
    // useEffect(()=>{
    //     window.scroll({
    //         top:0,
    //         behavior:"smooth"
    //     });
    //     fetchPhotoIdByUserId(id);
    // },[])
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [popupActive, setPopupActive] = useState(false);
    const [frontPreview, isFrontPreview] = useState(false);
    const [backPreview, isBackPreview] = useState(false);
    const [filesImage1, setFilesImage1] = useState([]);
    const [filesImage2, setFilesImage2] = useState([]);
    const [imagePreviewImage1, setImagePreviewImage1] = useState('');
    const [imagePreviewImage2, setImagePreviewImage2] = useState('');
    const [imageKeyImage1, setImageKeyImage1] = useState("");
    const [imageKeyImage2, setImageKeyImage2] = useState("");
    const [callHandleSave, setCallHandleSave] = useState(0);
    const [image1Error, setImage1Error] = useState("");
    const [image2Error, setImage2Error] = useState("");
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const [uploadProgress, setUploadProgress] = useState({});
  
    const uploadConfig = (progressEvent, name) => {
      if(progressEvent.loaded === progressEvent.total){
          delete uploadProgress[name];            
          const array = uploadProgress;
          setUploadProgress(array);
      }else{
          const array = {
              ...uploadProgress,
              [name] :  Math.round( (progressEvent.loaded * 100) / progressEvent.total )
          }
          setUploadProgress(array);
      }
    };

    const handleFailedUpload = (img, err) => {
      setPopupActive(false);
      console.log(err);
      if(img == key1){
        setFilesImage1([]);
        setImagePreviewImage1("");
        setImageKeyImage1("");
        setImage1Error(t('upload-failed'));
      }
      if(img == key2){
        setFilesImage2([]);
        setImagePreviewImage2("");
        setImageKeyImage2("");
        setImage2Error(t('upload-failed'));
      }
    }

    let uploadedFilesImage1 = filesImage1.length > 0 && (
      <Stack alignment="center">
        <Thumbnail
          size="small"
          alt={filesImage1[filesImage1.length - 1].name}
          source={
            validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
              ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
              : "/assets/images/Fileicon.svg"
          }
        />
        <div>
          {filesImage1[filesImage1.length - 1].name}{" "}
          <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
        </div>
      </Stack>
    );
    const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
    let imageType = "";

    const handleDropZoneImage2 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>{
            setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]);
        },
        []
    );
      const sendImage2 = useCallback(
        (filesImage2) => {
          setImage2Error('');
          setPopupActive(true);
          setImagePreviewImage2(
            validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
              ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
              : "/assets/images/Fileicon.svg"
          );
          imageType = filesImage2[filesImage2.length - 1].type;
          let headersVar = null;
          let url2 = "";
          let img = key2;
          let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
          const form_data = new FormData();
          form_data.append("content_type", imageType);
          form_data.append("file_name", filesImage2[filesImage2.length - 1].name);
          instance
            .post("/admin/v1/images/s3", form_data, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            })
            .then((res) => {
              setImageKeyImage2(res.data.key);
              url2 = res.data.url;
              headersVar = res.data.headers;
              axios
                .put(url2, filesImage2[filesImage2.length - 1], {
                  headers: {
                    "x-amz-acl": ["public-read-write"],
                    "Content-Type": imageType,
                  },
                  onUploadProgress: (event) => uploadConfig(event, img)
                })
                .then((res) => {
                  setCallHandleSave(1);
                })
                .catch((err) => {
                  handleFailedUpload(img, err);
                });
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        },
    
        [filesImage2]
      );
      const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
      let uploadedFilesImage2 = filesImage2.length > 0 && (
        <Stack alignment="center">
          <Thumbnail
            size="small"
            alt={filesImage2[filesImage2.length - 1].name}
            source={
              validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
                ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
                : "/assets/images/Fileicon.svg"
            }
          />
          <div>
            {filesImage2[filesImage2.length - 1].name}{" "}
            <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
          </div>
        </Stack>
      );
    
      const handleDropZoneImage1 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
          setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
        []
      );
    
      const sendImage1 = useCallback(
        (filesImage1) => {
          setImage1Error('');
          setPopupActive(true);
          setImagePreviewImage1(
            validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
              ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
              : "/assets/images/Fileicon.svg"
          );
          imageType = filesImage1[filesImage1.length - 1].type;
          let headersVar = null;
          let url2 = "";
          let img = key1;
          const form_data = new FormData();
          form_data.append("content_type", imageType);
          form_data.append("file_name", filesImage1[filesImage1.length - 1].name);
          instance
            .post("/admin/v1/images/s3", form_data, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            })
            .then((res) => {
              setImageKeyImage1(res.data.key);
              url2 = res.data.url;
              headersVar = res.data.headers;
              axios
                .put(url2, filesImage1[filesImage1.length - 1], {
                  headers: {
                    "x-amz-acl": ["public-read-write"],
                    "Content-Type": imageType,
                  },
                  onUploadProgress: (event) => uploadConfig(event, img)
                })
                .then((res) => {
                  setCallHandleSave(1);
                })
                .catch((err) => {
                  handleFailedUpload(img, err);
                });
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        },
    
        [filesImage1]
      );

      const saveImage = async () =>{
        setImage1Error("");
        setImage2Error("");
        if(imageKeyImage1 || imageKeyImage2){
          setPopupActive(true);
          try{
            const res = await instance.post(`/admin/v1/user/${id}/${api}/upload`, {
                  [key1] : imageKeyImage1,
                  [key2] : imageKeyImage2,
              });
              const data = res.data;
              if(data.success){
                  // navigate(`/dashboard/users/${id}`);
                  if(imageKeyImage1 != ""){
                    setImageKeyImage1("");
                  }
                  if(imageKeyImage2 != ""){
                    setImageKeyImage2("");
                  }
              }
              else{
                if(imageKeyImage1 != ""){
                  setImage1Error(t('upload-failed'));
                }
                if(imageKeyImage2 != ""){
                  setImage2Error(t('upload-failed'));
                }
              }
              setPopupActive(false);
          }catch(error){
              if(imageKeyImage1 != ""){
                setImage1Error(t('upload-failed'));
              }
              if(imageKeyImage2 != ""){
                setImage2Error(t('upload-failed'));
              }
              console.log({error});
              setPopupActive(false);
          }
        }
    }

    const fetchPhotoIdByUserId = async () =>{
        setPopupActive(true);
        try{
            const res = await instance.get(`/admin/v1/user/${id}/${api}/`);
              const data = res.data;
              if(data.success){
                if(api != 'membershipId'){
                  if(data?.user_photo_id?.photo_front_id_url){
                      isFrontPreview(true);
                      setImagePreviewImage1(data?.user_photo_id?.photo_front_id_url);
                  }
                  if(data?.user_photo_id?.photo_back_id_url){
                      isBackPreview(true);
                      setImagePreviewImage2(data?.user_photo_id?.photo_back_id_url);
                  }
                }else{
                  if(data?.member_ship_id?.membership_image_one_url){
                    isFrontPreview(true);
                    setImagePreviewImage1(data?.member_ship_id?.membership_image_one_url);
                  }
                  if(data?.member_ship_id?.membership_image_two_url){
                      isBackPreview(true);
                      setImagePreviewImage2(data?.member_ship_id?.membership_image_two_url);
                  }
                }
              }
              setPopupActive(false);
        }catch(error){
            setPopupActive(false);
            console.log({error});
        }
    }
     
    function handleDelete(e) {
        const imageID = e.currentTarget.id;
        if (imageID == key1) {
            if(frontPreview){
                setImageKeyImage1("");
                setImagePreviewImage1("");
                setFilesImage1([]);
                deleteImage(key1);
            }else{
                setImageKeyImage1("");
                setImagePreviewImage1("");
                setFilesImage1([]);
            }
        }
        if (imageID == key2) {
            if(backPreview){
                setImageKeyImage2("");
                setImagePreviewImage2("");
                setFilesImage2([]);
                deleteImage(key2);
            }else{
                setImageKeyImage2("");
                setImagePreviewImage2("");
                setFilesImage2([]);
            }
        }
    }

    const deleteImage = async (value) =>{
        setPopupActive(true);
        try{
            const res = await instance.delete(`/admin/v1/user/${id}/${api}/${value}/delete`);
            const data = res.data;
            if(data.success){
                if(data.reference == key1){
                    isFrontPreview(false);
                    setImagePreviewImage1("");
                }else if(data.reference == key2){
                    isBackPreview(false);
                    setImageKeyImage2("");
                }
            }
            setPopupActive(false);
        }catch(error){
            console.log({error});
            setPopupActive(false);
        }
        
    }

    useEffect(()=>{
        fetchPhotoIdByUserId();
    },[])

    useEffect(()=>{
      if(callHandleSave == 1){
          saveImage();
          setCallHandleSave(0);
      }
    }, [callHandleSave]);

  return (
    <Layout isProfile={true}>
       <Page
            title={title}
            breadcrumbs={[
                {
                    url: `/dashboard/users/${id}`,
                },
            ]}
        >
        <Card  sectioned>
        <FormLayout.Group>
        <FormLayout>
        <TextStyle variation="strong">{t(photoTitle1)}</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreviewImage1 ? imagePreviewImage1 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone disabled={clinicImpersonated() || popupActive} onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
                {uploadedFilesImage1}
                {fileUploadImage1}
              </DropZone>
              {(uploadProgress?.key1) &&
                <Box style={{ marginTop: '1rem'}}>
                    <LinearProgress variant="determinate" value={uploadProgress?.key1} />
                </Box>
              }
            </div>
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            {/* <Button disabled={clinicImpersonated() || popupActive} id="photo_front_id" onClick={(e) => handleDelete(e)}>
                {t('Delete')}
            </Button> */}
            <div style={{marginLeft: "2vw"}}>
              <TextStyle variation="negative">{image1Error}</TextStyle>
            </div>
          </div>
        </FormLayout>
        <FormLayout>
        <TextStyle variation="strong">{t(photoTitle2)}</TextStyle>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
             <div style={{ float: "left" }}>
                <Thumbnail
                  size="large"
                  source={imagePreviewImage2 ? imagePreviewImage2 : ""}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone disabled={clinicImpersonated() || popupActive} onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
                  {uploadedFilesImage2}
                  {fileUploadImage2}
                </DropZone>
                {(uploadProgress?.key2) &&
                  <Box style={{ marginTop: '1rem' }}>
                      <LinearProgress variant="determinate" value={uploadProgress?.key2} />
                  </Box>
                }
              </div>
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            {/* <Button disabled={clinicImpersonated() || popupActive} id="photo_back_id" onClick={(e) => handleDelete(e)}>
                {t('Delete')}
            </Button> */}
            <div style={{marginLeft: "2vw"}}>
              <TextStyle variation="negative">{image2Error}</TextStyle>
            </div>
          </div>
        </FormLayout>
        </FormLayout.Group>
        <div style={{display: "flex", marginTop: "1.5rem", flexDirection: "column", alignItems: "flex-end"}}>
          <div>
            <PageActions
                style={{marginTop:"1rem"}}
                primaryAction={{
                  disabled: clinicImpersonated(),
                  content: t('Save'),
                  onClick: saveImage,
                }}
            />
          </div>
        </div>
        {/* <Modal open={popupActive} loading={true}></Modal> */}
      </Card>
    </Page>
    </Layout>
  )
}

export default AddPhotoIdImages