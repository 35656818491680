import { AppBar, Tabs, Tab, Box } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import lodashMap from 'lodash/map'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../lib/theme'
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider'
import { useContext } from 'react'
import Cookies from 'js-cookie'

const useStyles = makeStyles(() => ({
    root: {
        fontWeight:600,
    },
    tabBar: {
        width: '100%',
        backgroundColor: theme.palette.background.main,
        border: "1px lightgray solid",
        boxShadow:"0px 1px 10px 0px rgb(0 0 0 / 12%)",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    children: {
        border: "1px lightgray solid",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        backgroundColor: theme.palette.background.main,
        boxShadow:"0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
    }
}))

export default function TabNav(props) {
    const { tabs, children } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const {
		currentTab,
		actions: { setCurrentTab },
	} = useContext(TabsControllerContext);
    

    const handleChange = (event, newValue) => {
            Cookies.set('currentTab', newValue)
            setCurrentTab(newValue)
            window.scroll({
                top:0,
                behavior:"smooth"
            })
    }
    return (
        <div>
            <AppBar className={classes.tabBar} position='static' color='default'>
                <Tabs
                    value={currentTab}
                    className={classes.tabs}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}>
                    {lodashMap(tabs, (tab, index) => {
                        return <Tab label={tab.label} key={index} />
                    })}
                </Tabs>
            </AppBar>
            <Box index={currentTab}>
                {lodashMap(children, (child, index) => {
                    if(child) {
                        return React.cloneElement(child, { currentTab, index })
                    }
                })}
            </Box>
        </div>
    )
}

export function TabPanel(props) {
    const { children, currentTab, index, ...other } = props
    
    const classes = useStyles()
    return (
        <div
            role='tabpanel'
            hidden={currentTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}>
            {currentTab === index && <Box className={classes.children} p={3}>{children}</Box>}
        </div>
    )
}

TabNav.propTypes = {
    tabs: PropTypes.array,
    currentTab: PropTypes.number,
    onSave: PropTypes.func,
    handleChange: PropTypes.func,
}

TabNav.defaultProps = {
    tabs: [],
    currentTab: 0,
}

TabPanel.propTypes = {
    currentTab: PropTypes.number,
    index: PropTypes.number,
}

TabPanel.defaultProps = {
    currentTab: 0,
    index: 0,
}