import React, { useContext, useState } from "react";
import axios from "../../util/axios";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Layout from "../TopNav/Topnav";
import {
  Box,
  Card,
  FormControl,
  makeStyles,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  FormHelperText,
  CircularProgress
} from "@material-ui/core";
import { optionsCountries } from "../../lib/CountriesList";
import ActiveContext from "../../Context/Context";
import _, { unset } from 'lodash'
import { age, country, email, firstName, language, lastName, GlobalCountries} from "../../util/validation";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabsControllerContext } from "../../Context/Tabs/TabsProvider";
const useStyles = makeStyles(()=>{
  return {
      textField: {
        width:"49%",
        ['@media only screen and (max-width: 1200px) '] : {
          width: '100%'
         },
      },
      card: {
        padding: '5%',
        display: 'grid',
        gap: '20px',
        width: '98%',
        margin: '0 auto',
        marginTop: '20px',
      },
      alertMargin:{
        width: '100%',
        textAlign:"center",
        margin: '0 auto',
        whiteSpace: 'pre-line'
      },
      box: {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '15px',
        rowGap: '25px',
        marginTop: "5px"
      }, 
      typo: {
        fontSize:'18px!important',
        marginTop:"20px"
      },
      headerBox: {
        textAlign: 'right',
        marginTop: '20px',
      },
      cancel: {
        width: '78px',
        height: '36px',
        borderRadius: '3px',
        fill: 'Linear gradient, 180°',
        border: '1px #F9FAFB solid',
        boxShadow: '0px 1px 5px lightgray',
        margin: '5px',     

      },
      save: {
        width: '78px',
        height: '36px',
        borderRadius: '3px',
        fill: 'Linear gradient, 180°',
        backgroundColor: '#FF2C46',
        color: 'white',
        margin: '5px',     
      },
      loading: {
        textAlign: "center",
        margin: "0 auto",
        color: "#fff",
      },
    patientTypeWrapper: {
      width: "fit-content",
      display: "flex",
      columnGap: 10,
      ["@media only screen and (max-width: 1200px) "]: {
        width: "100%",
      },
    },
    selectedPatientType: {
      cursor: "pointer",
      textAlign: "center",
      padding: "12px 15px",
      borderRadius: "50px",
      backgroundColor: "rgba(0,0,0,.08)",
      "&:hover": {
        opacity: 0.7,
        transition: "0.2s ease-in",
        transform: "scale(1.03)",
      },
    },
    patientType: {
      cursor: "pointer",
      textAlign: "center",
      padding: "12px 15px",
      borderRadius: "50px",
      transition: ".5s",
      boxShadow:
          "0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12), 0 1px 3px 0 rgba(0,0,0,.2)",
      "&:hover": {
        opacity: 0.7,
        transition: "0.2s ease-in",
        transform: "scale(1.03)",
      },
    },
}})
const AddPatient = () => { 
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(false);
    const { id } = useParams();
    const [message, setMessage] = useState('');
    const [ errors, setErrors ] = useState({})
    const { clinicData } = useContext(ActiveContext);
    const [selectedStraightenessReason, setSelectedStraightenessReason] = useState("");
    const [selectedPatientType, setSelectedPatientType] = useState("Walk-in Patient");

    const {
      clinicNotifications,
    } = useContext(TabsControllerContext);

    const handleSelectChangeStraightenessReason = (e) => {
    setSelectedStraightenessReason(e.target.value);
  }
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setMessage('')
}
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const handleSelectChangeStartTime = (e) => {
    setSelectedStartTime(e.target.value);
  }
  const [selectedProblem, setSelectedProblem] = useState("");
  const handleSelectChangeProblem = (e) => {
    setSelectedProblem(e.target.value);
  }
  const [selectedTeethDescription, setSelectedTeethDescription] = useState("");
  const handleSelectChangeTeethDescription = (e) => {
    setSelectedTeethDescription(e.target.value);
  }
  const [selectedWarnBraces, setSelectedWarnBraces] = useState("");
  const handleSelectChangeWarnBraces = (e) => {
    setSelectedWarnBraces(e.target.value);
  }
  const [selectedHowDidYouHear, setSelectedHowDidYouHear] = useState("");
  const handleSelectChangeHowDidYouHear = (e) => {
    setSelectedHowDidYouHear(e.target.value);
  }
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value);
    const validEmail = email(e.target.value)
    if(!validEmail){
      setErrors({
        ...errors,
        email: 'Invalid Email'
      })
    } else {
      unset(errors, 'email')
    }
  }
  if(openAlert){
    window.scroll({
        top:0,
        behavior:"smooth"
    })
}
  const [firstNameValue, setFirstNameValue] = useState("");
  const handleChangeFirstName = (e) => {
    setFirstNameValue(e.target.value);
    const validFirstname = firstName(e.target.value);
    if(!validFirstname){
      setErrors({
        ...errors,
        firstname: 'Invalid first name'
      })
    } else {
      unset(errors, 'firstname')
    }
  }
  const [lastNameValue, setLastNameValue] = useState("");
  const handleChangeLastName = (e) => {
    setLastNameValue(e.target.value);
    const validLastname = lastName(e.target.value)
    if(!validLastname){
      setErrors({
        ...errors,
        lastname: 'Invalid last name'
      })
    } else {
      unset(errors, 'lastname')
    }
  }
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const handleChangePhoneNumber = (e) => {
    setPhoneNumberValue(e.target.value);
  }
  const [personalMobileNumberValue, setPersonalMobileNumberValue] =
    useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState('');
  const handleChangePersonalMobileNumber = (e) => {
    setPersonalMobileNumberValue(e);
    if (e === undefined || e === "") {
      setErrors({
        ...errors,
        phone: t("phonenbrrequired"),
      })
    } else if (!isValidPhoneNumber(e)) {
      setErrors({
        ...errors,
        phone: t("validphonenumber"),
      })
    } else {
      unset(errors, 'phone')
    }
  }
  const handleChangeWhatsAppMobileNumber = (e) => {
    setWhatsAppNumber(e);
  }
  const [otherStraigntnessReasonValue, setOtherStraigntnessReasonValue] =
    useState("");
  const handleChangeOtherStraigntnessReason = (e) => {
    setOtherStraigntnessReasonValue(e.target.value)
  }
  const [otherHearMethodValue, setOtherHearMethodValue] = useState("");
  const handleChangeOtherHearMethod = (e) => {
    setOtherHearMethodValue(e.target.value)
  }
  const [selectedAge, setSelectedAge] = useState("");
  const handleSelectChangeAge = (e) => {
    setSelectedAge(e.target.value);
    const validAge = age(e.target.value)
    if(!validAge){
      setErrors({
        ...errors,
        age: 'Invalid Age level'
      })
    } else {
      unset(errors, 'age')
     
    }
  }
  
  const [emailMainValue, setEmailMainValue] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("0");
  const handleSelectChangeLanguage = (e) => {
    setSelectedLanguage(e.target.value)
    const validLanguage = language(e.target.value)
    if(!validLanguage){
      setErrors({
        ...errors,
        language: 'Invalid language'
      })
    } else {
      unset(errors, 'language')
    }
  }
  const handleSelectChangePatientType = (value) => {
    setSelectedPatientType(value);
  }
  const [selectedCountry, setSelectedCountry] = useState("");
  const handleSelectChangeCountry = (e) => {
    setSelectedCountry(e.target.value)
    const validCountry = country(e.target.value)
    if(!validCountry){
      setErrors({
        ...errors,
        country: 'Invalid country level'
      })
    } else {
      unset(errors, 'country')
    }
  }
  const handleCancel = () => {
    navigate('/dashboard')
  }

  return (
    <Layout hideCreate={true}>
      <Card className={classes.card}>
      {openAlert && <Alert open={openAlert} className={classes.alertMargin} severity="error" onClose={handleCloseAlert}>
        {message}
        </Alert>}
            <Box className={classes.box}>
              <TextField className={classes.textField}
                label={t("First-Name")}
                required={true}
                value={firstNameValue}
                error={errors.firstname}
                helperText={errors.firstname}
                
                onChange={(e) => handleChangeFirstName(e)}
              />
              <TextField className={classes.textField}
                label={t("Last-Name")}
                required={true}
                value={lastNameValue}
                error={errors.lastname}
                helperText={errors.lastname}
                onChange={(e) => handleChangeLastName(e)}
              />
              <TextField className={classes.textField}
                value={emailValue}
                label={t("Email-Address")}
                required={true}
                onChange={(e) => handleChangeEmail(e)}
                error={errors.email}
                helperText={errors.email}
                placeholder={t("Email-Address")}
              />
            <div className='add-patient-input-wrapper'>
              <PhoneInput
                className='add-patient-input-text-phone'
                countries={GlobalCountries}
                rules={{ required: true }}
                flagUrl="../../../flags/{xx}.svg"
                placeholder={t("Personal-Mobile-Number")}
                value={personalMobileNumberValue}
                onChange={(e) => handleChangePersonalMobileNumber(e)}
                defaultCountry={clinicNotifications?.country?.code}
              />
              {errors?.phone && <FormHelperText error={true}>{errors.phone}</FormHelperText>}
            </div>
            <div className='add-patient-input-wrapper'>
              <PhoneInput
                className='add-patient-input-text-phone'
                countries={GlobalCountries}
                flagUrl="../../../flags/{xx}.svg"
                placeholder={t('whatsapp-Mobile-Number')}
                value={whatsAppNumber}
                onChange={(e) => handleChangeWhatsAppMobileNumber(e)}
                defaultCountry={clinicNotifications?.country?.code}
              />
            </div>
            <FormControl className={classes.textField}>
            <InputLabel
              error={errors.age}
              required={true}
             id="demo-simple-select-standard-label">{t('Age')}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                placeholder={t("Please-Select")}
                value={selectedAge}
                error={errors.age}
                onChange={(e) => {
                  handleSelectChangeAge(e)
                }}
                label={t('Age')}
              >
                <MenuItem value="">{t("Please-Select")}</MenuItem>
                <MenuItem value="6">Under 18</MenuItem>
                <MenuItem value="7">18-20</MenuItem>
                <MenuItem value="8">21-25</MenuItem>
                <MenuItem value="9">26-30</MenuItem>
                <MenuItem value="10">31-35</MenuItem>
                <MenuItem value="11">36-40</MenuItem>
                <MenuItem value="12">41-50</MenuItem>
                <MenuItem value="13">50+</MenuItem>
              </Select>
              {errors.age && <FormHelperText error={true}>{errors.age}</FormHelperText>}
            </FormControl>
              <FormControl className={classes.textField}>
              <InputLabel
                required={true}
                error={errors.language}
                 id="demo-simple-select-standard-label">{t('Select-Language')}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                placeholder="Please Select"
                value={selectedLanguage}
                error={errors.language}
                required={true}
                onChange={(e) => {
                  handleSelectChangeLanguage(e)
                }}
                label={t("Age")}
              >
                <MenuItem value="">{t('Please-Select')}</MenuItem>
                <MenuItem value="en">{t('English')}</MenuItem>
                <MenuItem value="ar">{t('Arabic')}</MenuItem>
              </Select>
              {errors.language && <FormHelperText error={true}>{errors.language}</FormHelperText>}
              </FormControl>
            <FormControl className={classes.textField}>
              <InputLabel
                error={errors.country}
                required
                 id="demo-simple-select-standard-label">{t('Select-Country')}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                placeholder={t("Please-Select")}
                value={selectedCountry}
                error={errors.country}
                required={true}
                onChange={(e) => {
                  handleSelectChangeCountry(e)
                }}
                label={t("Country")}              
                >
                <MenuItem value="">{t('Please-Select')}</MenuItem>
                {optionsCountries && optionsCountries.map((val, key) => {
                  return <MenuItem key={key} value={val.value}>{val.label}</MenuItem>
                })}
              </Select>
              {errors.country && <FormHelperText error={true}>{errors.country}</FormHelperText>}
              </FormControl>
              </Box>
        <Box className={classes.box}>
       <FormControl variant="standard" className={classes.textField}>
        <InputLabel required
         id="demo-simple-select-standard-label">{t('How-soon-do-you-want-to-start?')}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          placeholder={t("Please-Select")}
          value={selectedStartTime}

          onChange={(e) => {
            handleSelectChangeStartTime(e)
          }}
          label={t("Age")}
        >
          <MenuItem value="">{t('Please-Select')}</MenuItem>
          <MenuItem value={'1'}>{t('Immediatly')}</MenuItem>
          <MenuItem value={'2'}>{t('In-a-few-weeks')}</MenuItem>
          <MenuItem value={'3'}>{t('In-a-few-months')}</MenuItem>
          <MenuItem value={'4'}>{t('Not-sure')}</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="standard" className={classes.textField}>
        <InputLabel required
         id="demo-simple-select-standard-label">{t('Have-you-ever-warn-braces-or-aligners?')}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          placeholder={t("Please-Select")}
          value={selectedWarnBraces}
          onChange={(e) => {
            handleSelectChangeWarnBraces(e)
          }}
          label={t("problem")}
        >
          <MenuItem value="">{t('Please-Select')}</MenuItem>
          <MenuItem value={'1'}>{t('Yes')}</MenuItem>
          <MenuItem value={'0'}>{t('No')}</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="standard" className={classes.textField}>
            <InputLabel required
             id="demo-simple-select-standard-label">{t('How-did-you-hear-about-us?')}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          placeholder={t("Please-Select")}
          value={selectedHowDidYouHear}
          onChange={(e) => {
            handleSelectChangeHowDidYouHear(e)
          }}
          label={t("problem")}
        >
          <MenuItem value="">{t('Please-Select')}</MenuItem>
          <MenuItem value={'1'}>Twitter</MenuItem>
          <MenuItem value="2">Facebook</MenuItem>
          <MenuItem value="3">Snapchat</MenuItem>
          <MenuItem value="4">TikTok</MenuItem>
          <MenuItem value="5">Google</MenuItem>
          <MenuItem value="6">Email</MenuItem>
          <MenuItem value="7">Instagram</MenuItem>
          <MenuItem value="8">Friend</MenuItem>
          <MenuItem value="9">YouTube</MenuItem>
          <MenuItem value="11">Linkedin</MenuItem>
          <MenuItem value="12">Ad on other website</MenuItem>
          <MenuItem value="15">Eyewa Store</MenuItem>
          <MenuItem value="10">Other</MenuItem>
        </Select>
         </FormControl>
          {selectedHowDidYouHear === "10" && (
                <TextField className={classes.textField}
                  value={otherHearMethodValue}
                  placeholder= {t("How-did-you-hear-about-us-Other")}
                  onChange={handleChangeOtherHearMethod}
                />
          )}
          <FormControl>
            <Box className={classes.patientTypeWrapper}>
              <Box
                  className={
                    selectedPatientType == "Walk-in Patient"
                        ? classes.selectedPatientType
                        : classes.patientType
                  }
                  onClick={() => handleSelectChangePatientType("Walk-in Patient")}
              >
                <p>{t("walk-in-patient")}</p>
              </Box>
              <Box
                  className={
                    selectedPatientType == "Clinic Referred Patient"
                        ? classes.selectedPatientType
                        : classes.patientType
                  }
                  onClick={() =>
                      handleSelectChangePatientType("Clinic Referred Patient")
                  }
              >
                <p>{t("clinic-referred-patient")}</p>
              </Box>
            </Box>
          </FormControl>
          </Box>
        <Box className={classes.headerBox}>
          <Button className={classes.cancel} onClick={handleCancel}>{t('Cancel')}</Button>
          <Button className={classes.save} disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={24} className={classes.loading} /> : t('Save')}
          </Button>
        </Box>
      </Card>
      </Layout>
  );
  function handleSave() {
    const bodyObj = {
      ...(firstNameValue && { first_name: firstNameValue }),
      ...(lastNameValue && { last_name: lastNameValue }),
      ...(emailMainValue !== emailValue && emailValue && { email: emailValue }),
      ...(personalMobileNumberValue && {
        personal_mobile_number: personalMobileNumberValue,
      }),
      ...(whatsAppNumber && {
        whatsapp_number: whatsAppNumber,
      }),
      ...(phoneNumberValue && { phone: phoneNumberValue }),
      ...(selectedWarnBraces != "" && {
        has_braces: parseInt(selectedWarnBraces),
      }),
      options: {
        language: selectedLanguage,
        ...(lastNameValue.selectedStraightenessReason != "" && {
          question1: selectedStraightenessReason,
        }),
        ...(otherStraigntnessReasonValue && {
          question1_other: otherStraigntnessReasonValue,
        }),
        ...(selectedStartTime && { question2: parseInt(selectedStartTime) }),
        ...(selectedHowDidYouHear != "1" && {
          question5: selectedHowDidYouHear,
        }),
        ...(otherHearMethodValue && {
          question5_other: otherHearMethodValue,
        }),
      },
      ...(selectedTeethDescription != "" && {
        issue_id: parseInt(selectedTeethDescription),
      }),
      ...(selectedAge != "" && { age_id: parseInt(selectedAge) }),
      ...(selectedProblem && {
        teeth_description_ids: parseInt(selectedProblem),
      }),
      ...(selectedCountry && { country: selectedCountry }),
      clinicAdded: true,
      patientType: selectedPatientType,
      // clinic_id: clinicData && parseInt(clinicData.data.clinic_info.id)
    };
   if(!_.isEmpty(errors) || firstNameValue === '' 
       || lastNameValue === '' || emailValue === '' || personalMobileNumberValue === ''
       || selectedWarnBraces === '' || selectedLanguage === '' || selectedStartTime === '' || selectedHowDidYouHear === ''
       || (otherHearMethodValue === '' && selectedHowDidYouHear === "10")
       || selectedAge === '' || selectedCountry === ''
   ){
    window.scroll({
      top:0,
      behavior:"smooth"
    })
    setOpenAlert(true)
    setMessage('Missing fields')
   }else {
     setLoading(true);
    axios
      .post("admin/v1/users/clinic", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("access_token_clinic"),
        },
      })
      .then((res) => {
        if (res?.data?.errors) {
          let message = '';
          Object.keys(res?.data?.errors).map((field) => {
              res?.data?.errors[field].map((error) => {
                message += `${error} \n`
                return error;
              });
              return field
          });
          setMessage(message);
          setOpenAlert(true);
          return;
        }
        if (res?.data?.success === false) {
          let message = '';
          if (res?.data?.message) {
            message = res?.data?.message;
            setMessage(message);
            setOpenAlert(true);
            return;
          }
        }
        setOpenAlert(false)
        setMessage('')
        setTimeout(() => {
          navigate("/dashboard");
        },200)
      })
      .catch((err) => {
        setOpenAlert(true);
        console.log('Error in Creating a user', err);
        setMessage('Something Wrong Happened, Please contact Basma Support');
      }).finally(() => setLoading(false));
    }
  }
  //}
};

export default AddPatient;
