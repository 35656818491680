import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import LoginForm from '../../Components/Forms/LoginForm';
import "../../App.css"
import Cookies from 'js-cookie';
const useStyles = makeStyles(() => ({
    logo: {
        maxWidth: 130
    },
    wrapper: {
        display: "flex",
        width: "100%",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 30,
        
    },
}))
function LoginPage() {
    const classes = useStyles()
    useEffect(()=>{
        if(!Cookies.get('i18next')){
            Cookies.set('i18next', 'en');
        }
    },[]);
  return <Box >
      <div className={classes.wrapper}>
            <img className={classes.logo} src={"/assets/images/Basmacom Logo"} alt="Basma-logo" />
        </div>
      <LoginForm/>
  </Box>;
}

export default LoginPage;
