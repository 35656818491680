import React, { useEffect, useState } from "react";
import Layout from "../../../Components/TopNav/Topnav";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const Faq = ({ align }) => {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    } else {
      setIsArabic(false);
    }
  }, [i18n.language]);

  const toggleItem = (index) => {
    if (expandedIndices.includes(index)) {
      setExpandedIndices(expandedIndices.filter((i) => i !== index));
    } else {
      setExpandedIndices([...expandedIndices, index]);
    }
  };
  const unorderedListStyle = isArabic
    ? {
        margin: "10px 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "10px 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };

  const unorderedSubListStyle = isArabic
    ? {
        margin: "0 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "0 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };
  return (
    <div>
      <Layout isProfile={true}>
        <h2 className="faqNewMainSubTitle">{urlParams.get("title")}</h2>
        <div className="faq_accordion">
          <div className="faq_accordion-item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "end",
              }}
              onClick={() => toggleItem(0)}
            >
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
                component="h1"
              >
                {t("faq-header-1")}
              </Typography>
              <button className="faq_accordion-button">
                <img
                  src="/assets/images/accordion-arrow.svg"
                  alt="image"
                  className="learning_img"
                />
              </button>
            </div>
            {expandedIndices.includes(0) && (
              <div className="faq_accordion-content">
                <Typography variant="div">{t("faq-info-1-1")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-1-2")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-1-3")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-1-4")}</Typography>
                <br />
                <ol style={unorderedListStyle}>
                  <li>{t("faq-info-1-5")}</li>
                  <li>{t("faq-info-1-6")}</li>
                  <li>{t("faq-info-1-7")}</li>
                </ol>
              </div>
            )}
          </div>

          <div className="faq_accordion-item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "end",
              }}
              onClick={() => toggleItem(1)}
            >
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
                component="h1"
              >
                {t("faq-header-2")}
              </Typography>
              <button className="faq_accordion-button">
                <img
                  src="/assets/images/accordion-arrow.svg"
                  alt="image"
                  className="learning_img"
                />
              </button>
            </div>
            {expandedIndices.includes(1) && (
              <div className="faq_accordion-content">
                <Typography variant="div">{t("faq-info-2-1")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-2-2")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-2-3")}</Typography>
              </div>
            )}
          </div>

          <div className="faq_accordion-item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "end",
              }}
              onClick={() => toggleItem(2)}
            >
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
                component="h1"
              >
                {t("faq-header-3")}
              </Typography>
              <button className="faq_accordion-button">
                <img
                  src="/assets/images/accordion-arrow.svg"
                  alt="image"
                  className="learning_img"
                />
              </button>
            </div>
            {expandedIndices.includes(2) && (
              <div className="faq_accordion-content">
                <Typography variant="div">{t("faq-info-3-1")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-3-2")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-3-3")}</Typography>
                <br />
                <br />
                <Typography variant="div">{t("faq-info-3-4")}</Typography>
              </div>
            )}
          </div>

          <div className="faq_accordion-item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "end",
              }}
              onClick={() => toggleItem(3)}
            >
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
                component="h1"
              >
                {t("faq-header-4")}
              </Typography>
              <button className="faq_accordion-button">
                <img
                  src="/assets/images/accordion-arrow.svg"
                  alt="image"
                  className="learning_img"
                />
              </button>
            </div>
            {expandedIndices.includes(3) && (
              <div className="faq_accordion-content">
                <Typography variant="div">{t("faq-info-4-1")}</Typography>
                <br />
                <br />
                <ul style={unorderedListStyle}>
                  <li>{t("faq-info-4-2")}</li>
                  <li>{t("faq-info-4-3")}</li>
                  <li>{t("faq-info-4-4")}</li>
                  <li>{t("faq-info-4-5")}</li>
                </ul>
              </div>
            )}
          </div>

          <div className="faq_accordion-item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "end",
              }}
              onClick={() => toggleItem(4)}
            >
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
                component="h1"
              >
                {t("faq-header-5")}
              </Typography>

              <button className="faq_accordion-button">
                <img
                  src="/assets/images/accordion-arrow.svg"
                  alt="image"
                  className="learning_img"
                />
              </button>
            </div>
            {expandedIndices.includes(4) && (
              <div className="faq_accordion-content">
                <Typography variant="div">{t("faq-info-5-1")}</Typography>
                <br />
                <br />
                <ul style={unorderedListStyle}>
                  <li>{t("faq-info-5-2")}</li>
                  <li>{t("faq-info-5-3")}</li>
                  <li>{t("faq-info-5-4")}</li>
                </ul>
              </div>
            )}
          </div>

          <div className="faq_accordion-item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "end",
              }}
              onClick={() => toggleItem(5)}
            >
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
                component="h1"
              >
                {t("faq-header-6")}
              </Typography>
              <button className="faq_accordion-button">
                <img
                  src="/assets/images/accordion-arrow.svg"
                  alt="image"
                  className="learning_img"
                />
              </button>
            </div>
            {expandedIndices.includes(5) && (
              <div className="faq_accordion-content">
                <Typography variant="div">{t("faq-info-6-1")}</Typography>
                <br />
                <br />
                <a
                  href={`https://basma.com/${
                    isArabic ? "ar" : "en"
                  }/ae/how-much-are-invisible-braces`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://basma.com/${
                    isArabic ? "ar" : "en"
                  }/ae/how-much-are-invisible-braces`}
                </a>
              </div>
            )}
          </div>

          <div className="faq_accordion-item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "end",
              }}
              onClick={() => toggleItem(6)}
            >
              <Typography
                variant="h5"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
                component="h1"
              >
                {t("faq-header-7")}
              </Typography>
              <button className="faq_accordion-button">
                <img
                  src="/assets/images/accordion-arrow.svg"
                  alt="image"
                  className="learning_img"
                />
              </button>
            </div>
            {expandedIndices.includes(6) && (
              <div className="faq_accordion-content">
                <ol style={unorderedListStyle}>
                  <li>{t("faq-info-7-1")}</li>
                  <li>{t("faq-info-7-2")}</li>
                </ol>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Faq;
