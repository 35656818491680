import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../util/axios";
import axiosAws from "axios";
import ProcedureHeader from "./procedure-header.component";
import Cookies from "js-cookie";
import { clinicImpersonated } from "../../util/functions";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import { TextField } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      marginBottom: 15,
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    title: {
      height: "16px",
      width: "134px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
      marginBottom: "1rem",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  };
});

export default function PostTreatmentPhotos({
  data = { isAllProvided: null, data: {} },
  disable,
  appointmentId,
  appointmentType,
  handleTabsExpand,
  userId,
  index,
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const { t } = useTranslation();
  const { data: photosData, isAllProvided } = data;
  const classes = useStyles();
  const [photos, setPhotos] = useState(photosData);
  const [photosArray, setPhotosArray] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [imageKey, setImageKey] = useState(null);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [iconPhotos, setIconPhotos] = useState(isAllProvided);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState({
    disabled: false,
    popupActive: false,
    success: false,
    disableReviewBtn: false,
  });
  const [deleteLoading, setDeleteLoading] = useState({
    frontFacingBigSmile: false,
    frontFacingNoSmile: false,
    rightSideProfileNoSmile: false,
    upperOcclusal: false,
    lowerOcclusal: false,
    bigSmile: false,
    bigSmileFullFace: false,
    leftClosed: false,
    rightClosed: false,
    lowerTeeth: false,
  });
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const handleIconPhotosView = (value) => {
    setIconPhotos(value);
  };

  const handleChange = (value) => {
    setImageKey({
      note: value,
    })
  }

  const handleImages = (e, id) => {
    setLoading(prev => ({
      ...prev,
      success: false,
      disabled: true,
    }));
    const imageId = id;
    const file = e[0];
    let imgUrl =
      validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : "../assets/images/Fileicon.svg";
    setPhotos((prev) => ({
      ...prev,
      [imageId]: imgUrl,
    }));
    saveInAws(file, imageId);
  };

  const handleUploadError = (type, err) => {
    setPhotos((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setImageKey((prev) => ({
      // ...prev,
      [type]: undefined,
    }));
    console.log(err);
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const saveInAws = (value, type) => {
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      setImageKey((prev) => ({
        // ...prev,
        [type]: res.data.key,
      }));
      const url2 = res.data.url;
      axiosAws.put(url2, value, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, type),
      }).then((res) => {
        setCallHandleSave(1);
      }).catch((err) => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
        handleUploadError(type, err);
      });
    }).catch((err) => {
      setLoading(prev => ({
        ...prev,
        disabled: false,
      }));
      handleUploadError(type, err);
    });
  };

  const handleDelete = (photoId, type) => {
    setDeleteLoading(prev => ({ ...prev, [type]: true }));
    axios.delete(`admin/v2/case-record-post-treatment/delete/${photoId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if (res.data.success) {
        handleDataFetched();
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => setDeleteLoading(prev => ({ ...prev, [type]: false })));
  }

  const handleSave = () => {
    setSuccess(false);
    if (imageKey) {
      setLoading(prev => ({
        ...prev,
        success: false,
      }));
      const form_data = new FormData();
      imageKey?.upper_occlusal && form_data.append("upper_occlusal", imageKey.upper_occlusal);
      imageKey?.lower_occlusal && form_data.append("lower_occlusal", imageKey.lower_occlusal);
      imageKey?.big_smile && form_data.append("big_smile", imageKey.big_smile);
      imageKey?.big_smile_full_face && form_data.append("big_smile_full_face", imageKey.big_smile_full_face);
      imageKey?.left_closed && form_data.append("left_closed", imageKey.left_closed);
      imageKey?.right_closed && form_data.append("right_closed", imageKey.right_closed);
      imageKey?.note && form_data.append("note", imageKey.note);
      form_data.append("user_scan_info_id", appointmentId);
      form_data.append("procedure", appointmentType?.procedure);
      axios.post(`admin/v2/users/${userId}/case-record-post-treatment`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }).then((res) => {
        if (res.data.success) {
          setSuccess(true);
          setLoading(prev => ({
            ...prev,
            success: false,
          }));
        }
      }).catch((err) => {
        setSuccess(false);
        console.log(err);
      }).finally(() => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
      });
    }
  };

  const handleReviewStatus = (status) => {
    if (status) {
      setLoading(prev => ({
        ...prev,
        disableReviewBtn: true,
      }));
      const form_data = new FormData();
      form_data.append('review_status', status);
      form_data.append('appointment_id', appointmentId);
      form_data.append('procedure', appointmentType?.procedure);
      axios.post(`/admin/v2/users/case-record-post-treatment/${photos?.id}/approval-status`, form_data, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        if (res.data.success) {
          setPhotos(prev => ({
            ...prev,
            reviewer: res.data.data?.reviewer,
            review_status: res.data.data?.review_status,
            reviewed_at: res.data.data?.reviewed_at,
          }));
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
      });
    }
  };

  const handleDataFetched = () => {
    setLoading(prev => ({
      ...prev,
      popupActive: true,
    }));
    axios.get(`admin/v2/users/case-record-post-treatment/${appointmentId}/show?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setPhotos(res.data.data);
        handleIconPhotosView(res.data.isAllProvided);
      } else {
        handleIconPhotosView(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        popupActive: false,
      }));
    })
  };

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, "photos");
  }, [openCloseTabs]);

  useEffect(() => {
    if (photos) {
      let arr = [];
      if (photos.upper_occlusal && !arr.includes(photos.upper_occlusal)) {
        arr.push({ id: 'upper_occlusal', url: photos.upper_occlusal });
      }
      if (photos.lower_occlusal && !arr.includes(photos.lower_occlusal)) {
        arr.push({ id: 'lower_occlusal', url: photos.lower_occlusal });
      }
      if (photos.big_smile && !arr.includes(photos.big_smile)) {
        arr.push({ id: 'big_smile', url: photos.big_smile });
      }
      if (photos.big_smile_full_face && !arr.includes(photos.big_smile_full_face)) {
        arr.push({ id: 'big_smile_full_face', url: photos.big_smile_full_face });
      }
      if (photos.left_closed && !arr.includes(photos.left_closed)) {
        arr.push({ id: 'left_closed', url: photos.left_closed });
      }
      if (photos.right_closed && !arr.includes(photos.right_closed)) {
        arr.push({ id: 'right_closed', url: photos.right_closed });
      }

      setPhotosArray(arr);
    }
  }, [photos]);

  useEffect(() => {
    scrollToExpandedTab(index, "postTreatmentPhotos");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="postTreatmentPhotos"
      headerTitle="post-treatment-photos"
      index={index}
      iconCheck={iconPhotos}
    >
      {openCloseTabs[index]?.postTreatmentPhotos && (
        <Box className={`${classes.wrapper} postTreatmentPhotos-${index}`}>
          <Box className={`${classes.photoBox} ${classes.photoWidth}`}>
            <ScreeningPhotoUpload
              disabled={disable || loading.disabled}
              value={photos?.upper_occlusal}
              handleImages={handleImages}
              id='upper_occlusal'
              title='Upper Occlusal'
              uploadProgress={uploadProgress?.upper_occlusal}
              handleDelete={() => handleDelete(photos?.upper_occlusal_id, 'upperOcclusal')}
              deleteDisabled={deleteLoading.upperOcclusal || !photos?.upper_occlusal_id}
              imagesArray={photosArray}
              identifier={`post-treatment-${appointmentType?.procedure}`}
            />
            <ScreeningPhotoUpload
              disabled={disable || loading.disabled}
              value={photos?.lower_occlusal}
              handleImages={handleImages}
              id='lower_occlusal'
              title='Lower Occlusal'
              uploadProgress={uploadProgress?.lower_occlusal}
              handleDelete={() => handleDelete(photos?.lower_occlusal_id, 'lowerOcclusal')}
              deleteDisabled={deleteLoading.lowerOcclusal || !photos?.lower_occlusal_id}
              imagesArray={photosArray}
              identifier={`post-treatment-${appointmentType?.procedure}`}
            />
            <ScreeningPhotoUpload
              disabled={disable || loading.disabled}
              value={photos?.big_smile}
              handleImages={handleImages}
              id='big_smile'
              title='Big Smile'
              uploadProgress={uploadProgress?.big_smile}
              handleDelete={() => handleDelete(photos?.big_smile_id, 'bigSmile')}
              deleteDisabled={deleteLoading.bigSmile || !photos?.big_smile_id}
              imagesArray={photosArray}
              identifier={`post-treatment-${appointmentType?.procedure}`}
            />
            <ScreeningPhotoUpload
              disabled={disable || loading.disabled}
              value={photos?.big_smile_full_face}
              handleImages={handleImages}
              id='big_smile_full_face'
              title='Big Smile - Full Face'
              uploadProgress={uploadProgress?.big_smile_full_face}
              handleDelete={() => handleDelete(photos?.big_smile_full_face_id, 'bigSmileFullFace')}
              deleteDisabled={deleteLoading.bigSmileFullFace || !photos?.big_smile_full_face_id}
              imagesArray={photosArray}
              identifier={`post-treatment-${appointmentType?.procedure}`}
            />
            <ScreeningPhotoUpload
              disabled={disable || loading.disabled}
              value={photos?.left_closed}
              handleImages={handleImages}
              id='left_closed'
              title='Left Closed'
              uploadProgress={uploadProgress?.left_closed}
              handleDelete={() => handleDelete(photos?.left_closed_id, 'leftClosed')}
              deleteDisabled={deleteLoading.leftClosed || !photos?.left_closed_id}
              imagesArray={photosArray}
              identifier={`post-treatment-${appointmentType?.procedure}`}
            />
            <ScreeningPhotoUpload
              disabled={disable || loading.disabled}
              value={photos?.right_closed}
              handleImages={handleImages}
              id='right_closed'
              title='Right Closed'
              uploadProgress={uploadProgress?.right_closed}
              handleDelete={() => handleDelete(photos?.right_closed_id, 'rightClosed')}
              deleteDisabled={deleteLoading.rightClosed || !photos?.right_closed_id}
              imagesArray={photosArray}
              identifier={`post-treatment-${appointmentType?.procedure}`}
            />
          </Box>
          <TextField
            label='Note'
            value={imageKey?.note || photos?.note}
            onChange={(e) => handleChange(e)}
            multiline={4}
          />
          <br />
          <Box className={classes.uploadBox}>
            <Button
              disabled={clinicImpersonated()}
              className={classes.saveBtn}
              onClick={handleSave}
            >
              {success ? t("Saved") : t("Save")}
            </Button>
          </Box>
        </Box>
      )}
    </ProcedureHeader>
  );
}
