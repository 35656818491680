import React, { useContext } from 'react'
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import AddpatientMallCollab from './AddpatientMallCollab';
import AddPatient from './Addpatient';

const Patient = () => {
    const {
        clinicNotifications,
    } = useContext(TabsControllerContext);

    return (
        clinicNotifications?.is_mall_collaboration ?
        <AddpatientMallCollab /> :
        <AddPatient />
    )
}

export default Patient