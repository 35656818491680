import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../util/functions";

const useStyles = makeStyles(() => {
  return {
    root: {
      backgroundColor: "rgb(246, 246, 247)",
    },
    boxWrapper: {
      display: "grid",
      gap: 25,
      backgroundColor: "rgb(246, 246, 247)",
      height: "fit-content",
      padding: 20,
    },
    statusesWrapper: {
      display: "grid",
      width: "10%",
      gap: 10,
    },
    loadingBox: {
      margin: "8% auto",
      textAlign: "center",
      padding: 30,
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    cardStyle: {
      display: "grid",
      width: "100%",
      flexWrap: "wrap",
      ["@media only screen and (max-width: 1170px) "]: {
        gridTemplateColumns: "1fr",
      },
    },
    gridtemplate2Column: {
      gridTemplateColumns: "1fr 1fr",
    },
    gridtemplate1Column: {
      gridTemplateColumns: "1fr",
    },
    btnWrapper: {
      backgroundColor: "#fff",
      borderRadius: "3px",
      height: 0,
      boxShadow: "inset 0 -1px 0 0 #DFE3E8",
      position: "absolute",
      top: "100%",
      width: "10.5rem",
      overflow: "hidden",
      transition: "height 0.3s ease-out",
      zIndex: 100,
    },
    btnWrapperPositionRight: {
      right: "0.5%",
    },
    btnWrapperPositionLeft: {
      left: "0.5%",
    },
    padding: {
      padding: "1rem",
    },
    backbtn: {
      color: "#637381",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "20px",
      "&:hover": {
        backgroundColor: "#F6F6F7",
      },
    },
    slideBtn: {
      width: "100%",
      height: "45px",
      justifyContent: "flex-start",
      color: "#212B36",
      fontSize: "14px !important",
      letterSpacing: 0,
      lineHeight: "20px",
      padding: "0 1rem",
      boxShadow: "inset 0 -1px 0 0 #DFE3E8",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
    slideanimation: {
      height: "10.5rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    uploadBtn: {
      height: "32px",
      width: "fit-content",
      backgroundColor: "#FF2C46",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1rem 0",
    },
  };
});
export default function TopBar({ userId }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isVisible, setVisible] = useState(false);
  return (
    <Box className={classes.topBar}>
      <Button
        onClick={() => navigate("/dashboard")}
        className={classes.backbtn}
        startIcon={
          i18n.language === "ar" ? (
            <ArrowForwardIosIcon
              style={{
                marginLeft: "1rem",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            />
          ) : (
            <ArrowBackIosIcon
              style={{ fontSize: "15px", fontWeight: "bold" }}
            />
          )
        }
      >
        {t("back")}
      </Button>
      <Box style={{ position: "relative" }}>
        <Box
          className={`${
            i18n.language === "ar"
              ? classes.btnWrapperPositionLeft
              : classes.btnWrapperPositionRight
          } ${classes.btnWrapper} ${isVisible ? classes.slideanimation : ""}`}
        >
          <Button
            className={`${classes.slideBtn}`}
            style={{ height: "35px" }}
            disabled
          >
            {t("Select an action")}
          </Button>
          <Button
            onClick={() => navigate(`/dashboard/users/${userId}/addPhotoId`)}
            className={`${classes.slideBtn}`}
          >
            {t("Photo-ID")}
          </Button>
          <Button
            onClick={() =>
              navigate(`/dashboard/users/${userId}/new-file/Other`)
            }
            className={`${classes.slideBtn}`}
          >
            {t("Other-Files")}
          </Button>
          <Button
            onClick={() => navigate(`/dashboard/users/${userId}/memberShipId`)}
            className={`${classes.slideBtn}`}
          >
            {t("membership-id")}
          </Button>
        </Box>
        <Button
          disabled={clinicImpersonated()}
          onClick={() => setVisible((prev) => !prev)}
          className={classes.uploadBtn}
        >
          <ArrowDropDownIcon /> {t("Upload-Files")}
        </Button>
      </Box>
    </Box>
  );
}
