import React from "react";
import InformationRadioOptions from "../InformationRadioOptions";
import InformationRadioButtonGroup from "./InformationRadioButtonGroup";
import { InputLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import permanent from '../../assets/images/fv-info-details/dentition_type_2.svg';
import mixed from '../../assets/images/fv-info-details/dentition_type_1.svg';
import aesthetic1 from '../../assets/images/fv-info-details/treatment_approach_2.svg';
import aesthetic2 from '../../assets/images/fv-info-details/treatment_approach_mixed_2.svg';
import fullArch1 from '../../assets/images/fv-info-details/treatment_approach_1.svg';
import fullArch2 from '../../assets/images/fv-info-details/treatment_approach_mixed_1.svg';;

const TreatmentApproach = ({
    t,
    infoDetails,
    data,
    handleRadioButtonClick,
}) => {
    const arches_to_treat = [
        {
            value: 3,
            label: 'both-arches',
        },
        {
            value: 1,
            label: 'upper-arch',
        },
        {
            value: 2,
            label: 'lower-arch',
        },
    ];

    const dentition_types = [
        {
            value: 'permanent',
            img: permanent,
            label: 'permanent',
            info: 'permanent-info',
        },
        {
            value: 'mixed',
            img: mixed,
            label: 'mixed-dentition',
            info: 'mixed-dentition-info',
        },
    ];

    const treatment_approach_permanent = [
        {
            value: 'aesthetic',
            img: aesthetic1,
            label: 'aesthetic',
            info: 'aesthetic-info',
        },
        {
            value: 'full-arch',
            img: fullArch1,
            label: 'full-arch-correction',
            info: 'full-arch-correction-info',
        },
    ];

    const treatment_approach_mixed = [
        {
            value: 'full-arch',
            img: fullArch2,
            label: 'full-arch-correction-recommended',
            info: 'full-arch-correction-info',
        },
        {
            value: 'aesthetic',
            img: aesthetic2,
            label: 'aesthetic',
            info: 'aesthetic-info',
        },
    ];

    return (
        <div className='treatment-approach-container'>
            <Typography className='treatment-approach-title'>{t('arches-to-treat')}</Typography>
            <RadioGroup name='arches' className='info-arches-container'>
                {
                    arches_to_treat.map((arch, key) => {
                        return (
                            <InputLabel key={key} className='info-arches-InputLabel'>
                                <Radio onClick={() => handleRadioButtonClick('arches_to_treat', arch.value)} value={arch.value} checked={data?.arches_to_treat === arch.value} />
                                {t(arch.label)}
                            </InputLabel>
                        )
                    })
                }
            </RadioGroup>
            {/* <InformationRadioOptions
                title={t('dentition-type')}
                subtitle={t('select-dentition-type')}
            >
                <InformationRadioButtonGroup
                    radioGroupTypes={infoDetails}
                    groupName='dentition_type'
                    radioButtons={dentition_types}
                    handleRadioButtonClick={handleRadioButtonClick}
                    t={t}
                />
            </InformationRadioOptions> */}
            <InformationRadioOptions
                title={t('treatment-approach-2')}
                subtitle={t('select-treatment-approach-type')}
            >
                <InformationRadioButtonGroup
                    radioGroupTypes={infoDetails}
                    groupName='treatment_approach'
                    radioButtons={infoDetails.dentition_type === 'permanent' ? treatment_approach_permanent : treatment_approach_mixed}
                    handleRadioButtonClick={handleRadioButtonClick}
                    t={t}
                />
            </InformationRadioOptions>
        </div>
    );
};

export default TreatmentApproach;
