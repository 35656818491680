export const TableActionsConstants = {
  DONE_ELSEWHERE: 'Done Elsewhere',
  POSTPONE: 'Postpone',
  FIRST_CALL_ATTEMPTED: 'First Call Attempted',
  SECOND_CALL_ATTEMPTED: 'Second Call Attempted',
  WHATSAPP_SENT: 'WhatsApp Sent',
};

export const TableAppointmentsStatues = {
  APPOINTMENT_DONE_ELSEWHERE: 'Appointment Done Elsewhere',
  NEEDS_RESCHEDULING: 'Needs Rescheduling',
  FIRST_CALL_ATTEMPTED: 'Pending (First Call Attempted)',
  SECOND_CALL_ATTEMPTED: 'Pending (Second Call Attempted)',
  WHATSAPP_SENT: 'Pending (WhatsApp Sent)',
}
