import React from "react";
import { Typography } from "@mui/material";

const IPRComponent = ({ align, isArabic, t }) => {
  const unorderedListStyle = isArabic
    ? {
        margin: "10px 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "10px 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };

  const divStyles = {
    margin: "15px 0",
    textAlign: align,
    padding: "10px",
  };

  const title = { fontSize: "14px", mb: 2 };

  const listHeaderStyle = {
    color: "#000000",
    fontSize: "14px",
    mb: 2,
    marginTop: " 16px",
  };
  const divStyles2 = {
    margin: "15px 0",
    textAlign: align,
  };

  return (
    <div className="learning_container">
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq")}
            </Typography>
          </li>
        </ul>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography sx={title} variant="span" component="span">
            {t("ipr_faq_1")}
            <br />
            {t("ipr_faq_2")}
            <br />
            {t("ipr_faq_3")}
            <br />
          </Typography>
        </Typography>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_faq_4")}
          </Typography>
          <ol style={unorderedListStyle}>
            <li>{t("ipr_faq_5")}</li>
            <li>{t("ipr_faq_6")}</li>
            <li>{t("ipr_faq_7")}</li>
            <li>{t("ipr_faq_8")}</li>
          </ol>
        </Typography>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        <Typography
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
          component="h1"
        >
          {t("ipr_faq_9")}
        </Typography>
        <ul style={unorderedListStyle}>
          <li>{t("ipr_faq_10")}</li>
          <li>{t("ipr_faq_11")}</li>
          <li>{t("ipr_faq_12")}</li>
          <li>{t("ipr_faq_13")}</li>
        </ul>
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src="/assets/images/ipr1.png" alt="" className="learning_img" />
      </div>

      <Typography sx={divStyles} variant="div" component="div">
        <Typography
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
          component="h1"
        >
          {t("ipr_faq_14")}
        </Typography>
        <Typography sx={listHeaderStyle} variant="span" component="span">
          {t("ipr_faq_15")}
        </Typography>
        <ul style={unorderedListStyle} sx={{ mb: 2 }}>
          <li>{t("ipr_faq_16")}</li>
          <li>{t("ipr_faq_17")}</li>
          <li>{t("ipr_faq_18")}</li>
        </ul>
        <Typography sx={divStyles2} variant="div" component="div">
          <Typography sx={listHeaderStyle} variant="span" component="span">
            {t("ipr_faq_19")}
          </Typography>
        </Typography>
        <ul style={unorderedListStyle} sx={{ mb: 2 }}>
          <li>{t("ipr_faq_20")}</li>
          <li>{t("ipr_faq_21")}</li>
          <li>{t("ipr_faq_22")}</li>
          <li>{t("ipr_faq_23")}</li>
        </ul>
      </Typography>
    </div>
  );
};

export default IPRComponent;
