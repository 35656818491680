import React, { useState } from "react";
import {
  InlineError,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../util/axios";
import Cookies from "js-cookie";
import { Editor } from "@tinymce/tinymce-react";

import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../TopNav/Topnav";
import { useTranslation } from "react-i18next";

function EditComment() {
  var { userId, commentId } = useParams();
  const navigate = useNavigate();
  const [valueComment, setvalueComment] = useState(
    Cookies.get("clickedComment")
  );
  const { t } = useTranslation();
  const handleChangeComment = (e) => {
    setvalueComment(e.target.getContent());
  };
  const [fieldRequiredComment, setfieldRequiredComment] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );

  return (
   <Layout>
      <AppProvider>
      <Page
        title="Edit Comment"
        breadcrumbs={[
          {
            content: "Dashboard",
            url: "/dashboard/users/" + userId,
          },
        ]}
      >
        <Card sectioned>
          <FormLayout>
            <TextStyle variation="strong">{t('Comment')}</TextStyle>
            <Editor
              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
              value={valueComment}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
              }}
              onChange={handleChangeComment}
            />

            {fieldRequiredComment}
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: t("Save"),
            onClick: handleSave,
          }}
          secondaryActions={[
            {
              content: t("Delete"),
              onClick: () =>
                axios
                  .delete(
                    "admin/v1/users/" +
                      userId +
                      "/comments/" +
                      commentId +
                      "/delete"
                  )
                  .then((result) => {
                    navigate(`/dashboard/users/${userId}`);
                  })
                  .catch((err) => console.log(err)),
            },
          ]}
        />
      </Page>
    </AppProvider>
   </Layout>
  );

  function handleSave() {
    if (valueComment === "") {
      setfieldRequiredComment(
        <InlineError message="This field is required" fieldID="myFieldID" />
      );
    } else {
      const bodyObj = {
        comment: valueComment,
      };
      axios
        .post("admin/v1/users/" + userId + "/comments/" + commentId, bodyObj)
        .then((res) => {
          navigate(`/dashboard/users/${userId}`);
        })
        .catch((err) => console.log(err));
    }
  }
}
export default EditComment;
