import {
  Caption,
  DropZone,
  FormLayout,
  Stack,
  TextStyle,
  Thumbnail,
  Button as PolarisButton,
} from "@shopify/polaris";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { clinicImpersonated } from "../../util/functions";
import { Box, LinearProgress, makeStyles, Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import axios from "../../util/axios";
import axiosAws from "axios";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import ProcedureHeader from "./procedure-header.component";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  };
});

const MediaFiles = ({
  handleTabsExpand,
  appointmentId,
  openCloseTabs,
  data = { data: {}, isAllProvided: null },
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
}) => {
  const {
    data: mediaData,
    isAllProvided,
  } = data;

  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [addFilesSave, setAddFilesSave] = useState([]);
  const [addFilesPreview, setAddFilesPreview] = useState(mediaData?.media_files ? mediaData.media_files : []);
  const [errorFilesUpload, setErrorFilesUpload] = useState([]);
  const [addFilesKey, setAddFilesKey] = useState([]);
  const [mediaIcon, setMediaIcon] = useState(isAllProvided);
  const [indexFiles, setIndexFiles] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [popupActive, setPopupActive] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const { t } = useTranslation();
  const isResponsive = useMediaQuery({
    query: "(max-width: 902px)",
  });

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const handleFilesDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, index) => {
      addFilesSave[index] = acceptedFiles[acceptedFiles.length - 1];
      setAddFilesSave(addFilesSave);
    },
    []
  );
  const sendImages = useCallback(
    (files, index, imageId, array) => {
      setPopupActive(true);
      const validImageUrl =
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "/assets/images/Fileicon.svg";
      array[index] = { id: imageId, file_url: validImageUrl };
      setAddFilesPreview(array);
      let imageType = files[files.length - 1].type;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([
            {
              id: imageId,
              file_type: imageType,
              file: res.data.key,
            },
          ]);
          url2 = res.data.url;
          axiosAws
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) =>
                uploadConfig(event, `image_${index}`),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              console.log("Error", err);
              errorFilesUpload[index] = "Upload failed. Please try again.";
              array[index] = null;
              addFilesSave[index] = null;
              setPopupActive(false);
            });
        })
        .catch((err) => {
          console.log("Error", err);
          errorFilesUpload[index] = "Upload failed. Please try again.";
          array[index] = null;
          addFilesSave[index] = null;
          setPopupActive(false);
        });
    },

    [addFilesSave]
  );

  const handleAddFiles = () => {
    setAddFilesPreview((prev) => [...prev, {}]);
  };

  const handleSave = async () => {
    setErrorFilesUpload([]);
    setSuccess(false);
    const form_data = new FormData();
    form_data.append("files", JSON.stringify(addFilesKey));
    form_data.append("procedure", appointmentType?.procedure);
    form_data.append("user_scan_info_id", appointmentId);
    setPopupActive(true);
    const url = `/admin/v2/users/${userId}/case-record-media`;
    try {
      const response = await axios.post(url, form_data);
      if (response.data.success) {
        if (addFilesKey.length > 0) {
          setAddFilesKey([]);
        }
        if (response.data?.data?.media_files?.length > 0) {
          addFilesPreview[indexFiles] = response.data.data.media_files[indexFiles];
          setAddFilesPreview(addFilesPreview);
        }
        setPopupActive(false);
        setSuccess(true);
        handleIconMediaView(response.data?.isAllProvided);
      }
    } catch (error) {
      setPopupActive(false);
      setSuccess(false);
    }
  };

  const handleIconMediaView = (value) => {
    setMediaIcon(value);
  };

  useEffect(() => {
    if (callHandleSave === 1) {
      void handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, "media");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="media"
      headerTitle="Photos"
      index={index}
      iconCheck={mediaIcon}
    >
      {openCloseTabs[index]?.media && (
        <Box className={`${classes.wrapper} media-${index}`}>
          <div style={{ textAlign: "right", marginTop: "1rem" }}>
            <PolarisButton
              disabled={clinicImpersonated() || popupActive}
              onClick={handleAddFiles}
            >
              {t("add-another-file")}
            </PolarisButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            {Array.from(Array(addFilesPreview.length).keys()).map((idx) => (
              <div
                key={idx}
                style={{
                  width:
                    isResponsive ||
                    (addFilesPreview.length === idx + 1 &&
                      addFilesPreview.length % 2 !== 0)
                      ? "100%"
                      : "49%",
                  marginTop: "1rem",
                }}
              >
                <FormLayout>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      {addFilesPreview[idx]?.file_type?.includes("video") ? (
                        <div
                          style={{
                            width: "5rem",
                            height: "5rem",
                            position: "relative",
                          }}
                        >
                          <PlayCircleOutlineIcon
                            style={{
                              position: "absolute",
                              opacity: 0.5,
                              top: "50%",
                              left: "50%",
                              transform: "translateX(-50%) translateY(-50%)",
                            }}
                          />
                          <video
                            className="video"
                            style={{ width: "100%", height: "100%" }}
                            src={addFilesPreview[idx]?.file_url}
                          ></video>
                        </div>
                      ) : (
                        <Thumbnail
                          size="large"
                          source={addFilesPreview[idx]?.file_url ? addFilesPreview[idx].file_url : ''}
                          alt={`media #${idx}`}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) =>
                          handleFilesDropZone(
                            _dropFiles,
                            acceptedFiles,
                            _rejectedFiles,
                            idx
                          )
                        }
                        onDropAccepted={(file) => {
                          setIndexFiles(idx);
                          if (addFilesPreview[idx]?.id) {
                            sendImages(
                              file,
                              idx,
                              addFilesPreview[idx]?.id,
                              addFilesPreview
                            );
                          } else {
                            sendImages(file, idx, 0, addFilesPreview);
                          }
                        }}
                      >
                        {addFilesSave[idx] != null && (
                          <Stack alignment="center">
                            <Thumbnail
                              size="small"
                              alt={addFilesSave[idx]?.name}
                              source={
                                validImageTypes.indexOf(
                                  addFilesSave[idx]?.type
                                ) > 0
                                  ? window.URL.createObjectURL(
                                      addFilesSave[idx]
                                    )
                                  : "/assets/images/Fileicon.svg"
                              }
                            />
                            <div>
                              {addFilesSave[idx]?.name}{" "}
                              <Caption>{addFilesSave[idx]?.type} bytes</Caption>
                            </div>
                          </Stack>
                        )}
                      </DropZone>
                      <TextStyle variation="negative">
                        {errorFilesUpload[idx]}
                      </TextStyle>
                      {uploadProgress[`image_${idx}`] && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress[`image_${idx}`]}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
              </div>
            ))}
          </div>
          <Box className={classes.uploadBox}>
            <Button
              disabled={clinicImpersonated()}
              className={classes.saveBtn}
              onClick={handleSave}
            >
              {success ? t("Saved") : t("Save")}
            </Button>
          </Box>
        </Box>
      )}
    </ProcedureHeader>
  );
};

export default MediaFiles;
