import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import BookingsProtocol from "./BookingsProtocol";
import ExaminationProtocol from "./ExaminationProtocol";
import ScanProtocal from "./ScanProtocol";
import ProceduresAndCancelledBookingsProtocol from "./ProceduresAndCancelledBookingsProtocol";
import IPRAttachmentsButtonsProtocol from "./IPRAttachmentsButtonsProtocol";
import ClinicTreatmentProtocalGeneralGuidelines from "./ClinicTreatmentProtocalGeneralGuidelines";

function ClinicTreatmentProtocal({ align }) {
  const { t } = useTranslation();

  return (
    <Layout isProfile={true}>
      <div className="learning_container">
        <Typography
          variant="h5"
          sx={{ mb: 2, textAlign: "center", paddingTop: "100px" }}
          component="h1"
        >
          {t("clinic_treatment_title")}
        </Typography>
        <BookingsProtocol align={align} isPage={false} />
        <ExaminationProtocol align={align} isPage={false} />
        <ScanProtocal align={align} isPage={false} />
        <ProceduresAndCancelledBookingsProtocol align={align} isPage={false} />
        <IPRAttachmentsButtonsProtocol align={align} isPage={false} />
        <ClinicTreatmentProtocalGeneralGuidelines align={align} isPage={false} />
      </div>
    </Layout>
  );
}

export default ClinicTreatmentProtocal;
