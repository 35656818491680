import React, { useState } from 'react'
import { Box, InputLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

const InformationRadioButtonGroup = ({ radioGroupTypes, groupName, radioButtons, handleRadioButtonClick, t }) => {
    const [radioInfo, setRadioInfo] = useState(radioButtons.map(() => ({
        isOpen: false,
    })));

    const openInfo = (index) => {
        setRadioInfo(prev => {
            const newRadioInfo = prev.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        isOpen: !item.isOpen
                    };
                }
                return item;
            });
            return newRadioInfo;
        });
    };

    return (
        <RadioGroup name={groupName} className='info-images-container'>
            {
                radioButtons.map((radio, key) => {
                    return (
                        <div key={key} className='info-single-image-container' style={{padding: !radioInfo[key].isOpen ? '20px' : 0}}>
                            {
                                !radioInfo[key].isOpen ?
                                <>
                                    <div className='info-single-image-info' onClick={() => openInfo(key)}>
                                        <InfoIcon fontSize='small' />
                                    </div>
                                    <Box component='img' className='info-single-image-img' src={radio.img} />
                                </> :
                                <div className='info-single-image-info-content'>
                                    <div className='info-single-image-info' onClick={() => openInfo(key)}>
                                        <CloseIcon fontSize='small' />
                                    </div>
                                    <Typography className='info-single-image-details'>{t(radio.info)}</Typography>
                                </div>
                            }
                            <InputLabel>
                                <Radio onClick={() => handleRadioButtonClick(groupName, radio.value)} value={radio.value} checked={radioGroupTypes[groupName] === radio.value} />
                                {t(radio.label)}
                            </InputLabel>
                        </div>
                    )
                })
            }
        </RadioGroup>
    )
}

export default InformationRadioButtonGroup
