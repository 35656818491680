import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
const CustomTooltip = (props) => {
  const theme = {width:"1rem", height:"1rem", marginTop:"auto", marginBottom:"auto"};
  return (
      <Tooltip title={props.title}>
        <img src={"/assets/images/tooltip.png"} style={theme}></img>
      </Tooltip>
  );
};

export default CustomTooltip;
