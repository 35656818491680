import {
  Box,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../util/axios";
import axiosAws from "axios";
import SinglePhoto from "./SinglePhoto";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clinicImpersonated } from "../../util/functions";
import ProcedureHeader from "./procedure-header.component";

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    singlePhotoBox: {
      display: "flex",
      width: "70.19px",
      height: "109.03px",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "1rem",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    errorMessage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      ["@media only screen and (max-width: 500px) "]: {
        flex: "33%",
      },
    },
    title: {
      height: "16px",
      width: "134px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
      marginBottom: "1rem",
    },
    errors: {
      color: "#D72C0D",
      fontSize: "12px !important",
      marginTop: "1rem",
    },
    imageTitle: {
      height: "33.6px",
      width: "73px",
      color: "#212B36",
      fontSize: "9px !important",
      letterSpacing: 0,
      lineHeight: "16.8px",
      textAlign: "center",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
  };
});

export default function ButtonsFiles({
  disable,
  appointmentId,
  appointmentType,
  handleTabsExpand,
  index,
  data = { isAllProvided: null, data: {} },
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const { data: buttonsData, isAllProvided } = data;
  const { id } = useParams();
  const classes = useStyles();
  const [photos, setPhotos] = useState(buttonsData);
  const [imageKey, setImageKey] = useState({});
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [iconPhotos, setIconPhotos] = useState(isAllProvided);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const handlePhotosValue = (value) => {
    setPhotos(value);
  };

  const handleIconPhotosView = (value) => {
    setIconPhotos(value);
  };

  const handleImages = (files, id, fileName = null) => {
    setErrorMsg("");
    setSuccess(false);
    setDisabled(true);
    const imageId = id;
    const file = files[0];
    saveInAws(file, imageId, fileName);
  };

  const saveInAws = (value, id, fileName) => {
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios
      .post("/admin/v1/images/s3", form_data)
      .then((res) => {
        setImageKey({
          file_type: imageType,
          file: res.data.key,
          name: id,
        });
        const url2 = res.data.url;
        axiosAws
          .put(url2, value, {
            headers: {
              "x-amz-acl": ["public-read-write"],
              "Content-Type": imageType,
            },
          })
          .then((res) => {
            setCallHandleSave(1);
          })
          .catch((err) => {
            setDisabled(false);
          });
      })
      .catch((err) => {
        setDisabled(false);
      });
  };

  const handleSave = () => {
    setErrorMsg("");
    let body = {
      files: [imageKey],
      procedure: appointmentType?.procedure,
      user_scan_info_id: appointmentId,
    };
    if (
      !Object.values(body).every(
        (x) => x === null || x === "" || x === undefined
      )
    ) {
      axios
        .post(`admin/v2/users/${id}/case-record-buttons`, body)
        .then((res) => {
          if (res.data.success) {
            handlePhotosValue(res.data.data);
            handleIconPhotosView(res.data.isAllProvided);
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        })
        .catch((err) => {
          setErrorMsg("Upload failed. Please try again.");
          console.log(err);
        })
        .finally(() => setDisabled(false));
    }
  };

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, "buttons");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="buttons"
      headerTitle="Buttons"
      index={index}
      iconCheck={iconPhotos}
    >
      {openCloseTabs[index]?.buttons && (
        <Box className={`${classes.photoWrapper} buttons-${index}`}>
          <Typography className={classes.title}>
            {t("Buttons")}
          </Typography>
          <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={photos?.upper_url}
                fileType={photos?.upper_type}
                handleImages={handleImages}
                id='upper_occlusal'
              />
              <Typography className={classes.imageTitle}>
                {t('Upper Occlusal')}
              </Typography>
            </Box>

            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={photos?.lower_url}
                fileType={photos?.lower_type}
                handleImages={handleImages}
                id='lower_occlusal'
              />
              <Typography className={classes.imageTitle}>
                {t('Lower Occlusal')}
              </Typography>
            </Box>
          </Box>
          <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={photos?.center_url}
                fileType={photos?.center_type}
                handleImages={handleImages}
                id='center'
              />
              <Typography className={classes.imageTitle}>
                {t('Center-Bite')}
              </Typography>
            </Box>

            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={photos?.left_url}
                fileType={photos?.left_type}
                handleImages={handleImages}
                id='left'
              />
              <Typography className={classes.imageTitle}>
                {t('Left-Bite')}
              </Typography>
            </Box>

            <Box className={classes.singlePhotoBox}>
              <SinglePhoto
                disable={disable || disabled}
                value={photos?.right_url}
                fileType={photos?.right_type}
                handleImages={handleImages}
                id='right'
              />
              <Typography className={classes.imageTitle}>
                {t('Right-Bite')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.errorMessage}>
            <Button
              disabled={clinicImpersonated()}
              className={classes.saveBtn}
              onClick={handleSave}
            >
              {success ? t("Saved") : t("Save")}
            </Button>
            <Typography className={classes.errors}>{errorMsg}</Typography>
          </Box>
        </Box>
      )}
    </ProcedureHeader>
  );
}
