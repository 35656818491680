import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import AttachmentsFaqs from "./AttachmentsFaqs";

function ClearAlignerAttachments({ align }) {
  const { t } = useTranslation();

  return (
    <Layout isProfile={true}>
      <div className="learning_container">
        <Typography
          variant="h4"
          sx={{ mb: 2, textAlign: "center", paddingTop: "100px" }}
          component="h1"
        >
          {t("aligner_1")}
        </Typography>

        <AttachmentsFaqs align={align} isPage={false} />
      </div>
    </Layout>
  );
}

export default ClearAlignerAttachments;
