import React, { useCallback, useEffect, useState } from "react";
import { Box, LinearProgress, makeStyles, Button } from "@material-ui/core";
import {
  Caption,
  DropZone,
  FormLayout,
  Stack,
  TextStyle,
  Thumbnail,
  TextField,
} from "@shopify/polaris";
import { clinicImpersonated } from "../../util/functions";
import { useTranslation } from "react-i18next";
import axios from "../../util/axios";
import axiosAws from "axios";
import ProcedureHeader from "./procedure-header.component";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  };
});

const RedoScansPhotos = ({
  handleTabsExpand,
  appointmentId,
  openCloseTabs,
  data = { data: {}, isAllProvided: null, redo_info: {} },
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
}) => {
  if (data?.data === null) {
    data.data = {
      center_bite: "",
      left_bite: "",
      lower_teeth: "",
      right_bite: "",
      upper_teeth: "",
      note: "",
    };
  }
  const {
    data: {
      center_bite,
      left_bite,
      lower_teeth,
      right_bite,
      upper_teeth,
      note,
    },
    isAllProvided,
    redo_info,
  } = data;
  const classes = useStyles();
  const [noteValue, setNoteValue] = useState(note);
  const [redoScanPhotosIcon, setRedoScanPhotosIcon] = useState(isAllProvided);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const [popupActive, setPopupActive] = useState(false);
  const [filesCenterBite, setFilesCenterBite] = useState([]);
  const [filesUpperTeeth, setFilesUpperTeeth] = useState([]);
  const [filesLowerTeeth, setFilesLowerTeeth] = useState([]);
  const [filesRightBite, setFilesRightBite] = useState([]);
  const [filesLeftBite, setFilesLeftBite] = useState([]);
  const [filesFrontFacingNoSmile, setFilesFrontFacingNoSmile] = useState([]);
  const [filesFrontFacingBigSmile, setFilesFrontFacingBigSmile] = useState([]);
  const [filesProfileNoSmile, setFilesProfileNoSmile] = useState([]);
  const [imageKeyRightBite, setImageKeyRightBite] = useState("");
  const [imageKeyLowerTeeth, setImageKeyLowerTeeth] = useState("");
  const [imageKeyUpperTeeth, setImageKeyUpperTeeth] = useState("");
  const [imageKeyLeftBite, setImageKeyLeftBite] = useState("");
  const [imageKeyCenterBite, setImageKeyCenterBite] = useState("");
  const [imageKeyFrontFacingNoSmile, setImageKeyFrontFacingNoSmile] =
    useState("");
  const [imageKeyFrontFacingBigSmile, setImageKeyFrontFacingBigSmile] =
    useState("");
  const [imageKeyProfileNoSmile, setImageKeyProfileNoSmile] = useState("");
  const [topErrorMessage, setTopErrorMessage] = useState("");
  const [bottomErrorMessage, setBottomErrorMessage] = useState("");
  const [frontErrorMessage, setFrontErrorMessage] = useState("");
  const [rightErrorMessage, setRightErrorMessage] = useState("");
  const [leftErrorMessage, setLeftErrorMessage] = useState("");
  const [frontFacingNoSmileErrorMessage, setFrontFacingNoSmileErrorMessage] =
    useState("");
  const [frontFacingBigSmileErrorMessage, setFrontFacingBigSmileErrorMessage] =
    useState("");
  const [profileNoSmileErrorMessage, setProfileNoSmileErrorMessage] =
    useState("");
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreviewCenterBite, setImagePreviewCenterBite] = useState(
    center_bite ? center_bite : "/assets/images/center_bite.jpeg"
  );
  const [imagePreviewLeftBite, setImagePreviewLeftBite] = useState(
    left_bite ? left_bite : "/assets/images/left_bite.jpg"
  );
  const [imagePreviewRightBite, setImagePreviewRightBite] = useState(
    right_bite ? right_bite : "/assets/images/right_bite.jpeg"
  );
  const [imagePreviewUpperTeeth, setImagePreviewUpperTeeth] = useState(
    upper_teeth ? upper_teeth : "/assets/images/upper_teeth.jpeg"
  );
  const [imagePreviewLowerTeeth, setImagePreviewLowerTeeth] = useState(
    lower_teeth ? lower_teeth : "/assets/images/lower_teeth.jpeg"
  );
  const [imagePreviewFrontFacingNoSmile, setImagePreviewFrontFacingNoSmile] =
    useState(
      redo_info?.front_facing_no_smile
        ? redo_info?.front_facing_no_smile
        : "/assets/images/front_facing_no_smile.jpg"
    );
  const [imagePreviewFrontFacingBigSmile, setImagePreviewFrontFacingBigSmile] =
    useState(
      redo_info?.front_facing_big_smile
        ? redo_info?.front_facing_big_smile
        : "/assets/images/front_facing_big_smile.jpg"
    );
  const [imagePreviewProfileNoSmile, setImagePreviewProfileNoSmile] = useState(
    redo_info?.profile_no_smile
      ? redo_info?.profile_no_smile
      : "/assets/images/right_side_profile.jpg"
  );

  const handleChangeNoteValue = useCallback(
    (newValue) => setNoteValue(newValue),
    []
  );

  const handleIconRedoScanPhotosView = (value) => {
    setRedoScanPhotosIcon(value);
  };

  const handleFailedUpload = (img, err) => {
    setPopupActive(false);
    console.log(err);
    if (img === "center_bite") {
      setFrontErrorMessage(t("upload-failed"));
      setFilesCenterBite([]);
      setImagePreviewCenterBite("/assets/images/center_bite.jpeg");
      setImageKeyCenterBite("");
    }
    if (img === "left_bite") {
      setLeftErrorMessage(t("upload-failed"));
      setFilesLeftBite([]);
      setImagePreviewLeftBite("/assets/images/left_bite.jpg");
      setImageKeyLeftBite("");
    }
    if (img === "right_bite") {
      setRightErrorMessage(t("upload-failed"));
      setFilesRightBite([]);
      setImagePreviewRightBite("/assets/images/placeholder-image.png");
      setImageKeyRightBite("");
    }
    if (img === "upper_teeth") {
      setTopErrorMessage(t("upload-failed"));
      setFilesUpperTeeth([]);
      setImagePreviewUpperTeeth("/assets/images/upper_teeth.jpeg");
      setImageKeyUpperTeeth("");
    }
    if (img === "lower_teeth") {
      setBottomErrorMessage(t("upload-failed"));
      setFilesLowerTeeth([]);
      setImagePreviewLowerTeeth("/assets/images/lower_teeth.jpeg");
      setImageKeyLowerTeeth("");
    }
    if (img === "front_facing_no_smile") {
      setFrontFacingNoSmileErrorMessage(t("upload-failed"));
      setFilesFrontFacingNoSmile([]);
      setImagePreviewFrontFacingNoSmile(
        "/assets/images/front_facing_no_smile.jpg"
      );
    }
    if (img === "front_facing_big_smile") {
      setFrontFacingBigSmileErrorMessage(t("upload-failed"));
      setFilesFrontFacingBigSmile([]);
      setImagePreviewFrontFacingBigSmile(
        "/assets/images/front_facing_big_smile.jpg"
      );
    }
    if (img === "profile_no_smile") {
      setProfileNoSmileErrorMessage(t("upload-failed"));
      setFilesProfileNoSmile([]);
      setImagePreviewProfileNoSmile("/assets/images/right_side_profile.jpg");
    }
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const fileUploadCenterBite = !filesCenterBite.length && (
    <DropZone.FileUpload />
  );

  let uploadedFilesCenterBite = filesCenterBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesCenterBite[filesCenterBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesCenterBite[filesCenterBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesCenterBite[filesCenterBite.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesCenterBite[filesCenterBite.length - 1].name}{" "}
        <Caption>
          {filesCenterBite[filesCenterBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );

  const handleDropZoneCenterBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesCenterBite((filesCenterBite) => [
        ...filesCenterBite,
        ...acceptedFiles,
      ]),
    []
  );
  const sendImageCenterBite = useCallback(
    (filesCenterBite) => {
      setFrontErrorMessage("");
      setPopupActive(true);
      setImagePreviewCenterBite(
        validImageTypes.indexOf(
          filesCenterBite[filesCenterBite.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesCenterBite[filesCenterBite.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      let imageType = filesCenterBite[filesCenterBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let img = "center_bite";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        filesCenterBite[filesCenterBite.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyCenterBite(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesCenterBite[filesCenterBite.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) => uploadConfig(event, img),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },
    [filesCenterBite]
  );

  const handleDropZoneLeftBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLeftBite((filesLeftBite) => [...filesLeftBite, ...acceptedFiles]),
    []
  );
  const sendImageLeftBite = useCallback(
    (filesLeftBite) => {
      setLeftErrorMessage("");
      setPopupActive(true);
      setImagePreviewLeftBite(
        validImageTypes.indexOf(filesLeftBite[filesLeftBite.length - 1].type) >
          0
          ? window.URL.createObjectURL(filesLeftBite[filesLeftBite.length - 1])
          : "/assets/images/Fileicon.svg"
      );
      let headersVar = null;
      let imageType = filesLeftBite[filesLeftBite.length - 1].type;
      let url2 = "";
      let img = "left_bite";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        filesLeftBite[filesLeftBite.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyLeftBite(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesLeftBite[filesLeftBite.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) => uploadConfig(event, img),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },
    [filesLeftBite]
  );

  const fileUploadLeftBite = !filesLeftBite.length && <DropZone.FileUpload />;
  let uploadedFilesLeftBite = filesLeftBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLeftBite[filesLeftBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLeftBite[filesLeftBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLeftBite[filesLeftBite.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesLeftBite[filesLeftBite.length - 1].name}
        <Caption>{filesLeftBite[filesLeftBite.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const handleDropZoneRightBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesRightBite((filesRightBite) => [
        ...filesRightBite,
        ...acceptedFiles,
      ]),
    []
  );

  const sendImageRightBite = useCallback(
    (filesRightBite) => {
      setRightErrorMessage("");
      setPopupActive(true);
      setImagePreviewRightBite(
        validImageTypes.indexOf(
          filesRightBite[filesRightBite.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesRightBite[filesRightBite.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      let imageType = filesRightBite[filesRightBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let img = "right_bite";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        filesRightBite[filesRightBite.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyRightBite(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesRightBite[filesRightBite.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) => uploadConfig(event, img),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },
    [filesRightBite]
  );

  const fileUploadRightBite = !filesRightBite.length && <DropZone.FileUpload />;
  let uploadedFilesRightBite = filesRightBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesRightBite[filesRightBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesRightBite[filesRightBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesRightBite[filesRightBite.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesRightBite[filesRightBite.length - 1].name}{" "}
        <Caption>
          {filesRightBite[filesRightBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );

  const handleDropZoneUpperTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesUpperTeeth((filesUpperTeeth) => [
        ...filesUpperTeeth,
        ...acceptedFiles,
      ]),
    []
  );

  const sendImageUpperTeeth = useCallback(
    (filesUpperTeeth) => {
      setTopErrorMessage("");
      setPopupActive(true);
      setImagePreviewUpperTeeth(
        validImageTypes.indexOf(
          filesUpperTeeth[filesUpperTeeth.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesUpperTeeth[filesUpperTeeth.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      let headersVar = null;
      let imageType = filesUpperTeeth[filesUpperTeeth.length - 1].type;
      let url2 = "";
      let img = "upper_teeth";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        filesUpperTeeth[filesUpperTeeth.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyUpperTeeth(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesUpperTeeth[filesUpperTeeth.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) => uploadConfig(event, img),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },
    [filesUpperTeeth]
  );

  const fileUploadUpperTeeth = !filesUpperTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesUpperTeeth = filesUpperTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesUpperTeeth[filesUpperTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesUpperTeeth[filesUpperTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesUpperTeeth[filesUpperTeeth.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesUpperTeeth[filesUpperTeeth.length - 1].name}{" "}
        <Caption>
          {filesUpperTeeth[filesUpperTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );

  const handleDropZoneLowerTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLowerTeeth((filesLowerTeeth) => [
        ...filesLowerTeeth,
        ...acceptedFiles,
      ]),
    []
  );

  const sendImageLowerTeeth = useCallback(
    (filesLowerTeeth) => {
      setBottomErrorMessage("");
      setPopupActive(true);
      setImagePreviewLowerTeeth(
        validImageTypes.indexOf(
          filesLowerTeeth[filesLowerTeeth.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesLowerTeeth[filesLowerTeeth.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      let imageType = filesLowerTeeth[filesLowerTeeth.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let img = "lower_teeth";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        filesLowerTeeth[filesLowerTeeth.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyLowerTeeth(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesLowerTeeth[filesLowerTeeth.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) => uploadConfig(event, img),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [filesLowerTeeth]
  );

  const fileUploadLowerTeeth = !filesLowerTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesLowerTeeth = filesLowerTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLowerTeeth[filesLowerTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLowerTeeth[filesLowerTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLowerTeeth[filesLowerTeeth.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesLowerTeeth[filesLowerTeeth.length - 1].name}{" "}
        <Caption>
          {filesLowerTeeth[filesLowerTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );

  const handleDropZoneFrontFacingNoSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesFrontFacingNoSmile((frontFacingNoSmile) => [
        ...frontFacingNoSmile,
        ...acceptedFiles,
      ]),
    []
  );

  const sendImageFrontFacingNoSmile = useCallback(
    (frontFacingNoSmile) => {
      setPopupActive(true);
      setImagePreviewFrontFacingNoSmile(
        validImageTypes.indexOf(
          frontFacingNoSmile[frontFacingNoSmile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              frontFacingNoSmile[frontFacingNoSmile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let imageType = frontFacingNoSmile[frontFacingNoSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        frontFacingNoSmile[frontFacingNoSmile.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyFrontFacingNoSmile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, frontFacingNoSmile[frontFacingNoSmile.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) =>
                uploadConfig(event, "front_facing_no_smile"),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload("front_facing_no_smile", err);
            });
        })
        .catch((err) => {
          handleFailedUpload("front_facing_no_smile", err);
        });
    },

    [filesFrontFacingNoSmile]
  );

  const fileUploadFrontFacingNoSmile = !filesFrontFacingNoSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFrontFacingNoSmile = filesFrontFacingNoSmile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].name}{" "}
        <Caption>
          {filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].type}{" "}
          bytes
        </Caption>
      </div>
    </Stack>
  );

  const handleDropZoneFrontFacingBigSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesFrontFacingBigSmile((frontFacingBigSmile) => [
        ...frontFacingBigSmile,
        ...acceptedFiles,
      ]),
    []
  );

  const sendImageFrontFacingBigSmile = useCallback(
    (frontFacingBigSmile) => {
      setPopupActive(true);
      setImagePreviewFrontFacingBigSmile(
        validImageTypes.indexOf(
          frontFacingBigSmile[frontFacingBigSmile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              frontFacingBigSmile[frontFacingBigSmile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let imageType = frontFacingBigSmile[frontFacingBigSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        frontFacingBigSmile[frontFacingBigSmile.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyFrontFacingBigSmile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, frontFacingBigSmile[frontFacingBigSmile.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) =>
                uploadConfig(event, "front_facing_big_smile"),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload("front_facing_big_smile", err);
            });
        })
        .catch((err) => {
          handleFailedUpload("front_facing_big_smile", err);
        });
    },

    [filesFrontFacingBigSmile]
  );

  const fileUploadFrontFacingBigSmile = !filesFrontFacingBigSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFrontFacingBigSmile = filesFrontFacingBigSmile.length >
    0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].name}{" "}
        <Caption>
          {filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].type}{" "}
          bytes
        </Caption>
      </div>
    </Stack>
  );

  const handleDropZoneProfileNoSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesProfileNoSmile((profileNoSmile) => [
        ...profileNoSmile,
        ...acceptedFiles,
      ]),
    []
  );

  const sendImageProfileNoSmile = useCallback(
    (profileNoSmile) => {
      setPopupActive(true);
      setImagePreviewProfileNoSmile(
        validImageTypes.indexOf(
          profileNoSmile[profileNoSmile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              profileNoSmile[profileNoSmile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let imageType = profileNoSmile[profileNoSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append(
        "file_name",
        profileNoSmile[profileNoSmile.length - 1].name
      );
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKeyProfileNoSmile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, profileNoSmile[profileNoSmile.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress: (event) =>
                uploadConfig(event, "profile_no_smile"),
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload("profile_no_smile", err);
            });
        })
        .catch((err) => {
          handleFailedUpload("profile_no_smile", err);
        });
    },

    [filesProfileNoSmile]
  );

  const fileUploadProfileNoSmile = !filesProfileNoSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesProfileNoSmile = filesProfileNoSmile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesProfileNoSmile[filesProfileNoSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesProfileNoSmile[filesProfileNoSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesProfileNoSmile[filesProfileNoSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesProfileNoSmile[filesProfileNoSmile.length - 1].name}{" "}
        <Caption>
          {filesProfileNoSmile[filesProfileNoSmile.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );

  useEffect(() => {
    if (callHandleSave === 1) {
      void handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  const handleSave = async () => {
    setSuccess(false);
    setFrontErrorMessage("");
    setLeftErrorMessage("");
    setRightErrorMessage("");
    setTopErrorMessage("");
    setBottomErrorMessage("");
    setFrontFacingNoSmileErrorMessage("");
    setFrontFacingBigSmileErrorMessage("");
    setProfileNoSmileErrorMessage("");
    const form_data = new FormData();
    form_data.append("center_bite", imageKeyCenterBite);
    form_data.append("left_bite", imageKeyLeftBite);
    form_data.append("right_bite", imageKeyRightBite);
    form_data.append("upper_teeth", imageKeyUpperTeeth);
    form_data.append("lower_teeth", imageKeyLowerTeeth);
    form_data.append("front_facing_no_smile", imageKeyFrontFacingNoSmile);
    form_data.append("front_facing_big_smile", imageKeyFrontFacingBigSmile);
    form_data.append("profile_no_smile", imageKeyProfileNoSmile);
    noteValue && form_data.append("note", noteValue);
    form_data.append("user_scan_info_id", appointmentId);
    form_data.append("procedure", appointmentType?.procedure);
    setPopupActive(true);
    const url = `/admin/v2/users/${userId}/case-record-post-redo`;
    try {
      const response = await axios.post(url, form_data);
      if (response.status === 200) {
        if (imageKeyCenterBite !== "") {
          setImageKeyCenterBite("");
        }
        if (imageKeyLeftBite !== "") {
          setImageKeyLeftBite("");
        }
        if (imageKeyRightBite !== "") {
          setImageKeyRightBite("");
        }
        if (imageKeyLowerTeeth !== "") {
          setImageKeyLowerTeeth("");
        }
        if (imageKeyUpperTeeth !== "") {
          setImageKeyUpperTeeth("");
        }
        if (imageKeyFrontFacingNoSmile !== "") {
          setImageKeyFrontFacingNoSmile("");
        }
        if (imageKeyFrontFacingBigSmile !== "") {
          setImageKeyFrontFacingBigSmile("");
        }
        if (imageKeyProfileNoSmile !== "") {
          setImageKeyProfileNoSmile("");
        }
        setPopupActive(false);
        setSuccess(true);
        handleIconRedoScanPhotosView(response?.data?.isAllProvided);
      }
    } catch (error) {
      setPopupActive(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    scrollToExpandedTab(index, "screeningInformation");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="redoScanPhotos"
      headerTitle={
        appointmentType?.procedure == t('redo-scan-photos') ? 
        'redo-scan-photos' :
        (
          appointmentType?.procedure == t('mca-photos') ?
          'mca-photos' :
          'refinement-photos'
        )
      }
      index={index}
      iconCheck={redoScanPhotosIcon}
    >
      {openCloseTabs[index]?.redoScanPhotos && (
        <Box className={`${classes.wrapper} redoScanPhotos-${index}`}>
          <FormLayout>
            <FormLayout>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("center-bite-title")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewCenterBite}
                        alt={"Center Bite Image"}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneCenterBite}
                        onDropAccepted={sendImageCenterBite}
                      >
                        {uploadedFilesCenterBite}
                        {fileUploadCenterBite}
                      </DropZone>
                      <TextStyle variation="negative">
                        {frontErrorMessage}
                      </TextStyle>
                      {uploadProgress?.center_bite && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.center_bite}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("left-bite-title")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewLeftBite}
                        alt="Left Bite Image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneLeftBite}
                        onDropAccepted={sendImageLeftBite}
                      >
                        {uploadedFilesLeftBite}
                        {fileUploadLeftBite}
                      </DropZone>
                      <TextStyle variation="negative">
                        {leftErrorMessage}
                      </TextStyle>
                      {uploadProgress?.left_bite && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.left_bite}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("right-bite-title")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewRightBite}
                        alt="Right Bite Image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneRightBite}
                        onDropAccepted={sendImageRightBite}
                      >
                        {uploadedFilesRightBite}
                        {fileUploadRightBite}
                      </DropZone>
                      <TextStyle variation="negative">
                        {rightErrorMessage}
                      </TextStyle>
                      {uploadProgress?.right_bite && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.right_bite}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("upper-teeth-title")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewUpperTeeth}
                        alt="Upper Teeth Image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneUpperTeeth}
                        onDropAccepted={sendImageUpperTeeth}
                      >
                        {uploadedFilesUpperTeeth}
                        {fileUploadUpperTeeth}
                      </DropZone>
                      <TextStyle variation="negative">
                        {topErrorMessage}
                      </TextStyle>
                      {uploadProgress?.upper_teeth && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.upper_teeth}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("lower-teeth-title")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewLowerTeeth}
                        alt="Lower Teeth Image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneLowerTeeth}
                        onDropAccepted={sendImageLowerTeeth}
                      >
                        {uploadedFilesLowerTeeth}
                        {fileUploadLowerTeeth}
                      </DropZone>
                      <TextStyle variation="negative">
                        {bottomErrorMessage}
                      </TextStyle>
                      {uploadProgress?.lower_teeth && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.lower_teeth}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("Front-Facing-No-Smile")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewFrontFacingNoSmile}
                        alt="Front Facing No Smile Image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneFrontFacingNoSmile}
                        onDropAccepted={sendImageFrontFacingNoSmile}
                      >
                        {uploadedFilesFrontFacingNoSmile}
                        {fileUploadFrontFacingNoSmile}
                      </DropZone>
                      <TextStyle variation="negative">
                        {frontFacingNoSmileErrorMessage}
                      </TextStyle>
                      {uploadProgress?.front_facing_no_smile && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.front_facing_no_smile}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("Front-Facing-Big-Smile")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewFrontFacingBigSmile}
                        alt="Front Facing Big Smile Image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneFrontFacingBigSmile}
                        onDropAccepted={sendImageFrontFacingBigSmile}
                      >
                        {uploadedFilesFrontFacingBigSmile}
                        {fileUploadFrontFacingBigSmile}
                      </DropZone>
                      <TextStyle variation="negative">
                        {frontFacingBigSmileErrorMessage}
                      </TextStyle>
                      {uploadProgress?.front_facing_big_smile && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.front_facing_big_smile}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    {t("Profile-No-Smile")}
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewProfileNoSmile}
                        alt="Profile No Smile Image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneProfileNoSmile}
                        onDropAccepted={sendImageProfileNoSmile}
                      >
                        {uploadedFilesProfileNoSmile}
                        {fileUploadProfileNoSmile}
                      </DropZone>
                      <TextStyle variation="negative">
                        {profileNoSmileErrorMessage}
                      </TextStyle>
                      {uploadProgress?.profile_no_smile && (
                        <Box style={{ marginTop: "1rem" }}>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress?.profile_no_smile}
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>
            </FormLayout>
            <TextField
              label={t("Notes")}
              value={noteValue}
              onChange={handleChangeNoteValue}
              multiline={4}
            />
            <Box className={classes.uploadBox}>
              <Button
                disabled={clinicImpersonated()}
                className={classes.saveBtn}
                onClick={handleSave}
              >
                {success ? t("Saved") : t("Save")}
              </Button>
            </Box>
          </FormLayout>
        </Box>
      )}
    </ProcedureHeader>
  );
};

export default RedoScansPhotos;
