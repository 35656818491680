import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useStyles } from "./doctors-dropdown-container.styles";
import ActiveContext from "../../Context/Context";
import { useTranslation } from "react-i18next";
import axios from "../../util/axios";
import Cookies from "js-cookie";

const DoctorsDropdownContainer = ({
  setSelectedDoctor,
  selectedDoctor,
  setEnableDialog,
  otherDoctor,
  setOtherDoctor,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const data = useContext(ActiveContext);
  const [doctorsMenu, setDoctorsMenu] = useState(["Other"]);

  const getDoctors = async () => {
    try {
      const response = await axios.get(
        `admin/v1/clinic/${Cookies.get("clinic_id")}/doctors`
      );
      if (response.status === 200) {
        const { doctors } = response.data;
        setDoctorsMenu((prevState) => [...doctors, ...prevState]);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  const handleOtherTextField = (event) => {
    setOtherDoctor(event.target.value);
  };

  useEffect(() => {
    if (doctorsMenu.length === 2) {
      setSelectedDoctor(doctorsMenu[1]);
    }
  }, [doctorsMenu]);

  useEffect(() => {
    if (doctorsMenu.length === 1) {
      if (data?.clinicData?.doctors) {
        setDoctorsMenu((prevState) => [
          ...data?.clinicData?.doctors,
          ...prevState,
        ]);
      } else if (data?.clinicData?.data?.clinic_info?.doctors) {
        setDoctorsMenu((prevState) => [
          ...data?.clinicData?.data?.clinic_info?.doctors,
          ...prevState,
        ]);
      } else {
        void getDoctors();
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      (selectedDoctor !== "Other" && selectedDoctor !== "") ||
      (selectedDoctor === "Other" && otherDoctor !== "")
    ) {
      setEnableDialog(true);
    } else {
      setEnableDialog(false);
    }
  }, [otherDoctor, selectedDoctor]);

  return (
    <div className={classes.dropdownContainer}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {t("doctors-dropdown-label")}
        </InputLabel>
        <Select
          label={t("doctors-dropdown-label")}
          value={selectedDoctor}
          onChange={handleChange}
        >
          {doctorsMenu.map((doctor, index) => (
            <MenuItem key={index} value={doctor}>
              {doctor === "Other" ? "Other" : doctor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedDoctor === "Other" && (
        <TextField
          onChange={handleOtherTextField}
          fullWidth
          label="Other"
          value={otherDoctor}
        />
      )}
    </div>
  );
};

export default DoctorsDropdownContainer;
