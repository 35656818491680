import React, { useState } from 'react'
import { Box, Typography } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

const InformationCheckboxGroup = ({
    checkboxes,
    checkboxTypes,
    group,
    handleCheckboxClick,
    t,
}) => {
    const [checkboxInfo, setCheckboxInfo] = useState(checkboxes.map(() => ({
        isOpen: false,
    })));

    const openInfo = (event, index) => {
        event.stopPropagation();
        setCheckboxInfo(prev => {
            const newRadioInfo = prev.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        isOpen: !item.isOpen
                    };
                }
                return item;
            });
            return newRadioInfo;
        });
    };

    const handleClick = (check) => {
        if(!check.disabled) {
            handleCheckboxClick(group, check.value);
        }
    }

    return (
        <Box className='info-checkboxes-group-box'>
            {
                checkboxes.map((check, key) => {
                    return (
                        <Box className={`info-checkbox-group-box ${checkboxTypes[check.value] ? 'info-checkbox-group-selected-box' : ''}`} key={key} onClick={() => handleClick(check)}>
                            {
                                !checkboxInfo[key].isOpen ?
                                <div className={`info-checkbox-group-info-box ${check.disabled ? 'info-checkbox-group-disabled-box' : ''}`}>
                                    <div className='info-checkbox-group-info' onClick={(e) => openInfo(e, key)}>
                                        <InfoIcon fontSize='small' />
                                    </div>
                                    <Box component='img' className='info-checkbox-group-img' src={check.img} />
                                    <Typography className='info-checkbox-group-details'>{t(check.label)}</Typography>
                                </div>
                                :
                                <div className='info-checkbox-group-info-content'>
                                    <div className='info-checkbox-group-info' onClick={(e) => openInfo(e, key)}>
                                        <CloseIcon fontSize='small' />
                                    </div>
                                    <Typography className='info-checkbox-group-details'>{check.info}</Typography>
                                </div>
                            }
                        </Box>
                    )
                })
            }

        </Box>
    )
}

export default InformationCheckboxGroup
