import React, { useState, useRef, useEffect } from "react";
import Chevron from "./Chevron";
import "./Accordion.css";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { GeneralFormats } from "../../util/constants";
import moment from "moment";

const Accordion = ({
  mcaDisabledStep,
  title,
  trackStatus,
  children,
  isNearest,
  status,
  isRefinement,
  date,
  changeHeight,
  disabled = false,
}) => {
  const dateFormat = GeneralFormats.dateFormat;
  const [active, setActive] = useState({firstOpen:false,status:false});
  const [height, setHeight] = useState("0px");
  const [rotation, setRotation] = useState("accordion__icon");

  const content = useRef();

  const toggleAccordion = () => {
    if(!disabled) {
      setActive((prev) => ({firstOpen:true,status:!prev.status}));
    }
  };

  const Status = () => {
    if (status == 11) {
      return <CheckIcon className="accordion__green" />;
    }
    if (status == 0) {
      return <CloseIcon className="accordion__red" />;
    }
    if (0 < status && status < 11 ) {
      return <WarningIcon className="accordion__yellow" />;
    } else {
      return <p className="accordion__green"></p>;
    }
  }

  useEffect(() => {
    setHeight(!active.status ? "0px" : `${content.current.scrollHeight}px`);
    setRotation(!active.status  ? "accordion__icon" : "accordion__icon rotate");
  }, [active, changeHeight]);

  return (
    <div className="accordion__section">
      <button
        className={`accordion ${active?.status ? "active" : ""}`}
        onClick={toggleAccordion}
        style={{ backgroundColor: isNearest && "#8bffa6" }}
      >
        <p
          className={`accordion__title ${mcaDisabledStep && 'accordion__title_disabled'}`}
          style={{ minWidth: "80px", float: "left", textAlign: "left" }}
        >
          {title}
        </p>
        <Status />
        <p
          className="accordion__title accordion__status"
          style={{color: trackStatus && trackStatus.includes('Off Track') ? "#ff3c4e" : "#898989"}}
        >
          {trackStatus}
        </p>
        <b className="accordion__ref">{isRefinement}</b>
        <div className="accordion__date">
          <p>Wear until:</p>
          {date ? moment(date, 'DD-MM-YYYY').format(dateFormat) : '-'}
        </div>
        {!disabled && <Chevron width={10} fill={"#777"} className={`${rotation}`} />}
      </button>
      {active.firstOpen ? (
        <div
          ref={content}
          style={{ maxHeight: `${height}` }}
          className="accordion__content"
        >
          <div className="accordion__text">{children}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Accordion;
