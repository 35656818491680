import { useMutation } from "@tanstack/react-query";
import { fetchPendingBookingStatus } from "../../api/pending-booking.api";

export const usePendingBookingStatus = () => {
  return useMutation(fetchPendingBookingStatus, {
    retry: 10, // Number of retry attempts,
    retryDelay: 2000, // Optional delay between retries in milliseconds,
    onError: (error, variables, context) => {
      console.error("An error occurred:", error);
    },
  });
};
