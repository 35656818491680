import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";

function BookingsProtocol({ align, isPage = true }) {
  const { i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  const Component = ({ align, isArabic }) => {
    const { t } = useTranslation();
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };

    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };

    const title = { fontSize: "14px", mb: 2 };

    const listHeaderStyle = {
      color: "#000000",
      fontSize: "14px",
      mb: 2,
      marginTop: " 16px",
    };
    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_1")}
          </Typography>

          <Typography sx={divStyles} variant="div" component="div">
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("clinic_treatment_2")}
            </Typography>
            <Typography sx={title} variant="span" component="span">
              {t("clinic_treatment_3")}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
            >
              <img
                src="/assets/images/clinicpolicies1.png"
                alt=""
                className="doc_img"
              />
            </div>
            <ul style={unorderedListStyle}>
              <li>{t("clinic_treatment_4")}</li>
              <br />
              <li>{t("clinic_treatment_5")}</li>
              <br />
              <li>{t("clinic_treatment_6")}</li>
              <br />
              <li>{t("clinic_treatment_7")}</li>
              <br />
              <li>{t("clinic_treatment_8")}</li>
              <br />
              <li>{t("clinic_treatment_9")}</li>
              <br />
              <li>{t("clinic_treatment_10")}</li>
              <br />
              <li>{t("clinic_treatment_11")}</li>
              <br />
              <li>{t("clinic_treatment_12")}</li>
              <br />
              <li>{t("clinic_treatment_13")}</li>
              <br />
            </ul>
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_14")} {t("clinic_treatment_15")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#FF0000" }}>
              {t("clinic_treatment_16")} <b>{t("clinic_treatment_17")} </b>{" "}
              {t("clinic_treatment_18")}{" "}
            </p>
          </Typography>
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="/assets/images/clinicpolicies2.png"
            alt=""
            className="doc_img"
          />
        </div>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_19")}
          </Typography>
          <Typography sx={listHeaderStyle} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("clinic_treatment_20") }} />
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_21")}
          </Typography>

          <ol style={unorderedListStyle}>
            <li>{t("clinic_treatment_22")}</li>
            <br />
            <li>{t("clinic_treatment_23")}</li>
            <br />
            <li>{t("clinic_treatment_24")}</li>
            <br />
            <li>{t("clinic_treatment_25")}</li>
            <br />
            <li>{t("clinic_treatment_26")}</li>
            <br />
            <li>{t("clinic_treatment_27")}</li>
            <br />
          </ol>
          <Typography sx={listHeaderStyle} variant="span" component="span">
            {t("clinic_treatment_28")}
            <br />
            <br />
            {t("clinic_treatment_29")}
          </Typography>
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component align={align} isArabic={isArabic} />
  );
}

export default BookingsProtocol;
