import React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

const Content = () => {
  const {t}=useTranslation()
  return (
    <>
      <div className="row_learning">
        <div
          className="col-lg-learning-6 col-12"
          style={{ display: "grid", alignContent: "center" }}
        >
          <div>
          </div>
          <p className="def_txt_2">
          {t("instructions-1")}
          </p>
          <p className="def_txt_3">
          {t("instructions-2")}

          </p>
        </div>
        <div className="col-lg-learning-6 col-12">
          <div style={{ textAlign: "center" }}>
            <img
              src="/assets/images/clinic.webp"
              alt="image"
              className="learning_img"
              style={{borderRadius:"16px"}}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
