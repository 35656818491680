import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import { Link } from "react-router-dom";

function ExaminationProtocol({ align, isPage = true }) {
  const { t } = useTranslation();

  const Component = ({ t, align }) => {
    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };
    const title = { fontSize: "14px", mb: 2 };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    };

    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_30")}
          </Typography>
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="/assets/images/clinicpolicies3.png"
            alt=""
            className="doc_img"
          />
        </div>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_31")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#FF0000" }}>{t("clinic_treatment_32")}</p>{" "}
            <br />
            {t("clinic_treatment_33")}
          </Typography>
        </Typography>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_34")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            {t("clinic_treatment_35")}
            <br />
            <br />
            {t("clinic_treatment_36")}
            <br />
            <br />
            {t("clinic_treatment_37")}
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("clinic_treatment_38")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#FF0000" }}>{t("clinic_treatment_39")}</p>{" "}
            <Link
              to="/dashboard/first-Visit-appointment"
              onClick={scrollToTop}
              style={{ color: "#FF0000" }}
            >
              {t("clinic_treatment_40")}
            </Link>
          </Typography>
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "16px",
          }}
        >
          <img
            src="/assets/images/clinicpolicies4.png"
            alt=""
            className="doc_img"
          />
        </div>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography sx={title} variant="span" component="span">
            <p style={{ color: "#FF0000" }}>{t("clinic_treatment_41")}</p>{" "}
          </Typography>
        </Typography>
      </div>
    )
  }

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} />
    </Layout>
  ) : (
    <Component t={t} align={align} />
  );
}

export default ExaminationProtocol;
