import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}))
EnhancedTableHead.propTypes = {
    sort: PropTypes.string,
    headCells: PropTypes.array.isRequired,
}
export default function EnhancedTableHead(props) {
    const { setField,setSort, headCells, sort} = props
    
    return (
        <TableHead>
            <TableRow>
                {headCells.filter(cell => !cell?.hideRow).map((headCell) =>
 		            headCell.label  &&
                        <TableCell
                            key={headCell.id}
                            align='center' 
                            >
                            <TableSortLabel
                                direction={sort}
                                hideSortIcon={headCell.hideSort}
                                onClick={() => {
                                    if(!headCell.hideSort){
                                        setSort((prev) => prev == 'asc' ? 'desc' : 'asc');
                                        setField(headCell.id);
                                    }
                                }}>
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                )}
            </TableRow>
        </TableHead>
    )
}