import axios from "axios";
import Cookies from 'js-cookie'
import history from './history'
// let navigate = useNavigate()
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${Cookies.get('access_token_clinic')}`;
    return config;
});

const errorHandler = (status) => {
  console.log("Error " + status);
  if (status === 401 || status === 403) {
    Cookies.get('access_token_clinic') && Cookies.remove('access_token_clinic');
    Cookies.get('clinic_impersonate') && Cookies.remove('clinic_impersonate');
    Cookies.get('clinic_name') && Cookies.remove('clinic_name');
    Cookies.get('is_scan_at_home') && Cookies.remove('is_scan_at_home');
    if(document.location.pathname!='/'){
    history.push('/');
    document.location.reload()
    }
  }
  return Promise.reject({ ...status });
};

const successHandler = (response) => {
  return response;
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error.response.status)
);
instance.defaults.headers.post['Accept'] = 'application/json'

export default instance;
