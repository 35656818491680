import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#199dd1',
        },
        secondary: {
            main:'#ff2c46',
            // main: '#ff9f43',
        },
        link: {
            // #2D7AFF
            // main:"#2D7AFF",
            main: '#e91e63',
            secondary: '#ff9f43',
        },
        logo : {
            main : '#fff'
        },
        error: {
            main: red.A400,
        },
        background: {
            main: '#fff',
            secondary: 'rgb(247, 249, 250)',
            tertiary: '#f5f8fa',
            body: '#f6f6f7'
        },
        text: {
            main: '#444',
            secondary: '#808080',
            tertiary: '#2b4260',
            body: '#777',
        },
        chip: {
            main: '#9C27B0',
        },
        success: {
            main: '#008000',
        },
    },
    typography: {
        h1: {
            '@media (max-width: 600px)': {
                fontSize: '2rem',
                fontWeight: 'normal',
            },
            '@media (min-width: 1230px)': {
                fontSize: '3rem',
                fontWeight: 'normal',
            },
        },
        h2: {
            '@media (max-width: 600px)': {
                fontSize: '1.2rem',
                fontWeight: 'normal',
            },
            '@media (min-width: 1230px)': {
                fontSize: '2rem',
                fontWeight: 'normal',
            },
        },
        h3: {
            '@media (max-width: 600px)': {
                fontSize: '0.875rem',
                fontWeight: 'normal',
            },
            '@media (min-width: 1230px)': {
                fontSize: '1.825rem',
                fontWeight: 'normal',
            },
        },
        h4: {
            '@media (max-width: 600px)': {
                fontSize: '0.875rem',
                fontWeight: 'normal',
            },
            '@media (min-width: 1230px)': {
                fontSize: '1.25rem',
                fontWeight: 'normal',
            },
        },
    },
})

theme.typography.h1[theme.breakpoints.between('sm', 'lg')] = {
    fontSize: '2.75rem',
    fontWeight: 'normal',
}

theme.typography.h2[theme.breakpoints.between('sm', 'lg')] = {
    fontSize: '2rem',
    fontWeight: 'normal',
}

theme.typography.h3[theme.breakpoints.between('sm', 'lg')] = {
    fontSize: '1.75rem',
    fontWeight: 'normal',
}

theme.typography.h4[theme.breakpoints.between('sm', 'lg')] = {
    fontSize: '1rem',
    fontWeight: 'normal',
}

export default theme