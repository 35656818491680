import React from "react";
import { Typography } from "@mui/material";

const ButtonsComponent = ({ isArabic, align, t }) => {
  const unorderedListStyle = isArabic
    ? {
        margin: "10px 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "10px 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };

  const divStyles = {
    margin: "15px 0",
    textAlign: align,
    padding: "10px",
  };

  const title = { fontSize: "14px", mb: 2 };

  return (
    <div className="learning_container">
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_43")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          <p dangerouslySetInnerHTML={{ __html: t("ipr_faq_44") }} />
        </Typography>
      </Typography>

      <Typography sx={divStyles} variant="div" component="div">
        <Typography
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
          component="h1"
        >
          {t("ipr_faq_45")}
        </Typography>
        <ol style={unorderedListStyle}>
          <li>{t("ipr_faq_47")}</li>
          <li>{t("ipr_faq_46")}</li>
          <li>{t("ipr_faq_49")}</li>
          <li>{t("ipr_faq_50")}</li>
          <li>{t("ipr_faq_51")}</li>
          <li>{t("ipr_faq_52")}</li>
          <li>{t("ipr_faq_53")}</li>
        </ol>
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src="/assets/images/ipr2.png" alt="" className="learning_img" />
      </div>
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_54")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          {t("ipr_faq_55")}
        </Typography>
        <br />
        <br />
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_56")}
            </Typography>
          </li>
        </ul>
        <ol style={unorderedListStyle}>
          <li>{t("ipr_faq_57")}</li>
          <li>{t("ipr_faq_58")}</li>
        </ol>
      </Typography>
    </div>
  );
};

export default ButtonsComponent;
