import { Box, Button, CircularProgress, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import axios from "../../util/axios";
import axiosAws from "axios";
import SinglePhoto from './SinglePhoto';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clinicImpersonated } from '../../util/functions';
import { Checkbox } from '@shopify/polaris';

const useStyles = makeStyles(()=>{return {
  photoWrapper:{
    padding:'1rem',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
  },
  photoCheckWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkBoxWrapper: {
    transform: 'scale(0.7)',
    marginTop: '8px',
  },
  photoBox:{
    display:'flex',
    justifyContent:'space-between',
    flexWrap:'wrap'
  },
  photoWidthSM:{
    width:'60%',
    ['@media only screen and (max-width: 700px) '] : {
      width: '100%',
    }

  },
  photoWidth:{
    width:'70%',
    ['@media only screen and (max-width: 700px) '] : {
      width: '100%',
    }
  },
  singlePhotoBox:{
    display:'flex',
    width: '70.19px',
    height: '109.03px',
    flexDirection:'column',
    justifyContent:'space-between',
    marginTop:'1rem',
    ['@media only screen and (max-width: 500px) '] : {
      flex: '33%',
    }
  },
  errorMessage:{
    display:'flex',
    flexDirection:'column',
    alignItems: "flex-end",
    ['@media only screen and (max-width: 500px) '] : {
      flex: '33%',
    }
  },
  title:{
    height: '16px',
    width: '134px',
    color: '#303030',
    fontSize: '16px !important',
    letterSpacing: 0,
    lineHeight: '22px',
    marginBottom:'1rem'
  },
  errors:{
    color: '#D72C0D',
    fontSize: '12px !important',
    marginTop:'1rem',
  },
  error:{
    color: '#D72C0D',
    fontSize: '12px !important',
  },
  imageTitle:{
    height: '33.6px',
    width: '73px',
    color: '#212B36',
    fontSize: '9px !important',
    letterSpacing: 0,
    lineHeight: '16.8px',
    textAlign: 'center',
  },
  saveBtn:{
    display:'block',
    width: '65px',
    marginTop:'0.5rem',
    marginLeft:'auto',
    borderRadius: '4.7px',
    color:'#fff',
    backgroundColor: '#FF2C46',
    '&:hover':{
      backgroundColor: '#FF2C46',
    }
  },
  loadingBox:{
    margin: "0 auto",
    textAlign: "center",
    padding : 30,
    color:'#FF2C46'
  },
  loading: {
    textAlign: 'center',
    margin: "0 auto",
    color: '#ff2c46'
  },
}})
export default function Photos({handleIconPhotosView, photos, handlePhotosValue, disable}) {
  const { id } = useParams();
  const classes = useStyles();
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [imageKey, setImageKey] = useState({});
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fetchById, setfetchById] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [notApplicable, setNotApplicable] = useState({
    front_facing_big_smile_na: false,
    front_facing_no_smile_na: false,
    right_side_profile_no_smile_na: false,
  });
  const navigate = useNavigate();
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const { t } = useTranslation()

  const handleImages = (files, id)=>{
    setErrorMsg('');
    setSuccess(false);
    setDisabled(true);
    setLoading(true);
    const imageId = id;
    const file = files[0];
    let imgUrl = validImageTypes.indexOf(file.type) > 0
      ? window.URL.createObjectURL(file)
      : "/assets/images/Fileicon.svg";
    setImagePreview((prev) => ({
      ...prev,
      [imageId] :  imgUrl
    }));
    saveInAws(file, imageId);
  }

  const handleUploadError = (type, err) => {
    setErrorMsg('Upload failed. Please try again.');
    setImagePreview((prev) => ({
      ...prev,
      [type] : undefined
    }));
    setImageKey((prev)=>({
      ...prev,
      [type] : undefined
    }));
    setLoading(false);
    console.log(err);
  }

  const uploadConfig = (progressEvent, name) => {
    if(progressEvent.loaded === progressEvent.total){
      delete uploadProgress[name];
      const array = uploadProgress;
      setUploadProgress(array);
    }else{
      const array = {
        ...uploadProgress,
        [name] :  Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      }
      setUploadProgress(array);
    }
  };

  const saveInAws = (value, type)=>{
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios
      .post("/admin/v1/images/s3", form_data)
      .then((res) => {
        setImageKey((prev)=>({
          ...prev,
          [type] : res.data.key
        }));
        const url2 = res.data.url;
        axiosAws
          .put(url2, value, {
            headers: {
              "x-amz-acl": ["public-read-write"],
              "Content-Type": imageType,
            },
            onUploadProgress: (event) => uploadConfig(event, type)
          })
          .then((res) => {
            setCallHandleSave(1);
            setLoading(false);
          })
          .catch((err) =>{
            setDisabled(false)
            handleUploadError(type, err);
          });
      })
      .catch((err) =>{
        setDisabled(false)
        handleUploadError(type, err);
      });
  }

  const handleSave = () => {
    setErrorMsg('');
    let body = {
      ...imageKey,
      ...notApplicable
    };
    if(!(Object.values(body).every(x => x === null || x === '' || x === undefined))){
      setLoading(true);
      axios
        .post(`admin/v1/users/${id}/impressions&photos/step3`, body)
        .then((res) => {
          if(res.data.success){
            // navigate("/dashboard/users/" + id);
            handleDatafetched(res.data.data)
            handlePhotosValue(res.data.data)
            handleIconPhotosView(res.data.isAllProvidedStep3)
            setfetchById(true);
            setSuccess(true);
          }
          else{
            setSuccess(false);
          }
          setLoading(false);
        })
        .catch((err) =>{
          setErrorMsg('Upload failed. Please try again.');
          setLoading(false);
          console.log(err);
        }).finally(() => setDisabled(false));
    }
  }

  const handleChangeNotApplicable = (prop) => {
    setNotApplicable(prev => ({...prev, [prop]: !prev[prop]}));
    setCallHandleSave(1);
  }

  const handleDatafetched = (value) =>{
    setImagePreview({
      front_facing_big_smile : value?.front_facing_big_smile_url,
      front_facing_no_smile : value?.front_facing_no_smile_url,
      right_side_profile_no_smile : value?.right_side_profile_no_smile_url,
      center_bite : value?.center_bite_url,
      right_bite : value?.right_bite_url,
      left_bite : value?.left_bite_url,
      upper_teeth : value?.upper_teeth_url,
      lower_teeth :  value?.lower_teeth_url,
    })
    setNotApplicable({
      front_facing_big_smile_na: value?.front_facing_big_smile_na,
      front_facing_no_smile_na: value?.front_facing_no_smile_na,
      right_side_profile_no_smile_na: value?.right_side_profile_no_smile_na,
    });
  }

  useEffect(()=>{
    handleDatafetched(photos);
  },[fetchById]);

  useEffect(() => {
    if(callHandleSave == 1){
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  return (

    <Box className={classes.photoWrapper}>
      {
        //   loading ? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box>:
        <>
          <Typography className={classes.title}>{t('Screening Photos')}</Typography>
          <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
            <div className={classes.photoCheckWrapper}>
              <Box className={classes.singlePhotoBox}>
                <SinglePhoto disable={disable || disabled} value={imagePreview?.front_facing_big_smile} handleImages={handleImages} id={'front_facing_big_smile'}/>
                <Typography className={classes.imageTitle}>{t('Front-Facing-Big-Smile')}</Typography>
                {(uploadProgress?.front_facing_big_smile) &&
                  <Box>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                  </Box>}
              </Box>
              <div className={classes.checkBoxWrapper}>
                <Checkbox
                  label={t('not-applicable')}
                  checked={notApplicable.front_facing_big_smile_na}
                  onChange={() => handleChangeNotApplicable('front_facing_big_smile_na')}
                />
              </div>
            </div>

            <div className={classes.photoCheckWrapper}>
              <Box className={classes.singlePhotoBox}>
                <SinglePhoto disable={disable || disabled} value={imagePreview?.front_facing_no_smile} handleImages={handleImages} id={'front_facing_no_smile'}/>
                <Typography className={classes.imageTitle}>{t('Front-Facing-No-Smile')}</Typography>
                {(uploadProgress?.front_facing_no_smile) &&
                  <Box>
                    <LinearProgress variant="determinate" value={uploadProgress?.front_facing_no_smile} />
                  </Box>}
              </Box>
              <div className={classes.checkBoxWrapper}>
                <Checkbox
                  label={t('not-applicable')}
                  checked={notApplicable.front_facing_no_smile_na}
                  onChange={() => handleChangeNotApplicable('front_facing_no_smile_na')}
                />
              </div>
            </div>

            <div className={classes.photoCheckWrapper}>
              <Box className={classes.singlePhotoBox}>
                <SinglePhoto disable={disable || disabled} value={imagePreview?.right_side_profile_no_smile} handleImages={handleImages} id={'right_side_profile_no_smile'}/>
                <Typography className={classes.imageTitle}>{t('Profile-No-Smile')}</Typography>
                {(uploadProgress?.right_side_profile_no_smile) &&
                  <Box>
                    <LinearProgress variant="determinate" value={uploadProgress?.right_side_profile_no_smile} />
                  </Box>}
              </Box>
              <div className={classes.checkBoxWrapper}>
                <Checkbox
                  label={t('not-applicable')}
                  checked={notApplicable.right_side_profile_no_smile_na}
                  onChange={() => handleChangeNotApplicable('right_side_profile_no_smile_na')}
                />
              </div>
            </div>

          </Box>

          <Box className={`${classes.photoBox} ${classes.photoWidth}`}>

            <Box className={classes.singlePhotoBox}>
              <SinglePhoto disable={disable || disabled} value={imagePreview?.center_bite} handleImages={handleImages} id={'center_bite'}/>
              <Typography className={classes.imageTitle}>{t('Center-Bite')}</Typography>
              {(uploadProgress?.center_bite) &&
                <Box>
                  <LinearProgress variant="determinate" value={uploadProgress?.center_bite} />
                </Box>}
            </Box>


            <Box className={classes.singlePhotoBox}>
              <SinglePhoto disable={disable || disabled} value={imagePreview?.right_bite} handleImages={handleImages} id={'right_bite'}/>
              <Typography className={classes.imageTitle}>{t('Right-Bite')}</Typography>
              {(uploadProgress?.right_bite) &&
                <Box>
                  <LinearProgress variant="determinate" value={uploadProgress?.right_bite} />
                </Box>}
            </Box>
            <Box className={classes.singlePhotoBox}>
              <SinglePhoto disable={disable || disabled} value={imagePreview?.left_bite} handleImages={handleImages} id={'left_bite'}/>
              <Typography className={classes.imageTitle}>{t('Left-Bite')}</Typography>
              {(uploadProgress?.left_bite) &&
                <Box>
                  <LinearProgress variant="determinate" value={uploadProgress?.left_bite} />
                </Box>}
            </Box>


            <Box className={classes.singlePhotoBox}>
              <SinglePhoto disable={disable || disabled} value={imagePreview?.upper_teeth} handleImages={handleImages} id={'upper_teeth'}/>
              <Typography className={classes.imageTitle}>{t('Top-Bite')}</Typography>
              {(uploadProgress?.upper_teeth) &&
                <Box>
                  <LinearProgress variant="determinate" value={uploadProgress?.upper_teeth} />
                </Box>}
            </Box>


            <Box className={classes.singlePhotoBox}>
              <SinglePhoto disable={disable || disabled} value={imagePreview?.lower_teeth} handleImages={handleImages} id={'lower_teeth'}/>
              <Typography className={classes.imageTitle}>{t('Bottom-Bite')}</Typography>
              {(uploadProgress?.lower_teeth) &&
                <Box>
                  <LinearProgress variant="determinate" value={uploadProgress?.lower_teeth} />
                </Box>}
            </Box>

          </Box>
          <Box className={classes.errorMessage}>
            <Button disabled={clinicImpersonated()} className={classes.saveBtn} onClick={handleSave}>{success ? t('Saved') : t('Save')}</Button>
            <Typography className={classes.errors}>{errorMsg}</Typography>
          </Box>
        </>}
    </Box>
  )
}
