import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";

function ClinicTreatmentProtocalGeneralGuidelines({ align, isPage = true }) {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  const Component = ({ t, align, isArabic }) => {
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };

    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };

    const title = { fontSize: "14px", mb: 2 };

    const listHeaderStyle = {
      color: "#000000",
      fontSize: "14px",
      mb: 2,
      marginTop: " 16px",
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    };
    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "#A31C1C",
            }}
            component="h1"
          >
            {t("clinic_treatment_130")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>
              {t("clinic_treatment_131")}
              <ol style={unorderedListStyle}>
                <li>{t("clinic_treatment_132")}</li>
                <li>{t("clinic_treatment_133")}</li>
                <li>{t("clinic_treatment_134")}</li>
              </ol>
            </li>
            <br />
            <li>{t("clinic_treatment_135")}</li>
            <br />
            <li>{t("clinic_treatment_136")}</li>
            <br />
            <li>{t("clinic_treatment_137")}</li>
            <br />
            <li>{t("clinic_treatment_138")}</li>
            <br />
            <li>{t("clinic_treatment_139")}</li>
            <br />
            <li>{t("clinic_treatment_140")}</li>
            <br />
            <li>{t("clinic_treatment_141")}</li>
            <br />
            <li>{t("clinic_treatment_142")}</li>
            <br />
            <li>{t("clinic_treatment_143")}</li>
            <br />
            <li>{t("clinic_treatment_144")}</li>
            <br />
            <li>{t("clinic_treatment_145")}</li>
            <br />
            <li>{t("clinic_treatment_146")}</li>
            <br />
            <li>
              {t("clinic_treatment_147")}{" "}
              <a style={title} href="tel:+1234567890">
                {t("clinic_treatment_148")}
              </a>
            </li>
            <br />
          </ul>
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
}

export default ClinicTreatmentProtocalGeneralGuidelines;
