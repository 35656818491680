import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ScansDropZone from './ScansDropZone'
import axios from "../../util/axios";
import WarningDialog from '../ConfirmationDialog/ConfirmationDialog'
import axiosAws from "axios";
import { clinicImpersonated, imageValue } from '../../util/functions';
import instance from '../../util/axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import DoctorsDropdownContainer from "../Appointments/doctors-dropdown-container.component";

const useStyles = makeStyles(()=>{return{
  fontFamilyAlmarai:{
    fontFamily:'Almarai !important'
  },
  fontFamilyEina: {
    fontFamily: "Eina-semi !important",
  },
  wrapper:{
    padding:'2rem',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
  },
  box:{
    display: "flex",
    flexWrap: "wrap",

  },
  titleStyle:{
    height: '16px',
    color: '#303030',
    fontSize: '16px !important',
    letterSpacing: 0,
    lineHeight: '22px',
  },
  titleBox:{
    height: '48px',
    width: '360px',
  },
  uploadBox:{
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  saveBtn:{
    display:'block',
    width: '65px',
    marginTop:'0.5rem',
    marginLeft:'auto',
    borderRadius: '4.7px',
    color:'#fff',
    backgroundColor: '#FF2C46',
    '&:hover':{
      backgroundColor: '#FF2C46',
    }
  },
  loadingBox:{
    margin: "0 auto",
    textAlign: "center",
    padding : 30,
    color:'#FF2C46'
  },
  loading: {
    textAlign: 'center',
    margin: "0 auto",
    color: '#ff2c46'
  },
  errorMsg: {
    color: '#D72C0D',
    fontSize: '12px !important',
    marginTop: "1rem"
  },
}})
export default function Scans({customStyle = {} ,userId, handleIconScanView, scans, handleScanValue, redo, isCompleted, handleIsCompleted, disable = false}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [saveCheckBox, setSaveCheckBox] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState({});
  const [imageKey, setImageKey] = useState(null);
  const [checkedScanFiles, setCheckedScanFiles] = useState(false);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [condMsg, setCondMsg] = useState("");
  const [open, setOpen] = useState(false)
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [otherDoctor, setOtherDoctor] = useState("");
  const [enableDialog, setEnableDialog] = useState(false);
  const { t, i18n } = useTranslation()
  const handleUploadError = (type, err) => {
    setErrorMsg('Upload failed. Please try again.');
    setImagePreview((prev) => ({
      ...prev,
      [type] : undefined
    }));
    setImageKey((prev)=>({
      ...prev,
      [type] : undefined
    }));
    setLoading(false);
    setDisabled(false);
    console.log(err);
  }

  const handleChange = (files, id)=>{
    if(isCompleted){
      setDisabled(true);
      setErrorMsg('');
      setLoading(true);
      setSuccess(false);
      const imageId = id;
      const file = files[0];
      let imgUrl = validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : "/assets/images/stl-thumbnail.png";
      setImagePreview((prev) => ({
        ...prev,
        [imageId] :  imgUrl
      }));
      saveInAws(file, imageId);
    }else{
      setOpen(true);
      setCondMsg('The appointment must be marked as completed before uploading scans.');
    }
  }

  const uploadConfig = (progressEvent, name) => {
    if(progressEvent.loaded === progressEvent.total){
      delete uploadProgress[name];
      const array = uploadProgress;
      setUploadProgress(array);
    }else{
      const array = {
        ...uploadProgress,
        [name] :  Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      }
      setUploadProgress(array);
    }
  };

  const saveInAws = (value, type)=>{
    setErrorMsg('');
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios
      .post("/admin/v1/images/s3", form_data)
      .then((res) => {
        if(type == 'document'){
          setImageKey((prev)=>({
            ...prev,
            [type] : res.data.key,
            name : value.name
          }));
        }else{
          setImageKey((prev)=>({
            ...prev,
            [type] : res.data.key,
            [`${type}_name`] : value.name
          }));
        }
        const url2 = res.data.url;
        axiosAws
          .put(url2, value, {
            headers: {
              "x-amz-acl": ["public-read-write"],
              "Content-Type": imageType,
            },
            onUploadProgress: (event) => uploadConfig(event, type)
          })
          .then((res) => {
            setCallHandleSave(1);
            setLoading(false);
          })
          .catch((err) =>{
            handleUploadError(type, err);
          });
      })
      .catch((err) => {
        handleUploadError(type, err);
      });
  }

  const handleSave = () =>{
    const form_data = new FormData();
    imageKey?.document && form_data.append("document", imageKey?.document);
    imageKey?.name && form_data.append("name", imageKey?.name);
    imageKey?.lower_scan && form_data.append("lower_scan", imageKey?.lower_scan);
    imageKey?.lower_scan_name && form_data.append("lower_scan_name", imageKey?.lower_scan_name);
    imageKey?.bite_scan_1 && form_data.append("bite_scan_1", imageKey?.bite_scan_1);
    imageKey?.bite_scan_1_name && form_data.append("bite_scan_1_name", imageKey?.bite_scan_1_name);
    imageKey?.bite_scan_2 && form_data.append("bite_scan_2", imageKey?.bite_scan_2);
    imageKey?.bite_scan_2_name && form_data.append("bite_scan_2_name", imageKey?.bite_scan_2_name);
    setErrorMsg('');
    let url = null;
    let emptyFormData = true;
    for (var value of form_data.values()) {
      if(value){
        emptyFormData = false;
        break;
      }
    }
    form_data.append("redo", redo);
    const check = !(Object.values(imagePreview).every(x => x === null || x === '' || x === undefined));
    if(!emptyFormData || check){
      setLoading(true);
      if(scans){
        url = `/admin/v1/users/${userId}/document/${scans.id}/update`;
      }else{
        url = `/admin/v1/users/${userId}/documents`;
      }
      axios
        .post(url, form_data)
        .then((res) => {
          if(res.data.success){
            !redo && handleIconScanView(checkedScanFiles ? checkedScanFiles : res.data.isAllProvided)
            handleDatafetched(res.data.data)
            handleScanValue(res.data.data)
            setSuccess(true);
          }else{
            setImagePreview({});
            setOpen(true);
            setCondMsg('The appointment must be marked as completed before uploading scans.');
          }
          setDisabled(false);
          setLoading(false);
        })
        .catch((err) =>{
          setErrorMsg('Upload failed. Please try again.');
          setSuccess(false);
          setLoading(false);
          setDisabled(false);
          console.log(err)
        }).finally(() => setSaveCheckBox(false));
    }
    else{
      setErrorMsg("Please upload scan files")
    }
  }


  const handleDatafetched = (value) =>{
    if(value){
      setImagePreview({
        document:  imageValue(value?.document),
        name : value?.name ? value.name.split('.')[0] : null,
        lower_scan: imageValue(value?.lower_scan),
        lower_scan_name : value?.lower_scan_name ? value.lower_scan_name.split('.')[0] : null,
        bite_scan_1: imageValue(value?.bite_scan_1),
        bite_scan_1_name : value?.bite_scan_1_name ? value.bite_scan_1_name.split('.')[0] : null,
        bite_scan_2: imageValue(value?.bite_scan_2),
        bite_scan_2_name : value?.bite_scan_2_name ? value.bite_scan_2_name.split('.')[0] : null
      });
      setCheckedScanFiles(value?.sent_via_email == 1 ? true : false);
    }
  }

  const markAsCompleted = async () =>{
    setLoading(true);
    try{
      const response = await instance.put(`/admin/v1/clinic/user/${userId}/sub-status/update`,{
        action: "Completed",
        clinicId: Cookies.get('clinic_id'),
        scan_id: 0
      });
      if(response?.data?.success){
        setOpen(false);
        handleIsCompleted(true);
        setImageKey(null);
      }
    }catch(error){
      console.log(error);
    }finally{
      setLoading(false);
      setDisabled(false);
    }
  }

  useEffect(()=>{
    handleDatafetched(scans)
  },[scans]);

  useEffect(()=>{
    if(callHandleSave == 1){
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  return (
    <Box style={customStyle} className={classes.wrapper}>
      {
        <>
          <WarningDialog
            open={open}
            handleConfirm={markAsCompleted}
            dialogTitle={t('Uploading-scans')}
            confirmationButtonText={t('Yes-mark-appointment-as-completed')}
            dialogContentText={condMsg}
            isWarning={true}
            handleClose={()=>(setOpen(false))}
            loading={loading}
            enableDialog={enableDialog}
            confirmBtnStyle={{width:'fit-content'}}
          >
            <DoctorsDropdownContainer
                selectedDoctor={selectedDoctor}
                setSelectedDoctor={setSelectedDoctor}
                setEnableDialog={setEnableDialog}
                otherDoctor={otherDoctor}
                setOtherDoctor={setOtherDoctor}
            />
          </WarningDialog>
          <Box className={classes.titleBox}>
            <Typography className={`${classes.titleStyle} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{redo ? t('Upload-Redo-Scans') : t('Upload-Scans')}</Typography>
          </Box>
          <Box className={classes.box}>
            <ScansDropZone
              uploadProgress={uploadProgress?.document}
              disable={disable || disabled}
              value={imagePreview?.document}
              id={'document'}
              handleChange={handleChange}
              message={t('Upload-upper-scan')}
              name={imagePreview?.name}
            />
            <ScansDropZone
              uploadProgress={uploadProgress?.lower_scan}
              disable={disable || disabled}
              value={imagePreview?.lower_scan}
              id={'lower_scan'}
              handleChange={handleChange}
              message={t('Upload-lower-scan')}
              name={imagePreview?.lower_scan_name}
            />
          </Box>
          <Box className={classes.box}>
            <ScansDropZone
              uploadProgress={uploadProgress?.bite_scan_1}
              disable={disable || disabled}
              value={imagePreview?.bite_scan_1}
              id={'bite_scan_1'}
              handleChange={handleChange}
              message={t('Bite-scan-1')}
              name={imagePreview?.bite_scan_1_name}
            />
            <ScansDropZone
              uploadProgress={uploadProgress?.bite_scan_2}
              disable={disable || disabled}
              value={imagePreview?.bite_scan_2}
              id={'bite_scan_2'}
              handleChange={handleChange}
              message={t('Bite-scan-2')}
              name={imagePreview?.bite_scan_2_name}
            />
          </Box>
          <Box className={classes.uploadBox}>
            <Button disabled={clinicImpersonated()} className={classes.saveBtn} onClick={() => saveCheckBox ? handleSave() : () => {}}>{success ? t('Saved') : t('Save')}</Button>
            <Typography className={classes.errorMsg}>{errorMsg}</Typography>
          </Box>
        </>}
    </Box>
  )
}
