import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";
import IPRComponent from "../../../Components/IPRComponent";

function IPR({ align }) {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    }
    if (i18n.language === "en") {
      setIsArabic(false);
    }
  }, [i18n.language, isArabic]);

  return (
      <Layout isProfile={true}>
        <IPRComponent
          isArabic={isArabic}
          align={align}
          t={t}
        />
      </Layout>
  );
}

export default IPR;
