import React from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";

function Card({ tags, title, content, icon, destination, scrollTo, src = '/assets/images/front_facing_no_smile.jpg' }) {
  let navigate = useNavigate();

  const goTo = () => {
    scrollTo();
    navigate(destination);
  }

  return (
    <div className="card-wrapper" onClick={goTo}>
      <div className="card-header">
        <div className="redesign_content-card-image">
          <img
            src={src}
            alt=""
            className="card_img"
          />
        </div>
        <div className="redesign_content-card-body">
          <div className="redesign_content-card-type-container">
            <div className="redesign_content-tags-container">
              {
                tags.map((tag, key) => (
                  <div key={key} className="redesign_content-card-type ondemand-webinar-color">
                    {tag}
                  </div>
                ))
              }
            </div>
            <div>
              <img src={icon} alt="" className="attachment_png" />
            </div>
          </div>
          <h4 className="redesign_heading-h4">{title}</h4>
          <p className="redesign_content-card-author-role">{content}</p>
        </div>
      </div>
    </div>
  );
}

export default Card;
