import React, { useCallback, useEffect, useState } from "react";
import {
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  Button,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import axios from "../../../util/axios";
import axiosAws from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
import Layout from "../../TopNav/Topnav";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { clinicImpersonated } from "../../../util/functions";

const AddFile = () => {
  const { id, type } = useParams();
  const { t } = useTranslation();
  const isResponsive = useMediaQuery({
    query: '(max-width: 902px)'
  });
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [popupActive, setPopupActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [postType, setPostType] = useState('');
  const [selected, setSelected] = useState("Post IPR"); 
  const [update, setUpdate] = useState(false);
  const [storageId, setStorageId] = useState(false);
  const navigate = useNavigate()
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [files1, setFiles1] = useState([]);

  const uploadConfig = (progressEvent, name) => {
    if(progressEvent.loaded === progressEvent.total){
        delete uploadProgress[name];            
        const array = uploadProgress;
        setUploadProgress(array);
    }else{
        const array = {
            ...uploadProgress,
            [name] :  Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        }
        setUploadProgress(array);
    }
};

  const handleDropZoneDrop1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles1((files1) => [...files1, ...acceptedFiles]),
    []
  );
  const fileUpload1 = !files1.length && <DropZone.FileUpload />;
  const uploadedFiles1 = files1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files1[files1.length - 1].name}
        source={
          validImageTypes.indexOf(files1[files1.length - 1].type) > 0
            ? window.URL.createObjectURL(files1[files1.length - 1])
            : NoteMinor
        }
      />
      <div>{files1[files1.length - 1].name} </div>
    </Stack>
  );
  const [imagePreview1, setImagePreview1] = useState("");
  const [imageKey1, setImageKey1] = useState("");

  const handleSuccessfulUpload = (type) => {
    if(!update){
      setUpdate(true);
    }
    setPopupActive(false);
    if(type != "Other"){
      if(imageKeyCenterBite != ""){
        setImageKeyCenterBite("")
      }
      if(imageKeyLeftBite != ""){
        setImageKeyLeftBite("")
      }
      if(imageKeyRightBite != ""){
        setImageKeyRightBite("")
      }
      if(imageKeyUpperTeeth != ""){
        setImageKeyUpperTeeth("")
      }
      if(imageKeyLowerTeeth != ""){
        setImageKeyLowerTeeth("")
      }
      if(imageKey6 != ""){
        setImageKey6("")
      }
      if(imageKey7 != ""){
        setImageKey7("")
      }
      if(imageKey8 != ""){
        setImageKey8("")
      }
      if(imageKey9 != ""){
        setImageKey9("")
      }
      if(imageKey10 != ""){
        setImageKey10("")
      }
      if(video1Key != ""){
        setVideo1Key("")
      }
      if(video2Key != ""){
        setVideo2Key("")
      }
    }
  }
  
  const handleFailedSave = (type) => {
    setPopupActive(false);
    if(noteValue != ""){
      setNoteErrorMessage(t("upload-failed"))
    }
    if(type != "Other"){
      if(imageKeyCenterBite != ""){
        setFrontErrorMessage(t("upload-failed"))
      }
      if(imageKeyLeftBite != ""){
        setLeftErrorMessage(t("upload-failed"))
      }
      if(imageKeyRightBite != ""){
        setRightErrorMessage(t("upload-failed"))
      }
      if(imageKeyUpperTeeth != ""){
        setTopErrorMessage(t("upload-failed"))
      }
      if(imageKeyLowerTeeth != ""){
        setBottomErrorMessage(t("upload-failed"))
      }
    }
    else{
      setErrorMessage(t("upload-failed"));
    }
  }

  const handleFailedUpload = (img, err) => {
    setPopupActive(false);
    console.log(err);
    if(img == "other"){
      setErrorMessage(t("upload-failed"));
      setFiles1([]);
      setImagePreview1("/assets/images/placeholder-image.png");
      setImageKey1("");
    }
    if(img == "center_bite"){
      setFrontErrorMessage(t("upload-failed"));
      setFilesCenterBite([]);
      setImagePreviewCenterBite(type == 'post-redo' ? '/assets/images/center_bite.jpeg' : '/assets/images/placeholder-image.png');
      setImageKeyCenterBite("");
    }
    if(img == "left_bite"){
      setLeftErrorMessage(t("upload-failed"));
      setFilesLeftBite([]);
      setImagePreviewLeftBite(type == 'post-redo' ? '/assets/images/left_bite.jpg' : '/assets/images/placeholder-image.png');
      setImageKeyLeftBite("");
    }
    if(img == "right_bite"){
      setRightErrorMessage(t("upload-failed"));
      setFilesRightBite([]);      
      setImagePreviewRightBite(type == 'post-redo' ? '/assets/images/right_bite.jpeg' : '/assets/images/placeholder-image.png');
      setImageKeyRightBite("");
    }
    if(img == "upper_teeth"){
      setTopErrorMessage(t("upload-failed"));
      setFilesUpperTeeth([]);
      setImagePreviewUpperTeeth(type == 'post-redo' ? '/assets/images/upper_teeth.jpeg' : '/assets/images/placeholder-image.png');
      setImageKeyUpperTeeth("");
    }
    if(img == "lower_teeth"){
      setBottomErrorMessage(t("upload-failed"));
      setFilesLowerTeeth([]);      
      setImagePreviewLowerTeeth(type == 'post-redo' ? '/assets/images/lower_teeth.jpeg' : '/assets/images/placeholder-image.png');
      setImageKeyLowerTeeth("");
    }
    if(img == "front_facing_no_smile"){
      setFrontFacingNoSmileErrorMessage(t("upload-failed"));
      setFrontFacingNoSmile([]);
      setImagePreviewFrontFacingNoSmile(type == 'post-redo' ? '/assets/images/front_facing_no_smile.jpg' : '/assets/images/placeholder-image.png');
    }
    if(img == "front_facing_big_smile"){
      setFrontFacingBigSmileErrorMessage(t("upload-failed"));
      setFrontFacingBigSmile([]);
      setImagePreviewFrontFacingBigSmile(type == 'post-redo' ? '/assets/images/front_facing_big_smile.jpg' : '/assets/images/placeholder-image.png');
    }
    if(img == "profile_no_smile"){
      setProfileNoSmileErrorMessage(t("upload-failed"));
      setProfileNoSmile([]);
      setImagePreviewProfileNoSmile(type == 'post-redo' ? '/assets/images/right_side_profile.jpg' : '/assets/images/placeholder-image.png');
    }
    setAddFilesKey([]);
  }

  const sendImage1 = useCallback(
    (files1, other_id) => {
      setErrorMessage("");
      setPopupActive(true);
      setImagePreview1(
        validImageTypes.indexOf(files1[files1.length - 1].type) > 0
          ? window.URL.createObjectURL(files1[files1.length - 1])
          : "/assets/images/Fileicon.svg"
      );
      let url2 = "";
      let img = 'other';
      let imgType = files1[files1.length - 1].type;
      const form_data = new FormData();
      form_data.append("content_type", imgType);
      form_data.append("file_name", files1[files1.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([{id: other_id ?? 0, name: img, file_type: imgType, file: res.data.key}])
          url2 = res.data.url;
          axiosAws
            .put(url2, files1[files1.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": files1[files1.length - 1].type,
              },
              onUploadProgress : (event) => uploadConfig(event, img)
            })
            .then((res) => {
              setCallHandleSave(1);
              setFileError("");
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [files1]
  );
  const [noteValue, setNoteValue] = useState("");
  const handleChangeNoteValue = useCallback(
    (newValue) => setNoteValue(newValue),
    []
  );
  const [fileError, setFileError] = useState("");

  let imageType = "";
  const [postRedoObject, setPostRedoObject] = useState(null);
  const [imageKeyCenterBite, setImageKeyCenterBite] = useState("");
  const [imageKeyLeftBite, setImageKeyLeftBite] = useState("");
  const [imageKeyRightBite, setImageKeyRightBite] = useState("");
  const [imageKeyUpperTeeth, setImageKeyUpperTeeth] = useState("");
  const [imageKeyLowerTeeth, setImageKeyLowerTeeth] = useState("");
  const [imageKey6, setImageKey6] = useState("");
  const [imageKey7, setImageKey7] = useState("");
  const [imageKey8, setImageKey8] = useState("");
  const [imageKey9, setImageKey9] = useState("");
  const [imageKey10, setImageKey10] = useState("");
  const [video1Key, setVideo1Key] = useState("");
  const [video2Key, setVideo2Key] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [noteErrorMessage, setNoteErrorMessage] = useState("");
  const [frontErrorMessage, setFrontErrorMessage] = useState("");
  const [rightErrorMessage, setRightErrorMessage] = useState("");
  const [leftErrorMessage, setLeftErrorMessage] = useState("");
  const [topErrorMessage, setTopErrorMessage] = useState("");
  const [bottomErrorMessage, setBottomErrorMessage] = useState("");
  const [fronFacingNoSmileErrorMessage, setFrontFacingNoSmileErrorMessage] = useState("");
  const [frontFacingBigSmileErrorMessage, setFrontFacingBigSmileErrorMessage] = useState("");
  const [profileNoSmileErrorMessage, setProfileNoSmileErrorMessage] = useState("");
  const [imagePreviewCenterBite, setImagePreviewCenterBite] =
    useState(type == 'post-redo' ? '/assets/images/center_bite.jpeg' : '/assets/images/placeholder-image.png');
  const [imagePreviewLeftBite, setImagePreviewLeftBite] =
    useState(type == 'post-redo' ? '/assets/images/left_bite.jpg' : '/assets/images/placeholder-image.png');
  const [imagePreviewRightBite, setImagePreviewRightBite] =
    useState(type == 'post-redo' ? '/assets/images/right_bite.jpeg' : '/assets/images/placeholder-image.png');
  const [imagePreviewUpperTeeth, setImagePreviewUpperTeeth] =
    useState(type == 'post-redo' ? '/assets/images/upper_teeth.jpeg' : '/assets/images/placeholder-image.png');
  const [imagePreviewLowerTeeth, setImagePreviewLowerTeeth] =
    useState(type == 'post-redo' ? '/assets/images/lower_teeth.jpeg' : '/assets/images/placeholder-image.png');
  const [imagePreviewFronFacingNoSmile, setImagePreviewFrontFacingNoSmile] =
    useState(type == 'post-redo'  ? '/assets/images/front_facing_no_smile.jpg' : '/assets/images/placeholder-image.png');
  const [imagePreviewFrontFacingBigSmile, setImagePreviewFrontFacingBigSmile] =
    useState(type == 'post-redo'  ? '/assets/images/front_facing_big_smile.jpg' : '/assets/images/placeholder-image.png');
  const [imagePreviewProfileNoSmile, setImagePreviewProfileNoSmile] =
    useState(type == 'post-redo'  ? '/assets/images/right_side_profile.jpg' : '/assets/images/placeholder-image.png');
  const [filesCenterBite, setFilesCenterBite] = useState([]);
  const handleDropZoneCenterBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesCenterBite((filesCenterBite) => [
        ...filesCenterBite,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadCenterBite = !filesCenterBite.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesCenterBite = filesCenterBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesCenterBite[filesCenterBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesCenterBite[filesCenterBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesCenterBite[filesCenterBite.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesCenterBite[filesCenterBite.length - 1].name}{" "}
        <Caption>
          {filesCenterBite[filesCenterBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageCenterBite = useCallback(
    (filesCenterBite, center_bite_id) => {
      setFrontErrorMessage("");
      setPopupActive(true);
      setImagePreviewCenterBite(
        validImageTypes.indexOf(
          filesCenterBite[filesCenterBite.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesCenterBite[filesCenterBite.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      imageType = filesCenterBite[filesCenterBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let img = 'center_bite'
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesCenterBite[filesCenterBite.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([{id: center_bite_id ?? 0, name: img, file_type: imageType, file: res.data.key}])
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesCenterBite[filesCenterBite.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress : (event) => uploadConfig(event, img)
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [filesCenterBite]
  );

  const [filesLeftBite, setFilesLeftBite] = useState([]);
  const handleDropZoneLeftBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLeftBite((filesLeftBite) => [...filesLeftBite, ...acceptedFiles]),
    []
  );
  const fileUploadLeftBite = !filesLeftBite.length && <DropZone.FileUpload />;
  let uploadedFilesLeftBite = filesLeftBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLeftBite[filesLeftBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLeftBite[filesLeftBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLeftBite[filesLeftBite.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesLeftBite[filesLeftBite.length - 1].name}{" "}
        <Caption>{filesLeftBite[filesLeftBite.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageLeftBite = useCallback(
    (filesLeftBite, left_bite_id) => {
      setLeftErrorMessage("");
      setPopupActive(true);
      setImagePreviewLeftBite(
        validImageTypes.indexOf(filesLeftBite[filesLeftBite.length - 1].type) >
          0
          ? window.URL.createObjectURL(filesLeftBite[filesLeftBite.length - 1])
          : "/assets/images/Fileicon.svg"
      );
      let headersVar = null;
      imageType = filesLeftBite[filesLeftBite.length - 1].type;
      let url2 = "";
      let img = 'left_bite';
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesLeftBite[filesLeftBite.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([{id: left_bite_id ?? 0, name: img, file_type: imageType, file: res.data.key}])
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesLeftBite[filesLeftBite.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress : (event) => uploadConfig(event, img)
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [filesLeftBite]
  );

  const [filesRightBite, setFilesRightBite] = useState([]);
  const handleDropZoneRightBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesRightBite((filesRightBite) => [
        ...filesRightBite,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadRightBite = !filesRightBite.length && <DropZone.FileUpload />;
  let uploadedFilesRightBite = filesRightBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesRightBite[filesRightBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesRightBite[filesRightBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesRightBite[filesRightBite.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesRightBite[filesRightBite.length - 1].name}{" "}
        <Caption>
          {filesRightBite[filesRightBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageRightBite = useCallback(
    (filesRightBite, right_bite_id) => {
      setRightErrorMessage("");
      setPopupActive(true);
      setImagePreviewRightBite(
        validImageTypes.indexOf(
          filesRightBite[filesRightBite.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesRightBite[filesRightBite.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      imageType = filesRightBite[filesRightBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let img = 'right_bite';
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesRightBite[filesRightBite.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([{id: right_bite_id ?? 0, name: img, file_type: imageType, file: res.data.key}])
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesRightBite[filesRightBite.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress : (event) => uploadConfig(event, img)
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [filesRightBite]
  );

  const [filesUpperTeeth, setFilesUpperTeeth] = useState([]);
  const handleDropZoneUpperTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesUpperTeeth((filesUpperTeeth) => [
        ...filesUpperTeeth,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadUpperTeeth = !filesUpperTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesUpperTeeth = filesUpperTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesUpperTeeth[filesUpperTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesUpperTeeth[filesUpperTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesUpperTeeth[filesUpperTeeth.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesUpperTeeth[filesUpperTeeth.length - 1].name}{" "}
        <Caption>
          {filesUpperTeeth[filesUpperTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageUpperTeeth = useCallback(
    (filesUpperTeeth, upper_teeth_id) => {
      setTopErrorMessage("");
      setPopupActive(true);
      setImagePreviewUpperTeeth(
        validImageTypes.indexOf(
          filesUpperTeeth[filesUpperTeeth.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesUpperTeeth[filesUpperTeeth.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      let headersVar = null;
      imageType = filesUpperTeeth[filesUpperTeeth.length - 1].type;
      let url2 = "";
      let img = 'upper_teeth';
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name",  filesUpperTeeth[filesUpperTeeth.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([{id: upper_teeth_id ?? 0, name: img, file_type: imageType, file: res.data.key}])
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesUpperTeeth[filesUpperTeeth.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress : (event) => uploadConfig(event, img)
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [filesUpperTeeth]
  );

  const [filesLowerTeeth, setFilesLowerTeeth] = useState([]);
  const handleDropZoneLowerTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLowerTeeth((filesLowerTeeth) => [
        ...filesLowerTeeth,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadLowerTeeth = !filesLowerTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesLowerTeeth = filesLowerTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLowerTeeth[filesLowerTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLowerTeeth[filesLowerTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLowerTeeth[filesLowerTeeth.length - 1]
              )
            : "/assets/images/Fileicon.svg"
        }
      />
      <div>
        {filesLowerTeeth[filesLowerTeeth.length - 1].name}{" "}
        <Caption>
          {filesLowerTeeth[filesLowerTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageLowerTeeth = useCallback(
    (filesLowerTeeth, lower_teeth_id) => {
      setBottomErrorMessage("");
      setPopupActive(true);
      setImagePreviewLowerTeeth(
        validImageTypes.indexOf(
          filesLowerTeeth[filesLowerTeeth.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesLowerTeeth[filesLowerTeeth.length - 1]
            )
          : "/assets/images/Fileicon.svg"
      );
      imageType = filesLowerTeeth[filesLowerTeeth.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let img = 'lower_teeth';
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name",  filesLowerTeeth[filesLowerTeeth.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([{id: lower_teeth_id ?? 0, name: img, file_type: imageType, file: res.data.key}])
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, filesLowerTeeth[filesLowerTeeth.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress : (event) => uploadConfig(event, img)
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [filesLowerTeeth]
  );

    /** new Images */ 
    const [frontFacingNoSmile, setFrontFacingNoSmile] = useState([]);
    const handleDropZoneFrontFacingNoSmile = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFrontFacingNoSmile((frontFacingNoSmile) => [
          ...frontFacingNoSmile,
          ...acceptedFiles,
        ]),
      []
    );
    const fileUploadFrontFacingNoSmile = !frontFacingNoSmile.length && (
      <DropZone.FileUpload />
    );
    let uploadedFilesFrontFacingNoSmile = frontFacingNoSmile.length > 0 && (
      <Stack alignment="center">
        <Thumbnail
          size="small"
          alt={frontFacingNoSmile[frontFacingNoSmile.length - 1].name}
          source={
            validImageTypes.indexOf(
              frontFacingNoSmile[frontFacingNoSmile.length - 1].type
            ) > 0
              ? window.URL.createObjectURL(
                  frontFacingNoSmile[frontFacingNoSmile.length - 1]
                )
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
          }
        />
        <div>
          {frontFacingNoSmile[frontFacingNoSmile.length - 1].name}{" "}
          <Caption>
            {frontFacingNoSmile[frontFacingNoSmile.length - 1].type} bytes
          </Caption>
        </div>
      </Stack>
    );
    const sendImageFrontFacingNoSmile = useCallback(
      (frontFacingNoSmile, front_facing_no_smile_id) => {
        setPopupActive(true);
        setImagePreviewFrontFacingNoSmile(
          validImageTypes.indexOf(
            frontFacingNoSmile[frontFacingNoSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                frontFacingNoSmile[frontFacingNoSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
        imageType = frontFacingNoSmile[frontFacingNoSmile.length - 1].type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", frontFacingNoSmile[frontFacingNoSmile.length - 1].name);
        axios
          .post("/admin/v1/images/s3", form_data)
          .then((res) => {
            setAddFilesKey([{id: front_facing_no_smile_id ?? 0, name: 'front_facing_no_smile', file_type: imageType ,file : res.data.key}]);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axiosAws
              .put(url2, frontFacingNoSmile[frontFacingNoSmile.length - 1], {
                headers: {
                  "x-amz-acl": ["public-read-write"],
                  "Content-Type": imageType,
                },
                onUploadProgress : (event) => uploadConfig(event, 'front_facing_no_smile')
              })
              .then((res) => {
                setCallHandleSave(1);
              })
              .catch((err) => {
                handleFailedUpload('front_facing_no_smile', err);
              });
          })
          .catch((err) =>{ 
            handleFailedUpload('front_facing_no_smile', err);
          });
      },
  
      [frontFacingNoSmile]
    );
  
    const [frontFacingBigSmile, setFrontFacingBigSmile] = useState([]);
    const handleDropZoneFrontFacingBigSmile = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFrontFacingBigSmile((frontFacingBigSmile) => [
          ...frontFacingBigSmile,
          ...acceptedFiles,
        ]),
      []
    );
    const fileUploadFrontFacingBigSmile = !frontFacingBigSmile.length && (
      <DropZone.FileUpload />
    );
    let uploadedFilesFrontFacingBigSmile = frontFacingBigSmile.length > 0 && (
      <Stack alignment="center">
        <Thumbnail
          size="small"
          alt={frontFacingBigSmile[frontFacingBigSmile.length - 1].name}
          source={
            validImageTypes.indexOf(
              frontFacingBigSmile[frontFacingBigSmile.length - 1].type
            ) > 0
              ? window.URL.createObjectURL(
                  frontFacingBigSmile[frontFacingBigSmile.length - 1]
                )
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
          }
        />
        <div>
          {frontFacingBigSmile[frontFacingBigSmile.length - 1].name}{" "}
          <Caption>
            {frontFacingBigSmile[frontFacingBigSmile.length - 1].type} bytes
          </Caption>
        </div>
      </Stack>
    );
    const sendImageFrontFacingBigSmile = useCallback(
      (frontFacingBigSmile, front_facing_big_smile_id) => {
        setPopupActive(true);
        setImagePreviewFrontFacingBigSmile(
          validImageTypes.indexOf(
            frontFacingBigSmile[frontFacingBigSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                frontFacingBigSmile[frontFacingBigSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
        imageType = frontFacingBigSmile[frontFacingBigSmile.length - 1].type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", frontFacingBigSmile[frontFacingBigSmile.length - 1].name);
        axios
          .post("/admin/v1/images/s3", form_data)
          .then((res) => {
            setAddFilesKey([{id: front_facing_big_smile_id ?? 0, name: 'front_facing_big_smile', file_type: imageType ,file : res.data.key}]);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axiosAws
              .put(url2, frontFacingBigSmile[frontFacingBigSmile.length - 1], {
                headers: {
                  "x-amz-acl": ["public-read-write"],
                  "Content-Type": imageType,
                },
                onUploadProgress : (event) => uploadConfig(event, 'front_facing_big_smile')
              })
              .then((res) => {
                setCallHandleSave(1);
              })
              .catch((err) => {
                handleFailedUpload('front_facing_big_smile', err);
              });
          })
          .catch((err) =>{ 
            handleFailedUpload('front_facing_big_smile', err);
          });
      },
  
      [frontFacingBigSmile]
    );
  
    const [profileNoSmile, setProfileNoSmile] = useState([]);
    const handleDropZoneProfileNoSmile = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setProfileNoSmile((profileNoSmile) => [
          ...profileNoSmile,
          ...acceptedFiles,
        ]),
      []
    );
    const fileUploadProfileNoSmile = !profileNoSmile.length && (
      <DropZone.FileUpload />
    );
    let uploadedFilesProfileNoSmile = profileNoSmile.length > 0 && (
      <Stack alignment="center">
        <Thumbnail
          size="small"
          alt={profileNoSmile[profileNoSmile.length - 1].name}
          source={
            validImageTypes.indexOf(
              profileNoSmile[profileNoSmile.length - 1].type
            ) > 0
              ? window.URL.createObjectURL(
                  profileNoSmile[profileNoSmile.length - 1]
                )
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
          }
        />
        <div>
          {profileNoSmile[profileNoSmile.length - 1].name}{" "}
          <Caption>
            {profileNoSmile[profileNoSmile.length - 1].type} bytes
          </Caption>
        </div>
      </Stack>
    );
    const sendImageProfileNoSmile = useCallback(
      (profileNoSmile, profile_no_smile_id) => {
        setPopupActive(true);
        setImagePreviewProfileNoSmile(
          validImageTypes.indexOf(
            profileNoSmile[profileNoSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                profileNoSmile[profileNoSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
        imageType = profileNoSmile[profileNoSmile.length - 1].type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", profileNoSmile[profileNoSmile.length - 1].name);
        axios
          .post("/admin/v1/images/s3", form_data)
          .then((res) => {
            setAddFilesKey([{id: profile_no_smile_id ?? 0, name: 'profile_no_smile', file_type: imageType ,file : res.data.key}]);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axiosAws
              .put(url2, profileNoSmile[profileNoSmile.length - 1], {
                headers: {
                  "x-amz-acl": ["public-read-write"],
                  "Content-Type": imageType,
                },
                onUploadProgress : (event) => uploadConfig(event, 'profile_no_smile')
              })
              .then((res) => {
                setCallHandleSave(1);
              })
              .catch((err) => {
                handleFailedUpload('profile_no_smile', err);
              });
          })
          .catch((err) =>{ 
            handleFailedUpload('profile_no_smile', err);
          });
      },
  
      [profileNoSmile]
    );
  const handlePostRedofiles = (value) =>{
      value?.lower_teeth && setImagePreviewLowerTeeth(value?.lower_teeth);
      value?.center_bite && setImagePreviewCenterBite(value?.center_bite);
      value?.upper_teeth && setImagePreviewUpperTeeth(value?.upper_teeth);
      value?.left_bite && setImagePreviewLeftBite(value?.left_bite);
      value?.right_bite && setImagePreviewRightBite(value?.right_bite);
      value?.front_facing_no_smile && setImagePreviewFrontFacingNoSmile(value.front_facing_no_smile);
      value?.front_facing_big_smile && setImagePreviewFrontFacingBigSmile(value.front_facing_big_smile);
      value?.profile_no_smile && setImagePreviewProfileNoSmile(value.profile_no_smile);
  }

  /* 
  *  multiple add files Post IPR photos 
  */
  const [addFilesSave, setAddFilesSave] = useState([]);
  const [addFilesPreview, setAddFilesPreview] = useState([]); 
  const [errorFilesUpload, setErrorFilesUpload] = useState([]); 
  const [addFilesKey, setAddFilesKey] = useState([]);
  const [indexFiles, setIndexFiles] = useState(0);
  const handleFilesDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, index) =>
      {
      addFilesSave[index] = acceptedFiles[acceptedFiles.length - 1]
      setAddFilesSave(addFilesSave);
      },
    []
  );
  const sendImages = useCallback(
    (files, index, imageId, array) => {
      setPopupActive(true);
      const validImageUrl = validImageTypes.indexOf(
        files[files.length - 1].type
      ) > 0
        ? window.URL.createObjectURL(
            files[files.length - 1]
          )
        : "/assets/images/Fileicon.svg";
      array[index] = { id: imageId, name: null, file_url : validImageUrl };
      setAddFilesPreview(array);
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setAddFilesKey([{id: imageId, name: null, file_type: imageType, file : res.data.key}]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axiosAws
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
              onUploadProgress : (event) => uploadConfig(event, `image_${index}`)
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) =>{
              errorFilesUpload[index] = "Upload failed. Please try again.";
              array[index] = null;
              addFilesSave[index] = null;
              setPopupActive(false);
            });
              
        })
        .catch((err) =>{
          errorFilesUpload[index] = "Upload failed. Please try again.";
          array[index] = null;
          addFilesSave[index] = null;
          setPopupActive(false)
        });
    },

    [addFilesSave]
  );

  const handleAddFiles = () => {
    setAddFilesPreview((prev)=>[
      ...prev,
      {}
    ])
  }

  useEffect(()=>{
    if(type == 'post-ipr'){
      setPostType('Post IPR');
      setAddFilesPreview([{},{},{}])
    }else if(type == 'post-redo'){
      setPostType('Post Redo')
    }else if(type == 'Other'){
      setPostType(type);
    }
  },[])

  useEffect(()=>{
    if(callHandleSave == 1){
        handleSave();
        setCallHandleSave(0);
    }
  }, [callHandleSave]);

  return (
  <Layout isProfile={true}>
      <Page
      title="Add File"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: `/dashboard/users/${id}`,
        },
      ]}
      PageActions={{ disabled : clinicImpersonated()}}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">{postType}</TextStyle>
          {type == "Other" && (
            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={imagePreview1 ? imagePreview1 : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    disabled={clinicImpersonated() || popupActive}
                    onDrop={handleDropZoneDrop1}
                    onDropAccepted={(file) => sendImage1(file, postRedoObject?.other_id)}
                  >
                    {uploadedFiles1}
                    {fileUpload1}
                  </DropZone>
                  <TextStyle variation="negative">{errorMessage}</TextStyle>
                  {(uploadProgress?.other) &&
                    <Box style={{ marginTop: '1rem' }}>
                        <LinearProgress variant="determinate" value={uploadProgress?.other} />
                    </Box>
                  }
                </div>
              </div>
              {fileError}
            </div>
          )}
          {(type == "post-ipr" || type == "post-redo") && (
            <div style={{textAlign : 'right'}}>
              <Button disabled={clinicImpersonated() || popupActive} onClick={(e) => handleAddFiles()}>
                  {t('add-another-file')}
              </Button>
            </div>
          )}
          {(type == "post-redo") && (
            <FormLayout>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">{t('center-bite-title')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewCenterBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneCenterBite}
                        onDropAccepted={(file) => sendImageCenterBite(file, postRedoObject?.center_bite_id)}
                      >
                        {uploadedFilesCenterBite}
                        {fileUploadCenterBite}
                      </DropZone>
                      <TextStyle variation="negative">{frontErrorMessage}</TextStyle>
                      {(uploadProgress?.center_bite) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.center_bite} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">{t('left-bite-title')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewLeftBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneLeftBite}
                        onDropAccepted={(file) => sendImageLeftBite(file, postRedoObject?.left_bite_id)}
                      >
                        {uploadedFilesLeftBite}
                        {fileUploadLeftBite}
                      </DropZone>
                      <TextStyle variation="negative">{leftErrorMessage}</TextStyle>
                      {(uploadProgress?.left_bite) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.left_bite} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">{t('right-bite-title')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewRightBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneRightBite}
                        onDropAccepted={(file) => sendImageRightBite(file, postRedoObject?.right_bite_id)}
                      >
                        {uploadedFilesRightBite}
                        {fileUploadRightBite}
                      </DropZone>
                      <TextStyle variation="negative">{rightErrorMessage}</TextStyle>
                      {(uploadProgress?.right_bite) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.right_bite} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">{t('upper-teeth-title')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewUpperTeeth} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneUpperTeeth}
                        onDropAccepted={(file) => sendImageUpperTeeth(file, postRedoObject?.upper_teeth_id)}
                      >
                        {uploadedFilesUpperTeeth}
                        {fileUploadUpperTeeth}
                      </DropZone>
                      <TextStyle variation="negative">{topErrorMessage}</TextStyle>
                      {(uploadProgress?.upper_teeth) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.upper_teeth} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">{t('lower-teeth-title')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewLowerTeeth} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneLowerTeeth}
                        onDropAccepted={(file) => sendImageLowerTeeth(file, postRedoObject?.lower_teeth_id)}
                      >
                        {uploadedFilesLowerTeeth}
                        {fileUploadLowerTeeth}
                      </DropZone>
                      <TextStyle variation="negative">{bottomErrorMessage}</TextStyle>
                      {(uploadProgress?.lower_teeth) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.lower_teeth} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">{t('Front-Facing-No-Smile')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewFronFacingNoSmile} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneFrontFacingNoSmile}
                        onDropAccepted={(file) => sendImageFrontFacingNoSmile(file, postRedoObject?.front_facing_no_smile_id)}
                      >
                        {uploadedFilesFrontFacingNoSmile}
                        {fileUploadFrontFacingNoSmile}
                      </DropZone>
                      <TextStyle variation="negative">{fronFacingNoSmileErrorMessage}</TextStyle>
                      {(uploadProgress?.front_facing_no_smile) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.front_facing_no_smile} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>
              
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">{t('Front-Facing-Big-Smile')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewFrontFacingBigSmile} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneFrontFacingBigSmile}
                        onDropAccepted={(file) => sendImageFrontFacingBigSmile(file, postRedoObject?.front_facing_big_smile_id)}
                      >
                        {uploadedFilesFrontFacingBigSmile}
                        {fileUploadFrontFacingBigSmile}
                      </DropZone>
                      <TextStyle variation="negative">{frontFacingBigSmileErrorMessage}</TextStyle>
                      {(uploadProgress?.front_facing_big_smile) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.front_facing_big_smile} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">{t('Profile-No-Smile')}</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewProfileNoSmile} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <DropZone 
                        disabled={clinicImpersonated() || popupActive}
                        onDrop={handleDropZoneProfileNoSmile}
                        onDropAccepted={(file) => sendImageProfileNoSmile(file, postRedoObject?.profile_no_smile_id)}
                      >
                        {uploadedFilesProfileNoSmile}
                        {fileUploadProfileNoSmile}
                      </DropZone>
                      <TextStyle variation="negative">{profileNoSmileErrorMessage}</TextStyle>
                      {(uploadProgress?.profile_no_smile) &&
                        <Box style={{ marginTop: '1rem' }}>
                            <LinearProgress variant="determinate" value={uploadProgress?.profile_no_smile} />
                        </Box>
                      }
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>
            </FormLayout>
          )}
          {(type == "post-ipr" || type == "post-redo") && (
            <>
              <div style={{display: 'flex', flexDirection:'row', justifyContent : 'space-between', flexWrap:'wrap', gap:'1rem'}}>
                {Array.from(Array(addFilesPreview.length).keys()).map((idx)=>
                  <div style={{ 
                    width: isResponsive || (addFilesPreview.length == (idx + 1) && addFilesPreview.length % 2 != 0) ? '100%' : '49%', 
                    marginTop:'1rem'
                  }}>
                    <FormLayout>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          {" "}
                          <div style={{ float: "left" }}>
                            {addFilesPreview[idx]?.file_type?.includes('video') ?
                              <div style={{width:'5rem', height:'5rem', position:'relative'}}>
                                <PlayCircleOutlineIcon 
                                    style={{
                                      position: 'absolute',
                                      opacity: 0.5, 
                                      top: '50%', 
                                      left: '50%', 
                                      transform: 'translateX(-50%) translateY(-50%)'
                                  }}/>
                                <video
                                    className="video"
                                    style={{width:'100%', height:'100%'}}
                                    src={addFilesPreview[idx]?.file_url}
                                ></video>
                              </div>                      
                              : 
                              <Thumbnail size="large" source={addFilesPreview[idx]?.file_url} />}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "10px",
                            }}
                          >
                            <DropZone 
                              disabled={clinicImpersonated() || popupActive}
                              onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => handleFilesDropZone(_dropFiles, acceptedFiles, _rejectedFiles, idx)}
                              onDropAccepted={(file) =>{
                                setIndexFiles(idx);
                                if(addFilesPreview[idx]?.id){
                                  sendImages(file, idx, addFilesPreview[idx]?.id, addFilesPreview)
                                }else{
                                  sendImages(file, idx, 0, addFilesPreview)
                                }
                              }}
                            >
                             {addFilesSave[idx] != null && <Stack alignment="center">
                                <Thumbnail
                                  size="small"
                                  alt={addFilesSave[idx]?.name}
                                  source={
                                    validImageTypes.indexOf(
                                      addFilesSave[idx]?.type
                                    ) > 0
                                      ? window.URL.createObjectURL(
                                          addFilesSave[idx]
                                        )
                                      : "/assets/images/Fileicon.svg"
                                  }
                                />
                                <div>
                                  {addFilesSave[idx]?.name}{" "}
                                  <Caption>
                                    {addFilesSave[idx]?.type} bytes
                                  </Caption>
                                </div>
                              </Stack>}
                            </DropZone>
                            <TextStyle variation="negative">{errorFilesUpload[idx]}</TextStyle>
                            {(uploadProgress[`image_${idx}`]) &&
                              <Box style={{ marginTop: '1rem' }}>
                                  <LinearProgress variant="determinate" value={uploadProgress[`image_${idx}`]} />
                              </Box>
                            }
                          </div>
                        </div>
                    </FormLayout>
                  </div>
                )}
              </div>
            </>
          )}

          <TextField
            label="Note"
            value={noteValue}
            onChange={handleChangeNoteValue}
            multiline={4}
          />
          <TextStyle variation="negative">{noteErrorMessage}</TextStyle>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: () => navigate(`/dashboard/users/${id}`),
        }}
      /> 
    </Page>
  </Layout>
  );
  function handleSave() {
    setErrorFilesUpload([]);
    setErrorMessage("");
    setFrontErrorMessage("");
    setLeftErrorMessage("");
    setRightErrorMessage("");
    setTopErrorMessage("");
    setBottomErrorMessage("");
    setNoteErrorMessage("");
    setFrontFacingNoSmileErrorMessage('');
    setFrontFacingBigSmileErrorMessage('');
    setProfileNoSmileErrorMessage('');
    const form_data = new FormData();
    form_data.append("content", imageKey1);
    form_data.append("center_teeth", imageKeyCenterBite);
    form_data.append("left_teeth", imageKeyLeftBite);
    form_data.append("right_teeth", imageKeyRightBite);
    form_data.append("upper_teeth", imageKeyUpperTeeth);
    form_data.append("lower_teeth", imageKeyLowerTeeth);
    form_data.append("image_six", imageKey6);
    form_data.append("image_seven", imageKey7);
    form_data.append("image_eight", imageKey8);
    form_data.append("image_nine", imageKey9);
    form_data.append("image_ten", imageKey10);
    form_data.append("video_one", video1Key);
    form_data.append("video_two", video2Key);
    form_data.append("array_ipr_files", JSON.stringify(addFilesKey));
    form_data.append("note", noteValue);
    selected && form_data.append("type", postType);
    let emptyFormData = true;
    for (let value of form_data.values()) {
      if(value && value != postType){
        emptyFormData = false;
        break;
      }
    }
    if(!emptyFormData){
      setPopupActive(true);
      let url = null;
      if(update){
        url = `/admin/v1/users/${id}/storage/${storageId}/update`;
      }else{
        url = `/admin/v1/users/${id}/storage`;
      }
      axios
        .post(url, form_data)
        .then((res) => {
          setStorageId(res?.data?.data?.id);
          if(res?.data?.storage_files.length > 0){
            addFilesPreview[indexFiles] = res?.data?.storage_files[0];
            setAddFilesPreview(addFilesPreview);
          }
          if(res?.data?.redo_files != null){
            handlePostRedofiles(res?.data?.redo_files);
            setPostRedoObject(res?.data?.redo_files);
          }
          handleSuccessfulUpload(type);
        })
        .catch((err) => {
          handleFailedSave(type);
        });
      }
    }
};

export default React.memo(AddFile);
