import React, { Fragment, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles, TextField } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import { clinicImpersonated } from '../../util/functions';
import { Thumbnail } from '@shopify/polaris';
import ActiveContext from '../../Context/Context';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";


const useStyles=makeStyles((theme)=>{ return {
   
}})

function NotesRow({row,setOpenEditNote,setnotificationLoading}) {

    const[open,setOpen]=useState(false)
    const classes=useStyles()
    const {
      photoIndex,
      imagesArray,
      setPhotoIndex,
      handleShowLargeImage,
      lightboxisOpen,
      handleCloseLargeImage,
      setImageKey,setImages
    } = useContext(ActiveContext);

    const unHtmlizeComment = (comment, showFullComment) => {
      if(showFullComment){
        if(comment.replace(/<[^>]+>/g, '').trim().length+3
        < comment.replace(/<[^>]+>/g, '').length){
          return comment.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ').trim()
        } else {
          return comment.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ')
        }  
      }else {
        if(comment.replace(/<[^>]+>/g, '').substring(0,65).trim().length+3
        < comment.replace(/<[^>]+>/g, '').length){
          return comment.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ').substring(0,65).trim()+"..."
        } else {
          return comment.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ')
        }
      }
    }
  return (
    <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.admin}
        </TableCell>
        <TableCell ><Typography  style={{fontSize:"1em"}}>{unHtmlizeComment(row.comment, false)}</Typography></TableCell>
        <TableCell><div style={{display:"flex", gap: "10px",flexWrap:"wrap"}}>
          {row.images.map((image,key)=>
        <div onClick={()=>{handleShowLargeImage(row.images,key)}}>
          <Thumbnail
          size="small"
          source={
            image.image
              ? image.image
              : ""
          }/>
        </div>  )}</div></TableCell>
        <TableCell >{row.created_at}</TableCell>
        <TableCell >
            {row.editable && <EditIcon onClick={()=>{
              if(!clinicImpersonated()){
                setnotificationLoading(true); 
                console.log(row.id); 
                setOpenEditNote(row.id);
                setImages(row.images);
                setImageKey(row.images.map((image)=>image.image))
              }
            }} />}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
        <TableCell colSpan={6}>
      <Typography style={{fontSize:"1em"}} >{unHtmlizeComment(row.comment, true)}
      </Typography>
      </TableCell>
      </TableRow>
      )}
      { lightboxisOpen && imagesArray?.length &&  <Lightbox startIndex={photoIndex} onClose={() => handleCloseLargeImage()} images={imagesArray.map((val) => val.image !== null && val.image)}></Lightbox>}
    </Fragment>
  )
}

export default NotesRow