import React, { useContext, useEffect, useState } from "react";
import {
  InlineError,
  TextStyle,
  Page,
  FormLayout,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../util/axios";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../TopNav/Topnav";
import { scrollToTop } from "../../../util/functions";
import { useTranslation } from "react-i18next";
import MultipleUploadPhotos from "../../Note/MutipleUploadPhotos";
import ActiveContext from "../../../Context/Context";
import { TextareaAutosize } from "@material-ui/core";

function AddComment() {
  let { id } = useParams();
  const navigate = useNavigate()
  const [valueComment, setvalueComment] = useState("");
  const { t } = useTranslation();
  const [fieldRequiredComment, setfieldRequiredComment] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const handleChangeComment = (e) => {
    setvalueComment(e.target.value);
  };
  const {
    setImageKey,setImages,imagesKey,setFilesImage
  } = useContext(ActiveContext);

  useEffect(()=>{
    scrollToTop(0);
  },[])
  return (
    <Layout isProfile={true}>
      <Page
      title={t("Add-comment")}
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/dashboard/users/" + id,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">{t('Comment')}</TextStyle>

          <TextareaAutosize
              placeholder={t("Add-comment...")}
              style={{
                width: "100%",
                resize: "none",
                outline: "none",
                padding: "0.8rem",
                height: "200px",
                overflow: "auto",
                "&:focus": {
                  outline: "none",
                },
              }}
              value={valueComment}
              onChange={handleChangeComment}
          />

          {fieldRequiredComment}
        </FormLayout>
        <MultipleUploadPhotos/>
      </Card>
      <PageActions
        primaryAction={{
          content: t("Save"),
          onClick: handleSave,
        }}
      />
    </Page>
    </Layout>
  );

  function handleSave() {
    if (valueComment === "") {
      setfieldRequiredComment(
        <InlineError message="This field is required" fieldID="myFieldID" />
      );
    } else {
      const bodyObj = {
        comment: valueComment,
        images: imagesKey
      };
      axios
        .post("admin/v1/users/" + id + "/comments/", bodyObj)
        .then((res) => {
          setImageKey([])
          setFilesImage([])
          setImages([]);
          navigate("/dashboard/users/" + id);
        })
        .catch((err) => console.log(err));
    }
  }
}
export default AddComment;
