import { Box } from '@material-ui/core'
import { Thumbnail } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NotesTable from './NotesTable'




function Note({setOpenEditNote,data,setnotificationLoading, }) {
const { t } = useTranslation()
function createData(id,admin,images, created_at,comment,editable) {
  return {
    id,
    admin,
    images:images?.length>0? images :[] ,
    created_at,
    comment,
    editable
    }
  }
  const titles=["",t("Admin"),t("Comment"),t("additional-photos"),t("Created-at"),""]

  const[rows,setRows]=useState([])
  useEffect(()=>{
    if(data.length){
      data.map((dat)=>{
        setRows((prev)=>{
          return [...prev,createData(dat?.id,dat?.admins?.first_name+" "+dat?.admins?.last_name,dat?.images_url,dat?.created_at,dat?.comment,dat?.editable)]
        })

      })
    }
  },[data])

  return (
    <Box>
     <NotesTable titles={titles} rows={rows} setOpenEditNote={setOpenEditNote} setnotificationLoading={setnotificationLoading} />
    </Box>
  )
}

export default Note