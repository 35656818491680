import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Layout from "../../../Components/TopNav/Topnav";

const DentalHistoryChecking = ({ align, isPage = true }) => {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  const Component = ({ t, align, isArabic }) => {
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };

    const divStyles = {
      mb: 2,
      mt: 2,
      textAlign: align,
      color: "#ff2c46",
      padding: 2,
      borderRadius: "5px",
      fontWeight: "bold",
    };

    return (
      <div
        className="learning_container"
        style={{ backgroundColor: "transparent" }}
      >
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            {t("first-visit-app-1")}
          </Typography>{" "}
          <Typography
            variant="p"
            sx={{
              mb: 2,

              padding: 2,
              borderRadius: "5px",
              paddingInline: "0px",
              color: "black",
            }}
            component="p"
          >
            {t("first-visit-app-2")}
            <br />
            <br />
            {t("first-visit-app-3")}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            {t("first-visit-app-4")}
          </Typography>{" "}
          <Typography
            variant="p"
            sx={{
              mb: 2,

              padding: 2,
              borderRadius: "5px",
              paddingInline: "0px",
              color: "black",
            }}
            component="p"
          >
            {t("first-visit-app-5")}
            <br />
            <br />
            {t("first-visit-app-6")}
            <ul style={unorderedListStyle}>
              <li>{t("first-visit-app-7")}</li>
              <li>{t("first-visit-app-8")}</li>
            </ul>
            <br />
            {t("first-visit-app-9")}
            <br />
            <br />
            {t("first-visit-app-10")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              mb: 2,

              padding: 2,
              borderRadius: "5px",
              paddingInline: "0px",
              color: "black",
            }}
            component="p"
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                fontSize: "18px",
                color: "black",
              }}
              component="h1"
            >
              {t("first-visit-app-11")}
            </Typography>{" "}
            {t("first-visit-app-12")}
            <br />
            {t("first-visit-app-13")}
            <br />
            {t("first-visit-app-14")}
            <br />
          </Typography>
          <Typography
            variant="p"
            sx={{
              mb: 2,

              padding: 2,
              borderRadius: "5px",
              paddingInline: "0px",
              color: "black",
            }}
            component="p"
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                fontSize: "18px",
                color: "black",
              }}
              component="h1"
            >
              {t("first-visit-app-15")}
            </Typography>{" "}
            {t("first-visit-app-16")}
            <br />
            {t("first-visit-app-17")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              mb: 2,

              padding: 2,
              borderRadius: "5px",
              paddingInline: "0px",
              color: "black",
            }}
            component="p"
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                fontSize: "18px",
                color: "black",
              }}
              component="h1"
            >
              {t("first-visit-app-18")}
            </Typography>{" "}
            {t("first-visit-app-19")}
            <br />
            {t("first-visit-app-20")}
            <br />
            {t("first-visit-app-21")}
          </Typography>
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
};

export default DentalHistoryChecking;
