import { Badge, Box, Chip } from "@material-ui/core";

function AppointmentStatus({ row, setOpenNote, classes, chipClass, label }) {
  return (
    <Box className={classes.statusStyle}>
      <Chip className={chipClass} label={label} />
      <Box>
        {row.comments ? (
          row["unread_comments"] ? (
            <Badge variant="dot" color="error">
              <img
                src="/assets/images/Note.svg"
                className={classes.note}
                onClick={() => {
                  setOpenNote(row.user_id);
                }}
                alt="unread-comments-image"
              />
            </Badge>
          ) : (
            <img
              src="/assets/images/Note.svg"
              className={classes.note}
              onClick={() => {
                setOpenNote(row.user_id);
              }}
              alt="comments-image"
            />
          )
        ) : null}
      </Box>
    </Box>
  );
}

export default AppointmentStatus;
