import React, { useCallback, useEffect, useState } from "react";
import {
  Page,
  FormLayout,
  Card,
  Modal,
  PageActions,
  Thumbnail,
  Stack,
  DropZone,
  TextStyle,
  Button
} from "@shopify/polaris";
import axios from "../../../util/axios";
import axiosAws from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
import Layout from "../../TopNav/Topnav";
import CustomTooltip from "../../CutomToolTip";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

function EditScan() {
  const { id, documentId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [popupActive, setPopupActive] = useState(false);
  const [imageError, setImageError] = useState("");
  const [imageError2, setImageError2] = useState("");
  const [imageError3, setImageError3] = useState("");
  const [imageError4, setImageError4] = useState("");
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [files4, setFiles4] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const fileUpload2 = !files2.length && <DropZone.FileUpload />;
  const fileUpload3 = !files3.length && <DropZone.FileUpload />;
  const fileUpload4 = !files4.length && <DropZone.FileUpload />;
  const [imagePreview, setImagePreview] = useState("");
  const [imagePreview2, setImagePreview2] = useState("");
  const [imagePreview3, setImagePreview3] = useState("");
  const [imagePreview4, setImagePreview4] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [imageKey2, setImageKey2] = useState("");
  const [imageKey3, setImageKey3] = useState("");
  const [imageKey4, setImageKey4] = useState("");
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [upperScanErrorMsg, setUpperScanErrorMsg] = useState("");
  const [lowerScanErrorMsg, setLowerScanErrorMsg] = useState("");
  const [biteScan1ErrorMsg, setBiteScan1ErrorMsg] = useState("");
  const [biteScan2ErrorMsg, setBiteScan2ErrorMsg] = useState("");
  const urlPlaceholder = "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
  const validateIfImage = (value) =>{
    return validImageTypes.indexOf(value[value.length - 1].type) > 0
    ? window.URL.createObjectURL(value[value.length - 1])
    : urlPlaceholder
  }

  const handleFailedUpload = (img, err) => {
    setPopupActive(false);
    console.log(err);
    if(img == 'upper_scan'){
      setUpperScanErrorMsg(t('upload-failed'));
      setFiles([]);
      setImagePreview('');
      setImageKey("");
    }
    if(img == "lower_scan"){
      setLowerScanErrorMsg(t('upload-failed'));
      setFiles2([]);
      setImagePreview2('');
      setImageKey2("");
    }
    if(img == "bite_scan1"){
      setBiteScan1ErrorMsg(t('upload-failed'));
      setFiles3([]);
      setImagePreview3('');
      setImageKey3("");
    }
    if(img == "bite_scan2"){
      setBiteScan2ErrorMsg(t('upload-failed'));
      setFiles4([]);
      setImagePreview4('');
      setImageKey4("");
    }
  }

  const uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : NoteMinor
        }
      />
      <div>{files[files.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (files) => {
      setUpperScanErrorMsg('');
      setPopupActive(true);
      setImagePreview(
        validateIfImage(files)
      );
      let url2 = "";
      let img = 'upper_scan';
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKey(res.data.key);
          setImageError("");
          url2 = res.data.url;
          axiosAws
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [files]
  );

  const uploadedFiles2 = files2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files2[files2.length - 1].name}
        source={
          validImageTypes.indexOf(files2[files2.length - 1].type) > 0
            ? window.URL.createObjectURL(files2[files2.length - 1])
            : NoteMinor
        }
      />
      <div>{files2[files2.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles2((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage2 = useCallback(
    (file) => {      
      setLowerScanErrorMsg('');
      setPopupActive(true);
      setImagePreview2(
        validateIfImage(file)
      );
      let url2 = "";
      let img = 'lower_scan';
      const form_data = new FormData();
      form_data.append("content_type", file[file.length - 1].type);
      form_data.append("file_name", file[file.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKey2(res.data.key);
          setImageError2("");
          url2 = res.data.url;
          axiosAws
            .put(url2, file[file.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": file[file.length - 1].type,
              },
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [files2]
  );

  const uploadedFiles3 = files3.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files3[files3.length - 1].name}
        source={
          validImageTypes.indexOf(files3[files3.length - 1].type) > 0
            ? window.URL.createObjectURL(files3[files3.length - 1])
            : NoteMinor
        }
      />
      <div>{files3[files3.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop3 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles3((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage3 = useCallback(
    (file) => {      
      setBiteScan1ErrorMsg('');
      setPopupActive(true);
      setImagePreview3(
        validateIfImage(file)
      );
      let url2 = "";
      let img = "bite_scan1";
      const form_data = new FormData();
      form_data.append("content_type", file[file.length - 1].type);
      form_data.append("file_name", file[file.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data)
        .then((res) => {
          setImageKey3(res.data.key);
          setImageError3("");
          url2 = res.data.url;
          axiosAws
            .put(url2, file[file.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": file[file.length - 1].type,
              },
            })
            .then((res) => {
              setCallHandleSave(1);
            })
            .catch((err) => {
              handleFailedUpload(img, err);
            });
        })
        .catch((err) => {
          handleFailedUpload(img, err);
        });
    },

    [files3]
  );
  const uploadedFiles4 = files4.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files4[files4.length - 1].name}
        source={
          validImageTypes.indexOf(files4[files4.length - 1].type) > 0
            ? window.URL.createObjectURL(files4[files4.length - 1])
            : NoteMinor
        }
      />
      <div>{files4[files4.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop4 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles4((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage4 = useCallback(
    (file) => {
        setBiteScan2ErrorMsg('');
        setPopupActive(true);
        setImagePreview4(
          validateIfImage(file)
        );
        let url2 = "";
        let img = "bite_scan2";
        const form_data = new FormData();
        form_data.append("content_type", file[file.length - 1].type);
        form_data.append("file_name", file[file.length - 1].name);
        axios
          .post("/admin/v1/images/s3", form_data)
          .then((res) => {
            setImageKey4(res.data.key);
            setImageError4("");
            url2 = res.data.url;
            axiosAws
              .put(url2, file[file.length - 1], {
                headers: {
                  "x-amz-acl": ["public-read-write"],
                  "Content-Type": file[file.length - 1].type,
                },
              })
              .then((res) => {
                setCallHandleSave(1);
              })
              .catch((err) => {
                handleFailedUpload(img, err);
              });
          })
          .catch((err) => {
            handleFailedUpload(img, err);
          });
        },

    [files4]
  );

  function handleDelete(e) {
    const imageID = e.currentTarget.id;
    updateImageStates(imageID);
  }

  const updateImageStates = (value) => {
    if (value == "document") {
          setImageKey("");
          setImagePreview("");
          setImageError("");
          setFiles([]);
    }
    if (value == "lower_scan") {
          setImageKey2("");
          setImagePreview2("");
          setImageError2("");
          setFiles2([]);
    }
    if (value == "bite_scan_1") {
          setImageKey3("");
          setImagePreview3("");
          setImageError3("");
          setFiles3([]); 
    }
    if (value == "bite_scan_2") {
          setImageKey4("");
          setImagePreview4("");
          setImageError4("");
          setFiles4([]);
    }
  }

  const handleUploadMessage = (value, message) => {
    if (value.document) {
      setImageError(t("Upper-scan-image") + message)
    }
    if (value.lower_scan) {
      setImageError2(t("Lower-scan-image") + message)

    }
    if (value.bite_scan_1) {
      setImageError3(t("Bite-scan-1-image") + message)

    }
    if (value.bite_scan_2) {
      setImageError4(t("Bite-scan-2-image") + message)

    }
  }

  const handleFetchApiCall = (value) =>{      
      if(value.document){
        setImagePreview(typeOfFile(value.document)? imageUrl(value.document) : urlPlaceholder);
      }
      
      if(value.lower_scan){
        setImagePreview2(typeOfFile(value.lower_scan)? imageUrl(value.lower_scan) : urlPlaceholder)
      }

      if(value.bite_scan_1){
        setImagePreview3(typeOfFile(value.bite_scan_1)? imageUrl(value.bite_scan_1) : urlPlaceholder)
      }
      
      if(value.bite_scan_2){
        setImagePreview4(typeOfFile(value.bite_scan_2)? imageUrl(value.bite_scan_2) : urlPlaceholder)
      }
  }

  const fetchDocumentById = async () =>{
    setPopupActive(true);
    try{
        const res = await axios.get(`/admin/v1/users/${id}/documents/${documentId}/show`);
          const data = res.data;
          if(data.success){
            handleFetchApiCall(data.data);
          }
          setPopupActive(false);
    }catch(error){
        setPopupActive(false);
        console.log({error});
    }
  }

  const imageUrl = (value) =>{
    return `${process.env.REACT_APP_AWS_URL}${value}`
  }

  const typeOfFile = (value) => {
    const n = value.lastIndexOf('.');
    const validType = ['jpeg', 'png', 'gif'];
    const value2 =  value.split('.', n);
    if(validType.indexOf(value2[value2.length - 1]) > 0){
      return true;
    }else{
      return false;
    }
  }

  useEffect(()=>{
    fetchDocumentById();
  },[])

  useEffect(()=>{
    if(callHandleSave == 1){
        handleSave();
        setCallHandleSave(0);
    }
  }, [callHandleSave]);

  return (
    <Layout>
      <Page
      title="Edit Scan"
      breadcrumbs={[
        {
          url: `/dashboard/users/${id}`,
        },
      ]}
    >
      <Card sectioned>
      <FormLayout.Group>
        <FormLayout>
          <TextStyle>{t('Upper Scan')}</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            
            <div>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError}</p>
          <div style={{display: "flex", alignItems: "center"}}>
            <Button id="document" onClick={(e) => handleDelete(e)}>
                {t('Delete')}
            </Button>
            <div style={{marginLeft: "15px"}}>
              <TextStyle variation="negative">{upperScanErrorMsg}</TextStyle>
            </div>
          </div>
          </FormLayout>
          <FormLayout>
          <TextStyle>{t('Lower-Scan')}</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview2 ? imagePreview2 : ""}

              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop2} onDropAccepted={sendImage2}>
                {uploadedFiles2}
                {fileUpload2}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError2}</p>
          <div style={{display: "flex", alignItems: "center"}}>
            <Button id="lower_scan" onClick={(e) => handleDelete(e)}>
                {t('Delete')}
            </Button>
            <div style={{marginLeft: "15px"}}>
              <TextStyle variation="negative">{lowerScanErrorMsg}</TextStyle>
            </div>
          </div>
          </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
          <FormLayout>
          <TextStyle>{t('Bite Scan 1')}</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview3 ? imagePreview3: ""}

              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop3} onDropAccepted={sendImage3}>
                {uploadedFiles3}
                {fileUpload3}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError3}</p>
          <div style={{display: "flex", alignItems: "center"}}>
            <Button id="bite_scan_1" onClick={(e) => handleDelete(e)}>
                {t('Delete')}
            </Button>
            <div style={{marginLeft: "15px"}}>
              <TextStyle variation="negative">{biteScan1ErrorMsg}</TextStyle>
            </div>
          </div>
          </FormLayout>
          <FormLayout>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between"
            }}
          >
            <TextStyle>{t('Bite Scan 2')}</TextStyle>
            <CustomTooltip title="If you are using 3 Shape, upload a second bite scan file"/>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview4 ? imagePreview4 : ""}

              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop4} onDropAccepted={sendImage4}>
                {uploadedFiles4}
                {fileUpload4}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError4}</p>
          <div style={{display: "flex", alignItems: "center"}}>
            <Button id="bite_scan_2" onClick={(e) => handleDelete(e)}>
                {t('Delete')}
            </Button>
            <div style={{marginLeft: "15px"}}>
              <TextStyle variation="negative">{biteScan2ErrorMsg}</TextStyle>
            </div>
          </div>
          </FormLayout>
          </FormLayout.Group>
          <div style={{marginTop:"1.5rem"}}>
            <PageActions
              primaryAction={{
                content: "Save",
                onClick: handleSave,
              }}
            />
          </div>
      </Card>
     
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
    </Layout>
  );

  function handleSave() {
      setUpperScanErrorMsg('');
      setLowerScanErrorMsg('');
      setBiteScan1ErrorMsg('');
      setBiteScan2ErrorMsg('');
      let bodyObj = {
        document: imageKey,
        lower_scan: imageKey2,
        bite_scan_1: imageKey3,
        bite_scan_2: imageKey4,
      };

      if(files.length > 0){
          bodyObj = {
            ...bodyObj,
            name: files[files.length - 1].name,
          }
      }

      if(files2.length > 0){
        bodyObj = {
          ...bodyObj,
          lower_scan_name: files2[files2.length - 1].name,
          
        }
      }

      if(files3.length > 0){
        bodyObj = {
          ...bodyObj,
          bite_scan_1_name: files3[files3.length - 1].name,
        }
      }

      if(files4.length > 0){
        bodyObj = {
          ...bodyObj,
          bite_scan_2_name: files4[files4.length - 1].name,
        }
      }
      if(!(Object.values(bodyObj).every(x => x === null || x === '' || x === undefined))){
        setPopupActive(true);
        axios
          .post(`/admin/v1/users/${id}/document/${documentId}/update`, bodyObj)
          .then((res) => {
            if(res.data.success){
              // navigate("/dashboard/users/" + id);
              if(imageKey != ""){
                setImageKey('');
              }
              if(imageKey2 != ""){
                setImageKey2('');
              }
              if(imageKey3 != ""){
                setImageKey3('');
              }
              if(imageKey4 != ""){
                setImageKey4('');
              }
            }else{
              res.data.errors &&  handleUploadMessage(res.data.errors, " wasn't uploaded");
            }
            setPopupActive(false);
          })
          .catch((err) =>{
            setPopupActive(false);
            console.log(err);
            if(imageKey != ""){
              setUpperScanErrorMsg(t('upload-failed'));
            }
            if(imageKey2 != ""){
              setLowerScanErrorMsg(t('upload-failed'));
            }
            if(imageKey3 != ""){
              setBiteScan1ErrorMsg(t('upload-failed'));
            }
            if(imageKey4 != ""){
              setBiteScan2ErrorMsg(t('upload-failed'));
            }
        });
      }
    }
}
export default EditScan;
