import React, { useContext, useState } from "react";
import axios from "../../util/axios";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import Layout from "../TopNav/Topnav";
import {
  Box,
  makeStyles,
  TextField,
  Button,
  FormHelperText,
  CircularProgress
} from "@material-ui/core";
import _, { unset } from 'lodash'
import { email, firstName, language, lastName, GlobalCountries } from "../../util/validation";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabsControllerContext } from "../../Context/Tabs/TabsProvider";
import { optionsCountries } from "../../lib/CountriesList";

const useStyles = makeStyles(() => {
  return {
    textField: {
      width: "30%",
      ['@media only screen and (max-width: 1200px) ']: {
        width: '100%'
      },
    },
    alertMargin: {
      width: '100%',
      textAlign: "center",
      margin: '0 auto',
      whiteSpace: 'pre-line'
    },
    box: {
      margin: 'auto',
      width: '95%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      columnGap: '15px',
      rowGap: '25px',
      marginTop: '2%',
    },
    headerBox: {
      width: "30%",
      margin: 'auto',
      marginTop: '20px',
      ['@media only screen and (max-width: 1200px) ']: {
        width: '100%'
      },
    },
    cancel: {
      width: '78px',
      height: '36px',
      borderRadius: '3px',
      fill: 'Linear gradient, 180°',
      border: '1px #F9FAFB solid',
      boxShadow: '0px 1px 5px lightgray',
      margin: '5px',

    },
    save: {
      padding: '10px 24px',
      borderRadius: '50px',
      fill: 'Linear gradient, 180°',
      backgroundColor: '#FF2C46',
      color: 'white',
      margin: '5px',
    },
    submit: {
      fontSize: '16px'
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#fff",
    },
    languageWrapper: {
      width: '30%',
      display: 'flex',
      columnGap: 10,
      ['@media only screen and (max-width: 1200px) ']: {
        width: '100%'
      },
    },
    selectedLang: {
      cursor: 'pointer',
      textAlign: 'center',
      padding: '12px 15px',
      borderRadius: '50px',
      backgroundColor: 'rgba(0,0,0,.08)',
      '&:hover': {
        opacity: 0.7,
        transition: '0.2s ease-in',
        transform: 'scale(1.03)',
      }
    },
    lang: {
      cursor: 'pointer',
      textAlign: 'center',
      padding: '12px 15px',
      borderRadius: '50px',
      transition: '.5s',
      boxShadow: '0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12), 0 1px 3px 0 rgba(0,0,0,.2)',
      '&:hover': {
        opacity: 0.7,
        transition: '0.2s ease-in',
        transform: 'scale(1.03)',
      }
    },
    contentWrapper: {
      width: '98%',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
    },
    img: {
      width: '100%',
    },
    descriptionBox: {
      marginTop: 30,
      lineHeight: '30px',
    },
    description: {
      color: '#404040',
      fontSize: '26px',
      fontWeight: 'bold',
    },
    description2: {
      color: '#404040',
      fontSize: '20px',
    },
  }
})

const AddpatientMallCollab = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [emailValue, setEmailValue] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("0");
  const [lastNameValue, setLastNameValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [personalMobileNumberValue, setPersonalMobileNumberValue] = useState("");

  const {
    clinicNotifications,
  } = useContext(TabsControllerContext);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setMessage('')
  }

  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value);
    const validEmail = email(e.target.value)
    if (!validEmail) {
      setErrors({
        ...errors,
        email: 'Invalid Email'
      })
    } else {
      unset(errors, 'email')
    }
  }
  if (openAlert) {
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
  }

  const handleChangeFirstName = (e) => {
    setFirstNameValue(e.target.value);
    const validFirstname = firstName(e.target.value);
    if (!validFirstname) {
      setErrors({
        ...errors,
        firstname: 'Invalid first name'
      })
    } else {
      unset(errors, 'firstname')
    }
  }

  const handleChangeLastName = (e) => {
    setLastNameValue(e.target.value);
    const validLastname = lastName(e.target.value)
    if (!validLastname) {
      setErrors({
        ...errors,
        lastname: 'Invalid last name'
      })
    } else {
      unset(errors, 'lastname')
    }
  }

  const handleChangePersonalMobileNumber = (e) => {
    setPersonalMobileNumberValue(e);
    if (e === undefined || e === "") {
      setErrors({
        ...errors,
        phone: t("phonenbrrequired"),
      })
    } else if (!isValidPhoneNumber(e)) {
      setErrors({
        ...errors,
        phone: t("validphonenumber"),
      })
    } else {
      unset(errors, 'phone')
    }
  }

  const handleSelectChangeLanguage = (lang) => {
    setSelectedLanguage(lang)
    const validLanguage = language(lang)
    if (!validLanguage) {
      setErrors({
        ...errors,
        language: 'Invalid language'
      })
    } else {
      unset(errors, 'language')
    }
  }

  function handleSave() {
    const bodyObj = {
      ...(firstNameValue && { first_name: firstNameValue }),
      ...(lastNameValue && { last_name: lastNameValue }),
      ...(emailValue && { email: emailValue }),
      ...(personalMobileNumberValue && {
        personal_mobile_number: personalMobileNumberValue,
      }),
      country: optionsCountries.find(country => country.code == clinicNotifications?.country?.code)?.label,
      options: {
        language: selectedLanguage,
      },
      clinicAdded: true,
    };
    if (!_.isEmpty(errors) || firstNameValue === '' || lastNameValue === '' || emailValue === '' || personalMobileNumberValue === '' || selectedLanguage === '0') {
      window.scroll({
        top: 0,
        behavior: "smooth"
      })
      setOpenAlert(true)
      setMessage('Missing fields')
    } else {
      setLoading(true);
      axios.post("admin/v1/users/clinic", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("access_token_clinic"),
        },
      }).then((res) => {
        if (res?.data?.errors) {
          let message = '';
          Object.keys(res?.data?.errors).map((field) => {
            res?.data?.errors[field].map((error) => {
              message += `${error} \n`
              return error;
            });
            return field
          });
          setMessage(message);
          setOpenAlert(true);
          return;
        }
        setOpenAlert(false)
        setMessage('')
        setTimeout(() => {
          navigate("/dashboard");
        }, 200)
      }).catch((err) => {
        setOpenAlert(true);
        console.log('Error in Creating a user', err);
        setMessage('Something Wrong Happened, Please contact Basma Support');
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  return (
    <Layout hideCreate={true} hideHeader={true}>
      <div className={classes.contentWrapper}>
        <img src='/assets/images/andy.jpg' className={classes.img} />
        {openAlert && <Alert open={openAlert} className={classes.alertMargin} severity="error" onClose={handleCloseAlert}>
          {message}
        </Alert>}
      </div>
      <Box className={classes.box}>
        <Box className={classes.descriptionBox}>
          <h3 className={classes.description}>{t('mall-collab-add-patient-title-1')}</h3>
          <h5 className={classes.description2}>{t('mall-collab-add-patient-title-2')}</h5>
        </Box>
        <TextField className={classes.textField}
          label={t("First-Name")}
          value={firstNameValue}
          error={errors.firstname}
          helperText={errors.firstname}
          onChange={(e) => handleChangeFirstName(e)}
        />
        <TextField className={classes.textField}
          label={t("Last-Name")}
          value={lastNameValue}
          error={errors.lastname}
          helperText={errors.lastname}
          onChange={(e) => handleChangeLastName(e)}
        />
        <TextField className={classes.textField}
          value={emailValue}
          label={t("Email-Address")}
          onChange={(e) => handleChangeEmail(e)}
          error={errors.email}
          helperText={errors.email}
          placeholder={t("Email-Address")}
        />
        <div className='add-patient-input-wrapper-mall-collab'>
          <PhoneInput
            className='add-patient-input-text-phone'
            countries={GlobalCountries}
            rules={{ required: true }}
            flagUrl="../../../flags/{xx}.svg"
            placeholder={t("Phone-Number")}
            value={personalMobileNumberValue}
            onChange={(e) => handleChangePersonalMobileNumber(e)}
            defaultCountry={clinicNotifications?.country?.code}
          />
          {errors?.phone && <FormHelperText error={true}>{errors.phone}</FormHelperText>}
        </div>
        <Box className={classes.languageWrapper}>
          <Box className={selectedLanguage == 'en' ? classes.selectedLang : classes.lang} onClick={() => handleSelectChangeLanguage('en')}>
            <p>{t('English')}</p>
          </Box>
          <Box className={selectedLanguage == 'ar' ? classes.selectedLang : classes.lang} onClick={() => handleSelectChangeLanguage('ar')}>
            <p>{t('Arabic')}</p>
          </Box>
          {errors.language && <FormHelperText error={true}>{errors.language}</FormHelperText>}
        </Box>
        <Box className={classes.headerBox}>
          <Button className={classes.save} disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={24} className={classes.loading} /> : <p className={classes.submit}>{t('submit')}</p>}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default AddpatientMallCollab;
