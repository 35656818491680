import React, { useState, useEffect } from "react";
import {
    TextStyle,
    Card,
    Icon,
    Banner,
} from "@shopify/polaris";
import axios from '../../../util/axios';
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import Accordion from "../../../Components/Accordion/Accordion";
import { Box, FormGroup } from "@material-ui/core";
import { ArrowUpMinor, ArrowDownMinor } from "@shopify/polaris-icons";
import moment from "moment";
import { Chip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import { GeneralFormats } from "../../../util/constants";
import './smile-journey.css';

function SmileJourney(props) {
    let { id } = useParams();
    const dateFormat = GeneralFormats.dateFormat;
    const [isOpenSectionSmileJourney, setisOpenSectionSmileJourney] = useState(true);
    const [arraySmileJourney, setArraySmileJourney] = useState([]);
    const [data, setData] = useState({
        loading: false,
        trackingOption: '',
        comment: '',
        steps: [],
        stepsWithoutExtendedImages: [],
        procedures: [],
        currentStepInd: -1,
        isTreatementCompleted: false,
        startingDate: null,
    });

    function toggleSmileJourney() {
        setisOpenSectionSmileJourney(!isOpenSectionSmileJourney);
    }

    const getData = () => {
        setData(prev => ({
            ...prev,
            loading: true,
        }));
        axios
            .get(`admin/v1/users/${id}/smile-journey`, {
                headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                },
            })
            .then((result) => {
                let latestSmilePlanId = result.data.data.latest_smile_plan_id;
                let stepsArray = result.data.data.steps;
                let proceduresArray = result.data.data.procedures;
                let stepsWithoutExtendedImagesArray = stepsArray.filter(step => step.extended_id == null);
                let obj = {};
                stepsWithoutExtendedImagesArray.forEach((step, key) => {
                    obj[`tracking-opt-${key}`] = '';
                });
                let latestStepsWithoutExtendedImagesArray = stepsWithoutExtendedImagesArray.filter(step => step.smile_plan_id == latestSmilePlanId);
                let ind = latestStepsWithoutExtendedImagesArray.findIndex(step => step.date == null || moment().isSameOrBefore(moment(new Date(step.date))));
                ind = stepsWithoutExtendedImagesArray.length == latestStepsWithoutExtendedImagesArray.length ? ind : ind + stepsWithoutExtendedImagesArray.length - latestStepsWithoutExtendedImagesArray.length;
                let currentStepIndex = ind != -1 ? stepsWithoutExtendedImagesArray.findLastIndex(e => e.smile_plan_id == latestSmilePlanId && e.id == stepsWithoutExtendedImagesArray[ind].id) : null;
                setData({
                    trackingOption: obj,
                    steps: stepsArray,
                    stepsWithoutExtendedImages: stepsWithoutExtendedImagesArray,
                    procedures: proceduresArray,
                    currentStepInd: currentStepIndex,
                    isTreatementCompleted: result.data.data.treatment_completed,
                    startingDate: result.data.data.starting_date,
                })
                result.data.data && result.data.data.starting_date && props.onGetFirstTimeWearingAligner(result.data.data.starting_date);
            })
            .catch((err) => {
                console.log(err)
            }).finally(f => {
                setData(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    };

    const Status = (status) => {
        if (status == 1 || status == 2) {
            return <CheckIcon style={{ color: status == 1 ? 'green' : 'gray', fontSize: '20px' }} />;
        }
        if (status == 3) {
            return <CloseIcon style={{ color: '#CC0909', fontSize: '20px' }} />;
        }
        if (status == 4 || status == 5) {
            return <WarningIcon style={{ color: status == 4 ? '#FCBD17' : '#CC0909', fontSize: '20px' }} />;
        } else {
            return null;
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data.stepsWithoutExtendedImages.length && data.steps.length && data.trackingOption !== '') {
            let proceduresLinkedToStep = [];
            setArraySmileJourney(
                data.stepsWithoutExtendedImages.map((item, i) => {
                    let stepProcedures = [];
                    let proc = data.procedures.find(procedure => {
                        if (item.uaki_id && procedure.images_id) {
                            return procedure.images_id == item.uaki_id
                        }
                        if (procedure.step_id == item.id && !proceduresLinkedToStep.includes(item.id)) {
                            proceduresLinkedToStep.push(item.id);
                            return procedure.step_id == item.id;
                        }
                    });
                    if (proc) {
                        stepProcedures = proc.procedures;
                    }
                    let extendedImages = data.steps.filter(step => step.extended_id && step.extended_id == item.uaki_id);
                    extendedImages.unshift(item);
                    let actions = [];
                    extendedImages.forEach(img => {
                        actions.push(...img.actions_with_actors);
                    });
                    let length = extendedImages.length;
                    let image = length ? extendedImages[length - 1] : null;
                    const latestAction = actions.length ? actions[actions.length - 1] : null;
                    const replacedImgs = latestAction && latestAction.action == 'Requested Clearer Photos' && latestAction.properties.length ? latestAction.properties[latestAction.properties.length - 1] : null;
                    let step = item.position ? `${(item.step_nb)}.${item.position}` : `${(item.step_nb)}`;
                    let title = `Step ${step} ${(item.is_refinement || item.is_mca) ? `(${item.smile_plan?.name})` : ''} ${item?.mca_disabled_step ? ' - REPLACED' : ''}`;

                    return (
                        <div key={i}>
                            {stepProcedures.length ?
                                <>
                                    {
                                        stepProcedures.map((stepProcedure, key) => {
                                            return (
                                                <div className="procedure-container" key={key}>
                                                    <TurnRightIcon style={{ fontSize: '20px' }} />
                                                    <p className="appt-title">Appt #{stepProcedure.appt}</p>
                                                    {Status(stepProcedure.status)}
                                                    <p>{stepProcedure.types}</p>
                                                    {stepProcedure.auto_created == 1 && <Chip style={{ height: 'initial' }} label='Auto' />}
                                                </div>
                                            )
                                        })
                                    }
                                </> :
                                <></>
                            }
                            <FormGroup>
                                <Accordion
                                    mcaDisabledStep={item?.mca_disabled_step}
                                    title={title}
                                    isNearest={data.currentStepInd == i}
                                    trackStatus={actions.map(img => {
                                        if (img.extended_days) {
                                            return `${img.action} ${img.extended_days} days`;
                                        }
                                        return img.action;
                                    }).join(', ')}
                                    status={(item.smile_plan_id < data.stepsWithoutExtendedImages[data.stepsWithoutExtendedImages.length - 1].smile_plan_id || i <= data.currentStepInd) ? ((i == data.currentStepInd && image.is_all_provided == 0) ? null : image.is_all_provided) : null}
                                    isRefinement={item.is_refinement ? 'REF' : item.is_mca ? 'MCA' : null}
                                    date={item.date ? moment(new Date(item.date)).format("DD-MM-YYYY") : null}
                                    changeHeight={[data.trackingOption]}
                                    disabled={true}
                                >
                                </Accordion>
                            </FormGroup>
                        </div>
                    );
                })
            );
        }
    }, [data]);

    return (
        data.steps.length > 0 &&
        <Box>
            <Card
                sectioned
                title="SMILE JOURNEY"
                actions={[
                    {
                        content: 'Refresh',
                        onClick: getData,
                        loading: data.loading,
                        disabled: data.loading,
                    },
                    {
                        content: isOpenSectionSmileJourney ? (
                            <Icon source={ArrowUpMinor}></Icon>
                        ) : (
                            <Icon source={ArrowDownMinor}></Icon>
                        ),
                        onClick: toggleSmileJourney,
                    },
                ]}
            >
                <TextStyle>{`First Aligner Start Date: ${moment(data.startingDate).format(dateFormat)}`}</TextStyle>
                <br />
                <br />
                {isOpenSectionSmileJourney ? arraySmileJourney : null}
                {data.isTreatementCompleted && (
                    <div style={{ marginTop: "10px" }}>
                        <Banner status="success">
                            <b>Treatment Completed</b>
                        </Banner>
                    </div>
                )}
            </Card>
        </Box>
    );
}

export default React.memo(SmileJourney);
