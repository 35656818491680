import React from "react";
import { Typography } from "@mui/material";

const AttachmentsComponent = ({ isArabic, align, t }) => {
  const unorderedListStyle = isArabic
    ? {
        margin: "10px 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "10px 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };

  const divStyles = {
    margin: "15px 0",
    textAlign: align,
    padding: "10px",
  };

  const title = { fontSize: "14px", mb: 2 };

  return (
    <div className="learning_container">
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_24")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          {t("ipr_faq_25")}
        </Typography>
      </Typography>

      <Typography sx={divStyles} variant="div" component="div">
        <Typography
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
          component="h1"
        >
          {t("ipr_faq_26")}
        </Typography>
        <ol style={unorderedListStyle}>
          <li>{t("ipr_faq_27")}</li>
          <li>{t("ipr_faq_28")}</li>
          <li>{t("ipr_faq_29")}</li>
          <li>{t("ipr_faq_30")}</li>
          <li>{t("ipr_faq_31")}</li>
          <li>{t("ipr_faq_32")}</li>
          <li>{t("ipr_faq_33")}</li>
          <li>{t("ipr_faq_34")}</li>
          <li>{t("ipr_faq_35")}</li>
        </ol>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_36")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          <p dangerouslySetInnerHTML={{ __html: t("ipr_faq_37") }} />
        </Typography>
      </Typography>

      <Typography sx={divStyles} variant="div" component="div">
        <Typography
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
          component="h1"
        >
          {t("ipr_faq_38")}
        </Typography>
        <ol style={unorderedListStyle}>
          <li>{t("ipr_faq_39")}</li>
          <li>{t("ipr_faq_40")}</li>
          <li>{t("ipr_faq_41")}</li>
          <li>{t("ipr_faq_42")}</li>
        </ol>
      </Typography>
    </div>
  );
};

export default AttachmentsComponent;
