import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Layout from "../../../Components/TopNav/Topnav";

const AlignerTreatmentProcess = ({ align, isPage = true }) => {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);
  
  const Component = ({ t, align, isArabic, isPage = false }) => {
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };
  
    const divStyles = {
      mb: 2,
      mt: 2,
      textAlign: align,
      color: "#ff2c46",
      padding: 2,
      borderRadius: "5px",
      fontWeight: "bold",
    };

    return (
      <div
        className="learning_container"
        style={{ backgroundColor: "transparent" }}
      >
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
            }}
            component="h1"
          >
            {t("first-visit-app-22")}{" "}
          </Typography>
          <p
            style={{ color: "black" }}
            dangerouslySetInnerHTML={{ __html: `${t("first-visit-app-23")} ${t("first-visit-app-24")}` }}
          />
        </Typography>
        {
          !isPage &&
          <Typography sx={divStyles} variant="div" component="div">
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                fontSize: "18px",
                color: "black",
              }}
              component="h1"
            >
              8. {t("show-the-box")}{" "}
              <span style={{ color: "#ff2c46" }}>{`(5 ${t("minutes")})`}</span>
            </Typography>
            <ul style={unorderedListStyle}>
              <li>{t("learn-more-aligner-kit-1")}</li>
              <li>{t("learn-more-aligner-kit-2")}</li>
              <li>{t("learn-more-aligner-kit-3")}</li>
            </ul>
          </Typography>
        }
      </div>
    )
  }

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} isPage />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
};

export default AlignerTreatmentProcess;
