import React from "react";
import { Typography } from "@mui/material";

const ScansComponent = ({ align, isArabic, t }) => {
  const unorderedListStyle = isArabic
    ? {
        margin: "10px 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "10px 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };

  const divStyles = {
    margin: "15px 0",
    textAlign: align,
    padding: "10px",
  };

  const title = { fontSize: "14px", mb: 2 };
  const title2 = { fontSize: "15px", mb: 2, fontWeight: "900" };

  return (
    <div className="learning_container">
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_59")}
            </Typography>
          </li>
        </ul>

        <Typography sx={title} variant="span" component="span">
          <p dangerouslySetInnerHTML={{ __html: t("ipr_faq_60") }} />
        </Typography>
        <ol style={unorderedListStyle}>
          <li>
            <Typography sx={title2} variant="span" component="span">
              {t("ipr_faq_611")}
            </Typography>{" "}
            {t("ipr_faq_6111")}
          </li>
          <br />
          <li>
            <Typography sx={title2} variant="span" component="span">
              {t("ipr_faq_61")}
            </Typography>{" "}
            {t("ipr_faq_62")}
          </li>
          <br />
          <li>
            <Typography sx={title2} variant="span" component="span">
              {t("ipr_faq_63")}
            </Typography>{" "}
            {t("ipr_faq_64")}
          </li>
        </ol>
      </Typography>
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_65")}
            </Typography>
          </li>
        </ul>

        <Typography sx={title} variant="span" component="span">
          <p dangerouslySetInnerHTML={{ __html: t("ipr_faq_66") }} />
        </Typography>
      </Typography>

      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_67")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          <p dangerouslySetInnerHTML={{ __html: t("ipr_faq_68") }} />
        </Typography>
      </Typography>

      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_69")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          {t("ipr_faq_70")}
          {t("ipr_faq_71")}

          <ol style={unorderedListStyle}>
            <li>{t("ipr_faq_72")}</li>
            <li>{t("ipr_faq_73")}</li>
            <li>{t("ipr_faq_74")}</li>
            <li>{t("ipr_faq_75")}</li>
            <li>{t("ipr_faq_76")}</li>
          </ol>
        </Typography>
      </Typography>
    </div>
  );
};

export default ScansComponent;
