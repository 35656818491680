import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../../Components/TopNav/Topnav";

function IPRFaqs({ align, isPage = true }) {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  const Component = ({ t, align, isArabic }) => {
    const unorderedListStyle = isArabic
      ? {
          margin: "10px 20px 0 0",
          color: "#000000",
          fontWeight: "normal",
        }
      : {
          margin: "10px 0 0 20px",
          color: "#000000",
          fontWeight: "normal",
        };

    const divStyles = {
      margin: "15px 0",
      textAlign: align,
      padding: "10px",
    };

    const title = { fontSize: "14px", mb: 2 };

    return (
      <div className="learning_container">
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_3")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("ipr_4") }} />
          </Typography>
          <ul style={unorderedListStyle}>
            <li>
              {t("ipr_5")} {t("ipr_6")}{" "}
            </li>
            <br />
            <li>
              {t("ipr_7")} {t("ipr_8")}{" "}
            </li>
            <br />
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_9")}
          </Typography>
          <ul style={unorderedListStyle}>
            <li>{t("ipr_10")} </li>
            <br />
            <li>{t("ipr_11")} </li>
            <br />
            <li>{t("ipr_12")} </li>
            <br />
          </ul>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_13")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("ipr_14") }} />
          </Typography>
        </Typography>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_15")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("ipr_16") }} />
          </Typography>
        </Typography>

        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_17")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("ipr_18") }} />
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_21")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("ipr_22") }} />
          </Typography>
        </Typography>
        <Typography sx={divStyles} variant="div" component="div">
          <Typography
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
            component="h1"
          >
            {t("ipr_19")}
          </Typography>
          <Typography sx={title} variant="span" component="span">
            <p dangerouslySetInnerHTML={{ __html: t("ipr_20") }} />
          </Typography>
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    setIsArabic(i18n.language === "ar");
  }, [i18n.language]);

  return isPage ? (
    <Layout isProfile={true}>
      <Component t={t} align={align} isArabic={isArabic} />
    </Layout>
  ) : (
    <Component t={t} align={align} isArabic={isArabic} />
  );
}

export default IPRFaqs;
