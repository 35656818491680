import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NotesRow from './NotesRow';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import { useTranslation } from 'react-i18next';
function NotesTable({rows,titles,setnotificationLoading,setOpenEditNote}) {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          {titles.map((title,key)=>(
            <TableCell key={key}>{title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        { rows.length==0 ?(
        <TableRow>
          <TableCell colSpan={6} rowSpan={3}>
            <Box style={{textAlign:"center"}}>
            <SpeakerNotesOffIcon style={{color:'#ff2c46', fontSize:'5em'}}  />
            <Typography>{t('No-Notes-Available')}</Typography>
            </Box>
          </TableCell>
        </TableRow>)
       : rows.map((row) => (
          <NotesRow  row={row} setnotificationLoading={setnotificationLoading} setOpenEditNote={setOpenEditNote}/>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

export default NotesTable