import React from "react";
import "./sidebar.css";

const SideBar = ({ tabs, activeItem }) => {

  return (
    <div className="sidebar">
      {
        tabs.map((tab, key) => (
          <div
            key={key}
            className={`side-bar-single-container ${activeItem === tab.value ? 'active' : ''} ${!tab.img ? 'side-bar-single-container-no-img' : ''}`}
            onClick={tab.onClick}
          >
            {
              tab.img &&
              <img
                className={`side-bar-single-img ${activeItem === tab.value ? 'activeimg' : ''}`}
                src={tab.img}
              />
            }
            <span style={{ paddingInline: '9px' }}>{tab.label}</span>
          </div>
        ))
      }
    </div>
  );
};

export default SideBar;
