import { Box, makeStyles, CircularProgress } from "@material-ui/core";
import React, {useState, useCallback, useEffect, useContext, useRef} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Profile from "../ProfileComponents/Profile";
import ListComments from "../ProfileComponents/Comments/List";
import axios from "../../util/axios";
import Cookies from "js-cookie";
import Layout from "../TopNav/Topnav";
import UserPhotoIdContext from "../../Context/UserPhotoId/UserPhotoIdContext";
import BookingInformation from "../BookingInformation/BookingInformation";
import UploadedFiles from "../UploadedFiles/UploadedFiles";
import TreatmentPlan from "../TreatmentPlan/TreatmentPlan";
import TopBar from "../TopBarProfile/TopBar";
import { scrollToTop } from "../../util/functions";
import PatientDataV2 from "../Patientdata/patient-data-v2.component";
import AppointmentsPatientProfile from "../Patientdata/appointments-patient-profile.component";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import SplitProceduresDialog from "../Procedures/split-procedures-dialog.component";
import instance from "../../util/axios";
import ActiveContext from "../../Context/Context";
import SmileJourney from "../ProfileComponents/SmileJourney/SmileJourney";
import ProcedureComments from "../ProcedureComments/ProcedureComments";

const useStyles = makeStyles(() => {
  return {
    root: {
      backgroundColor: "rgb(246, 246, 247)",
    },
    boxWrapper: {
      display: "grid",
      gap: 25,
      backgroundColor: "rgb(246, 246, 247)",
      height: "fit-content",
      padding: 20,
    },
    statusesWrapper: {
      display: "grid",
      width: "10%",
      gap: 10,
    },
    loadingBox: {
      margin: "8% auto",
      textAlign: "center",
      padding: 30,
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    cardStyle: {
      display: "grid",
      width: "100%",
      flexWrap: "wrap",
      gridTemplateColumns: "1fr 1fr",
      gap: "1rem",
      ["@media only screen and (max-width: 1170px) "]: {
        gridTemplateColumns: "1fr",
      },
    },
    btnWrapper: {
      backgroundColor: "#fff",
      borderRadius: "3px",
      height: 0,
      boxShadow: "inset 0 -1px 0 0 #DFE3E8",
      position: "absolute",
      right: "0.5%",
      top: "100%",
      width: "10.5rem",
      overflow: "hidden",
      transition: "height 0.3s ease-out",
    },
    padding: {
      padding: "1rem",
    },
    backbtn: {
      color: "#637381",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "20px",
      "&:hover": {
        backgroundColor: "#F6F6F7",
      },
    },
    slideBtn: {
      width: "100%",
      height: "45px",
      textAlign: "left",
      color: "#212B36",
      fontSize: "14px !important",
      letterSpacing: 0,
      lineHeight: "20px",
      boxShadow: "inset 0 -1px 0 0 #DFE3E8",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
    slideanimation: {
      height: "10.563rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    uploadBtn: {
      height: "32px",
      width: "fit-content",
      backgroundColor: "#FF2C46",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1rem 0",
    },
  };
});

export default function UserProfile() {
  const { id } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const [assignedDoctor, setAssignedDoctor] = useState("");
  const [profileClinic, setProfileClinic] = useState("");
  const [clinicId, setClinicId] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [membershipDisable, setMembershipDisable] = useState(false);
  const [profileResponse, setProfileResponse] = useState([]);
  const [patientDataValue, setPatientDataValue] = useState([]);
  const otherDoctorInputRef = useRef("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [action, setAction] = useState("");
  const [avatar, setAvatar] = useState("");
  const [couponCodeOptions, setCouponCodeOptions] = useState([]);
  const [coupon, setCoupon] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [completedProceduresDialog, setCompletedProceduresDialog] = useState({
    openCompletedProceduresDialog: false,
    appointmentProcedures: [],
    userId: null,
    scanId: null,
  });
  const [procedureComments, setProcedureComments] = useState([]);
  const isCommentNot = location?.state?.isCommentNot;
  const error = location?.state?.error;
  const handleAvatarImage = (value) => {
    setAvatar(value);
  };

  const {
    actions: { emptyImages },
  } = useContext(UserPhotoIdContext);

  const { setClinicData } = useContext(ActiveContext);

  const updatePatientActions = async (value, userId, scanId, dialog) => {
    setLoading(true);
    try {
      let doctor = selectedDoctor;
      if (selectedDoctor === "Other") {
        doctor = otherDoctorInputRef.current.value;
      }
      const response = await instance.put(
        `/admin/v1/clinic/user/${userId}/sub-status/update`,
        {
          action: value,
          scan_id: scanId,
          clinicId: Cookies.get('clinic_id'),
          doctor
        }
      );
      const dataRes = response.data;
      if (dataRes.success) {
        if (dataRes?.booking_status_completed) {
          navigate(
            `/dashboard/users/${userId}/appointments/${scanId}/edit-patient-data`
          );
        }
      } else {
        setOpenAlert(true);
        setMessage(t("This-user-is-not-eligible-to-book-a-scan"));
      }
    } catch (err) {
      setOpenAlert(true);
      setMessage(t("This-user-is-not-eligible-to-book-a-scan"));
    } finally {
      setLoading(false);
    }
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      let clinicId = Cookies.get("clinic_id");
      const result = await Promise.all([
        axios.get(`admin/v1/clinic/patient/information/${id}`),
        axios.get(`admin/v1/users/${id}/profile`),
        axios.get(`admin/v1/clinic/coupon-clinics/${clinicId}/show`),
        axios.get(`admin/v1/clinic/user-coupons/${id}/show`),
        axios.get(`admin/v1/clinic/${Cookies.get('clinic_id')}/doctors`),
        axios.get(`admin/v1/procedures/comments/${id}`)

      ]);
      setProcedureComments(result[5].data.data)
      if (result.length > 0) {
        setPatientDataValue(result);
        if (result[0]?.data) {
          handleAvatarImage(result[0]?.data?.front_facing_big_smile_url);
          setMembershipDisable(!result[0]?.data?.membership_uploaded);
        }

        result[1]?.data?.clinics && setClinicId(result[1]?.data?.clinics[0]?.id);

        result[1]?.data?.data && setProfileResponse(result[1].data);

        Cookies.set(
          "basma-profile-user",
          result[1].data.data.user.first_name +
            "-" +
            result[1].data.data.user.last_name
        );

        Cookies.set(
          "profile-user-name",
          result[1].data.data.user.first_name +
            " " +
            result[1].data.data.user.last_name
        );
        setAction(result[1]?.data?.action);
        result[1].data.assigned_to &&
          handleChangeAssignedDoctor(result[1].data.assigned_to);
        let couponOptionsArray = result[2]?.data?.data?.map((couponClinic) => ({
          value: couponClinic.coupon.id,
          code: couponClinic.coupon.code,
        }));
        setCouponCodeOptions(couponOptionsArray);
        let coupon = {
          id: result[3]?.data?.data?.coupon?.id,
          code: result[3]?.data?.data?.coupon?.code,
        };
        setCoupon(coupon.id);
        setClinicData(result[4]?.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setMessage("");
  };

  useEffect(() => {
    if (!isCommentNot) scrollToTop(0);

    let isFetched = false;

    if (!isFetched) {
      fetchItems();
    }

    return () => {
      isFetched = true;
      emptyImages();
    };
  }, []);
  const handleChangeProfileClinic = useCallback(
    (newValue) => setProfileClinic(newValue),
    []
  );
  const handleChangeAssignedDoctor = useCallback((newValue) => {
    newValue?.length &&
      setAssignedDoctor(
        <ul>
          {Object.keys(newValue).map(function (keyName, keyIndex) {
            return (
              <li key={keyName}>
                {newValue[keyName].user && (
                  <p>{`Name: ${newValue[keyName].user.first_name} ${newValue[keyName].user.last_name}`}</p>
                )}
                {newValue[keyName].city && (
                  <p>{`City: ${newValue[keyName].city}`}</p>
                )}
                {newValue[keyName].country && (
                  <p>{`Country: ${newValue[keyName].country}`}</p>
                )}
              </li>
            );
          })}
        </ul>
      );
  }, []);

  useEffect(() => {
    if (error) {
      setOpenAlert(true);
      setMessage(t("This-user-is-not-eligible-to-book-a-scan"));
    }
  }, []);
  
  

  return (
    <Layout isProfile={true}>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <Box className={classes.boxWrapper}>
          <TopBar userId={id} />
          {openAlert && (
            <Alert
              open={openAlert}
              className={classes.alertMargin}
              severity="error"
              onClose={handleCloseAlert}
            >
              {message}
            </Alert>
          )}
          <Box style={{ display: "flex" }}>
            <Profile
              profileResponse={profileResponse}
              membershipDisable={membershipDisable}
              onGetAssignedDoctor={handleChangeAssignedDoctor}
              handleChangeProfileClinic={handleChangeProfileClinic}
              id={id}
              couponCodeOptions={couponCodeOptions}
              setCoupon={setCoupon}
              coupon={coupon}
              avatar={avatar}
            />
          </Box>
          <Box className={`${classes.cardStyle}`}>
            <BookingInformation
              action={action}
              profileResponse={profileResponse}
            />
            <PatientDataV2
              patientDataValue={patientDataValue}
              userId={id}
              action={action}
              clinic={profileResponse}
            />
            <AppointmentsPatientProfile
              clinicId={clinicId}
              setCompletedProceduresDialog={setCompletedProceduresDialog}
            />
            <TreatmentPlan />
             {procedureComments.length!==0&&<ProcedureComments data={procedureComments}/>}
          </Box>
          <ListComments />
          <SmileJourney />
          <UploadedFiles />
          <SplitProceduresDialog
            completedProceduresDialog={completedProceduresDialog}
            setCompletedProceduresDialog={setCompletedProceduresDialog}
            updatePatientActions={updatePatientActions}
          />
        </Box>
      )}
    </Layout>
  );
}
