import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScansDropZone from "./ScansDropZone";
import axios from "../../util/axios";
import axiosAws from "axios";
import { clinicImpersonated, imageValue, scanType } from "../../util/functions";
import { useTranslation } from "react-i18next";
import ProcedureHeader from "./procedure-header.component";
import JSZip from "jszip";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    box: {
      display: "flex",
      flexWrap: "wrap",
    },
    titleStyle: {
      height: "16px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
    },
    titleBox: {
      height: "48px",
      width: "360px",
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    errorMsg: {
      color: "#D72C0D",
      fontSize: "12px !important",
      marginTop: "1rem",
    },
  };
});
export default function AppointmentScans({
  customStyle = {},
  userId,
  disable = false,
  data = { data: {}, isAllProvided: null },
  handleTabsExpand = () => {},
  index = 0,
  appointmentId = null,
  appointmentType = "scans",
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const { data: scansData, isAllProvided } = data;
  const classes = useStyles();
  const [saveCheckBox, setSaveCheckBox] = useState(false);
  const [iconScan, setIconScan] = useState(isAllProvided);
  const [scans, setScans] = useState(scansData);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState({});
  const [imageKey, setImageKey] = useState(null);
  const [checkedScanFiles, setCheckedScanFiles] = useState(false);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const { t, i18n } = useTranslation();
  const handleUploadError = (type, err) => {
    setErrorMsg("Upload failed. Please try again.");
    setImagePreview((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setImageKey((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setDisabled(false);
    console.log(err);
  };

  const handleIconScanView = (value) => {
    setIconScan(value);
  };

  const handleScanValue = (value) => {
    setScans(value);
    handleDataFetched(value);
  };

  const zipScanFiles = async (arrayFiles) => {
    const zip = new JSZip();
    // Function to read a File and add it to the zip.
    const addFileToZip = (file) => {
      return new Promise(async (resolve) => {
        // Read the file as an ArrayBuffer.
        const fileData = await new Promise((resolveFile, rejectFile) => {
          const reader = new FileReader();
          reader.onload = () => resolveFile(reader.result);
          reader.onerror = rejectFile;
          reader.readAsArrayBuffer(file);
        });

        // Add the file to the zip archive with its original name.
        zip.file(file.name, fileData, { compression: 'DEFLATE', compressionOptions: { level: 9 } });
        resolve();
      });
    };
    const promises = Array.from(arrayFiles).map(addFileToZip);
    try {
      await Promise.all(promises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const form_data = new FormData();
      form_data.append('content_type', 'text/plain');
      form_data.append('file_name', `user_${userId}_Scans-Folder.zip`);
      try {
        const response = await axios.post('/admin/v1/images/s3', form_data);
        if (response.status === 200) {
          const { key, url } = response.data;
          setImageKey((prev) => ({
            ...prev,
            scans_folder: key,
            scans_folder_name: `user_${userId}_Scans-Folder.zip`,
          }));
          await axiosAws.put(url, zipBlob, {
            headers: {
              "x-amz-acl": ["public-read-write"],
              "Content-Type": "text/plain",
            },
          }).then((res) => {
            setCallHandleSave(1);
          })
        }
      } catch (error) {
        console.log("Error in saving zip file on aws", error);
      }
    } catch (error) {
      console.error('Error zipping files:', error);
    }
  };

  const handleFolderUpload = (event) => {
    const files = event.target.files;
    zipScanFiles(files);
  };

  const handleChange = (files, id) => {
    setDisabled(true);
    setErrorMsg("");
    setSuccess(false);
    const imageId = id;
    const file = files[0];
    let imgUrl =
      validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : "/assets/images/stl-thumbnail.png";
    setImagePreview((prev) => ({
      ...prev,
      [imageId]: imgUrl,
    }));
    saveInAws(file, imageId);
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const saveInAws = (value, type) => {
    setErrorMsg("");
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios
      .post("/admin/v1/images/s3", form_data)
      .then((res) => {
        if (type == "document") {
          setImageKey((prev) => ({
            ...prev,
            [type]: res.data.key,
            name: value.name,
          }));
        } else {
          setImageKey((prev) => ({
            ...prev,
            [type]: res.data.key,
            [`${type}_name`]: value.name,
          }));
        }
        const url2 = res.data.url;
        axiosAws
          .put(url2, value, {
            headers: {
              "x-amz-acl": ["public-read-write"],
              "Content-Type": imageType,
            },
            onUploadProgress: (event) => uploadConfig(event, type),
          })
          .then((res) => {
            setCallHandleSave(1);
          })
          .catch((err) => {
            handleUploadError(type, err);
          });
      })
      .catch((err) => {
        handleUploadError(type, err);
      });
  };

  const handleSave = () => {
    const form_data = new FormData();
    imageKey?.document && form_data.append("document", imageKey?.document);
    imageKey?.name && form_data.append("name", imageKey?.name);
    imageKey?.lower_scan &&
      form_data.append("lower_scan", imageKey?.lower_scan);
    imageKey?.lower_scan_name &&
      form_data.append("lower_scan_name", imageKey?.lower_scan_name);
    imageKey?.bite_scan_1 &&
      form_data.append("bite_scan_1", imageKey?.bite_scan_1);
    imageKey?.bite_scan_1_name &&
      form_data.append("bite_scan_1_name", imageKey?.bite_scan_1_name);
    imageKey?.bite_scan_2 &&
      form_data.append("bite_scan_2", imageKey?.bite_scan_2);
    imageKey?.bite_scan_2_name &&
      form_data.append("bite_scan_2_name", imageKey?.bite_scan_2_name);
    imageKey?.scans_folder && form_data.append("scans_folder", imageKey?.scans_folder);
    imageKey?.scans_folder_name && form_data.append("scans_folder_name", imageKey?.scans_folder_name);
    setErrorMsg("");
    let url = null;
    let emptyFormData = true;
    for (let value of form_data.values()) {
      if (value) {
        emptyFormData = false;
        break;
      }
    }
    form_data.append("procedure", appointmentType?.procedure);
    form_data.append("user_scan_info_id", appointmentId);
    const check =
      !Object.values(imagePreview).every(
        (x) => x === null || x === "" || x === undefined
      );
    if (!emptyFormData || check) {
      url = `/admin/v2/users/${userId}/case-record-scans`;
      axios
        .post(url, form_data)
        .then((res) => {
          if (res.data.success) {
            handleIconScanView(
              checkedScanFiles ? checkedScanFiles : res.data.isAllProvided
            );
            const data = res.data.data;
            setScans(data);
            handleScanValue(data);
            handleDataFetched(data);
            setSuccess(true);
          }
          setDisabled(false);
        })
        .catch((err) => {
          setErrorMsg("Upload failed. Please try again.");
          setSuccess(false);
          setDisabled(false);
          console.log(err);
        })
        .finally(() => setSaveCheckBox(false));
    } else {
      setErrorMsg("Please upload scan files");
    }
  };

  const handleDataFetched = (value) => {
    if (value) {
      setImagePreview({
        document: imageValue(value?.document),
        name: value?.name ? value.name.split(".")[0] : null,
        lower_scan: imageValue(value?.lower_scan),
        lower_scan_name: value?.lower_scan_name
          ? value.lower_scan_name.split(".")[0]
          : null,
        bite_scan_1: imageValue(value?.bite_scan_1),
        bite_scan_1_name: value?.bite_scan_1_name
          ? value.bite_scan_1_name.split(".")[0]
          : null,
        bite_scan_2: imageValue(value?.bite_scan_2),
        bite_scan_2_name: value?.bite_scan_2_name
          ? value.bite_scan_2_name.split(".")[0]
          : null,
        scans_folder: value?.scans_folder ? '/assets/images/stl-thumbnail.png' : null,
        scans_folder_name: value?.scans_folder_name ? value.scans_folder_name.split(".")[0] : null,
      });
      setCheckedScanFiles(value?.sent_via_email === 1);
    }
  };

  useEffect(() => {
    handleDataFetched(scans);
  }, [scans]);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    if (scansData?.sent_via_email === 1) {
      setIconScan(1);
    }
  }, []);

  useEffect(() => {
    scrollToExpandedTab(index, "scans");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="scans"
      headerTitle={scanType(appointmentType?.procedure)}
      index={index}
      iconCheck={iconScan}
    >
      {openCloseTabs[index]?.scans && (
        <Box
          style={customStyle}
          className={`${classes.wrapper} scans-${index}`}
        >
          {
            <>
              <Box className={classes.titleBox}>
                <Typography
                  className={`${classes.titleStyle} ${
                    i18n.language === "ar"
                      ? classes.fontFamilyAlmarai
                      : classes.fontFamilyEina
                  }`}
                >
                  {t("Upload-Scans")}
                </Typography>
              </Box>
              {
                scans?.scanner_placeholders == 1 ?
                <Box className={classes.box}>
                  <ScansDropZone
                    uploadProgress={uploadProgress?.scans_folder}
                    disable={disable || disabled}
                    value={imagePreview?.scans_folder}
                    id={"scans_folder"}
                    handleChange={handleFolderUpload}
                    message={t("upload-scans-folder")}
                    name={imagePreview?.scans_folder_name}
                    folderUpload
                  />
                </Box> :
                <>
                  <Box className={classes.box}>
                    <ScansDropZone
                      uploadProgress={uploadProgress?.document}
                      disable={disable || disabled}
                      value={imagePreview?.document}
                      id={"document"}
                      handleChange={handleChange}
                      message={t("Upload-upper-scan")}
                      name={imagePreview?.name}
                    />
                    <ScansDropZone
                      uploadProgress={uploadProgress?.lower_scan}
                      disable={disable || disabled}
                      value={imagePreview?.lower_scan}
                      id={"lower_scan"}
                      handleChange={handleChange}
                      message={t("Upload-lower-scan")}
                      name={imagePreview?.lower_scan_name}
                    />
                  </Box>
                  <Box className={classes.box}>
                    <ScansDropZone
                      uploadProgress={uploadProgress?.bite_scan_1}
                      disable={disable || disabled}
                      value={imagePreview?.bite_scan_1}
                      id={"bite_scan_1"}
                      handleChange={handleChange}
                      message={t("Bite-scan-1")}
                      name={imagePreview?.bite_scan_1_name}
                    />
                    <ScansDropZone
                      uploadProgress={uploadProgress?.bite_scan_2}
                      disable={disable || disabled}
                      value={imagePreview?.bite_scan_2}
                      id={"bite_scan_2"}
                      handleChange={handleChange}
                      message={t("Bite-scan-2")}
                      name={imagePreview?.bite_scan_2_name}
                    />
                  </Box>
                </>
              }
              <Box className={classes.uploadBox}>
                <Button
                  disabled={clinicImpersonated()}
                  className={classes.saveBtn}
                  onClick={() => (saveCheckBox ? handleSave() : () => {})}
                >
                  {success ? t("Saved") : t("Save")}
                </Button>
                <Typography className={classes.errorMsg}>{errorMsg}</Typography>
              </Box>
            </>
          }
        </Box>
      )}
    </ProcedureHeader>
  );
}
