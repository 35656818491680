import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Layout from '../TopNav/Topnav';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clinicImpersonated, scrollToTop } from '../../util/functions';
import Photos from './Photos';
import Information from './Information';
import Scans from './Scans';
import IconCheckView from '../IconCheckView';
import axios from "../../util/axios";
import { useTranslation } from 'react-i18next';
import InformationMallCollab from './InformationMallCollab';

const useStyles = makeStyles(()=>{return {
  fontFamilyAlmarai:{
    fontFamily:'Almarai !important'
  },
  fontFamilyEina: {
    fontFamily: "Eina-semi !important",
  },
  wrapper:{
    margin:'auto',
    width: '60%',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    ['@media only screen and (max-width: 1000px) '] : {
      width:"100%"
    },
  },
  headerWrapper:{
    display:'flex',
    justifyContent:'space-between',
    padding:'1.375rem 1.313rem',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
  },
  infoWrapper:{
    padding:'2rem',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
  },
  boxWrapper:{
    height: '50%',
    width: '60%',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    margin: 'auto'
  },
  typoDesign:{
    height: '24px',
    width: 'fit-content',
    color: '#212B36',
    fontSize: '16px !important',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  bold:{
    fontWeight: 'bold',
  },
  btn:{
    height: '36px',
    width: '78px',
    border: '1px solid #C4CDD5',
    borderRadius: '3px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)',
    boxShadow: '0 1px 0 0 rgba(22,29,37,0.05)',
  },
  arrowBtn:{
    padding: 0,
    '&:hover':{
      backgroundColor:'#ffff'
    },
  },
  margin:{
    margin:'1rem'
  },
  flex:{
    display:'flex'
  },
  loadingBox:{
    margin: "0 auto",
    textAlign: "center",
    padding : 30,
    color:'#FF2C46'
  },
  loading: {
    textAlign: 'center',
    margin: "0 auto",
    color: '#ff2c46'
  },
  alertWrapper:{
    width: '60%',
    margin:'0 auto 0.7rem',
    gap:"5px",
    alignItems:"center",
    ['@media only screen and (max-width: 1000px) '] : {
      width: '100%'
    },
  },
  alertBox:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  alertButton:{
    textDecoration: 'underline',
    fontWeight:'600'
  },
}})
export default function EditPatientData() {
  const { t, i18n } = useTranslation();
  const { id, completed } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [viewScreening, setViewScreening] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [viewInformation, setViewInformation] = useState(false);
  const [viewScans, setViewScans] = useState(false);
  const [viewredoScans, setViewredoScans] = useState(false);
  const [iconPhotos, setIconPhotos] = useState(false);
  const [iconInf, setIconInf] = useState(false);
  const [isMandatory, setIsMandatory] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [iconScan, setIconScan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scans, setScan] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [isMallCollab, setIsMallCollab] = useState(false);
  const [information, setInformation] = useState(null);
  const screeningRef = useRef(null);
  const informationRef = useRef(null);
  const scanRef = useRef(null);
  const wrapperRef = useRef(null);


  const handleViewtabsScreening= () =>{
    viewInformation && setViewInformation((prev)=>!prev);
    viewScans && setViewScans((prev)=> !prev);
    viewredoScans && setViewredoScans((prev)=> !prev);
  }
  const handleViewtabsInformation = () =>{
    viewScreening && setViewScreening((prev)=>!prev);
    viewScans && setViewScans((prev)=> !prev);
    viewredoScans && setViewredoScans((prev)=> !prev);
  }

  const handleViewtabsScans = () =>{
    viewScreening && setViewScreening((prev)=>!prev);
    viewInformation && setViewInformation((prev)=>!prev);
    viewredoScans && setViewredoScans((prev)=> !prev);
  }

  const scrollScreening = () =>{
    const { innerHeight: height } = window
    window.scrollTo({
      top: height - (screeningRef.current.scrollHeight * 7),
      behavior:'smooth'
    })
    scrollToTop( height - (screeningRef.current.scrollHeight * 7))
  }

  const scrollToInformation = () =>{
    const { innerHeight: height } = window
    scrollToTop(height - (informationRef.current.scrollHeight * 6))

  }

  const scrollToScan = () =>{
    const { innerHeight: height } = window
    scrollToTop(height - (scanRef.current.scrollHeight * 4.5))
  }

  const handleIconPhotosView = (value) =>{
    setIconPhotos(value)
  }
  const handleIconInformationView = (value) =>{
    setIconInf(value)
  }
  const handleIconScanView = (value) =>{
    setIconScan(value)
  }

  const handlePhotosValue = (value) => {
    setPhotos(value);
  }

  const handleInformationValue = (value) => {
    setInformation(value);
  }

  const handleScanValue = (value) => {
    setScan(value);
  }

  const handleIsCompleted = (value) => {
    setIsCompleted(value);
  }

  const fetchItems = async () =>{
    setLoading(true);
    try{
      const result = await Promise.all([
        axios.get(`admin/v1/users/${id}/impressions&photos`),
        axios.get(`admin/v1/users/${id}/your-photos`),
        axios.get(`admin/v1/users/${id}/fv-documents`),
      ]);
      if(result.length > 0){
        if(result[0]?.data?.success){
          setIsMallCollab(result[0]?.data?.clinic?.is_mall_collaboration);
          setPhotos(result[0]?.data?.data?.step3);
          setIconPhotos(result[0].data.isAllProvidedStep3);
          if (result[0]?.data?.new_user) {
            navigate(`/dashboard/users/${id}`);
          }
        }

        if(result[1]?.data?.success){
          setInformation(result[1]?.data?.data);
          setIconInf(result[1]?.data?.isAllProvided);
          setIsMandatory(result[1]?.data?.mandatory);
        }
        if(clinicImpersonated()){
          setDisabled(true);
        }else{
          setDisabled(result[1]?.data?.patient_data_locked);
        }
        let scansData = result[2]?.data;
        if(scansData.success){
          if(completed === 'completed' && !scansData.isCompleted){
            setShowMessage(true);
          }else if(scansData.isCompleted){
            setIsCompleted(scansData.isCompleted);
          }
          setScan(scansData.data);
          if(scansData.data?.sent_via_email == 1){
            setIconScan(true);
          }else{
            setIconScan(scansData.isAllProvided);
          }
        }
        scrollToTop(0);
      }
      setLoading(false);
    }catch(err){
      setLoading(false);
      console.log( err);
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(()=>{
    fetchItems();
  },[])

  return (
    <Layout isProfile={true}>
      {showMessage && <Alert className={classes.alertWrapper} open={showMessage} severity="error">
        <Box className={classes.alertBox}>
          <AlertTitle margin={0}>{t('refresh-page')}</AlertTitle>
          <Button onClick={refreshPage} color="inherit" className={classes.alertButton} size="small">{t('refresh')}</Button>
        </Box>
      </Alert>}
      <Box className={classes.wrapper}>
        <Box className={classes.headerWrapper}>
          <Typography className={`${classes.typoDesign} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Patient-Data')}</Typography>
          <Button onClick={()=>navigate(`/dashboard/users/${id}`)} className={`${classes.btn} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Close')}</Button>
        </Box>
        {loading ? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box>:
          <>
            <div ref={wrapperRef} className={classes.infoWrapper}>
              {
                !isMallCollab &&
                <Box className={`${classes.margin}`}>
                  <div ref={screeningRef} onClick={()=> { scrollScreening(); handleViewtabsScreening(); setViewScreening((prev) => !prev)}} className={`${classes.headerWrapper}`}>
                    <Typography className={`${classes.typoDesign} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Photos')}</Typography>
                    <Box className={classes.flex}>
                      <IconCheckView value={iconPhotos}/>
                      <Button className={classes.arrowBtn}><KeyboardArrowDownIcon/></Button>
                    </Box>
                  </div>
                  {viewScreening && <Photos disable={disabled} handlePhotosValue={handlePhotosValue} photos={photos} handleIconPhotosView={handleIconPhotosView} />}
                </Box>
              }


              <Box className={`${classes.margin}`}>
                <div ref={informationRef} onClick={() =>{ scrollToInformation(); handleViewtabsInformation(); setViewInformation((prev) => !prev)}} className={`${classes.headerWrapper}`}>
                  <Typography className={`${classes.typoDesign} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Information')}</Typography>
                  <Box className={classes.flex}>
                    <IconCheckView value={iconInf}/>
                    <Button className={classes.arrowBtn}><KeyboardArrowDownIcon/></Button>
                  </Box>
                </div>
                {
                  viewInformation &&
                  (isMallCollab ? 
                    <InformationMallCollab isMandatory={isMandatory} handleInformationValue={handleInformationValue} information={information} userId={id} handleIconInformationView={handleIconInformationView}/> :
                    <Information isMandatory={isMandatory} handleInformationValue={handleInformationValue} information={information} userId={id} handleIconInformationView={handleIconInformationView}/>
                  )
                }
              </Box>

              <Box className={`${classes.margin}`}>
                <div ref={scanRef} onClick={() =>{ scrollToScan(); handleViewtabsScans(); setViewScans((prev) => !prev)}} className={`${classes.headerWrapper}`}>
                  <Typography className={`${classes.typoDesign} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{t('Scans')}</Typography>
                  <Box className={classes.flex}>
                    <IconCheckView value={iconScan}/>
                    <Button className={classes.arrowBtn}><KeyboardArrowDownIcon/></Button>
                  </Box>
                </div>
                {viewScans && <Scans disable={disabled} isCompleted={isCompleted} handleIsCompleted={handleIsCompleted} redo={false} handleScanValue={handleScanValue} scans={scans} userId={id} handleIconScanView={handleIconScanView} />}
              </Box>
            </div>
          </>}
      </Box>
    </Layout>
  )
}

