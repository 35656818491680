import { makeStyles, Menu, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ForwardToInboxTwoToneIcon from '@mui/icons-material/ForwardToInboxTwoTone';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from 'react';
import axios from '../../util/axios';
import moment from 'moment-timezone';
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { clinicImpersonated } from '../../util/functions';
import { useNavigate } from 'react-router-dom';

const defaultNumberOfNotifications=3;
const useStyles = makeStyles(() => ({
  fontFamilyAlmarai:{
    fontFamily:'Almarai !important'
  },
  fontFamilyEina: {
    fontFamily: "Eina-semi !important",
  },
  mainMenu:{
    "& .MuiPaper-root":{
     overflow:"unset"
    }
  },
  view:{
    cursor:"pointer",
    color:"#212B36",
    fontWeight:"bold",
    fontSize:"12px!important",
    opacity:0.56,
    "& svg":{
      width:'0.5em',height:'0.5em'
    }
  },
  pending:{
    fontWeight:'bold',
    opacity:0.56,
    fontSize:"12px!important",
    color:"#212B36"
  },
  clear:{
    fontWeight:'bold',
    opacity:0.56,
    fontSize:"12px!important",

    cursor:"pointer",
    color:"#C58D00"
  },
  notificationTab: {
    width: '100%',
    height: '100%',
    padding: 20
  },
  notiticationTabHeaderBox:{
  height: "30px",
  margin: 0,
  padding: "10px",
  position:"inherit",
  zIndex:2
  },
  notiticationTabHeader: {
    margin: 0,
    padding: "5px 10px",
    backgroundColor:"#F5F5F5",
    borderRadius: "4px",
    display:"flex",
   justifyContent:"space-between"
  },
  notificationsParent:{
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    overflowY:"auto",
    minHeight:defaultNumberOfNotifications*110,
    maxHeight: defaultNumberOfNotifications*110+60,
    "& div:last-child":{
      borderBottom:"none",
    }
  },
  notification:{
    cursor:"pointer",
    margin:"0px 10px 0 10px",
    display:"flex",
    padding:10,
    marginTop:"15px",
    borderBottom:"1px solid grey"
  },
  notificationIcon:{
    flex: "0 1 15%",
    margin:"5px 10px 0 0",
    color:"#212B36",
    "& img":{
      width:"100%",
    },
    

  },
  triangle:{
    height: "5px",
    width: "14px",
    backgroundColor: "white",
    boxShadow:" 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    position: "absolute",
    bottom: "100%",
    right: "3%",
  },
  notificationBody:{
  },
  description:{
   color: "#303030",
  fontWeight:'bold',
  fontSize:"12px!important",
   opacity:0.8,
   maxWidth: "25vw",
   ['@media(max-width: 1000px)'] : {
    maxWidth: "35vw",
  },
  ['@media(max-width: 600px)'] : {
    maxWidth: "45vw",
  },
  ['@media(max-width: 400px)'] : {
    maxWidth: "60vw",
  },
  },
  descriptionTime:{
    color:"#C58D00",
    fontWeight:"bold",
    fontSize:"12px!important",
    opacity:0.8,
   },
   title:{
    color: "#303030",
    fontWeight:"bold",
    fontSize:"14px!important",
    opacity:0.8,
    display: 'flex',
    gap: '5px',
    alignItems:"center"
   },
   timeAgo:{
    color:"#212B36",
    fontWeight:"bold",
    fontSize:"12px!important",
    opacity:0.56
   },
   redDot:{
     color:"red !important",
     fontSize:"1em !important",
     verticalAlign: "text-top",
   },
}));
function ClinicNotification({updatenotifications,anchorElNotification,openNotification,handleCloseNotificationTab,data ,setUpdate,isComment,isTime,clinicContent}) {
  const classes = useStyles()
  const navigate = useNavigate();
  const[isOpen,setIsOpen]=useState(false)
  const {
		currentTab,
		actions: { setCurrentTab, handleKeyPressPatient, setOpenNote},
	} = useContext(TabsControllerContext);
  const[sizenotifactions,setSize]=useState(defaultNumberOfNotifications)
  useEffect(()=>{
   
    if(isOpen){
      setSize(data.size_all);
    }
    else{
      setSize(defaultNumberOfNotifications);
    }
  },[isOpen])

  const ClearAll=()=>{
    if(!clinicImpersonated()){
      axios.get(`/admin/v1/clinic/notifications/all?is_comment=${isComment}`)
      .catch((err)=> {
          console.log(err)
      });
      let updateNumber = updatenotifications + 1;
      setUpdate(updateNumber);
    }
  }
  const ClearNotification=(id)=>{
    if(!clinicImpersonated()){
      axios.get(`/admin/v1/clinic/notifications/${id}`)
      .catch((err)=> {
          console.log(err)
      });
      let updateNumber = updatenotifications + 1;
      setUpdate(updateNumber);
    }
  }
  const ViewAll=()=>{
    setIsOpen((prev)=>{
      return !prev
    })
  }
  const { t, i18n } = useTranslation()
  return <Menu
    id="demo-positioned-menu1"
    aria-labelledby="demo-positioned-button1"
    anchorEl={anchorElNotification}
    open={openNotification}
    onClose={handleCloseNotificationTab}
    className={classes.mainMenu}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 35, horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {!isTime&&
      <div className={classes.notiticationTabHeaderBox}>
        <div className={classes.notiticationTabHeader}>
          <Typography variant='h4' className={`${classes.pending} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} display='inline'>{data.pending} {t('pending-notification')}</Typography>
          {!clinicImpersonated() && <Typography variant='h4' className={`${classes.clear} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} display='inline' onClick={ClearAll}>{t('Clear-all')}</Typography>}
          <Typography variant='h4' className={`${classes.view} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} display='inline' onClick={ViewAll}>{t('View-all')} { isOpen ?(<KeyboardArrowDownIcon/>):(<ArrowForwardIosIcon />)}</Typography>
        </div>
      </div>
      }
      {isTime?
        // <div className={classes.notification}  style={{width:'400px',border:'0px',overflow: 'auto', wordWrap: 'break-word'}} >
          clinicContent&&<div style={{width:'450px',height:'450px',border:'0px',overflow: 'auto',margin:'10px'}}  dangerouslySetInnerHTML={{ __html: clinicContent }} />
        // </div>
      :
      <div className={classes.notificationsParent} id={isOpen ? "opened" :""}>
        {data.size_all>0 &&data.notifications.map((notification,key)=>{
          return key < sizenotifactions &&(
            <div key={key} className={classes.notification} onClick={()=>{
              ClearNotification(notification.id)
              if(notification?.type == 'comment'){
                  // setOpenNote(notification?.user_id)
                  navigate(`/dashboard/users/${notification?.user_id}`,{state:{ isCommentNot : true}});
              }else if(notification?.type == 'reminder'){
                navigate(`/dashboard/users/${notification?.user_id}/edit-patient-data`);
              }else{
                setCurrentTab(4)
                Cookies.set('currentTab', 4)
              }
              handleKeyPressPatient({key:"Enter",
              target:{
                value:notification.user.first_name}
            })
            handleCloseNotificationTab()
            }}>
        <div className={classes.notificationIcon}>
            {notification.type=="comment"?<img src='/assets/images/Note.svg'/>
            : notification.type=="reschedule"? <img src='/assets/images/Reschedule.svg'/>
            : notification.type=="cancel"?<img src='/assets/images/Cancel.svg'/>
            : <img src='/assets/images/New Appointment.svg'/>}
            </div>
          <div className={classes.notificationBody}>
            <Typography variant='h4' gutterBottom className={`${classes.title} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}> {notification.read ==false &&<FiberManualRecordIcon className={classes.redDot}/>} {notification.title}</Typography>
            <Typography variant='h4' className={`${classes.description} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>{notification.description}</Typography>
            {notification.appointment_time && <Typography variant='h4' className={`${classes.descriptionTime} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}gutterBottom>{isComment ? moment.utc(notification.appointment_time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("ddd, MMM DD [at] hh:mm A") : moment(notification.appointment_time).format("ddd, MMM DD [at] hh:mm A")}</Typography>}
            <Typography variant='h4' className={`${classes.timeAgo} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} >  {moment.utc(notification?.created_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).fromNow()}</Typography>
            </div>
        </div>
          )
        })
      }
      </div>
      }
    </Menu>;
}

export default ClinicNotification;
