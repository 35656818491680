import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { styled, tableCellClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconCheckView from "../IconCheckView";
import { iconColors } from "../../util/functions";
import { useNavigate } from "react-router-dom";
import { AppointmentsTableStyles } from "./apoointments-table.styles";

const AppointmentsTable = ({ appointments, setCompletedProceduresDialog }) => {
  const [isArabic, setIsArabic] = useState(false);
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
    },
  }));
  const classes = AppointmentsTableStyles();

  let align = isArabic ? "right" : "left";
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const handleEditAppointment = (
    scanId,
    userId,
    bookingStatus,
    appointmentCompletedAt,
    procedures
  ) => {
    if (
      !procedures.includes("First Visit") &&
      !procedures.includes("Walk-in")
    ) {
      if (
        bookingStatus === "Confirmed Booking" &&
        appointmentCompletedAt === "-"
      ) {
        procedures = procedures.map((procedure) => {
          return { label: procedure, value: false };
        });
        setCompletedProceduresDialog({
          openCompletedProceduresDialog: true,
          appointmentProcedures: procedures,
          userId,
          scanId,
        });
      } else {
        navigate(
          `/dashboard/users/${userId}/appointments/${scanId}/edit-patient-data`
        );
      }
    } else {
      navigate(
        `/dashboard/users/${userId}/appointments/${scanId}/edit-patient-data`
      );
    }
  };

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    }
    if (i18n.language === "en") {
      setIsArabic(false);
    }
  }, [i18n.language, isArabic]);

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.appointmentTableProfile}
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">
              {t("treatment-stage")}
            </StyledTableCell>
            <StyledTableCell align={align}>
              {t("appointment-summary-column")}
            </StyledTableCell>
            <StyledTableCell align="center">{`${t(
              "before-step"
            )}`}</StyledTableCell>
            <StyledTableCell align={align}>{t("procedures")}</StyledTableCell>
            <StyledTableCell align={align}>{t("scheduled")}</StyledTableCell>
            <StyledTableCell align={align}>{t("completed")}</StyledTableCell>
            <StyledTableCell className={classes.submissionStatusColumn} align="center">
              {t("submission-status")}
            </StyledTableCell>
            <StyledTableCell align={align}>{t("actions")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointments.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center" component="th" scope="row">
                {row?.treatment_stage}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {row?.appointment_number}
              </TableCell>
              <TableCell align="center">{row?.before_step}</TableCell>
              <TableCell className={classes.proceduresColumn} align={align} >
                <Box className={classes.proceduresBox}>
                  {row?.type?.map((procedure, idx) => (
                    <Typography
                      key={idx}
                      className={classes.typeIcon}
                      style={{
                        backgroundColor:
                          iconColors[procedure]?.background ?? "#3CC7D3",
                        color: iconColors[procedure]?.color ?? "black",
                      }}
                    >
                      {t(procedure)}
                    </Typography>
                  ))}
                </Box>
              </TableCell>
              <TableCell align="center">{row?.booking_date}</TableCell>
              <TableCell align="center">
                {row?.appointment_completed_at}
              </TableCell>
              <TableCell align="center">
                <div
                  className={
                    isArabic
                      ? classes.submissionStatusAr
                      : classes.submissionStatusEn
                  }
                >
                  <IconCheckView value={row?.submission_status} />
                </div>
              </TableCell>
              <TableCell align={align}>
                <Button
                  disabled={!row?.has_required_files_data}
                  onClick={() =>
                    handleEditAppointment(
                      row?.id,
                      row?.user_id,
                      row?.booking_status,
                      row?.appointment_completed_at,
                      row?.type
                    )
                  }
                  className={classes.btn}
                >
                  {t("Edit")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppointmentsTable;
