import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core'
import React, {useContext, useEffect} from 'react'
import Layout from '../../Components/TopNav/Topnav'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {TabsControllerContext} from "../../Context/Tabs/TabsProvider";
import {usePendingBookingStatus} from "../../hooks/reactQuery/usePendingBookingStatus";

const useStyles = makeStyles(()=>{ return {
    loadingBox:{
        margin: "12% auto",
        textAlign: "center"
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
    emailErrorBox:{
        position:'absolute', 
        top:'50%', 
        transform:"translateY(-50%)", 
        width:'100%', 
        textAlign: 'center' 
    },
    emailErrorBtn:{
        backgroundColor:'#FF2C46', 
        color: '#fff', 
        margin:'3rem',
        '&:hover':{
            backgroundColor:'#FF2C46',
        }
    }
 } });

export default function ScanConfirmLoading() {
    const {
        pendingBooking,
        actions: { setPendingBooking }
    } = useContext(TabsControllerContext);
    const navigate = useNavigate()
    const {t} = useTranslation();
    const classes = useStyles();
    const query = new URLSearchParams(document.location.search);
    const pendingBookingQuery = query.get('pendingBookingId');
    const { id, isReschedule } = pendingBooking;
    let pendingBookingId = pendingBookingQuery;
    if (!pendingBookingId) {
        pendingBookingId = id;
    }

    const { mutate, data, isLoading, isError, isSuccess } =
      usePendingBookingStatus();
    
    const handleCheckBookingStatus = () => {
        const bookingData  = {
            pendingBookingId,
        };
        mutate(bookingData);
    }
    useEffect(() => {
        if (pendingBookingId && !isReschedule) {
            handleCheckBookingStatus();
        }
    }, []);

    useEffect(() => {
      if (data?.success === false || (isSuccess && data?.success === true)) {
        setPendingBooking({ id: null, isReschedule: false });
      }
      return () => {
        if (isSuccess && data?.success === true) {
          toast.success(t("Appointment-Confirmed-Successfully"), 3000);
        }
        navigate("/dashboard");
      };
    }, [data, isSuccess]);
    
    return (
      <Layout>
        {isReschedule && !isLoading && !isError && (
          <Box className={classes.emailErrorBox}>
            <Typography>{t("reschedule-confirmation")}</Typography>
            <Button
              onClick={() => handleCheckBookingStatus(true)}
              className={classes.emailErrorBtn}
            >
              {t("reschedule-confirmation-button")}
            </Button>
          </Box>
        )}
        {(isError) && (
          <Box className={classes.emailErrorBox}>
            <Typography>{t("booking-error")}</Typography>
            <Button
              onClick={() => navigate("/dashboard")}
              className={classes.emailErrorBtn}
            >
              {t("back-to-main")}
            </Button>
          </Box>
        )}
        {isLoading && (
          <div>
            <Box className={classes.loadingBox}>
              <Typography style={{ marginBottom: "16px" }}>
                {t("loading-confirmation")}
              </Typography>
              <CircularProgress className={classes.loading} />
            </Box>
          </div>
        )}
      </Layout>
    );
}