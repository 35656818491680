import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import parse from "react-html-parser";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    title: {
      color: "white",
      backgroundColor: "#FF2C46",
      boxShadow: "inset 3px 0 0 0 #FF2C46, inset 0 -1px 0 0 #DFE3E8",
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    pending: {
      textAlign: "center",
      margin: "0 auto",
      color: "#fff",
      padding: "5px",
    },
    closebutton: {
      position: "absolute",
      right: "20px",
      top: "20px",
      bottom: "20px",
      cursor: "pointer",
    },
    confirm: {
      borderRadius: "5.88px",
      backgroundColor: "#FF2C46",
      width: "20%",
      height: "45px",
    },
    cancel: {
      borderRadius: "5.88px",
      color: "black",
      width: "20%",
      height: "45px",
    },
    buttonRightToLeft: {
      fontFamily: "Almarai !important",
      margin: "0 10px",
    },
    dialogContext: {
      "& #procedure-title": {
        color: "#FF2C46",
        fontWeight: "bold",
        textDecoration: "underline",
        fontSize: "20px"
      }
    }
  };
});

export default function ConfirmationDialog({
  open,
  handleClose,
  handleOutsideClick,
  handleConfirm,
  confirmationButtonText,
  cancelButtonText,
  dialogTitle,
  dialogContentText,
  children,
  loading,
  notes = false,
  confirmBtnStyle = {},
  pending = false,
  enableDialog = true
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleOutsideClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className={`${classes.title} ${
          i18n.language === "ar"
            ? classes.fontFamilyAlmarai
            : classes.fontFamilyEina
        }`}
        id="alert-dialog-title"
      >
        <span>{dialogTitle}</span>
        {notes && (
          <span className={classes.closebutton} onClick={handleOutsideClick}>
            <CloseIcon />
          </span>
        )}
      </DialogTitle>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <>
          <DialogContent>
            {dialogContentText && (
              <DialogContentText id="alert-dialog-description" className={classes.dialogContext}>
                {typeof (dialogContentText) === "object" ? dialogContentText : parse(t(dialogContentText))}
              </DialogContentText>
            )}
            {children}
          </DialogContent>
          <DialogActions>
            {confirmationButtonText ? (
              pending ? (
                <Button
                  style={confirmBtnStyle}
                  disabled
                  className={`${classes.confirm} ${
                    i18n.language === "ar"
                      ? classes.buttonRightToLeft
                      : classes.fontFamilyEina
                  }`}
                  color="secondary"
                  variant="contained"
                >
                  <CircularProgress className={classes.pending} />
                </Button>
              ) : (
                <Button
                  style={confirmBtnStyle}
                  onClick={handleConfirm}
                  disabled={!enableDialog}
                  className={`${classes.confirm} ${
                    i18n.language === "ar"
                      ? classes.buttonRightToLeft
                      : classes.fontFamilyEina
                  }`}
                  color="secondary"
                  variant="contained"
                >
                  {confirmationButtonText}
                </Button>
              )
            ) : (
              ""
            )}
            {cancelButtonText && (
              <Button
                onClick={handleClose}
                className={`${classes.cancel} ${
                  i18n.language === "ar"
                    ? classes.buttonRightToLeft
                    : classes.fontFamilyEina
                }`}
                color="default"
                variant="outlined"
              >
                {t(cancelButtonText)}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  confirmationButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  dialogTitle: PropTypes.string,
  // dialogContentText: PropTypes.string || PropTypes.object || PropTypes.array,
};

ConfirmationDialog.defaultProps = {
  confirmationButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
  open: false,
}