export const email = (val) => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(val.match(mailformat)){
        return true;
    }
    else {
        return false;
    }
}

export const firstName = (val) => {
    const regex = /^[a-z , 0-9, ء-ي ,.'-]+$/i
    if(val.match(regex)){
        return true;
    }
    else {
        return false;
    }
}


export const lastName = (val) => {
    const regex = /^[a-z , 0-9, ء-ي ,.'-]+$/i
    if(val.match(regex)){
        return true;
    }
    else {
        return false;
    }
}

export const age = (val) => {
    if(val){
        return true;
    }
    else {
        return false;
    }
}
export const language = (val) => {
    if(val){
        return true;
    }
    else {
        return false;
    }
}

export const country = (val) => {
    if(val){
        return true;
    }
    else {
        return false;
    }
}

export const GlobalCountries = [
    "AF","AL","DZ","AS","AD","AO","AI","AG","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BE","BZ","BJ","BM","BT","BO","BQ","BA","BW","BR","IO","BN","BG","BF","BI","CV","KH","CM","CA","KY","CF","TD","CL","CN","CX","CC","CO","KM","CG","CK","CR","HR","CW","CY","CZ","DK","DJ","DM","DO","EC","EG","SV","GQ","ER","EE","SZ","ET","FK","FO","FJ","FI","FR","GF","PF","GA","GM","GE","DE","GH","GI","GR","GL","GD","GP","GU","GT","GG","GN","GW","GY","HT","VA","HN","HK","HU","IS","IN","ID","IE","IM","IT","JM","JP","JE","JO","KZ","KE","KI","KR","KW","KG","LA","LV","LB","LS","LY","LI","LT","LU","MO","MG","MW","MY","MV","ML","MT","MH","MQ","MR","MU","YT","MX","FM","MD","MC","MN","ME","MS","MA","MZ","MM","NA","NR","NP","NL","NC","NZ","NI","NE","NG","NU","NF","MP","NO","OM","PK","PW","PS","PA","PG","PY","PE","PH","PL","PT","PR","QA","MK","RO","RU","RW","RE","BL","SH","KN","LC","MF","PM","VC","WS","SM","ST","SA","SN","RS","SC","SL","SG","SX","SK","SI","SB","SO","ZA","SS","ES","LK","SR","SJ","SE","CH","TW","TJ","TZ","TH","TL","TG","TK","TO","TT","TN","TR","TM","TC","TV","UG","UA","AE","GB","US","UY","UZ","VU","VE","VN","VG","VI","WF","EH","YE","ZM","AX"
];

export const isValidCountryCode = (value) => {
    return (value != undefined && value != "" && value.indexOf("+972") == -1
        && value.indexOf("+98") == -1 && value.indexOf("+964") == -1 && value.indexOf("+963") == -1 
        && value.indexOf("+249") == -1 && value.indexOf("+95") == -1 && value.indexOf("+375") == -1 
        && value.indexOf("+225") == -1 && value.indexOf("+53") == -1 && value.indexOf("+243") == -1 
        && value.indexOf("+231") == -1 && value.indexOf("+850") == -1 && value.indexOf("+263")
    );
}
