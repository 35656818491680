import React from 'react'
import TeethComponent from './TeethComponent'
import { Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@mui/icons-material/Info';
import { Checkbox } from '@shopify/polaris';

const AdditionalInstructions = ({ t, ids, handleClick, onTagClick }) => {

    return (
        <div>
            <div className='additional-instructions-title-container'>
                <Typography className='treatment-approach-title'>{t('avoid-attachments')}</Typography>
                <Tooltip
                    arrow
                    title={t('avoid-attachments-info')}
                    placement='top'
                >
                    <InfoIcon className='additional-instructions-info' />
                </Tooltip>
            </div>
            <div className='additional-instructions-teeth-container'>
                <TeethComponent
                    type='upper'
                    handleClick={handleClick}
                    ids={ids.upper}
                    onTagClick={onTagClick}
                    title={t('upper-teeth')}
                />
                <TeethComponent
                    type='lower'
                    handleClick={handleClick}
                    ids={ids.lower}
                    onTagClick={onTagClick}
                    title={t('lower-teeth')}
                />
            </div>
            <Checkbox
                label={t('no-attachments-anterior')}
                checked={ids.no_anterior_attachments}
                onChange={(e) => handleClick(e, 'no_anterior_attachments')}
            />
            <br />
            <Checkbox
                label={t('no-attachments')}
                checked={ids.no_attachments}
                onChange={(e) => handleClick(e, 'no_attachments')}
            />
        </div>
    )
}

export default AdditionalInstructions