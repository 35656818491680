import React, { useContext, useEffect, useState } from 'react'
import Appointments from '../../Components/Appointments/Appointments'
import Calendar from '../../Components/Calendar/Calendar'
import Patients from '../../Components/Patients/Patients'
import TabNav, { TabPanel } from '../../Components/TabNav/Tabnav'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import theme from '../../lib/theme'
import Layout from '../../Components/TopNav/Topnav'
import Ipr from '../../Components/Ipr/IprPage'
import ActiveContext from '../../Context/Context'
import axios from '../../util/axios';
import Cookies from 'js-cookie'
import CancelledBooking from '../../Components/CancelledBooking/CancelledBooking'
import NeedRescheduling from "../../Components/NeedRescheduling/need-rescheduling.component";
const useStyles = makeStyles(() => ({
    boxWrap: {
        padding: 25,
        backgroundColor: theme.palette.background.body,
    },
}));

export default function CalendarPage(){
    const { t } = useTranslation()
    const classes = useStyles()
    const {setClinicData,setLoading} = useContext(ActiveContext);
    const tabNavProps = {
        tabs: [
            {
                id: 0,
                label: t('Calendar'),
            },
            {
                id: 1,
                label: t('Walk-in'),
            },
            {
                id: 2,
                label: t('Patients'),
            },
            {
                id: 3,
                label: t('Cancelled-Booking'),
            },
        ],
    };
    const [tabs, setTabs] = useState(
        <TabNav {...tabNavProps}>
            <TabPanel>
                <Calendar />
            </TabPanel>
            <TabPanel>
                <Ipr walkIn={true} />
            </TabPanel>
            <TabPanel>
                <Patients mallCollaboration={true} />
            </TabPanel>
            <TabPanel>
                <CancelledBooking />
            </TabPanel>
        </TabNav>
    );

    const fetchTabsData = async() => {
        setLoading(true);
        try{
            const response = await axios.get(`admin/v1/clinic/profile?clinic_id=${Cookies.get('clinic_id')}`)
            setClinicData(response.data);
            let clinic = response.data.data.clinic_info;
            if(!clinic?.is_mall_collaboration) {
                let tabs = [
                    {
                        id: 0,
                        label: t('Calendar'),
                    },
                    {
                        id: 1,
                        label: t('Scan-Bookings'),
                    },
                    {
                        id: 2,
                        label: t('Treatment-Procedures'),
                    },
                    {
                        id: 3,
                        label: t('Walk-in'),
                    },
                    {
                        id: 4,
                        label: t('Needs Rescheduling'),
                    },
                    {
                        id: 5,
                        label: t('Patients'),
                    },
                    {
                        id: 6,
                        label: t('Cancelled-Booking'),
                    }
                ];
                setTabs(
                    <TabNav tabs={tabs}>
                        <TabPanel>
                            <Calendar />
                        </TabPanel>
                        <TabPanel>
                            <Appointments />
                        </TabPanel>
                        <TabPanel>
                            <Ipr walkIn={false} />
                        </TabPanel>
                        <TabPanel>
                            <Ipr walkIn={true} />
                        </TabPanel>
                        <TabPanel>
                            <NeedRescheduling />
                        </TabPanel>
                        <TabPanel>
                            <Patients />
                        </TabPanel>
                        <TabPanel>
                            <CancelledBooking />
                        </TabPanel>
                    </TabNav>
                )
            }
          }catch(err){
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchTabsData(); 
    }, [])

    return (
        <Layout>
            <div className={classes.boxWrap}>
                {tabs}
            </div>
       </Layout>
    )
}