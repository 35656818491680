import {
    Box,
    makeStyles,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
    Menu,
    Grid,
    MenuItem,
    Radio,
    CircularProgress,
    RadioGroup,
    InputLabel,
    Select,
    FormControl,
    TextField
} from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState} from 'react'
import EnhancedTable from '../DataTable/EnhancedTable';
import axios from '../../util/axios'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog'
import { useNavigate } from 'react-router-dom';
import Note from '../Note/Note';
import Edit  from '../Note/EditNote' 
import { Alert } from '@mui/material';
import instance from '../../util/axios';
import { TabsControllerContext } from '../../Context/Tabs/TabsProvider';
import "../../App.css";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { clinicImpersonated, convertNumberToArabic, handleReadNotes } from '../../util/functions';
import { toast } from "react-toastify";
import ActiveContext from '../../Context/Context';
import moment from "moment";
import { TableActionsConstants, TableAppointmentsStatues } from "../../util/constants/table-actions.constants";

const useStyles=makeStyles(()=>{ return {
    boxWrapper: {
    },
    fontFamilyAlmarai:{
        fontFamily:'Almarai !important'
    },
    fontFamilyEina: {
        fontFamily: "Eina-semi !important",
    },
    box:{
        display:'flex',
        alignItems:"center",
        justifyContent:"space-around",
        flexWrap:"wrap"
    },
    checkContainer:{
    flex:"1 1 auto",
    margin:0,
    minWidth:'fit-content',
    justifyContent:"center",
    },
    menuBox: {
        display: "flex"
    },
    filterButtonWithArrow: {
        border: '1px lightgray solid',
        borderRadius: 0,
        // borderRight: 'none'
    },
    loadingBox:{
        margin: "0 auto",
        textAlign: "center"
    },
    loading: {
        textAlign: 'center',
        margin: "0 auto",
        color: '#ff2c46'
    },
    appointmentHide:{
        color: '#212B36',
        fontWeight: '400',
        fontSize: '12px!important'
    },
    RadioGroup:{
        "& li":{
            paddingLeft:0
        }
    },
    alertBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    alert: {
        alignItems: 'center',
    },
    searchBar: {
       
        //option 1
        // "&:focus":{
        //     outline:" none !important",
        //     borderColor: "#ff2c46",
        //     boxShadow:" 0 0 10px #ff2c46",
        // },
        // end
        //option 2
        "&:focus":{
            outlineColor: "#ff2c46",
            outlineStyle: "solid",
        },
        '&:focus::WebkitSearchCancelButton':{
            color:"red",
            backgroundColor:"black"
        },
        '&:focus::-ms-clear':{
            color:"red",
            backgroundColor:"black"
        },
        zIndex:10,
        // end
        width: '60%',
        paddingLeft: 10,
        height:41,
        border: '1px lightgray solid',
        '&::placeholder': {
            fontWeight: 400,
          },
          "& label.Mui-focused": {
            color: "#ff2c46"
          },
    },
    filterWrapper: {
        flex:"1 1 auto",
        justifyContent:"center",
        display: "flex",
        padding: 20
    },
    addNote: {
        width:"25%",
        textAlign: 'center',
        marginTop: 10,
        backgroundColor:"#FF2C46",
        float:"right",
        boxSizing:" border-box",
        border: "1px solid #C4CDD5",
        borderRadius: "3px",
        boxShadow:" 0 1px 0 0 rgba(22,29,37,0.05)",
    
     },
     appointment:{
        flex:"1 1 auto",
        display:"inline-block",
        minWidth:'fit-content',
        color: '#9B9BA1',
        fontWeight: "Eina-semi!important",
        fontSize: '14px!important',
        textAlign:'center'
    },
    blackColor: {
        color : '#000'
    }
 } });

export default function Appointments(){
    const {
		actions: { setCurrentTab, setPendingBooking },
	} = useContext(TabsControllerContext);
    const { t, i18n } = useTranslation()
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate()
    const [notificationLoading, setnotificationLoading] = useState(true)
    const[openEditNote,setOpenEditNote]=useState(0)
    const[openAddNote,setOpenAddNote]=useState(0)
    const[notes,setNotes]=useState([])
    const[newNote,setNewNote]=useState("")
    const[editNote,setEditNote]=useState({})
    const classes= useStyles();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [field, setField] = useState('created_at');
    const [sort, setSort] = useState('desc');
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showArchived, setShowArchived] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [singleRow, setSingleRow] = useState({})
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [lostReasons, setLostReasons] = React.useState('')
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const[isScanAtHome, setIsScanAtHome] = useState(false);
    const[products, setProducts] = useState([]);
    const postponedDate = useRef(null);
    const [openActionPostpone, setOpenActionPostpone] = useState({
      open: false,
      data: { value: null, userId: null, scan_id: null },
    });
    const tableActions = TableActionsConstants;
    const {
		search,
        appointmentfilter,
        refreshComment,
        openNote,
		actions: {setOpenNote,setSearchCancelled,setRefreshComment,setSearchPatients,handleSearch,setSearchCalendar,handleKeyPress,setAppointmentfilter,setPatientfilter,setCalendarfilter},
	} = useContext(TabsControllerContext);
    const {
        filesImage,setFilesImage,imagesKey,setImageKey,images,setImages
      } = useContext(ActiveContext);

    const [openLostDialog, setOpenLostDialog] = useState(false);
    const refreshPage = () => {
        window.location.reload();
    }
    const handleConfirmLost = async() => {
        const response = await axios.post(`admin/v1/clinic/${singleRow.user_id}/eligibility/lost/reason`)
        if(response?.data?.eligible){
            const body = {
                lostReason: lostReasons
            }
            await axios.post(`admin/v1/clinic/${singleRow.user_id}/lost/reason`, body)
            setMessage('')
            setOpenAlert(false)
            setOpenLostDialog(false);
        } else {
            setOpenLostDialog(false);
            setMessage(t('This-user-is-not-eligible'))
            setOpenAlert(true)
        }
    }

    const handleOpenLostDialog = (row) => {
        setOpenLostDialog(true);
        setSingleRow(row)
    }

    const handleCloseLostDialog = () => {
        setOpenLostDialog(false);
    }

    const handleChangeReason = (event) => {
        setLostReasons(event.target.value)
    }

    const handleCloseScheduleDialog = () => {
        setOpenScheduleDialog(false);
    }

    const handleOpenScheduleDialog = (row) => {
        setOpenScheduleDialog(true);
        setSingleRow(row)
    }
    
    const handlePostponeDialog = async () => {
        await updatePatientActions(
            openActionPostpone?.data?.value,
            openActionPostpone?.data?.userId,
            openActionPostpone?.data?.scan_id,
            true
        )
        setOpenActionPostpone((prev) => ({data: prev.data, open: false}))
    }

    const handleConfirmSchedule = async(row) => {
        setLoading(true);
        const name = row.first_name ? row.first_name.split(" ") : [];
        let firstName = '';
        let lastName = '';
        if(name.length > 1){
            firstName = name[0];
            lastName = name[1];
        }
        let sentId = row.id ?? '';
        let isAppointment = true;
        let scn = row.scn ?? '';
        const email = row.user_email;
        const phone = row.user_phone;
        const clinicId = Cookies.get('clinic_id');
        const query = `id=${sentId}&isAppointment=${isAppointment}&scn=${scn}&clinicId=${clinicId}&isReschedule=false`;
        const response = await axios.get(`admin/v1/clinic/${clinicId}/reschedule/booking/${row.user_id}?${query}`);
        setLoading(false);
        if (response?.data?.error) {
            setOpenAlert(true);
            setError(true);
            setMessage(t('This-update-is-not-eligible'));
        } else if(response.data.eligible === true) {
            const pendingBookingId = response?.data?.pendingBookingId;
            setPendingBooking({ id: pendingBookingId, isReschedule: false });
            const query = `id=${row.user_id}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}&bas_schedule=true${row.id ? '&usiId=' + row.id : ''}`;
            navigate(`/dashboard/booking?${query}`);
        } else {
            setOpenAlert(true)
            setMessage(t('This-user-is-not-eligible-to-book-a-scan'))
        }
        setOpenScheduleDialog(false);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleTableLoading = (value) => {
        setLoading(value);
    }

    useEffect(async()=>{
        if(Cookies.get('currentTab')){
            setCurrentTab(parseInt(Cookies.get('currentTab')));
        }
        setSearchPatients("");
        setSearchCalendar("");
        setSearchCancelled("");
      let isFetched = false;  
        setLoading(true)
        let newField = null;
        if(field == 'booking_time'){
            newField =  'booking_date';
        }else if(field == 'preferred_time'){
            newField = 'preferred_date';
        }else{
            newField = field;
        }
        try {
        if(!isFetched){
            let filter = handleFilter(appointmentfilter);
            const response = await axios.get(`/admin/v1/clinic/appointments?is_archived=${showArchived ? 1 : 0}&clinic_id=${Cookies.get('clinic_id')}&page=${page}&filter=${filter}&per_page=${perPage}&field=${newField}&type=${sort}&search=${search}`)
            const dat = response.data.data
            setData(dat)
            setTotal(response.data.total)
            let scanAtHome = response.data.is_scan_at_home;
            setIsScanAtHome(scanAtHome);
            if(scanAtHome){
                setProducts(response.data.products.map(prod => ({label: prod.product.alias, value: prod.id})));
            }
            }
        }catch(err){
            console.log(err)
        }finally {
            setLoading(false);
        }
        return () => isFetched = true;
    },[page, perPage, field, sort, appointmentfilter, search, showArchived])
    
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSingleRow(row)
    }
    const menu = [
        { id: 'Confirm', action: () => console.log('edit') },
        { id: 'No Answer', action: () => console.log('delete') },
        { id: 'Reschedule', action: (row) => handleOpenDialog(row) },
        { id: 'Delete', action: () => console.log('delete') },
        { id: 'Cancel', action: () => console.log('delete') },
    ]

    const headCells = [
        {
            id: 'first_name',
            label: t('Patient-name'),
            type: 'appointment_name',
            from:'appointments',
            preferredTime : 'preferred_time',
            preferredDate : 'preferred_date',
        },
        {
            id: 'created_at',
            label: t('Submitted-Date'),
            color:"#a5a5a5",
        }, 
        {
            id: 'added_on',
            label: t('added-on'),
            color:"#a5a5a5",
        },
        {
            id: 'phone_number',
            label: t('Phone-Number'),
            type:"phone",
            hideSort: true,
        },
        // {
        //     id: 'preferred_date',
        //     label: t('Preferred-Date'),
        //     color:"#a5a5a5",
        // },
        // {
        //     id: 'preferred_time',
        //     label: t('Preferred-Time'),
        //     color:"#a5a5a5",
        // },
        {
            id: 'user_sub_status',
            label: t('Status'),
            hideSort: true,
        },
        {
            id: 'user_type',
            label: t('Type'),
            type: 'type',
            hideSort: true,
        },
        {
            id: 'payment_status',
            label: t('payment-status'),
            hideSort: true,
            hideRow: !isScanAtHome,
        },
        {
            id: 'payment_type',
            label: t('payment-type'),
            hideSort: true,
            hideRow: !isScanAtHome,
        },
        {
            id: 'user_action',
            label: t('Action'),
            type: 'menu',
            hideSort: true,
        },
        {
            id: 'unread_comments',
            label: '',
            hideRow: true
        },
    ]

  

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleConfirm = async(row) => {
        const name = row.first_name.split(' ');
        const lastName = name.pop();
        const firstName = name.shift();
        const email = row.user_email;
        const phone = row.user_phone;
        let sentId = row.id ?? '';
        let isAppointment = true;
        let scn = row.scn ?? '';
        const clinicId = Cookies.get('clinic_id');
        const query = `id=${sentId}&isAppointment=${isAppointment}&scn=${scn}&clinicId=${clinicId}&isReschedule=true`;
        const response = await axios.get(`admin/v1/clinic/${clinicId}/reschedule/booking/${row.user_id}?${query}`)
        if (response?.data?.error) {
            setOpenAlert(true);
            setError(true);
            setMessage(t('This-update-is-not-eligible'));
        } else if(response.data.eligible === true) {
            const pendingBookingId = response?.data?.pendingBookingId;
            setPendingBooking({ id: pendingBookingId, isReschedule: true });
            const query = `id=${row.user_id}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}&bas_schedule=true${row.id ? '&usiId=' + row.id : ''}`;
            navigate(`/dashboard/booking?${query}`);
        } else {
            setOpenAlert(true)
            setMessage(t('This-user-is-not-eligible-to-book-a-scan'))
        }
        setOpenDialog(false);
    }

    const updatePatientActions = async (value, userId, scan_id, dialog) => {
      if (value === tableActions.POSTPONE && !dialog) {
        setOpenActionPostpone({ open: true, data: { value, userId, scan_id } });
      } else {
        setLoading(true);
        try {
            let date = null;
            if (value === tableActions.POSTPONE) {
                const dateRef = postponedDate.current;
                const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
                if (dateRef && !dateRef.value) {
                    date = tomorrow;
                } else {
                    date = dateRef.value;
                }
            }
          const response = await instance.put(
            `/admin/v1/clinic/user/${userId}/sub-status/update`,
            {
              action: value,
              clinicId: Cookies.get('clinic_id'),
              scan_id,
              date,
            }
          );
          const dataRes = response.data;
          if (dataRes.success) {
            if (
              dataRes.action_name == 'Confirm' ||
              dataRes.action_name == 'Cancel'
            ) {
              const newSortedRow = data.filter((row) => row.user_id != userId);
              setData(newSortedRow);
            } else {
              const newSortedRow = data.map((row) => {
                if (row.user_id == userId) {
                  return {
                    ...row,
                    user_sub_status: dataRes.user_sub_status,
                    user_action: dataRes.user_action,
                    is_archived: false,
                  };
                }
                return row;
              });
              setData(newSortedRow);
            }
            if (dataRes.action_name == 'Confirm')
              toast.success(t('Appointment-Confirmed-Successfully'), 3000);
          } else {
            setOpenAlert(true);
            setMessage(t('This-user-is-not-eligible-to-book-a-scan'));
          }
          setLoading(false);
        } catch (err) {
          console.log('error', err);
          setOpenAlert(true);
          setMessage(t('This-user-is-not-eligible-to-book-a-scan'));
          setLoading(false);
        }
      }
    };


    useEffect(async()=>{
        if(!openNote){
            setNotes([])
            setOpenEditNote(0)
            setEditNote({})
            return;//not chosen a note
        }
        if(!openEditNote){
            //not chosen a comment to edit but chosen a user to view the note
            try{
            setnotificationLoading(true)
            const res= await axios.get(`/admin/v1/users/${openNote}/comments`)
            if(res.data.success){
                setNotes(res.data.data.data)
                const newData = handleReadNotes(data, openNote, 'unread_comments', false)
                setData(newData)
                setRefreshComment(!refreshComment)

            }
            }catch(err){
                console.log(err)
            }finally{
            setnotificationLoading(false)
            }
            
        }
        else{
            //chosen a comment to edit display it
            try{
                setnotificationLoading(true)
                const res= await axios.get(`/admin/v1/users/${openNote}/comments/${openEditNote}`)
                if(res.data.success){
                    setEditNote(res.data.data.comment)
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
        }
    

    },[openNote,openEditNote,openAddNote])
    const handleCloseNote=()=>{
        if(openEditNote){
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            setNotes([])
            setNewNote("")
            setOpenAddNote(0)
        }
        setFilesImage([])
        setImageKey([])
        setImages([])

    }
    const handleConfirmNote=async()=>{
        if(openEditNote){
            //update before closing edit modal
            let body={comment:editNote,images:imagesKey}
            try{
                setnotificationLoading(true)
                const res= await axios.post(`/admin/v1/users/${openNote}/comments/${openEditNote}`,body)
                if(res.data.success){
                }
                }catch(err){
                    console.log(err)
                }finally{
                setnotificationLoading(false)
                }
            setnotificationLoading(true)
            setOpenEditNote(0)
            setEditNote({})
        }
        else{
            if(newNote){
                let body={comment:newNote,images:imagesKey}
                try{
                    setnotificationLoading(true)
                    const res= await axios.post(`/admin/v1/users/${openNote}/comments`,body)
                    if(res.data.success){
                    console.log('added')
                    }
                    }catch(err){
                        console.log(err)
                    }finally{
                    setOpenAddNote(0)
                    setNewNote("")
                    }
                }
        }
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleOutsideClick=()=>{
        setOpenNote(0)
        setOpenAddNote(0)
        setOpenEditNote(0)
        setNewNote("")
        setEditNote({})
        setFilesImage([])
        setImageKey([])
        setImages([])
    }
    const handleCloseAlert = () => {
        setOpenAlert(false);
        setMessage('')
    }
    if(openAlert){
        window.scroll({
            top:0,
            behavior:"smooth"
        })
    }
    const handleRadioButtonClick = (e)=> { 
        if(e.target.tagName=="INPUT"){
            if(e.target.value==""){
                setAppointmentfilter("");
                setPatientfilter("")
            }
            else{
                let filter = e.target.value;
                setAppointmentfilter(filter);
                setPatientfilter(filter);
            }
            setCalendarfilter("");
            setAnchorEl(null);
        }
    }

    const handleFilter = (filter) => {
        switch(filter) {
            case TableAppointmentsStatues.FIRST_CALL_ATTEMPTED:
                return tableActions.FIRST_CALL_ATTEMPTED;
            case TableAppointmentsStatues.SECOND_CALL_ATTEMPTED:
                return tableActions.SECOND_CALL_ATTEMPTED;
            case TableAppointmentsStatues.WHATSAPP_SENT:
                return tableActions.WHATSAPP_SENT;
            default:
                return filter;
        }
    }

    return (
        (loading || !page )? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box> : <Box className={classes.boxWrapper}>
            {openAlert && (
                <Alert
                    open={openAlert}
                    className={classes.alert}
                    severity="error"
                    onClose={handleCloseAlert}
                >
                    <Box className={classes.alertBox}>
                        {message}
                        {error && (
                            <Button variant="outlined" onClick={refreshPage} color="inherit" size="small">
                                {t('refresh')}
                            </Button>
                        )}
                    </Box>
                </Alert>
            )}
            <Box className={classes.box}>
            <Grid className={classes.filterWrapper} item lg={12} xs={12}>
                <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className={classes.filterButtonWithArrow}
                >
                    {
                        !appointmentfilter ? 
                            t('Filter') :
                            appointmentfilter.includes(' ') ?
                                (appointmentfilter.replace(' ', '-').length >= 15 ? t(appointmentfilter.replace(' ', '-')).replace(t(appointmentfilter.replace(' ', '-')).substr(15), '...') : t(appointmentfilter.replace(' ', '-'))) :
                                (appointmentfilter.length >= 15 ? t(appointmentfilter).replace(t(appointmentfilter).substr(15), '...') : t(appointmentfilter))
                    }
                    <ArrowDropDownIcon/>
                </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        // onClick={}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        > 
                        <RadioGroup name="radio-buttons-group" className={classes.RadioGroup}>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={''} checked={appointmentfilter == ''? true :false}/>{t('All')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Pending'}  checked={appointmentfilter == 'Pending'? true :false}/>{t('Pending')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={TableAppointmentsStatues.FIRST_CALL_ATTEMPTED} checked={appointmentfilter == TableAppointmentsStatues.FIRST_CALL_ATTEMPTED ? true :false}/>{t('Pending-(First Call Attempted)')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={TableAppointmentsStatues.SECOND_CALL_ATTEMPTED} checked={appointmentfilter == TableAppointmentsStatues.SECOND_CALL_ATTEMPTED ? true :false}/>{t('Pending-(Second Call Attempted)')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={TableAppointmentsStatues.WHATSAPP_SENT} checked={appointmentfilter == TableAppointmentsStatues.WHATSAPP_SENT ? true :false}/>{t('Pending-(WhatsApp Sent)')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'No Answer'}  checked={appointmentfilter == 'No Answer'? true :false}/>{t('No-Answer')}</MenuItem></InputLabel>
                       <InputLabel className={classes.blackColor}><MenuItem ><Radio onClick={handleRadioButtonClick} value={'Postponed'}   checked={appointmentfilter == 'Postponed'? true :false}/>{t('Postponed')}</MenuItem></InputLabel>
                       </RadioGroup>
                    </Menu>
                        <input 
                            type='search'
                            id="searchstyle"
                            className={classes.searchBar}
                            placeholder={t('Search')}
                            defaultValue={search}
                            onKeyPress={(e) => handleKeyPress(e)}
                            onChange={(e) => handleSearch(e)}
                            // children={<h5>X</h5>}
                        />
                        

                </Grid>
            <Typography variant='body1' className={classes.appointment} >
                {convertNumberToArabic( t, total)} {t('Appointment')}{total === 0 || total > 1 ? i18n.language == 'ar' ? '' : 's' : ''}
            </Typography>
            <FormControlLabel className={classes.checkContainer} control={<Checkbox checked={showArchived} onClick={()=>{
                setShowArchived((prev)=>{
                    return !prev;
                })
            }}/>}  label={<Typography variant='body1' className={`${classes.appointmentHide} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} >
                {t('Show-archived-appointments')}
            </Typography>} />
            </Box>
           <Box>
           {data && <EnhancedTable
                updatePatientActions={updatePatientActions}
                rowsData={data}
                handleOpenDialog={handleConfirm}
                handleOpenScheduleDialog={handleConfirmSchedule}
                handleTableLoading={handleTableLoading}
                headCells={headCells}
                menu={menu}
                setPage={setPage}
                perPage={perPage}
                page={page}
                handleOpenLostDialog={handleOpenLostDialog}
                field={field}
                setField={setField}
                sort={sort}
                setSort={setSort}
                total={total}
                setPerPage={setPerPage}
                setOpenNote={setOpenNote}
                products={products}
                isScanAtHome={isScanAtHome}
                ></EnhancedTable>}
           </Box>
            {/* Postponed Dialog */}
            <ConfirmationDialog
                open={openActionPostpone.open}
                handleConfirm={handlePostponeDialog}
                dialogTitle={t('postpone-dialog-title')}
                confirmationButtonText={t('Postpone')}
                isWarning={true}
                handleClose={() =>
                    setOpenActionPostpone((prev) => ({ data: prev.data, open: false }))
                }
                confirmBtnStyle={{ width: 'fit-content' }}
            >
                <TextField
                    id="date"
                    label={t("postpone-dialog-date-label")}
                    type="date"
                    fullWidth
                    inputRef={postponedDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </ConfirmationDialog>
           <ConfirmationDialog
            open={openDialog}
            handleClose={handleCloseDialog}
            handleConfirm={handleConfirm}
            confirmationButtonText={t('Confirm')}
            cancelButtonText={t('Cancel')}
            dialogTitle={t('Reschedule')}
            dialogContentText={t('Are-you-sure-you-want-to-reschedule?')}
            
           />
           <ConfirmationDialog
                open={openScheduleDialog}
                handleClose={handleCloseScheduleDialog}
                handleConfirm={handleConfirmSchedule}
                confirmationButtonText={t('Confirm')}
                cancelButtonText={t('Cancel')}
                dialogTitle={t('Schedule')}
                dialogContentText={t('Are-you-sure-you-want-to-Schedule?')}
            
           />
            <ConfirmationDialog
                    open={openLostDialog}
                    handleClose={handleCloseLostDialog}
                    handleConfirm={handleConfirmLost}
                    confirmationButtonText={t('Confirm')}
                    cancelButtonText={t('Cancel')}
                    dialogTitle={t('Lost Reasons')}
                    dialogContentText={ 
                    <FormControl style={{width:"100%"}}>
                        <Select variant='outlined' fullWidth
                            labelId="demo-select-small"
                            displayEmpty
                            renderValue={
                                !lostReasons ? () => <span>Select Lost Reason</span> : undefined
                              }
                            id="demo-select-small"
                            value={lostReasons}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleChangeReason}
                            >
                                <MenuItem value="">
                                    <em>Select Lost Reason</em>
                                </MenuItem>
                                <MenuItem value={'BAS - Lead Stage - No Reply'}>No Reply</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Wrong Number'}>Wrong Number</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Closed Number'}>Closed Number</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Invalid Number'}>Invalid Number</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Not Serious'}>Not Serious</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Not Interested'}>Not Interested</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Busy'}>Busy</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Incomplete Info'}>Incomplete Info</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Underage'}>Underage</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Difficult Case'}>Difficult Case</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Unhealthy Teeth/Gums'}>Unhealthy Teeth/Gums</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Country Not Covered'}>Country Not Covered</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Clinic Too Far'}>Clinic Too Far</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Cannot Afford Treatment'}>Cannot Afford Treatment</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Did Not Understand What They Booked'}>Did Not Understand What They Booked</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Looking For Other Dental Services'}>Looking For Other Dental Services</MenuItem>
                                <MenuItem value={'BAS - Lead Stage - Looking For Metal Braces'}>Looking For Metal Braces</MenuItem>
                        </Select>
                    </FormControl>
                    }
                />
             <ConfirmationDialog  open={openNote>0}
                 handleOutsideClick={()=>{handleOutsideClick()}}
                 handleClose={()=>{handleCloseNote()}}
                 handleConfirm={()=>{handleConfirmNote()}}
                 confirmationButtonText={openEditNote||openAddNote ? t("Save")  : ""}
                 cancelButtonText=  {openEditNote||openAddNote ? t("Cancel")  : ""}  
                 dialogTitle= {openEditNote ? t("Edit-Note")  :openAddNote? t("Add-Note") : t("Notes") }  
                 dialogContentText=""
                 notes={true}
                 loading={notificationLoading}
                  > {openEditNote  ? 
                  <Edit data={editNote} setData={setEditNote} />
                  : openAddNote ? <Edit data={newNote} setData={setNewNote} />:<><Note setOpenEditNote={setOpenEditNote} data={notes} setnotificationLoading={setnotificationLoading}/>
                   <Button disabled={clinicImpersonated()} className={`${classes.addNote} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} color="secondary" variant='contained' onClick={()=>{setOpenAddNote(1)}}>{t('Add-Note')}</Button></>}</ConfirmationDialog>
                   
        </Box>
    )
}