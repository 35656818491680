import React from "react";
import { Typography } from "@mui/material";
import parse from "react-html-parser";

const AttachmentsAndButtonsComponent = ({ align, isArabic, t }) => {
  const unorderedListStyle = isArabic
    ? {
        margin: "10px 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "10px 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };

  const divStyles = {
    margin: "15px 0",
    textAlign: align,
    padding: "10px",
  };

  const title = { fontSize: "14px", mb: 2 };

  return (
    <div className="learning_container">
      <Typography sx={divStyles} variant="div" component="div">
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_77")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          {t("ipr_faq_78")}
          <br />
          <br />
          {t("ipr_faq_79")}
          <br />
          <br />
          {t("ipr_faq_80")}
        </Typography>
        <br />
        <br />
        <ul style={unorderedListStyle}>
          <li>
            <Typography
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold", fontSize: "18px" }}
              component="h1"
            >
              {t("ipr_faq_81")}
            </Typography>
          </li>
        </ul>
        <Typography sx={title} variant="span" component="span">
          {parse(t("ipr_faq_82"))}
        </Typography>
      </Typography>
    </div>
  );
};

export default AttachmentsAndButtonsComponent;
