import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => {
  return {
    loadingBox: {
      margin: "12% auto",
      textAlign: "center",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
  };
});
